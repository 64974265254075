<div class="container-fluid"
     style="background-color: white; padding-left: 0px; padding-right: 0px;">
  <div class="row no-gutters" style="height: 70px;">
    <div
      class="col-12 no-gutters d-flex align-items-center justify-content-start">
      <div class="container-fluid clickable" (click)="open_project_picker()">
        <div class="row">
          <div class="col-12 v_text_descriptive">
            {{project_name}}
          </div>
        </div>
        <div class="row">
          <div class="col-12 main_information">
            {{_project_code || 'label.chose_project' | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
