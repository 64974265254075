/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { releaseTask } from '../fn/tsa-release-task/release-task';
import { ReleaseTask$Params } from '../fn/tsa-release-task/release-task';
import { TsmReleaseTaskResponse } from '../models/tsm-release-task-response';

@Injectable({ providedIn: 'root' })
export class TsaReleaseTaskService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `releaseTask()` */
  static readonly ReleaseTaskPath = '/api/tsk/task/{id}/release';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `releaseTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  releaseTask$Response(params: ReleaseTask$Params, context?: HttpContext): Observable<StrictHttpResponse<TsmReleaseTaskResponse>> {
    return releaseTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `releaseTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  releaseTask(params: ReleaseTask$Params, context?: HttpContext): Observable<TsmReleaseTaskResponse> {
    return this.releaseTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TsmReleaseTaskResponse>): TsmReleaseTaskResponse => r.body)
    );
  }

}
