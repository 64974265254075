/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createAreaInJobGroup1 } from '../fn/pra-job-group-area-delete-write/create-area-in-job-group-1';
import { CreateAreaInJobGroup1$Params } from '../fn/pra-job-group-area-delete-write/create-area-in-job-group-1';
import { PrmJobGroupAreaDeleteResponse } from '../models/prm-job-group-area-delete-response';

@Injectable({ providedIn: 'root' })
export class PraJobGroupAreaDeleteWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createAreaInJobGroup1()` */
  static readonly CreateAreaInJobGroup1Path = '/api/prj/job/group/{jobNo}/area/{areaNo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAreaInJobGroup1()` instead.
   *
   * This method doesn't expect any request body.
   */
  createAreaInJobGroup1$Response(params: CreateAreaInJobGroup1$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobGroupAreaDeleteResponse>> {
    return createAreaInJobGroup1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAreaInJobGroup1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createAreaInJobGroup1(params: CreateAreaInJobGroup1$Params, context?: HttpContext): Observable<PrmJobGroupAreaDeleteResponse> {
    return this.createAreaInJobGroup1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobGroupAreaDeleteResponse>): PrmJobGroupAreaDeleteResponse => r.body)
    );
  }

}
