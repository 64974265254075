import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from './dialog-data';

@Component({
  selector: 'app-yesnocancel-dialog',
  templateUrl: './yesnocancel-dialog.component.html',
  styleUrls: ['./yesnocancel-dialog.component.scss'],
})
export class YesnocancelDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<YesnocancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close('no');
  }
  onYesClick(): void {
    this.dialogRef.close('yes');
  }
  onCancelClick(): void {
    this.dialogRef.close('cancel');
  }
}
