/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { categoryRead } from '../fn/report-site-result-filtered-read/category-read';
import { CategoryRead$Params } from '../fn/report-site-result-filtered-read/category-read';
import { NvmQueryReportSiteResultResponse } from '../models/nvm-query-report-site-result-response';

@Injectable({ providedIn: 'root' })
export class ReportSiteResultFilteredReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `categoryRead()` */
  static readonly CategoryReadPath = '/api/nav/result/{category}';

  /**
   * Read configurable report with filters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `categoryRead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  categoryRead$Response(params: CategoryRead$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryReportSiteResultResponse>> {
    return categoryRead(this.http, this.rootUrl, params, context);
  }

  /**
   * Read configurable report with filters.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `categoryRead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  categoryRead(params: CategoryRead$Params, context?: HttpContext): Observable<NvmQueryReportSiteResultResponse> {
    return this.categoryRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryReportSiteResultResponse>): NvmQueryReportSiteResultResponse => r.body)
    );
  }

}
