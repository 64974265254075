var SHOW_LOGO_ATTRIBUTE = true;
var SHOW_MENU_ATTRIBUTE = false;

export class SystemConfiguration {
  public report: ConfigurationReport = new ConfigurationReport();
  public timesheet: ConfigurationTimesheet = new ConfigurationTimesheet();

  public showLogo() {
    return false;
  }

  public showMenu(): boolean {
    return true;
  }
}

export class ConfigurationReport {
  public show_foreman = false;
}

export class ConfigurationTimesheet {
  public show_foreman = false;
}
