import { PrmJobReadResponse } from './../../api/prj/models/prm-job-read-response';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AcmAccountQueryAccount } from 'src/app/api/acc/models';
import { PrmJobCreateRequest } from 'src/app/api/prj/models';
import {
  PraJobWriteService,
  PraProjectReadService,
} from 'src/app/api/prj/services';
import { ErrorHandlerService } from 'src/app/core/service/error-handler.service';
import { SnackMessageService } from 'src/app/core/service/snack-message.service';
import { User } from 'src/app/core/service/user/user';
import { AppState } from 'src/app/core/state/app-state';
import { UserSelectDialogComponent } from '../user-select-dialog/user-select-dialog.component';
import { isNullOrUndefined } from '../utilities';
import { DictionaryReadService as DictionaryReadService2 } from 'src/app/api/dic/services';
import { YesnocancelDialogComponent } from '../yesnocancel-dialog/yesnocancel-dialog.component';

@Component({
  selector: 'app-add-edit-project-dialog',
  templateUrl: './add-edit-project-dialog.component.html',
  styleUrls: ['./add-edit-project-dialog.component.scss'],
})
export class AddEditProjectDialogComponent implements OnInit {
  hasDataChanged = false;
  bottom_space = false;
  job: PrmJobReadResponse;
  form: FormGroup;
  isMobile = false;
  formKB: string;
  formPM: string;
  stateList: any;
  formDR: string;
  formDesigner: AcmAccountQueryAccount;
  statusList;
  categoryList;
  mainCatList;
  departmentList;
  marketList;
  technologyList;
  @ViewChild('name') nameField: ElementRef;
  private _user: User;
  private user_login = '';

  constructor(
    public dialogRef2: MatDialog,
    private _projectReadService: PraProjectReadService = undefined,
    private _dictionaryReadDic: DictionaryReadService2,
    private _errorHandler: ErrorHandlerService,
    private _snackMessage: SnackMessageService,
    private _praJobWriteService: PraJobWriteService,
    private _store: Store<AppState>,
    public dialogRef: MatDialogRef<AddEditProjectDialogComponent>,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _deviceService: DeviceDetectorService,
  ) {
    this.isMobile = this._deviceService.isMobile();
  }

  dataChanged() {
    this.hasDataChanged = true;
  }

  ngOnInit() {
    this.getDictionary('JOB_WIP_STATUS');
    this.getDictionary('DEPARTMENT_CODE');
    this.getDictionary('MARKET_CODE');
    this.getDictionary('TECHNOLOGY_CODE');
    this.getDictionary('JOB_CATEGORY_MAP');

    this._store.select('user').subscribe((user: User) => {
      if (user.logged()) {
        this._user = user;
        if (isNullOrUndefined(this.user_login) || this.user_login === '') {
          this.user_login = user.login();
        }
      }
    });

    this.form = this._formBuilder.group({
      status: [{ value: 'PROGRESS', disabled: true }],
      jobNo: [
        '',
        { Validators: [Validators.required, Validators.minLength(3)] },
      ],
      customerNo: '',
      customerName: '',
      CRM: '',
      jobName: '',
      jobCat: ['', { Validators: [Validators.required] }],
      technologyCode: ['', { Validators: [Validators.required] }],
      marketCode: ['', { Validators: [Validators.required] }],
      departmentCode: ['', { Validators: [Validators.required] }],
      kb: '',
      PM: '',
      DR: '',
      Designer: ''
    });

    if (this.data.mode == 'edit') {
      this.form = this._formBuilder.group({
        status: this.data.item.job.status,
        jobNo: [
          { value: this.data.item.job.no, disabled: true },
          {
            Validators: [
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(30),
            ],
          },
        ],
        customerNo: this.data.item.job.customer.no,
        customerName: this.data.item.job.customer.name,
        CRM: this.data.item.job.references[0].value,
        jobName: this.data.item.job.name,
        jobCat: [
          this.data.item.job.parameters.category,
          { Validators: [Validators.required] },
        ],
        technologyCode: [
          this.data.item.job.parameters.technologyCode,
          { Validators: [Validators.required] },
        ],
        marketCode: [
          this.data.item.job.parameters.marketCode,
          { Validators: [Validators.required] },
        ],
        departmentCode: [
          this.data.item.job.department.no,
          { Validators: [Validators.required] },
        ],
        kb: this.findUserForm('responsible'),
        PM: this.findUserForm('supervisor'),
        DR: this.findUserForm('sales_person'),
        Designer: '',
      });
    }
  }

  findUserForm(role) {
    for (const user of this.data.item.job.users) {
      if (user.role == role) {
        if (isNullOrUndefined(user.account)) {
          return '';
        }
        return user.account;
      }
    }
    return '';
  }

  close(): void {
    if (!this.hasDataChanged) {
      this.dialogRef.close('noRefresh');
    } else {
      const yesnoDialog = this.dialogRef2.open(YesnocancelDialogComponent, {
        data: {
          title: 'confirmation.title',
          content: 'message.inquiry.save',
        },
      });

      yesnoDialog.afterClosed().subscribe((result) => {
        if (result === 'yes') {
          this.saveProject();
        } else if (result === 'no') {
          this.dialogRef.close('noRefresh');
        }
      });
    }
  }

  showScrollSpace() {
    this.bottom_space = true;
  }

  hideScrollSpace() {
    this.bottom_space = false;
  }

  public clear_field(field: string): void {
    this.form.get(field).setValue('');
  }

  public select_user(fieldName: string): void {
    let userRole: any;
    if (fieldName == 'kb')
      userRole = [
        'SITEMANAGER',
        'SITEENGINEER',
        'SITEENGINEER GC',
        'SITEMANAGER GC',
        'SITEMANAGER CZ',
        'SITEENGINEER CZ',
        'MANAGER',
      ];
    if (fieldName == 'PM') userRole = ['MANAGER'];
    if (fieldName == 'DR') userRole = ['SALES'];
    if (fieldName == 'Designer') userRole = ['DESIGN'];

    let h, w, maxh, maxw;
    if (this.isMobile) {
      (maxw = '95vw'), (maxh = '90vh'), (w = '95%'), (h = '90%');
    } else {
      (maxw = '600px'), (maxh = '800px'), (h = '100%'), (w = '600px');
    }
    const dialogRef = this.dialog.open(UserSelectDialogComponent, {
      maxWidth: maxw,
      maxHeight: maxh,
      height: h,
      width: w,
      panelClass: 'mat-dialog-container-custom',
      data: { current_user: this.user_login, role: userRole },
    });

    dialogRef.afterClosed().subscribe((result: AcmAccountQueryAccount) => {
      if (!isNullOrUndefined(result)) {
        this.form.get(fieldName).setValue(result.login.toUpperCase());
      }
    });
  }

  public validatedRequest() {
    this.form.updateValueAndValidity();

    return this.form.valid;
  }

  public createNewProject() {
    if (this.validatedRequest()) this.saveProject();
  }

  private saveProject(): void {
    if (this.data.mode == 'edit') {
      this._praJobWriteService
        .update({
          body: this.getFormResult(),
          no: this.form.get('jobNo').value,
        })
        .subscribe(
          (data) => {
            if (data.result.code == 'SUCCESS') {
              this.dialogRef.close();
            } else {
              this._snackMessage.communicateError(
                this._errorHandler.errorCode(data.result.description),
              );
            }
          },
          (error: any) => {
            this._snackMessage.communicateError(
              this._errorHandler.errorCode(error),
            );
          },
        );
    } else {
      this._praJobWriteService
        .create3({ body: this.getFormResult() })
        .subscribe(
          (data) => {
            if (data.result.code == 'SUCCESS') {
              this.dialogRef.close();
            }
          },
          (error: any) => {
            this._snackMessage.communicateError(
              this._errorHandler.errorCode(error),
            );
          },
        );
    }
  }

  getFormResult(): PrmJobCreateRequest {
    let kb, dr, pm, designer;
    if (!isNullOrUndefined(this.form.get('kb').value)) {
      kb = this.form.get('kb').value;
    } else {
      kb = '';
    }
    if (!isNullOrUndefined(this.form.get('DR').value)) {
      dr = this.form.get('DR').value;
    } else {
      dr = '';
    }
    if (!isNullOrUndefined(this.form.get('PM').value)) {
      pm = this.form.get('PM').value;
    } else {
      pm = '';
    }
    if (!isNullOrUndefined(this.form.get('Designer').value)) {
      designer = this.form.get('Designer').value;
    } else {
      designer = '';
    }

    const formResult: PrmJobCreateRequest = {
      job: {
        no: this.form.get('jobNo').value,
        name: this.form.get('jobName').value,
        status: this.form.get('status').value,
        parameters: {
          category: this.form.get('jobCat').value,
          technologyCode: this.form.get('technologyCode').value,
          marketCode: this.form.get('marketCode').value,
          mainCategory: this.categoryList.find(
            (x) => x.code === this.form.get('jobCat').value,
          ).label,
        },
        customer: {
          no: this.form.get('customerNo').value,
          name: this.form.get('customerName').value,
        },
        users: [
          { account: kb, role: 'responsible' },
          { account: dr, role: 'sales_person' },
          { account: pm, role: 'supervisor' },
          { account: designer, role: 'designer' },
        ],
        department: { no: this.form.get('departmentCode').value },
        references: [
          { type: 'CRMReference', value: this.form.get('CRM').value },
        ],
      },
    };
    return formResult;
  }

  getDictionary(typeName) {
    this._dictionaryReadDic
      .dictionaryReadUsingGET({
        type: typeName,
      })
      .subscribe(
        (result) => {
          if (typeName == 'JOB_CATEGORY_MAP') {
            this.categoryList = result.items;
          }
          if (typeName == 'JOB_WIP_STATUS') {
            this.stateList = result.items;
          }
          if (typeName == 'DEPARTMENT_CODE') {
            this.departmentList = result.items;
          }
          if (typeName == 'MARKET_CODE') {
            this.marketList = result.items;
          }
          if (typeName == 'TECHNOLOGY_CODE') {
            this.technologyList = result.items;
          }
          if (typeName == 'JOB_MAIN_CATEGORY') {
            this.mainCatList = result.items;
          }
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error),
          );
        },
      );
  }
}
