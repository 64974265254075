/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AcmAccountImpersonateResponse } from '../models/acm-account-impersonate-response';
import { impersonate } from '../fn/aca-account-impersonate/impersonate';
import { Impersonate$Params } from '../fn/aca-account-impersonate/impersonate';

@Injectable({ providedIn: 'root' })
export class AcaAccountImpersonateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `impersonate()` */
  static readonly ImpersonatePath = '/api/acc/impersonate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `impersonate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  impersonate$Response(params: Impersonate$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountImpersonateResponse>> {
    return impersonate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `impersonate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  impersonate(params: Impersonate$Params, context?: HttpContext): Observable<AcmAccountImpersonateResponse> {
    return this.impersonate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountImpersonateResponse>): AcmAccountImpersonateResponse => r.body)
    );
  }

}
