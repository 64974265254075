/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CnmRelatedTopicsReadResponse } from '../models/cnm-related-topics-read-response';
import { CnmListContextReadResponse } from '../models/cnm-list-context-read-response';

/**
 * Cna List Context Read
 */
@Injectable({
  providedIn: 'root',
})
class ContextHelpReadService extends __BaseService {
  static readonly relatedTopicsReadUsingGETPath = '/api/ctx/context/related';
  static readonly listContextUsingGETPath = '/api/ctx/contexts';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * relatedTopicsRead
   * @param params The `ContextHelpReadService.RelatedTopicsReadUsingGETParams` containing the following parameters:
   *
   * - `path`: path
   *
   * - `language`: language
   *
   * - `header`: header
   *
   * @return OK
   */
  relatedTopicsReadUsingGETResponse(params: ContextHelpReadService.RelatedTopicsReadUsingGETParams): __Observable<__StrictHttpResponse<CnmRelatedTopicsReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.path != null) __params = __params.set('path', params.path.toString());
    if (params.language != null) __params = __params.set('language', params.language.toString());
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ctx/context/related`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CnmRelatedTopicsReadResponse>;
      })
    );
  }
  /**
   * relatedTopicsRead
   * @param params The `ContextHelpReadService.RelatedTopicsReadUsingGETParams` containing the following parameters:
   *
   * - `path`: path
   *
   * - `language`: language
   *
   * - `header`: header
   *
   * @return OK
   */
  relatedTopicsReadUsingGET(params: ContextHelpReadService.RelatedTopicsReadUsingGETParams): __Observable<CnmRelatedTopicsReadResponse> {
    return this.relatedTopicsReadUsingGETResponse(params).pipe(
      __map(_r => _r.body as CnmRelatedTopicsReadResponse)
    );
  }

  /**
   * listContext
   * @param params The `ContextHelpReadService.ListContextUsingGETParams` containing the following parameters:
   *
   * - `path`: path
   *
   * - `language`: language
   *
   * - `header`: header
   *
   * @return OK
   */
  listContextUsingGETResponse(params: ContextHelpReadService.ListContextUsingGETParams): __Observable<__StrictHttpResponse<CnmListContextReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.path != null) __params = __params.set('path', params.path.toString());
    if (params.language != null) __params = __params.set('language', params.language.toString());
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ctx/contexts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CnmListContextReadResponse>;
      })
    );
  }
  /**
   * listContext
   * @param params The `ContextHelpReadService.ListContextUsingGETParams` containing the following parameters:
   *
   * - `path`: path
   *
   * - `language`: language
   *
   * - `header`: header
   *
   * @return OK
   */
  listContextUsingGET(params: ContextHelpReadService.ListContextUsingGETParams): __Observable<CnmListContextReadResponse> {
    return this.listContextUsingGETResponse(params).pipe(
      __map(_r => _r.body as CnmListContextReadResponse)
    );
  }
}

module ContextHelpReadService {

  /**
   * Parameters for relatedTopicsReadUsingGET
   */
  export interface RelatedTopicsReadUsingGETParams {

    /**
     * path
     */
    path: string;

    /**
     * language
     */
    language: string;

    /**
     * header
     */
    header?: string;
  }

  /**
   * Parameters for listContextUsingGET
   */
  export interface ListContextUsingGETParams {

    /**
     * path
     */
    path: string;

    /**
     * language
     */
    language: string;

    /**
     * header
     */
    header?: string;
  }
}

export { ContextHelpReadService }
