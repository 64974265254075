/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReadRoles200ResponseDto } from '../../models/read-roles-200-response-dto';

export interface ReadRoles$Params {

/**
 * Sort results by the field, direction-field, direction asc, desc
 */
  sort?: Array<string>;

/**
 * Number of results on a page
 */
  max?: number;

/**
 * Page number to read, first page is 1
 */
  page?: number;

/**
 * Filter to query the backedn, each entry has three parts name-comparator-value, to search entries for user Jan we can use name-eq-Jan. Available fields : site_role, user_login, user_name, user_surname, role, sys_dt_create (from/to). Available comparators are: eq, ne, gt, ge, lt, le, contains
 */
  filter?: Array<string>;
}

export function readRoles(http: HttpClient, rootUrl: string, params?: ReadRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<ReadRoles200ResponseDto>> {
  const rb = new RequestBuilder(rootUrl, readRoles.PATH, 'get');
  if (params) {
    rb.query('sort', params.sort, {});
    rb.query('max', params.max, {});
    rb.query('page', params.page, {});
    rb.query('filter', params.filter, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ReadRoles200ResponseDto>;
    })
  );
}

readRoles.PATH = '/api/acc/role';
