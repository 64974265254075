/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countUnaccepted } from '../fn/pra-project-read/count-unaccepted';
import { CountUnaccepted$Params } from '../fn/pra-project-read/count-unaccepted';
import { PrmCountUnacceptedResponse } from '../models/prm-count-unaccepted-response';
import { PrmProjectQueryResponse } from '../models/prm-project-query-response';
import { PrmProjectReadResponse } from '../models/prm-project-read-response';
import { PrmProjectsReadAssignedResponse } from '../models/prm-projects-read-assigned-response';
import { query } from '../fn/pra-project-read/query';
import { Query$Params } from '../fn/pra-project-read/query';
import { readAssignedProjects } from '../fn/pra-project-read/read-assigned-projects';
import { ReadAssignedProjects$Params } from '../fn/pra-project-read/read-assigned-projects';
import { readResponseResponseEntity } from '../fn/pra-project-read/read-response-response-entity';
import { ReadResponseResponseEntity$Params } from '../fn/pra-project-read/read-response-response-entity';

@Injectable({ providedIn: 'root' })
export class PraProjectReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `countUnaccepted()` */
  static readonly CountUnacceptedPath = '/api/prj/timesheets/unaccepted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countUnaccepted()` instead.
   *
   * This method doesn't expect any request body.
   */
  countUnaccepted$Response(params?: CountUnaccepted$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmCountUnacceptedResponse>> {
    return countUnaccepted(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countUnaccepted$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countUnaccepted(params?: CountUnaccepted$Params, context?: HttpContext): Observable<PrmCountUnacceptedResponse> {
    return this.countUnaccepted$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmCountUnacceptedResponse>): PrmCountUnacceptedResponse => r.body)
    );
  }

  /** Path part for operation `query()` */
  static readonly QueryPath = '/api/prj/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `query()` instead.
   *
   * This method doesn't expect any request body.
   */
  query$Response(params: Query$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmProjectQueryResponse>> {
    return query(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `query$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  query(params: Query$Params, context?: HttpContext): Observable<PrmProjectQueryResponse> {
    return this.query$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmProjectQueryResponse>): PrmProjectQueryResponse => r.body)
    );
  }

  /** Path part for operation `readAssignedProjects()` */
  static readonly ReadAssignedProjectsPath = '/api/prj/projects/assigned/{account}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readAssignedProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  readAssignedProjects$Response(params: ReadAssignedProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmProjectsReadAssignedResponse>> {
    return readAssignedProjects(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readAssignedProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readAssignedProjects(params: ReadAssignedProjects$Params, context?: HttpContext): Observable<PrmProjectsReadAssignedResponse> {
    return this.readAssignedProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmProjectsReadAssignedResponse>): PrmProjectsReadAssignedResponse => r.body)
    );
  }

  /** Path part for operation `readResponseResponseEntity()` */
  static readonly ReadResponseResponseEntityPath = '/api/prj/project/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readResponseResponseEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  readResponseResponseEntity$Response(params: ReadResponseResponseEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmProjectReadResponse>> {
    return readResponseResponseEntity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readResponseResponseEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readResponseResponseEntity(params: ReadResponseResponseEntity$Params, context?: HttpContext): Observable<PrmProjectReadResponse> {
    return this.readResponseResponseEntity$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmProjectReadResponse>): PrmProjectReadResponse => r.body)
    );
  }

}
