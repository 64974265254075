/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CmmMailResponse } from '../models/cmm-mail-response';
import { CmmMailRequest } from '../models/cmm-mail-request';
import { CmmMessageResponse } from '../models/cmm-message-response';
import { CmmMessageRequest } from '../models/cmm-message-request';
import { CmmMessageWriteDeleteResponse } from '../models/cmm-message-write-delete-response';
import { CmmMessageWriteReadResponse } from '../models/cmm-message-write-read-response';
import { CmmMessageWriteCleanResponse } from '../models/cmm-message-write-clean-response';

/**
 * Cma Message Write Delete
 */
@Injectable({
  providedIn: 'root',
})
class MessageWriteService extends __BaseService {
  static readonly mailUsingPOSTPath = '/api/cmm/mail';
  static readonly sendUsingPOSTPath = '/api/cmm/message';
  static readonly deleteMessageUsingDELETEPath = '/api/cmm/message/{identifier}';
  static readonly readUsingPUTPath = '/api/cmm/message/{identifier}/read';
  static readonly readUsingDELETEPath = '/api/cmm/messages/{recipient}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * mail
   * @param params The `MessageWriteService.MailUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `header`: header
   *
   * @return OK
   */
  mailUsingPOSTResponse(params: MessageWriteService.MailUsingPOSTParams): __Observable<__StrictHttpResponse<CmmMailResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/cmm/mail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmMailResponse>;
      })
    );
  }
  /**
   * mail
   * @param params The `MessageWriteService.MailUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `header`: header
   *
   * @return OK
   */
  mailUsingPOST(params: MessageWriteService.MailUsingPOSTParams): __Observable<CmmMailResponse> {
    return this.mailUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as CmmMailResponse)
    );
  }

  /**
   * Send a message to system defined person
   * @param params The `MessageWriteService.SendUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `header`: header
   *
   * @return OK
   */
  sendUsingPOSTResponse(params: MessageWriteService.SendUsingPOSTParams): __Observable<__StrictHttpResponse<CmmMessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/cmm/message`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmMessageResponse>;
      })
    );
  }
  /**
   * Send a message to system defined person
   * @param params The `MessageWriteService.SendUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `header`: header
   *
   * @return OK
   */
  sendUsingPOST(params: MessageWriteService.SendUsingPOSTParams): __Observable<CmmMessageResponse> {
    return this.sendUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as CmmMessageResponse)
    );
  }

  /**
   * deleteMessage
   * @param params The `MessageWriteService.DeleteMessageUsingDELETEParams` containing the following parameters:
   *
   * - `identifier`: identifier
   *
   * - `header`: header
   *
   * @return OK
   */
  deleteMessageUsingDELETEResponse(params: MessageWriteService.DeleteMessageUsingDELETEParams): __Observable<__StrictHttpResponse<CmmMessageWriteDeleteResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/cmm/message/${encodeURIComponent(String(params.identifier))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmMessageWriteDeleteResponse>;
      })
    );
  }
  /**
   * deleteMessage
   * @param params The `MessageWriteService.DeleteMessageUsingDELETEParams` containing the following parameters:
   *
   * - `identifier`: identifier
   *
   * - `header`: header
   *
   * @return OK
   */
  deleteMessageUsingDELETE(params: MessageWriteService.DeleteMessageUsingDELETEParams): __Observable<CmmMessageWriteDeleteResponse> {
    return this.deleteMessageUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as CmmMessageWriteDeleteResponse)
    );
  }

  /**
   * read
   * @param params The `MessageWriteService.ReadUsingPUTParams` containing the following parameters:
   *
   * - `identifier`: identifier
   *
   * - `header`: header
   *
   * @return OK
   */
  readUsingPUTResponse(params: MessageWriteService.ReadUsingPUTParams): __Observable<__StrictHttpResponse<CmmMessageWriteReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/cmm/message/${encodeURIComponent(String(params.identifier))}/read`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmMessageWriteReadResponse>;
      })
    );
  }
  /**
   * read
   * @param params The `MessageWriteService.ReadUsingPUTParams` containing the following parameters:
   *
   * - `identifier`: identifier
   *
   * - `header`: header
   *
   * @return OK
   */
  readUsingPUT(params: MessageWriteService.ReadUsingPUTParams): __Observable<CmmMessageWriteReadResponse> {
    return this.readUsingPUTResponse(params).pipe(
      __map(_r => _r.body as CmmMessageWriteReadResponse)
    );
  }

  /**
   * read
   * @param params The `MessageWriteService.ReadUsingDELETEParams` containing the following parameters:
   *
   * - `recipient`: recipient
   *
   * - `header`: header
   *
   * @return OK
   */
  readUsingDELETEResponse(params: MessageWriteService.ReadUsingDELETEParams): __Observable<__StrictHttpResponse<CmmMessageWriteCleanResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/cmm/messages/${encodeURIComponent(String(params.recipient))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmMessageWriteCleanResponse>;
      })
    );
  }
  /**
   * read
   * @param params The `MessageWriteService.ReadUsingDELETEParams` containing the following parameters:
   *
   * - `recipient`: recipient
   *
   * - `header`: header
   *
   * @return OK
   */
  readUsingDELETE(params: MessageWriteService.ReadUsingDELETEParams): __Observable<CmmMessageWriteCleanResponse> {
    return this.readUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as CmmMessageWriteCleanResponse)
    );
  }
}

module MessageWriteService {

  /**
   * Parameters for mailUsingPOST
   */
  export interface MailUsingPOSTParams {

    /**
     * request
     */
    request: CmmMailRequest;

    /**
     * header
     */
    header: string;
  }

  /**
   * Parameters for sendUsingPOST
   */
  export interface SendUsingPOSTParams {

    /**
     * request
     */
    request: CmmMessageRequest;

    /**
     * header
     */
    header: string;
  }

  /**
   * Parameters for deleteMessageUsingDELETE
   */
  export interface DeleteMessageUsingDELETEParams {

    /**
     * identifier
     */
    identifier: string;

    /**
     * header
     */
    header?: string;
  }

  /**
   * Parameters for readUsingPUT
   */
  export interface ReadUsingPUTParams {

    /**
     * identifier
     */
    identifier: string;

    /**
     * header
     */
    header?: string;
  }

  /**
   * Parameters for readUsingDELETE
   */
  export interface ReadUsingDELETEParams {

    /**
     * recipient
     */
    recipient: string;

    /**
     * header
     */
    header?: string;
  }
}

export { MessageWriteService }
