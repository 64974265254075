import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as _moment from 'moment';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/state/app-state';

@Component({
  selector: 'app-month-selector',
  templateUrl: './month-selector.component.html',
  styleUrls: ['./month-selector.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter }],
})
export class MonthSelectorComponent implements OnInit {
  public year: any;
  public month_name: any;
  public _date: _moment.Moment = _moment(new Date());
  public today = false;
  public formatedDate: string = undefined;
  @Input() backgroundColor = '#ffffff';
  @Input() fontSize = '20';
  @Input() initialDate: Date = undefined;
  @Input() fullDateSelector = false;
  @Output() dataChanged: EventEmitter<Date> = new EventEmitter();
  @ViewChild('picker', { static: false }) picker;

  constructor(
    private _dateAdapter: DateAdapter<any>,
    private _store: Store<AppState>,
  ) {
    this._store.select('language').subscribe((language) => {
      this._dateAdapter.setLocale(language);
    });
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.initialDate)) {
      this._date = _moment(this.initialDate);
    }
    this.formatDate();
  }

  set_new_date($event: MatDatepickerInputEvent<Date>) {
    this._date = _moment($event.value);
    this.formatDate();
  }

  left_clicked() {
    this._date = this._date.add(-1, 'M');
    this.formatDate();
  }

  open_date_picker() {
    const self = this;
    setTimeout(() => {
      self.picker.open();
    }, 50);
  }
  right_clicked() {
    this._date = this._date.add(1, 'M');
    this.formatDate();
  }

  public resetToToday(): void {
    this._date = _moment(new Date());
    this.formatDate();
  }

  private formatDate(): void {
    this.year = this._date.format('YYYY');
    this.month_name = this._date.format('MM');
    this.formatedDate = this._date.format('YYYY-MM-DD');
    this.today = this.formatedDate === _moment(new Date()).format('YYYY-MM-DD');

    this.dataChanged.emit(this._date.toDate());
  }
}
