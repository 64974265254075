<aside aside class="sm:flex flexWrap h-100 bg-skin-theme900 text-muted_superlight hidden"
  [ngClass]="{ 'w-64': showDescription, 'w-12': !showDescription }" #main>
  <ul class="list-none mb-0 flex flexWrap">
    <li class="
        flex
        hover:bg-skin-theme800
        border-b border-solid
        border-muted_base
        h-12
      " (click)="toggleShowDescription()">
      <span class="w-12 p-2 cursor-pointer flex items-center justify-center">
        <mat-icon class="w-9 h-9 text-3xl">{{
          showDescription ? 'keyboard_arrow_left' : 'menu'
          }}</mat-icon> </span><span *ngIf="showDescription" class="
          pl-2
          py-1
          w-52
          content-center
          leading-10
          cursor-pointer
          font-bold
        ">Menu</span>
    </li>
    <li *ngFor="let item of menusidebarB" [ngClass]="{
        'bg-skin-theme700': item.active,
        'flex hover:bg-skin-theme800 border-b border-solid border-muted_base': true
      }" (click)="goto(item.URL, item.queryParams)">
      <span class="w-12 p-1 cursor-pointer">
        <div class="p-1" [ngClass]="{ borderActive: item.active }">
          <img [src]="item.IconUrl" class="w-8 h-8" alt="icon" [matTooltip]="item.Desc | translate" />
        </div>
      </span><span *ngIf="showDescription" class="pl-3 py-1 w-52 content-center leading-10 cursor-pointer">{{ item.Desc
        | translate | titlecase }}</span>
    </li>
    <li [ngClass]="{
        'bg-skin-theme700': showMore,
        'flex items-center border-b border-solid border-muted_base h-12 hover:bg-skin-theme800': true
      }" *ngIf="menusidebarS.length" (click)="toggleShowMore()">
      <span class="p-1">
        <div class="p-1" [ngClass]="{ borderActive: activeIconmenuS }">
          <mat-icon class="w-11 cursor-pointer" *ngIf="!showDescription">more_horiz</mat-icon>
        </div>
        <span *ngIf="showDescription" class="
            pl-16
            py-1
            w-full
            leading-10
            cursor-pointer
            flex
            justify-between
          ">More<mat-icon>keyboard_arrow_right</mat-icon></span>
      </span>
    </li>
  </ul>
</aside>
<!-- menu MORE -->
<aside *ngIf="menusidebarS.length && showMore" [ngClass]="{
    'left-14 ': !showDescription,
    'left-66': showDescription,
    'sm:flex h-auto bg-skin-theme900 text-muted_superlight hidden fixed bottom-3': true
  }">
  <li *ngFor="let item of menusidebarS" [ngClass]="{
      'bg-skin-theme700': item.active,
      'flex hover:bg-skin-theme800 border-b border-solid border-muted_base': true
    }" (click)="goto(item.URL, item.queryParams)">
    <span class="w-12 p-1 cursor-pointer">
      <div class="p-1" [ngClass]="{ borderActive: item?.active }">
        <img [src]="item.IconUrl" class="w-8 h-8" alt="icon" [matTooltip]="item.Desc | translate" />
      </div>
    </span>
  </li>
</aside>
