import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  constructor(private _el: ElementRef) {
  }

  @HostListener('input', ['$event']) onKeyDown(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9,\.\-\+\/\^=()*\ ]*/g, '');

    /*
    if (initalValue.indexOf(',') > -1) {
      if ((initalValue.length - initalValue.indexOf(',')) > 3) {
        this._el.nativeElement.value = this._el.nativeElement.value.substr(0, initalValue.indexOf(',') + 3);
      }
    }
   */

    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
