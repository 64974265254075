/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AcmPermissionsReadResponse } from '../models/acm-permissions-read-response';
import { permissions } from '../fn/permission/permissions';
import { Permissions$Params } from '../fn/permission/permissions';

@Injectable({ providedIn: 'root' })
export class PermissionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `permissions()` */
  static readonly PermissionsPath = '/api/acc/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissions$Response(params?: Permissions$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmPermissionsReadResponse>> {
    return permissions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissions(params?: Permissions$Params, context?: HttpContext): Observable<AcmPermissionsReadResponse> {
    return this.permissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmPermissionsReadResponse>): AcmPermissionsReadResponse => r.body)
    );
  }

}
