/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExaFileReadResponse } from '../../models/exa-file-read-response';

export interface Read2$Params {
  file: string;
}

export function read2(
  http: HttpClient,
  rootUrl: string,
  params: Read2$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<ExaFileReadResponse>> {
  const rb = new RequestBuilder(rootUrl, read2.PATH, 'get');
  if (params) {
    rb.path('file', params.file, {});
  }

  return http
    .request(rb.build({ responseType: 'json', accept: '*/*', context }))
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaFileReadResponse>;
      }),
    );
}

read2.PATH = '/api/exp/file/{file}';
