import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app-state';
import { User } from './user/user';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private logger: NGXLogger) { }

  public errorDescription(error: any): string {
    if (!isNullOrUndefined(error.error)) {
      if (!isNullOrUndefined(error.error.result)) {
        if (!isNullOrUndefined(error.error.result.description)) {
          return error.error.result.description;
        }
      }
    }

    return '';
  }
  public errorCode(error: any): string {
    this.logger.error({
      message: error,
    });
    if (!isNullOrUndefined(error.error)) {
      if (!isNullOrUndefined(error.error.result)) {
        if (!isNullOrUndefined(error.error.result.code)) {
          return error.error.result.code;
        }
      }
    }

    if (error.status === 403 || error.status === 401) {
      return 'LOGIN_REQUIRED';
    }

    return 'UNKNOWN_CODE';
  }
}
