import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { KeycloakProfile } from 'keycloak-js';
import { AppState } from '../state/app-state';
import { LAST_PROJECT } from '../state/reducers/project.reducer';
import { LOG_USER } from '../state/reducers/user.reducer';
import { UserDefault } from './user/user-default';
import { AccountService, LoginService } from 'src/app/api/acc/services';

@Injectable()
export class UserProfile {
  constructor(
    private _store: Store<AppState>,
    private _accountService: LoginService,
    private _router: Router,
  ) { }
  logUser(profile: KeycloakProfile, token: string) {
    this._accountService
      .login({
        body: {
          login: profile.username,
          password: '',
        },
      })
      .subscribe(
        (result) => {
          this._store.dispatch({
            type: LAST_PROJECT,
            payload: {
              project: result.preferences['project'],
            },
          });

          this._store.dispatch({
            type: LOG_USER,
            payload: {
              mail: profile.email,
              account: new UserDefault(
                profile.username,
                result.token,
                result.roles,
                result.name,
                result.surname,
                result.acceptor,
                result.absenceAcceptor,
                profile.email,
                result.siteRole
              ),
              token: token,
            },
          });
        },
        (error) => {
          this._store.dispatch({
            type: LOG_USER,
            payload: {
              mail: profile.email,
              account: new UserDefault(
                profile.username,
                '',
                [],
                profile.firstName,
                profile.lastName,
                null,
                null,
                profile.email,
                null
              ),
              token: null,
            },
          });
          console.log(error);
        },
      );
  }
}
