/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deals } from '../fn/import-write/deals';
import { Deals$Params } from '../fn/import-write/deals';
import { ExmImportDealsResponse } from '../models/exm-import-deals-response';
import { ExmImportItemRestartWriteResponse } from '../models/exm-import-item-restart-write-response';
import { ExmImportResourcesResponse } from '../models/exm-import-resources-response';
import { importResources1 } from '../fn/import-write/import-resources-1';
import { ImportResources1$Params } from '../fn/import-write/import-resources-1';
import { restart } from '../fn/import-write/restart';
import { Restart$Params } from '../fn/import-write/restart';

@Injectable({ providedIn: 'root' })
export class ImportWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `importResources1()` */
  static readonly ImportResources1Path = '/api/exp/import/resources';

  /**
   * Import resources data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importResources1()` instead.
   *
   * This method doesn't expect any request body.
   */
  importResources1$Response(params?: ImportResources1$Params, context?: HttpContext): Observable<StrictHttpResponse<ExmImportResourcesResponse>> {
    return importResources1(this.http, this.rootUrl, params, context);
  }

  /**
   * Import resources data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importResources1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importResources1(params?: ImportResources1$Params, context?: HttpContext): Observable<ExmImportResourcesResponse> {
    return this.importResources1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExmImportResourcesResponse>): ExmImportResourcesResponse => r.body)
    );
  }

  /** Path part for operation `restart()` */
  static readonly RestartPath = '/api/exp/import/modification_guard/{uid}';

  /**
   * Restart single operaton stored in the modification guard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restart()` instead.
   *
   * This method doesn't expect any request body.
   */
  restart$Response(params: Restart$Params, context?: HttpContext): Observable<StrictHttpResponse<ExmImportItemRestartWriteResponse>> {
    return restart(this.http, this.rootUrl, params, context);
  }

  /**
   * Restart single operaton stored in the modification guard.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restart(params: Restart$Params, context?: HttpContext): Observable<ExmImportItemRestartWriteResponse> {
    return this.restart$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExmImportItemRestartWriteResponse>): ExmImportItemRestartWriteResponse => r.body)
    );
  }

  /** Path part for operation `deals()` */
  static readonly DealsPath = '/api/exp/import/deals';

  /**
   * Import deals from the XLS file. The file has to beuploaded to the import directory to proper subdirectory (configured for the application). The import generates jobs entries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deals()` instead.
   *
   * This method doesn't expect any request body.
   */
  deals$Response(params?: Deals$Params, context?: HttpContext): Observable<StrictHttpResponse<ExmImportDealsResponse>> {
    return deals(this.http, this.rootUrl, params, context);
  }

  /**
   * Import deals from the XLS file. The file has to beuploaded to the import directory to proper subdirectory (configured for the application). The import generates jobs entries.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deals(params?: Deals$Params, context?: HttpContext): Observable<ExmImportDealsResponse> {
    return this.deals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExmImportDealsResponse>): ExmImportDealsResponse => r.body)
    );
  }

}
