/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { InmInventoryCommentReadResponse } from '../models/inm-inventory-comment-read-response';
import { readComments } from '../fn/inventory-comment-read/read-comments';
import { ReadComments$Params } from '../fn/inventory-comment-read/read-comments';

@Injectable({ providedIn: 'root' })
export class InventoryCommentReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readComments()` */
  static readonly ReadCommentsPath = '/api/inv/inventory/{uid}/usage/{entityType}/{entityNo}/comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  readComments$Response(params: ReadComments$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryCommentReadResponse>> {
    return readComments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readComments(params: ReadComments$Params, context?: HttpContext): Observable<InmInventoryCommentReadResponse> {
    return this.readComments$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryCommentReadResponse>): InmInventoryCommentReadResponse => r.body)
    );
  }

}
