/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CmmDirectMessageResponse } from '../models/cmm-direct-message-response';
import { directMessage } from '../fn/tma-direct-message-write/direct-message';
import { DirectMessage$Params } from '../fn/tma-direct-message-write/direct-message';
import { directMessage1 } from '../fn/tma-direct-message-write/direct-message-1';
import { DirectMessage1$Params } from '../fn/tma-direct-message-write/direct-message-1';
import { TmmPingResponse } from '../models/tmm-ping-response';

@Injectable({ providedIn: 'root' })
export class TmaDirectMessageWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `directMessage()` */
  static readonly DirectMessagePath = '/api/sys/ping';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `directMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  directMessage$Response(params: DirectMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmPingResponse>> {
    return directMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `directMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  directMessage(params: DirectMessage$Params, context?: HttpContext): Observable<TmmPingResponse> {
    return this.directMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmPingResponse>): TmmPingResponse => r.body)
    );
  }

  /** Path part for operation `directMessage1()` */
  static readonly DirectMessage1Path = '/api/sys/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `directMessage1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  directMessage1$Response(params: DirectMessage1$Params, context?: HttpContext): Observable<StrictHttpResponse<CmmDirectMessageResponse>> {
    return directMessage1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `directMessage1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  directMessage1(params: DirectMessage1$Params, context?: HttpContext): Observable<CmmDirectMessageResponse> {
    return this.directMessage1$Response(params, context).pipe(
      map((r: StrictHttpResponse<CmmDirectMessageResponse>): CmmDirectMessageResponse => r.body)
    );
  }

}
