/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HlmDeleteDictionaryItemRequest } from '../../models/hlm-delete-dictionary-item-request';
import { HlmDeleteDictionaryItemResponse } from '../../models/hlm-delete-dictionary-item-response';

export interface Delete$Params {
      body: HlmDeleteDictionaryItemRequest
}

export function delete$(http: HttpClient, rootUrl: string, params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmDeleteDictionaryItemResponse>> {
  const rb = new RequestBuilder(rootUrl, delete$.PATH, 'delete');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<HlmDeleteDictionaryItemResponse>;
    })
  );
}

delete$.PATH = '/api/hld/dictionary/absence_type';
