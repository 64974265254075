import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NumericInputComponent } from './input/input.component';
import { NumericKeyboardComponent } from './keyboard/keyboard.component';
import { NumericKeyboardInputComponent } from '../numeric-keyboard-input/numeric-keyboard-input.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  exports: [
    NumericKeyboardComponent,
    NumericKeyboardInputComponent,
    NumericInputComponent,
  ],
  declarations: [
    NumericInputComponent,
    NumericKeyboardComponent,
    NumericKeyboardInputComponent,
  ],
  entryComponents: [NumericKeyboardComponent],
  imports: [CommonModule, FormsModule, TranslateModule],
})
export class NumericInputModule {}
