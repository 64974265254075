/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OpmCommandExecuteUpdateResponse } from '../models/opm-command-execute-update-response';
import { OpmCommandExecuteUpdateRequest } from '../models/opm-command-execute-update-request';
import { OpmCommandExecuteResponse } from '../models/opm-command-execute-response';
import { OpmCommandExecuteRequest } from '../models/opm-command-execute-request';

/**
 * Opa Commnad Execute Update
 */
@Injectable({
  providedIn: 'root',
})
class OperationWriteService extends __BaseService {
  static readonly updateUsingPUTPath = '/api/opp/execution/{execution}';
  static readonly executeUsingPOSTPath = '/api/opp/operation/{operation}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Update an execution
   * @param params The `OperationWriteService.UpdateUsingPUTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `execution`: execution
   *
   * - `headers`: headers
   *
   * @return OK
   */
  updateUsingPUTResponse(params: OperationWriteService.UpdateUsingPUTParams): __Observable<__StrictHttpResponse<OpmCommandExecuteUpdateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    if (params.headers != null) __headers = __headers.set('headers', params.headers.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/opp/execution/${encodeURIComponent(String(params.execution))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmCommandExecuteUpdateResponse>;
      })
    );
  }
  /**
   * Update an execution
   * @param params The `OperationWriteService.UpdateUsingPUTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `execution`: execution
   *
   * - `headers`: headers
   *
   * @return OK
   */
  updateUsingPUT(params: OperationWriteService.UpdateUsingPUTParams): __Observable<OpmCommandExecuteUpdateResponse> {
    return this.updateUsingPUTResponse(params).pipe(
      __map(_r => _r.body as OpmCommandExecuteUpdateResponse)
    );
  }

  /**
   * Execute an operation
   * @param params The `OperationWriteService.ExecuteUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `operation`: operation
   *
   * - `headers`: headers
   *
   * @return OK
   */
  executeUsingPOSTResponse(params: OperationWriteService.ExecuteUsingPOSTParams): __Observable<__StrictHttpResponse<OpmCommandExecuteResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    if (params.headers != null) __headers = __headers.set('headers', params.headers.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/opp/operation/${encodeURIComponent(String(params.operation))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmCommandExecuteResponse>;
      })
    );
  }
  /**
   * Execute an operation
   * @param params The `OperationWriteService.ExecuteUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `operation`: operation
   *
   * - `headers`: headers
   *
   * @return OK
   */
  executeUsingPOST(params: OperationWriteService.ExecuteUsingPOSTParams): __Observable<OpmCommandExecuteResponse> {
    return this.executeUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as OpmCommandExecuteResponse)
    );
  }
}

module OperationWriteService {

  /**
   * Parameters for updateUsingPUT
   */
  export interface UpdateUsingPUTParams {

    /**
     * request
     */
    request: OpmCommandExecuteUpdateRequest;

    /**
     * execution
     */
    execution: string;

    /**
     * headers
     */
    headers?: string;
  }

  /**
   * Parameters for executeUsingPOST
   */
  export interface ExecuteUsingPOSTParams {

    /**
     * request
     */
    request: OpmCommandExecuteRequest;

    /**
     * operation
     */
    operation: string;

    /**
     * headers
     */
    headers?: string;
  }
}

export { OperationWriteService }
