/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InvAccountInventoryListResponse } from '../../models/inv-account-inventory-list-response';

export interface ListForAccount$Params {
  sortBy?: string;
  filterBy?: Array<string>;
  account: string;
  returnAll?: boolean;
  hideZero: boolean;
  showAll?: boolean;
  unfolded?: Array<string>;
}

export function listForAccount(http: HttpClient, rootUrl: string, params: ListForAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<InvAccountInventoryListResponse>> {
  const rb = new RequestBuilder(rootUrl, listForAccount.PATH, 'get');
  if (params) {
    rb.query('sortBy', params.sortBy, {});
    rb.query('filterBy', params.filterBy, {});
    rb.query('account', params.account, {});
    rb.query('returnAll', params.returnAll, {});
    rb.query('hideZero', params.hideZero, {});
    rb.query('showAll', params.showAll, {});
    rb.query('unfolded', params.unfolded, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InvAccountInventoryListResponse>;
    })
  );
}

listForAccount.PATH = '/api/inv/account/inventories';
