/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HlmAbsenceReadResponse } from '../../models/hlm-absence-read-response';

export interface ReadForUser$Params {
  user: string;

/**
 * Date format yyyy-MM-dd, if passed absence list will be restricted to that specific date.
 */
  date?: string;

/**
 * Date format yyyy-MM. Returns list of absences for given month.
 */
  month?: string;

/**
 * By default sorting is by REGISTRATION_DATE. If the value of sortField contains 'from' it will sorts results by ABSENCE_FROM.
 */
  sortField?: string;
  sortAsc?: boolean;
}

export function readForUser(http: HttpClient, rootUrl: string, params: ReadForUser$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmAbsenceReadResponse>> {
  const rb = new RequestBuilder(rootUrl, readForUser.PATH, 'get');
  if (params) {
    rb.path('user', params.user, {});
    rb.query('date', params.date, {});
    rb.query('month', params.month, {});
    rb.query('sortField', params.sortField, {});
    rb.query('sortAsc', params.sortAsc, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<HlmAbsenceReadResponse>;
    })
  );
}

readForUser.PATH = '/api/hld/absence/{user}';
