/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { UnassignTaskService } from './services/unassign-task.service';
import { TsaReleaseTaskService } from './services/tsa-release-task.service';
import { TsaCompleteTaskService } from './services/tsa-complete-task.service';
import { TsaClaimTaskService } from './services/tsa-claim-task.service';
import { AssignTaskService } from './services/assign-task.service';
import { TsaReadTaskListService } from './services/tsa-read-task-list.service';
import { TsaReadTaskListCountService } from './services/tsa-read-task-list-count.service';
import { TsaReadTaskService } from './services/tsa-read-task.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UnassignTaskService,
    TsaReleaseTaskService,
    TsaCompleteTaskService,
    TsaClaimTaskService,
    AssignTaskService,
    TsaReadTaskListService,
    TsaReadTaskListCountService,
    TsaReadTaskService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
