/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExmImportDealsResponse } from '../../models/exm-import-deals-response';

export interface Deals$Params { }

export function deals(
  http: HttpClient,
  rootUrl: string,
  params?: Deals$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<ExmImportDealsResponse>> {
  const rb = new RequestBuilder(rootUrl, deals.PATH, 'put');
  if (params) {
  }

  return http
    .request(rb.build({ responseType: 'json', accept: '*/*', context }))
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExmImportDealsResponse>;
      }),
    );
}

deals.PATH = '/api/exp/import/deals';
