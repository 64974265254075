/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { NvmCommandReportSiteTabResponse } from '../models/nvm-command-report-site-tab-response';
import { tabWrite } from '../fn/report-site-tab-write/tab-write';
import { TabWrite$Params } from '../fn/report-site-tab-write/tab-write';

@Injectable({ providedIn: 'root' })
export class ReportSiteTabWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tabWrite()` */
  static readonly TabWritePath = '/api/nav/tab/{jobNo}/{category}';

  /**
   * Store new value for the tab.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tabWrite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tabWrite$Response(params: TabWrite$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandReportSiteTabResponse>> {
    return tabWrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Store new value for the tab.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tabWrite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tabWrite(params: TabWrite$Params, context?: HttpContext): Observable<NvmCommandReportSiteTabResponse> {
    return this.tabWrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmCommandReportSiteTabResponse>): NvmCommandReportSiteTabResponse => r.body)
    );
  }

}
