/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NvmQueryReportSiteTabResponse } from '../../models/nvm-query-report-site-tab-response';

export interface TabRead$Params {
  jobNo: string;
  category: string;
  range: string;
}

export function tabRead(http: HttpClient, rootUrl: string, params: TabRead$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryReportSiteTabResponse>> {
  const rb = new RequestBuilder(rootUrl, tabRead.PATH, 'get');
  if (params) {
    rb.path('jobNo', params.jobNo, {});
    rb.path('category', params.category, {});
    rb.query('range', params.range, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<NvmQueryReportSiteTabResponse>;
    })
  );
}

tabRead.PATH = '/api/nav/tab/{jobNo}/{category}';
