import { AcmPermission } from "src/app/api/acc/models";

export class PermissionModel {
  constructor(
    private _permission: AcmPermission[]
  ) {
  }

  public hadRoleJob(role: string, jobNo: string): boolean {
    return this._permission?.filter(
      item => {
        return item.name === role && (item.jobNo === "*" || item.jobNo === jobNo)
      }
    ).length > 0;
  }

  public getAllRoles(){
    return this._permission
  }

  public hadRole(role: string): boolean {
    return this._permission.filter(
      item => {
        return item.name === role
      }
    ).length > 0;
  }
}
