/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InmReadHistoryForEntityResponse } from '../../models/inm-read-history-for-entity-response';

export interface ReadHistoryForEntity$Params {
  entity: string;
  number?: string;
  type?: string;
  page?: number;
}

export function readHistoryForEntity(http: HttpClient, rootUrl: string, params: ReadHistoryForEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<InmReadHistoryForEntityResponse>> {
  const rb = new RequestBuilder(rootUrl, readHistoryForEntity.PATH, 'get');
  if (params) {
    rb.query('entity', params.entity, {});
    rb.query('number', params.number, {});
    rb.query('type', params.type, {});
    rb.query('page', params.page, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InmReadHistoryForEntityResponse>;
    })
  );
}

readHistoryForEntity.PATH = '/api/inv/entity/history';
