import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diary-entry',
  templateUrl: './diary-entry.component.html',
  styleUrls: ['./diary-entry.component.css']
})
export class DiaryEntryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
