import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/state/app-state';
import { User } from '../../core/service/user/user';
import { ContextHelpReadService } from 'src/app/api/ctx/services';
import { CnmListContextReadResponse } from 'src/app/api/ctx/models';
import { SET_URL } from 'src/app/core/state/reducers/contexthelp.reducer';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input()
  set pageContext(val: string) {
    this.url = val;
  }
  public version = '';
  public userInfo = '';
  isHelp = false;
  public url = '/';

  constructor(
    private _contextHelp: ContextHelpReadService,
    private _store: Store<AppState>,
  ) {
    _store.select('version').subscribe((version) => {
      this.version = version;
    });

    this._store.select('user').subscribe((user: User) => {
      if (user.logged()) {
        this.userInfo = user.displayInfo();
      } else {
        this.userInfo = '';
      }
    });
  }
  ngOnInit(): void {
    this.loadContextHelp();
  }

  private loadContextHelp(): void {
    this._contextHelp
      .listContextUsingGET({
        language: 'pl',
        path: this.url,
        header: null,
      })
      .subscribe((result: CnmListContextReadResponse) => {
        this.isHelp = result?.content?.content !== undefined;
      });
  }
}
