import {CustomAction} from '../state.model';

export const LAST_ERROR = 'LAST_ERROR';

function formatNumber(value: number): string {
  return value < 10 ? '0' + value : '' + value;
}

export function error(message: string = '', action: CustomAction) {
  switch (action.type) {
    case LAST_ERROR:
      const now = new Date();

      return (
        '[' +
        formatNumber(now.getDate()) +
        '-' +
        formatNumber(now.getMonth() + 1) +
        '-' +
        now.getFullYear() +
        ' ' +
        formatNumber(now.getHours()) +
        ':' +
        formatNumber(now.getMinutes()) +
        ':' +
        formatNumber(now.getSeconds()) +
        '] ' +
        action.payload.message
      );
    default:
      return message;
  }
}
