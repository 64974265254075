/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TmmEmployeeStatementSendResponse } from '../../models/tmm-employee-statement-send-response';
import { TmmSendStatementRequest } from '../../models/tmm-send-statement-request';

export interface Send$Params {
  user: string;
  year: number;
  month: number;
      body: TmmSendStatementRequest
}

export function send(http: HttpClient, rootUrl: string, params: Send$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEmployeeStatementSendResponse>> {
  const rb = new RequestBuilder(rootUrl, send.PATH, 'post');
  if (params) {
    rb.path('user', params.user, {});
    rb.path('year', params.year, {});
    rb.path('month', params.month, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TmmEmployeeStatementSendResponse>;
    })
  );
}

send.PATH = '/api/tms/statement/{user}/{year}/{month}';
