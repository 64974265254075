/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TmmEntryToAcceptResponse } from '../../models/tmm-entry-to-accept-response';

export interface ToAccept$Params {
  project: string;
  user: string;
  state: string;
}

export function toAccept(http: HttpClient, rootUrl: string, params: ToAccept$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryToAcceptResponse>> {
  const rb = new RequestBuilder(rootUrl, toAccept.PATH, 'get');
  if (params) {
    rb.query('project', params.project, {});
    rb.query('user', params.user, {});
    rb.query('state', params.state, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TmmEntryToAcceptResponse>;
    })
  );
}

toAccept.PATH = '/api/tms/timesheet/accept';
