import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { AppState } from '../../core/state/app-state';
import { Store } from '@ngrx/store';
import { User } from '../../core/service/user/user';
import { LOGOUT_USER } from '../../core/state/reducers/user.reducer';
import { UserCookieStorage } from '../../core/service/user/user-cookie-storage';
import { Router } from '@angular/router';
import { UpdatesService } from '../../core/service/update.service';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { KeycloakService } from 'keycloak-angular';
import { TamTabDetails } from 'src/app/api/nav/models';
import { SystemConfiguration } from 'src/app/core/state/configuration.model';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent {
  public logged = false;
  public user: User = undefined;
  public showLogoConfig = true;

  @Input() description = '';
  @Input() projectDetails: TamTabDetails;
  @Input() show_logo = true;
  @Input() show_back_button = false;
  @Input() transparent = false;
  @Input() show_menu = true;
  @Input() back_route = '';
  @Output() back_pressed: EventEmitter<any> = new EventEmitter();

  constructor(
    private _store: Store<AppState>,
    private _injector: Injector,
    private _router: Router,
    private _updates: UpdatesService,
    private keycloakService: KeycloakService,
  ) {
    this._store.select('user').subscribe((user: User) => {
      this.logged = user.logged();
      this.user = user;
    });

    this._store
      .select('configuration')
      .subscribe((configuration: SystemConfiguration) => {
        this.showLogoConfig = configuration.showLogo();
      });
  }

  logout() {
    this._store.dispatch({
      type: LOGOUT_USER,
      payload: {},
    });
    new UserCookieStorage(this._injector).clear();
    this.keycloakService.logout();
    this._router.navigateByUrl('/login');
  }

  updateApp() {
    this._updates.checkForUpdate();
  }

  emit_back() {
    if (!isNullOrUndefined(this.back_pressed)) {
      this.back_pressed.emit();
    }
  }
}
