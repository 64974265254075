/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RpmExecutionReadResponse } from '../models/rpm-execution-read-response';
import { RpmReadResponse } from '../models/rpm-read-response';

/**
 * Rpa Execution Read
 */
@Injectable({
  providedIn: 'root',
})
class ReportReadService extends __BaseService {
  static readonly executeUsingGETPath = '/api/rep/execution/{executionUid}';
  static readonly readUsingGETPath = '/api/rep/report/{report}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ReportReadService.ExecuteUsingGETParams` containing the following parameters:
   *
   * - `executionUid`: executionUid
   *
   * - `headers`: headers
   *
   * @return OK
   */
  executeUsingGETResponse(params: ReportReadService.ExecuteUsingGETParams): __Observable<__StrictHttpResponse<RpmExecutionReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.headers != null) __headers = __headers.set('headers', params.headers.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/rep/execution/${params.executionUid}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RpmExecutionReadResponse>;
      })
    );
  }
  /**
   * @param params The `ReportReadService.ExecuteUsingGETParams` containing the following parameters:
   *
   * - `executionUid`: executionUid
   *
   * - `headers`: headers
   *
   * @return OK
   */
  executeUsingGET(params: ReportReadService.ExecuteUsingGETParams): __Observable<RpmExecutionReadResponse> {
    return this.executeUsingGETResponse(params).pipe(
      __map(_r => _r.body as RpmExecutionReadResponse)
    );
  }

  /**
   * @param params The `ReportReadService.ReadUsingGETParams` containing the following parameters:
   *
   * - `report`: report
   *
   * - `headers`: headers
   *
   * @return OK
   */
  readUsingGETResponse(params: ReportReadService.ReadUsingGETParams): __Observable<__StrictHttpResponse<RpmReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.headers != null) __headers = __headers.set('headers', params.headers.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/rep/report/${params.report}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RpmReadResponse>;
      })
    );
  }
  /**
   * @param params The `ReportReadService.ReadUsingGETParams` containing the following parameters:
   *
   * - `report`: report
   *
   * - `headers`: headers
   *
   * @return OK
   */
  readUsingGET(params: ReportReadService.ReadUsingGETParams): __Observable<RpmReadResponse> {
    return this.readUsingGETResponse(params).pipe(
      __map(_r => _r.body as RpmReadResponse)
    );
  }
}

module ReportReadService {

  /**
   * Parameters for executeUsingGET
   */
  export interface ExecuteUsingGETParams {

    /**
     * executionUid
     */
    executionUid: string;

    /**
     * headers
     */
    headers?: any;
  }

  /**
   * Parameters for readUsingGET
   */
  export interface ReadUsingGETParams {

    /**
     * report
     */
    report: string;

    /**
     * headers
     */
    headers?: any;
  }
}

export { ReportReadService }
