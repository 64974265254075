/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { readTask } from '../fn/tsa-read-task/read-task';
import { ReadTask$Params } from '../fn/tsa-read-task/read-task';
import { TsmReadTaskResponse } from '../models/tsm-read-task-response';

@Injectable({ providedIn: 'root' })
export class TsaReadTaskService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readTask()` */
  static readonly ReadTaskPath = '/api/tsk/task/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTask$Response(params: ReadTask$Params, context?: HttpContext): Observable<StrictHttpResponse<TsmReadTaskResponse>> {
    return readTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTask(params: ReadTask$Params, context?: HttpContext): Observable<TsmReadTaskResponse> {
    return this.readTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TsmReadTaskResponse>): TsmReadTaskResponse => r.body)
    );
  }

}
