/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PrmJobReadResponse } from '../models/prm-job-read-response';
import { readResponseResponseEntity2 } from '../fn/pra-job-read/read-response-response-entity-2';
import { ReadResponseResponseEntity2$Params } from '../fn/pra-job-read/read-response-response-entity-2';

@Injectable({ providedIn: 'root' })
export class PraJobReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readResponseResponseEntity2()` */
  static readonly ReadResponseResponseEntity2Path = '/api/prj/job/{no}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readResponseResponseEntity2()` instead.
   *
   * This method doesn't expect any request body.
   */
  readResponseResponseEntity2$Response(params: ReadResponseResponseEntity2$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobReadResponse>> {
    return readResponseResponseEntity2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readResponseResponseEntity2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readResponseResponseEntity2(params: ReadResponseResponseEntity2$Params, context?: HttpContext): Observable<PrmJobReadResponse> {
    return this.readResponseResponseEntity2$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobReadResponse>): PrmJobReadResponse => r.body)
    );
  }

}
