/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { list } from '../fn/pra-resource-read/list';
import { List$Params } from '../fn/pra-resource-read/list';
import { PrmResourceListResponse } from '../models/prm-resource-list-response';
import { PrmWorkTypeListResponse } from '../models/prm-work-type-list-response';
import { readEquipment } from '../fn/pra-resource-read/read-equipment';
import { ReadEquipment$Params } from '../fn/pra-resource-read/read-equipment';

@Injectable({ providedIn: 'root' })
export class PraResourceReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `list()` */
  static readonly ListPath = '/api/rsc/resource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list()` instead.
   *
   * This method doesn't expect any request body.
   */
  list$Response(params?: List$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmResourceListResponse>> {
    return list(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list(params?: List$Params, context?: HttpContext): Observable<PrmResourceListResponse> {
    return this.list$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmResourceListResponse>): PrmResourceListResponse => r.body)
    );
  }

  /** Path part for operation `readEquipment()` */
  static readonly ReadEquipmentPath = '/api/rsc/resource/equipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readEquipment()` instead.
   *
   * This method doesn't expect any request body.
   */
  readEquipment$Response(params?: ReadEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmWorkTypeListResponse>> {
    return readEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readEquipment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readEquipment(params?: ReadEquipment$Params, context?: HttpContext): Observable<PrmWorkTypeListResponse> {
    return this.readEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmWorkTypeListResponse>): PrmWorkTypeListResponse => r.body)
    );
  }

}
