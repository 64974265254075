import {CustomAction} from '../state.model';

export const LAST_RESOURCE = 'LAST_RESOURCE';

export function resource(state: string = '', action: CustomAction) {
  switch (action.type) {
    case LAST_RESOURCE:
      return action.payload.resource;
    default:
      return state;
  }
}
