/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { lockCheck } from '../fn/cost-processing/lock-check';
import { LockCheck$Params } from '../fn/cost-processing/lock-check';
import { lockCheck1 } from '../fn/cost-processing/lock-check-1';
import { LockCheck1$Params } from '../fn/cost-processing/lock-check-1';
import { NvmCommandCostLockDeleteResponse } from '../models/nvm-command-cost-lock-delete-response';
import { NvmQueryCostLockResponse } from '../models/nvm-query-cost-lock-response';

@Injectable({ providedIn: 'root' })
export class CostProcessingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lockCheck()` */
  static readonly LockCheckPath = '/api/nav/lock/{jobNo}/{transactionUid}/{action}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockCheck$Response(params: LockCheck$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryCostLockResponse>> {
    return lockCheck(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockCheck(params: LockCheck$Params, context?: HttpContext): Observable<NvmQueryCostLockResponse> {
    return this.lockCheck$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryCostLockResponse>): NvmQueryCostLockResponse => r.body)
    );
  }

  /** Path part for operation `lockCheck1()` */
  static readonly LockCheck1Path = '/api/nav/lock/{jobNo}/{transactionUid}/{action}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lockCheck1()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockCheck1$Response(params: LockCheck1$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandCostLockDeleteResponse>> {
    return lockCheck1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lockCheck1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lockCheck1(params: LockCheck1$Params, context?: HttpContext): Observable<NvmCommandCostLockDeleteResponse> {
    return this.lockCheck1$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmCommandCostLockDeleteResponse>): NvmCommandCostLockDeleteResponse => r.body)
    );
  }

}
