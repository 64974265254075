import {CustomAction} from '../state.model';

export const REFRESH_CALENDAR = 'REFRESH_CALENDAR';

export function calendar(state: string = '', action: CustomAction) {
  switch (action.type) {
    case REFRESH_CALENDAR:
      return 'true';
    default:
      return state;
  }
}
