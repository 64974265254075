/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CalculateWorkingDays200ResponseDto } from '../../models/calculate-working-days-200-response-dto';

export interface CalculateWorkingDays$Params {

/**
 * Start date in yyyy-mm-dd format
 */
  fromDate: string;

/**
 * End date in yyyy-mm-dd format
 */
  toDate: string;
}

export function calculateWorkingDays(http: HttpClient, rootUrl: string, params: CalculateWorkingDays$Params, context?: HttpContext): Observable<StrictHttpResponse<CalculateWorkingDays200ResponseDto>> {
  const rb = new RequestBuilder(rootUrl, calculateWorkingDays.PATH, 'get');
  if (params) {
    rb.query('fromDate', params.fromDate, {});
    rb.query('toDate', params.toDate, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CalculateWorkingDays200ResponseDto>;
    })
  );
}

calculateWorkingDays.PATH = '/api/hld/calculate/workingdays';
