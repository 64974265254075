/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { leaveValidate } from '../fn/validation/leave-validate';
import { LeaveValidate$Params } from '../fn/validation/leave-validate';
import { LeaveValidate200ResponseDto } from '../models/leave-validate-200-response-dto';


/**
 * the Validation API
 */
@Injectable({ providedIn: 'root' })
export class ValidationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `leaveValidate()` */
  static readonly LeaveValidatePath = '/api/hld/user/{login}/leave/validate';

  /**
   * Validate the possibility of taking leave.
   *
   * Validates whether it is possible to take leave based on the provided parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leaveValidate()` instead.
   *
   * This method doesn't expect any request body.
   */
  leaveValidate$Response(params: LeaveValidate$Params, context?: HttpContext): Observable<StrictHttpResponse<LeaveValidate200ResponseDto>> {
    return leaveValidate(this.http, this.rootUrl, params, context);
  }

  /**
   * Validate the possibility of taking leave.
   *
   * Validates whether it is possible to take leave based on the provided parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leaveValidate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  leaveValidate(params: LeaveValidate$Params, context?: HttpContext): Observable<LeaveValidate200ResponseDto> {
    return this.leaveValidate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LeaveValidate200ResponseDto>): LeaveValidate200ResponseDto => r.body)
    );
  }

}
