<app-top-menu [show_logo]="true" [transparent]="true"></app-top-menu>
<div class="container-fluid content-pageX" style="padding-top: 0px; padding-bottom: 100px" *ngIf="!loginInProgress">
  <div class="row">
    <div class="col-2 d-none d-lg-block"></div>
    <div class="col-12 col-lg-8 d-flex justify-content-center">
      <div>
        <div class="container">
          <div *ngIf="bannerMessages != undefined" class="mb-4 mt-6">
            <ng-container *ngFor="let item of bannerMessages">
              <app-banner *ngIf="item?.banner?.show" [msgDetail]="item" (closeEvent)="goto($event)"></app-banner>
            </ng-container>
          </div>
          <div class="row">
            <div [class]="'col-6 col-md-' + columns + ' d-flex align-items-center justify-content-center'
              " *ngIf="visibleModules.work_v2">
              <app-company-selector [moduleName]="'label.work' | translate" color="module_2"
                [icon]="'assets/icons/icons_work.png'" (button_pressed)="on_work_v2_selected()"></app-company-selector>
            </div>
            <div [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              " *ngIf="visibleModules.work">
              <app-company-selector [moduleName]="'label.work' | translate" color="module_2"
                [icon]="'assets/icons/icons_work.png'" (button_pressed)="on_work_selected()"></app-company-selector>
            </div>
            <div [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              " *ngIf="visibleModules.projects">
              <app-company-selector [moduleName]="'label.projects' | translate" color="module_3"
                [icon]="'assets/icons/icons_projects.png'" routeTo="project/home"></app-company-selector>
            </div>
            <div *ngIf="visibleModules.task" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector color="module_1" moduleName="module.task" [icon]="'assets/icons/icons_reports.png'"
                [counter]="awaitingTasks" routeTo="task"></app-company-selector>
            </div>
            <div *ngIf="visibleModules.message" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector [moduleName]="'label.messages' | translate" color="module_1"
                [icon]="'assets/icons/icons_message.png'" [counter]="numOfMessage" [important]="numOfImportantMessage"
                routeTo="message"></app-company-selector>
            </div>
            <div *ngIf="visibleModules.report" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector [moduleName]="'label.reports' | translate" color="module_1"
                [icon]="'assets/icons/icons_reports.png'" routeTo="report"></app-company-selector>
            </div>
            <div [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              " *ngIf="visibleModules.holidays">
              <app-company-selector [moduleName]="'label.absence' | translate" color="module_4"
                [icon]="'assets/icons/icons_holidays.png'" routeTo="holiday"></app-company-selector>
            </div>
            <div *ngIf="visibleModules.acceptance" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector [moduleName]="'label.accept' | translate" color="module_1"
                [icon]="'assets/icons/icons_accept.png'" [counter]="unacceptedTimesheets" routeTo="project/accept">
              </app-company-selector>
            </div>
            <div *ngIf="visibleModules.holidaysAcceptance" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector color="module_1" [moduleName]="'label.leave.acceptance' | translate"
                [icon]="'assets/icons/icons_accept_holidays.png'" [counter]="unacceptedAbsences"
                routeTo="holiday/accept"></app-company-selector>
            </div>
            <div [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              " *ngIf="visibleModules.volumeOfOrders">
              <app-company-selector [moduleName]="'label.voo' | translate" color="module_3"
                [icon]="'assets/icons/icons_reports.png'" routeTo="voo"></app-company-selector>
            </div>
            <div *ngIf="visibleModules.administration" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector color="module_1" [moduleName]="'label.administration' | translate"
                [icon]="'assets/icons/icons_work.png'" routeTo="administration/home"></app-company-selector>
            </div>
            <div *ngIf="visibleModules.hr" [class]="
            'col-6 col-md-' +
            columns +
            ' d-flex align-items-center justify-content-center'
          ">
              <app-company-selector color="module_1" [moduleName]="'HR' | translate"
                [icon]="'assets/icons/icons_holidays.png'" routeTo="hr"></app-company-selector>
            </div>

            <div *ngIf="visibleModules.ehs" [class]="
            'col-6 col-md-' +
            columns +
            ' d-flex align-items-center justify-content-center'
          ">
              <app-company-selector color="module_1" [moduleName]="'label.ehs' | translate"
                [icon]="'assets/icons/icons_holidays.png'" routeTo="ehs" [counter]="numOfUnreadZPW"></app-company-selector>
            </div>
            <div *ngIf="visibleModules.operations" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector color="module_1" [moduleName]="'label.header.operations' | translate"
                [icon]="'assets/icons/icons_work.png'" routeTo="operations"></app-company-selector>
            </div>

            <div *ngIf="visibleModules.reports" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector color="module_1" [moduleName]="'label.header.reports' | translate"
                [icon]="'assets/icons/icons_reports.png'" routeTo="reports"></app-company-selector>
            </div>
            <div *ngIf="visibleModules.inventory" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector color="module_1" moduleName="module.inventory"
                [icon]="'assets/icons/icons_reports.png'" routeTo="inventory"></app-company-selector>
            </div>
            <div *ngIf="visibleModules.workerPage" [class]="
                'col-6 col-md-' +
                columns +
                ' d-flex align-items-center justify-content-center'
              ">
              <app-company-selector color="module_1" moduleName="module.worker.page"
                [icon]="'assets/icons/icons_reports.png'" routeTo="worokerhome"></app-company-selector>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 d-none d-lg-block"></div>
  </div>
</div>
<app-footer pageContext="/"></app-footer>
<app-notification-box></app-notification-box>