/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { NavWriteService } from './services/nav-write.service';
import { KpiWriteService } from './services/kpi-write.service';
import { NvsDirectMessageReadService } from './services/nvs-direct-message-read.service';
import { NvsDirectMessageWriteService } from './services/nvs-direct-message-write.service';
import { ReportSiteTabReadService } from './services/report-site-tab-read.service';
import { ReportSiteTabWriteService } from './services/report-site-tab-write.service';
import { ReportSiteResultReadService } from './services/report-site-result-read.service';
import { ReportSiteResultWriteService } from './services/report-site-result-write.service';
import { ReportSiteResultFilteredReadService } from './services/report-site-result-filtered-read.service';
import { NavReadService } from './services/nav-read.service';
import { CostWriteService } from './services/cost-write.service';
import { CalendarWriteService } from './services/calendar-write.service';
import { VolumeOfOrdersReadService } from './services/volume-of-orders-read.service';
import { ReportResourcesReadService } from './services/report-resources-read.service';
import { ProjectsCostsReadService } from './services/projects-costs-read.service';
import { NvaQueryProcessService } from './services/nva-query-process.service';
import { ReportMaterialsReadService } from './services/report-materials-read.service';
import { CostProcessingService } from './services/cost-processing.service';
import { NavJobTaskListReadService } from './services/nav-job-task-list-read.service';
import { ReportReadService } from './services/report-read.service';
import { DictionaryReadService } from './services/dictionary-read.service';
import { ConfigurationReadService } from './services/configuration-read.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    NavWriteService,
    KpiWriteService,
    NvsDirectMessageReadService,
    NvsDirectMessageWriteService,
    ReportSiteTabReadService,
    ReportSiteTabWriteService,
    ReportSiteResultReadService,
    ReportSiteResultWriteService,
    ReportSiteResultFilteredReadService,
    NavReadService,
    CostWriteService,
    CalendarWriteService,
    VolumeOfOrdersReadService,
    ReportResourcesReadService,
    ProjectsCostsReadService,
    NvaQueryProcessService,
    ReportMaterialsReadService,
    CostProcessingService,
    NavJobTaskListReadService,
    ReportReadService,
    DictionaryReadService,
    ConfigurationReadService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
