/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CmmMessagesListResponse } from '../models/cmm-messages-list-response';

/**
 * Cma Messages List Read
 */
@Injectable({
  providedIn: 'root',
})
class MessagesReadService extends __BaseService {
  static readonly messagesListReadUsingGETPath = '/api/cmm/messages';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * messagesListRead
   * @param params The `MessagesReadService.MessagesListReadUsingGETParams` containing the following parameters:
   *
   * - `page`: page
   *
   * - `max`: max
   *
   * - `sortBy`: sortBy
   *
   * - `header`: header
   *
   * - `filterBy`: filterBy
   *
   * @return OK
   */
  messagesListReadUsingGETResponse(params: MessagesReadService.MessagesListReadUsingGETParams): __Observable<__StrictHttpResponse<CmmMessagesListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.max != null) __params = __params.set('max', params.max.toString());
    if (params.sortBy != null) __params = __params.set('sortBy', params.sortBy.toString());
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    if (params.filterBy != null) __params = __params.set('filterBy', params.filterBy.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/cmm/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmMessagesListResponse>;
      })
    );
  }
  /**
   * messagesListRead
   * @param params The `MessagesReadService.MessagesListReadUsingGETParams` containing the following parameters:
   *
   * - `page`: page
   *
   * - `max`: max
   *
   * - `sortBy`: sortBy
   *
   * - `header`: header
   *
   * - `filterBy`: filterBy
   *
   * @return OK
   */
  messagesListReadUsingGET(params: MessagesReadService.MessagesListReadUsingGETParams): __Observable<CmmMessagesListResponse> {
    return this.messagesListReadUsingGETResponse(params).pipe(
      __map(_r => _r.body as CmmMessagesListResponse)
    );
  }
}

module MessagesReadService {

  /**
   * Parameters for messagesListReadUsingGET
   */
  export interface MessagesListReadUsingGETParams {

    /**
     * page
     */
    page: number;

    /**
     * max
     */
    max: number;

    /**
     * sortBy
     */
    sortBy?: string;

    /**
     * header
     */
    header?: string;

    /**
     * filterBy
     */
    filterBy?: string;
  }
}

export { MessagesReadService }
