import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-helpIcon',
  templateUrl: './helpIcon.component.html',
  styleUrls: ['./helpIcon.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(200, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class HelpIconComponent {
  @Input() url = '/';

  public isHelpShowed = false;

  showHelp(visible?: boolean) {
    if (visible != undefined) {
      this.isHelpShowed = visible;
    } else {
      this.isHelpShowed = !this.isHelpShowed;
    }
  }
}
