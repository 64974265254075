/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PrmProjectQueryResponse } from '../../models/prm-project-query-response';

export interface Query$Params {
  nameOrId: string;
  size: number;
  page: number;
  limitedProjects?: boolean;
}

export function query(http: HttpClient, rootUrl: string, params: Query$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmProjectQueryResponse>> {
  const rb = new RequestBuilder(rootUrl, query.PATH, 'get');
  if (params) {
    rb.query('nameOrId', params.nameOrId, {});
    rb.query('size', params.size, {});
    rb.query('page', params.page, {});
    rb.query('limitedProjects', params.limitedProjects, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PrmProjectQueryResponse>;
    })
  );
}

query.PATH = '/api/prj/query';
