/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InmConfigurationTaskReadResponse } from '../../models/inm-configuration-task-read-response';

export interface Task$Params {
  categoryCode: string;
  operationType: string;
}

export function task(http: HttpClient, rootUrl: string, params: Task$Params, context?: HttpContext): Observable<StrictHttpResponse<InmConfigurationTaskReadResponse>> {
  const rb = new RequestBuilder(rootUrl, task.PATH, 'get');
  if (params) {
    rb.path('categoryCode', params.categoryCode, {});
    rb.path('operationType', params.operationType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InmConfigurationTaskReadResponse>;
    })
  );
}

task.PATH = '/api/inv/configuration/{categoryCode}/{operationType}/task';
