/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseEntity } from '../models/response-entity';
import { CmmFile } from '../models/cmm-file';

/**
 * Cma File Write
 */
@Injectable({
  providedIn: 'root',
})
class FileWriteService extends __BaseService {
  static readonly readUsingPOSTPath = '/api/cmm/file';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Read file and return as base64
   * @param params The `FileWriteService.ReadUsingPOSTParams` containing the following parameters:
   *
   * - `header`: header
   *
   * - `file`: file
   *
   * @return OK
   */
  readUsingPOSTResponse(params: FileWriteService.ReadUsingPOSTParams): __Observable<__StrictHttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    __body = params.file;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/cmm/file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseEntity>;
      })
    );
  }
  /**
   * Read file and return as base64
   * @param params The `FileWriteService.ReadUsingPOSTParams` containing the following parameters:
   *
   * - `header`: header
   *
   * - `file`: file
   *
   * @return OK
   */
  readUsingPOST(params: FileWriteService.ReadUsingPOSTParams): __Observable<ResponseEntity> {
    return this.readUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ResponseEntity)
    );
  }
}

module FileWriteService {

  /**
   * Parameters for readUsingPOST
   */
  export interface ReadUsingPOSTParams {

    /**
     * header
     */
    header: string;

    /**
     * file
     */
    file: CmmFile;
  }
}

export { FileWriteService }
