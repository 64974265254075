import {INIT_SESSION_ID} from './reducers/session.reducer';
import {SET_APP_VERSION} from './reducers/version.reducer';
import {Store} from '@ngrx/store';
import {AppState} from './app-state';
import {Injector} from '@angular/core';

export class StateComponent {
  // -----------------------------------------------------------------------------------------------------------------
  // Constructors
  // -----------------------------------------------------------------------------------------------------------------
  constructor(private store: Store<AppState>, private _injector: Injector) {
  }

  // -----------------------------------------------------------------------------------------------------------------
  // Business logic
  // -----------------------------------------------------------------------------------------------------------------
  init(): void {
    this.store.dispatch({
      type: SET_APP_VERSION,
    });

    this.store.dispatch({
      type: INIT_SESSION_ID,
    });
  }

  // -----------------------------------------------------------------------------------------------------------------
}
