/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { listOfIncidentComments } from '../fn/comment-read/list-of-incident-comments';
import { ListOfIncidentComments$Params } from '../fn/comment-read/list-of-incident-comments';
import { ListOfIncidentComments200ResponseDto } from '../models/list-of-incident-comments-200-response-dto';


/**
 * the CommentRead API
 */
@Injectable({ providedIn: 'root' })
export class CommentReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listOfIncidentComments()` */
  static readonly ListOfIncidentCommentsPath = '/api/ehs/incident/{incidentId}/comment';

  /**
   * Fetch list of incident comments.
   *
   * Endpoint for reading a list of incident comments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOfIncidentComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOfIncidentComments$Response(params: ListOfIncidentComments$Params, context?: HttpContext): Observable<StrictHttpResponse<ListOfIncidentComments200ResponseDto>> {
    return listOfIncidentComments(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch list of incident comments.
   *
   * Endpoint for reading a list of incident comments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOfIncidentComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOfIncidentComments(params: ListOfIncidentComments$Params, context?: HttpContext): Observable<ListOfIncidentComments200ResponseDto> {
    return this.listOfIncidentComments$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListOfIncidentComments200ResponseDto>): ListOfIncidentComments200ResponseDto => r.body)
    );
  }

}
