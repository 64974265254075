/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { PraProjectWriteService } from './services/pra-project-write.service';
import { PraJobWriteService } from './services/pra-job-write.service';
import { PraJobDiaryService } from './services/pra-job-diary.service';
import { PraJobGroupUpdateService } from './services/pra-job-group-update.service';
import { PraJobGroupAreaUpdateWriteService } from './services/pra-job-group-area-update-write.service';
import { PraDistributedProcessingLockWriteService } from './services/pra-distributed-processing-lock-write.service';
import { PraResourceReadService } from './services/pra-resource-read.service';
import { PraResourceWriteService } from './services/pra-resource-write.service';
import { PraJobJournalWriteService } from './services/pra-job-journal-write.service';
import { PraJobJournalReadService } from './services/pra-job-journal-read.service';
import { PraJobRelationWriteService } from './services/pra-job-relation-write.service';
import { PraJobGroupCreateService } from './services/pra-job-group-create.service';
import { PraJobGroupAreaCreateWriteService } from './services/pra-job-group-area-create-write.service';
import { PraDistributedProcessingRegisterService } from './services/pra-distributed-processing-register.service';
import { ServiceWorkTypeReadService } from './services/service-work-type-read.service';
import { PraProjectReadService } from './services/pra-project-read.service';
import { PraJobTaskReadService } from './services/pra-job-task-read.service';
import { PraJobJournalProjectsReadService } from './services/pra-job-journal-projects-read.service';
import { JobJournalReadService } from './services/job-journal-read.service';
import { PraJobReadService } from './services/pra-job-read.service';
import { PraJobGroupReadService } from './services/pra-job-group-read.service';
import { JobDistributedProcessingService } from './services/job-distributed-processing.service';
import { PraDepartmentReadService } from './services/pra-department-read.service';
import { JobRelationWriteService } from './services/job-relation-write.service';
import { PraJobGroupAreaDeleteWriteService } from './services/pra-job-group-area-delete-write.service';
import { PraDistributedProcessingRemoveService } from './services/pra-distributed-processing-remove.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PraProjectWriteService,
    PraJobWriteService,
    PraJobDiaryService,
    PraJobGroupUpdateService,
    PraJobGroupAreaUpdateWriteService,
    PraDistributedProcessingLockWriteService,
    PraResourceReadService,
    PraResourceWriteService,
    PraJobJournalWriteService,
    PraJobJournalReadService,
    PraJobRelationWriteService,
    PraJobGroupCreateService,
    PraJobGroupAreaCreateWriteService,
    PraDistributedProcessingRegisterService,
    ServiceWorkTypeReadService,
    PraProjectReadService,
    PraJobTaskReadService,
    PraJobJournalProjectsReadService,
    JobJournalReadService,
    PraJobReadService,
    PraJobGroupReadService,
    JobDistributedProcessingService,
    PraDepartmentReadService,
    JobRelationWriteService,
    PraJobGroupAreaDeleteWriteService,
    PraDistributedProcessingRemoveService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
