/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PrmDistributedProcessingToProcessReadResponse } from '../../models/prm-distributed-processing-to-process-read-response';

export interface ReadToProcess$Params {
  size: number;
}

export function readToProcess(http: HttpClient, rootUrl: string, params: ReadToProcess$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmDistributedProcessingToProcessReadResponse>> {
  const rb = new RequestBuilder(rootUrl, readToProcess.PATH, 'get');
  if (params) {
    rb.query('size', params.size, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PrmDistributedProcessingToProcessReadResponse>;
    })
  );
}

readToProcess.PATH = '/api/prj/dprc/actions';
