<div class="container-fluid">
  <div class="row">
    <div
      class="col-12 d-flex justify-content-center align-items-center clickable"
      *ngIf="fullDateSelector"
    >
      <table>
        <tr>
          <td class="d-flex justify-content-center center-title">
            <table>
              <tr>
                <td>
                  <input
                    [matDatepicker]="picker"
                    style="display: block; width: 92px; border: 0px; padding-left: 1px;"
                    [style.backgroundColor]="backgroundColor"
                    [style.fontSize.px]="fontSize"
                    class="center-title"
                    readonly
                    (dateChange)="set_new_date($event)"
                    [value]="_date"
                    [ngClass]="{
                      color_orange: !today
                    }"
                  />
                </td>
                <td *ngIf="!today" style="padding-bottom: 5px;">
                  <mat-icon
                    class="clickable"
                    (click)="resetToToday()"
                    style="top: -2px"
                    >close</mat-icon
                  >
                </td>
                <td>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                    style="position: relative; top: -2px;"
                  >
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div
      class="col-12 d-flex justify-content-center align-items-center"
      *ngIf="!fullDateSelector"
    >
      <table>
        <tr>
          <td
            class="left-button clickable disable-text-selection"
            (click)="left_clicked()"
          >
            &lt;
          </td>
          <td
            class="center-title d-flex justify-content-center disable-text-selection"
          >
            {{ "label.month." + month_name | translate }}&nbsp;{{ year }}
          </td>
          <td
            class="right-button clickable disable-text-selection"
            (click)="right_clicked()"
          >
            &gt;
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
