/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { read1 } from '../fn/resource-cost-read/read-1';
import { Read1$Params } from '../fn/resource-cost-read/read-1';

@Injectable({ providedIn: 'root' })
export class ResourceCostReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `read1()` */
  static readonly Read1Path = '/api/exp/resource_cost/{resourceId}/{workType}';

  /**
   * Read information about resource cost.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `read1()` instead.
   *
   * This method doesn't expect any request body.
   */
  read1$Response(params: Read1$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return read1(this.http, this.rootUrl, params, context);
  }

  /**
   * Read information about resource cost.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `read1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  read1(params: Read1$Params, context?: HttpContext): Observable<string> {
    return this.read1$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
