/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/dictionary-write/create';
import { Create$Params } from '../fn/dictionary-write/create';
import { createDictionary } from '../fn/dictionary-write/create-dictionary';
import { CreateDictionary$Params } from '../fn/dictionary-write/create-dictionary';
import { delete$ } from '../fn/dictionary-write/delete';
import { Delete$Params } from '../fn/dictionary-write/delete';
import { HlmCreateDictionaryItemResponse } from '../models/hlm-create-dictionary-item-response';
import { HlmCreateDictionaryResponse } from '../models/hlm-create-dictionary-response';
import { HlmDeleteDictionaryItemResponse } from '../models/hlm-delete-dictionary-item-response';

@Injectable({ providedIn: 'root' })
export class DictionaryWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/api/hld/dictionary/absence_type';

  /**
   * Add new entries to the dictionary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmCreateDictionaryItemResponse>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new entries to the dictionary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<HlmCreateDictionaryItemResponse> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<HlmCreateDictionaryItemResponse>): HlmCreateDictionaryItemResponse => r.body)
    );
  }

  /** Path part for operation `createDictionary()` */
  static readonly CreateDictionaryPath = '/api/hld/dictionary/absence_type';

  /**
   * Add new entries to the dictionary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDictionary()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDictionary$Response(params: CreateDictionary$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmCreateDictionaryResponse>> {
    return createDictionary(this.http, this.rootUrl, params, context);
  }

  /**
   * Add new entries to the dictionary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDictionary$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDictionary(params: CreateDictionary$Params, context?: HttpContext): Observable<HlmCreateDictionaryResponse> {
    return this.createDictionary$Response(params, context).pipe(
      map((r: StrictHttpResponse<HlmCreateDictionaryResponse>): HlmCreateDictionaryResponse => r.body)
    );
  }

  /** Path part for operation `delete()` */
  static readonly DeletePath = '/api/hld/dictionary/absence_type';

  /**
   * Delete entries from the dictionary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmDeleteDictionaryItemResponse>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete entries from the dictionary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<HlmDeleteDictionaryItemResponse> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<HlmDeleteDictionaryItemResponse>): HlmDeleteDictionaryItemResponse => r.body)
    );
  }

}
