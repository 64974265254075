import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CnmContent,
  CnmListContextReadResponse,
  CnmRelatedTopicsReadResponse,
  CnmTopic,
} from 'src/app/api/ctx/models';
import { ContextHelpReadService } from 'src/app/api/ctx/services';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  animations: [
    trigger('openClose', [
      state('false', style({ height: '500px', width: '400px' })),
      state('true', style({ height: '800px', width: '700px' })),
      transition('false <=> true', animate(200)),
    ]),
  ],
})
export class HelpComponent {
  constructor(private _contextHelp: ContextHelpReadService) { }
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  helpItems: { subject: string; context: string }[] = [];
  helpTopics: CnmTopic[] = undefined;
  item: CnmContent;
  pageContent: CnmContent = undefined;
  itemShow = false;
  isResize = false;
  private _url = '/';

  @Input()
  set url(val: any) {
    this._url = val;
    this.getTopics();
    this.getContext();
  }

  resize() {
    this.isResize = !this.isResize;
  }
  showItem(item) {
    this.item = item;
    this.itemShow = true;
  }

  closeItem() {
    this.itemShow = false;
  }

  closeHelp() {
    this.close.emit(false);
  }

  getTopics() {
    this._contextHelp
      .relatedTopicsReadUsingGET({
        language: 'pl',
        path: this._url,
      })
      .subscribe((result: CnmRelatedTopicsReadResponse) => {
        if (result.topics.length) this.helpTopics = result.topics;
      });
  }

  getItemContext(item: CnmTopic) {
    this._contextHelp
      .listContextUsingGET({
        language: item.language,
        path: item.path,
        header: item.subject,
      })
      .subscribe((result: CnmListContextReadResponse) => {
        this.item = result.content;
        this.showItem(this.item);
      });
  }

  getContext() {
    this._contextHelp
      .listContextUsingGET({
        language: 'pl',
        path: this._url,
        header: null,
      })
      .subscribe((result: CnmListContextReadResponse) => {
        this.pageContent = result.content;
      });
  }
}
