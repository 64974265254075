import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../core/state/app-state';
import {User} from '../../core/service/user/user';
import { isNullOrUndefined } from "src/app/shared/utilities";

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {
  public logged = false;
  public calculatedColor = 'module_0';
  @Input() moduleName: string;
  @Input() color;
  @Input() icon = '';
  @Input() routeTo = '';
  @Input() counter = 0;
  @Input() important = 0;
  @Output() button_pressed: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private _store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    const object = this;
    this._store.select('user').subscribe(
      (user: User) => {
        object.logged = user.logged();

        if (object.logged) {
          object.calculatedColor = object.color;
        } else {
          object.calculatedColor = 'module_0';
        }
      }
    );
  }

  navigate() {
    if (this.logged) {
      if (this.routeTo !== '') {
        this.router.navigate([this.routeTo]);
      } else {
        this.emit_press();
      }
    }
  }

  emit_press() {
    if (!isNullOrUndefined(this.button_pressed)) {
      this.button_pressed.emit();
    }
  }
}
