/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ListOfIncidents200ResponseDto } from '../../models/list-of-incidents-200-response-dto';

export interface ListOfIncidents$Params {

/**
 * Sort results by the field, field:direction, direction asc, desc
 */
  sort?: Array<string>;

/**
 * Number of results on a page
 */
  max?: number;

/**
 * Page number to read, first page is 1
 */
  page?: number;

/**
 * Filter to query the backend, each entry has three parts name:comparator:value, to search incident for status NEW we can use status:eq:NEW. Available filters are: jobNo, jobName, title, status, description, correctActions, login, userName, userSurname, createDate, category; Available comparators are: eq, equal, equalOrNull, contains, containsAll, containsOrAll, starts, ends, lt, gt, in, inOrNull, ge, geOrNull, le, leOrNull, empty, id, notIn, range, between, like
 */
  filter?: Array<string>;
}

export function listOfIncidents(http: HttpClient, rootUrl: string, params?: ListOfIncidents$Params, context?: HttpContext): Observable<StrictHttpResponse<ListOfIncidents200ResponseDto>> {
  const rb = new RequestBuilder(rootUrl, listOfIncidents.PATH, 'get');
  if (params) {
    rb.query('sort', params.sort, {});
    rb.query('max', params.max, {});
    rb.query('page', params.page, {});
    rb.query('filter', params.filter, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ListOfIncidents200ResponseDto>;
    })
  );
}

listOfIncidents.PATH = '/api/ehs/incident';
