/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InmInventoryEntityLedgerListResponse } from '../../models/inm-inventory-entity-ledger-list-response';

export interface InventoryLedgerForEntity$Params {
  entityNumber: string;
  entityType: string;
  inventoryUid?: string;
  page: number;
  max?: number;
  from?: string;
  to?: string;
}

export function inventoryLedgerForEntity(http: HttpClient, rootUrl: string, params: InventoryLedgerForEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryEntityLedgerListResponse>> {
  const rb = new RequestBuilder(rootUrl, inventoryLedgerForEntity.PATH, 'get');
  if (params) {
    rb.query('entityNumber', params.entityNumber, {});
    rb.query('entityType', params.entityType, {});
    rb.query('inventoryUid', params.inventoryUid, {});
    rb.query('page', params.page, {});
    rb.query('max', params.max, {});
    rb.query('from', params.from, {});
    rb.query('to', params.to, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InmInventoryEntityLedgerListResponse>;
    })
  );
}

inventoryLedgerForEntity.PATH = '/api/inv/inventory/entity/ledger/entries';
