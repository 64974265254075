<span *ngIf="counter > 0 || isFlagUp" style="z-index: 100001" class="w-100">
  <div
    class="container-fluid no_padding fixed-bottom"
    [ngClass]="{ footer: !is_open, extended_footer: is_open }"
    *ngIf="isMobile"
  >
    <div
      class="row no_padding"
      [ngClass]="{ panel: !is_open, extended_panel: is_open }"
    >
      <div class="col-12">
        <div class="container-fluid">
          <div
            class="row align-middle" style="height: 85px;"
          >
              <div
                class="
                  col-5
                  d-flex
                  justify-content-start
                  align-items-center
                  v_field_label_theme_bold v_title_upper
                  cursor-pointer
                "
                *ngIf="isFlagUp"
                (click)="viewInbox()"
              >
                <app-notification-box-icon
                  [icon]="'markunread_mailbox'"
                  [counter]="0"
                  [is_open]="is_open"
                ></app-notification-box-icon>
                <span class="message">{{
                  'message.new_messages' | translate
                }}</span>
              </div>
            <div
              class="
                d-flex
                justify-content-start
                align-items-center
                v_field_label_theme_bold v_title_upper
              "
                [ngClass]="{ 'col-10': !isFlagUp, 'col-5': isFlagUp }"
                (click)="is_open = !is_open"
                *ngIf="counter > 0"
            >
              <app-notification-box-icon
                [icon]="'notifications'"
                [counter]="0"
                [is_open]="is_open"
              ></app-notification-box-icon>
              <span class="message">{{ 'label.notifications' | translate }}</span>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <div *ngIf="!is_open" class="clickable section_label">
                <mat-icon>arrow_drop_up</mat-icon>
              </div>
              <div *ngIf="is_open" class="clickable section_label">
                <mat-icon>arrow_drop_down</mat-icon>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="is_open">
            <div class="col-12" style="max-height: 300px; overflow: auto">
              <app-message-notification></app-message-notification>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-top: 40px" *ngIf="!isMobile"  [ngClass]="{'w-full fixed bottom-0': true, open_section_bg: is_open, section_bg: !is_open}">
    <div class="container-fluid calendar_view">
      <div class="row">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <div class="container-fluid">
            <div class="row">
              <div
                class="
                  col-5
                  d-flex
                  justify-content-start
                  align-items-center
                  v_field_label_theme_bold v_title_upper
                  cursor-pointer
                "
                *ngIf="isFlagUp"
                (click)="viewInbox()"
              >
                <app-notification-box-icon
                  [icon]="'markunread_mailbox'"
                  [counter]="0"
                  [is_open]="is_open"
                ></app-notification-box-icon>
                <span class="message">{{
                  'message.new_messages' | translate
                }}</span>
              </div>
              <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  v_field_label_theme_bold v_title_upper
                  cursor-pointer
                "
                [ngClass]="{ 'col-10': !isFlagUp, 'col-5': isFlagUp }"
                (click)="is_open = !is_open"
                *ngIf="counter > 0"
              >
                <app-notification-box-icon
                  [icon]="'notifications'"
                  [counter]="counter"
                  [is_open]="is_open"
                ></app-notification-box-icon>
                <span class="message">{{
                  'label.notifications' | translate
                }}</span>
              </div>
              <div
                class="col-2 d-flex justify-content-center align-items-center"
                *ngIf="counter > 0"
              >
                <div *ngIf="!is_open" class="clickable section_label">
                  <mat-icon>arrow_drop_up</mat-icon>
                </div>
                <div *ngIf="is_open" class="clickable section_label">
                  <mat-icon>arrow_drop_down</mat-icon>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="is_open"
              style="padding-top: 20px; height: 300px"
            >
              <div class="col-12" style="max-height: 300px; overflow: auto">
                <app-message-notification
                  [flag]="isFlagUp"
                ></app-message-notification>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</span>
