/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { crateInventoryDefinition } from '../fn/inventory-write/crate-inventory-definition';
import { CrateInventoryDefinition$Params } from '../fn/inventory-write/crate-inventory-definition';
import { InmInventoryItemCreateResponse } from '../models/inm-inventory-item-create-response';
import { InmInventoryItemUpdateResponse } from '../models/inm-inventory-item-update-response';
import { InmInventoryTransferResponse } from '../models/inm-inventory-transfer-response';
import { InmInventoryWitdrawResponse } from '../models/inm-inventory-witdraw-response';
import { InmTransferAcceptResponse } from '../models/inm-transfer-accept-response';
import { InmTransferRejectResponse } from '../models/inm-transfer-reject-response';
import { transfer } from '../fn/inventory-write/transfer';
import { Transfer$Params } from '../fn/inventory-write/transfer';
import { transferAccept } from '../fn/inventory-write/transfer-accept';
import { TransferAccept$Params } from '../fn/inventory-write/transfer-accept';
import { transferReject } from '../fn/inventory-write/transfer-reject';
import { TransferReject$Params } from '../fn/inventory-write/transfer-reject';
import { updateInventoryItem } from '../fn/inventory-write/update-inventory-item';
import { UpdateInventoryItem$Params } from '../fn/inventory-write/update-inventory-item';
import { withdraw } from '../fn/inventory-write/withdraw';
import { Withdraw$Params } from '../fn/inventory-write/withdraw';

@Injectable({ providedIn: 'root' })
export class InventoryWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateInventoryItem()` */
  static readonly UpdateInventoryItemPath = '/api/inv/inventory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInventoryItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInventoryItem$Response(params: UpdateInventoryItem$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryItemUpdateResponse>> {
    return updateInventoryItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInventoryItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInventoryItem(params: UpdateInventoryItem$Params, context?: HttpContext): Observable<InmInventoryItemUpdateResponse> {
    return this.updateInventoryItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryItemUpdateResponse>): InmInventoryItemUpdateResponse => r.body)
    );
  }

  /** Path part for operation `crateInventoryDefinition()` */
  static readonly CrateInventoryDefinitionPath = '/api/inv/inventory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crateInventoryDefinition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crateInventoryDefinition$Response(params: CrateInventoryDefinition$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryItemCreateResponse>> {
    return crateInventoryDefinition(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crateInventoryDefinition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crateInventoryDefinition(params: CrateInventoryDefinition$Params, context?: HttpContext): Observable<InmInventoryItemCreateResponse> {
    return this.crateInventoryDefinition$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryItemCreateResponse>): InmInventoryItemCreateResponse => r.body)
    );
  }

  /** Path part for operation `transferReject()` */
  static readonly TransferRejectPath = '/api/inv/inventory/{inventoryUid}/transfer/{transferUid}/reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transferReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferReject$Response(params: TransferReject$Params, context?: HttpContext): Observable<StrictHttpResponse<InmTransferRejectResponse>> {
    return transferReject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transferReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferReject(params: TransferReject$Params, context?: HttpContext): Observable<InmTransferRejectResponse> {
    return this.transferReject$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmTransferRejectResponse>): InmTransferRejectResponse => r.body)
    );
  }

  /** Path part for operation `transferAccept()` */
  static readonly TransferAcceptPath = '/api/inv/inventory/{inventoryUid}/transfer/{transferUid}/accept';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transferAccept()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferAccept$Response(params: TransferAccept$Params, context?: HttpContext): Observable<StrictHttpResponse<InmTransferAcceptResponse>> {
    return transferAccept(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transferAccept$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferAccept(params: TransferAccept$Params, context?: HttpContext): Observable<InmTransferAcceptResponse> {
    return this.transferAccept$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmTransferAcceptResponse>): InmTransferAcceptResponse => r.body)
    );
  }

  /** Path part for operation `withdraw()` */
  static readonly WithdrawPath = '/api/inv/inventory/withdraw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `withdraw()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  withdraw$Response(params: Withdraw$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryWitdrawResponse>> {
    return withdraw(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `withdraw$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  withdraw(params: Withdraw$Params, context?: HttpContext): Observable<InmInventoryWitdrawResponse> {
    return this.withdraw$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryWitdrawResponse>): InmInventoryWitdrawResponse => r.body)
    );
  }

  /** Path part for operation `transfer()` */
  static readonly TransferPath = '/api/inv/inventory/transfer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transfer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transfer$Response(params: Transfer$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryTransferResponse>> {
    return transfer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transfer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transfer(params: Transfer$Params, context?: HttpContext): Observable<InmInventoryTransferResponse> {
    return this.transfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryTransferResponse>): InmInventoryTransferResponse => r.body)
    );
  }

}
