/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AcmAssignSuperiorResponse } from '../models/acm-assign-superior-response';
import { AcmDeleteSuperiorResponse } from '../models/acm-delete-superior-response';
import { assignSuperior } from '../fn/superior/assign-superior';
import { AssignSuperior$Params } from '../fn/superior/assign-superior';
import { deleteSuperior } from '../fn/superior/delete-superior';
import { DeleteSuperior$Params } from '../fn/superior/delete-superior';

@Injectable({ providedIn: 'root' })
export class SuperiorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `assignSuperior()` */
  static readonly AssignSuperiorPath = '/api/acc/account/{account}/superior/{superior}';

  /**
   * Assign the supperior to the account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSuperior()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignSuperior$Response(params: AssignSuperior$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAssignSuperiorResponse>> {
    return assignSuperior(this.http, this.rootUrl, params, context);
  }

  /**
   * Assign the supperior to the account
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignSuperior$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignSuperior(params: AssignSuperior$Params, context?: HttpContext): Observable<AcmAssignSuperiorResponse> {
    return this.assignSuperior$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAssignSuperiorResponse>): AcmAssignSuperiorResponse => r.body)
    );
  }

  /** Path part for operation `deleteSuperior()` */
  static readonly DeleteSuperiorPath = '/api/acc/account/{account}/superior';

  /**
   * Assign the supperior to the account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSuperior()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSuperior$Response(params: DeleteSuperior$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmDeleteSuperiorResponse>> {
    return deleteSuperior(this.http, this.rootUrl, params, context);
  }

  /**
   * Assign the supperior to the account
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSuperior$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSuperior(params: DeleteSuperior$Params, context?: HttpContext): Observable<AcmDeleteSuperiorResponse> {
    return this.deleteSuperior$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmDeleteSuperiorResponse>): AcmDeleteSuperiorResponse => r.body)
    );
  }

}
