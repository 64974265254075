import { NgModule } from '@angular/core';
import { NumberFormatPipe } from './number-format.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SearchProjectDialogComponent } from './search-project-dialog/search-project-dialog.component';
import { YesnocancelDialogComponent } from './yesnocancel-dialog/yesnocancel-dialog.component';
import { OkDialogComponent } from './ok-dialog/ok-dialog.component';
import { UserSelectDialogComponent } from './user-select-dialog/user-select-dialog.component';
import { SortIndicatorComponent } from './sort-indicator/sort-indicator.component';
import { UserMailDialogComponent } from './user-mail-dialog/user-mail-dialog.component';
import { SelectMonthDialogComponent } from './select-month-dialog/select-month-dialog.component';
import { LoadingMessageComponent } from './loading-message/loading-message.component';
import { TopMenuModule } from '../pages/top-menu/top-menu.module';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DiaryComponent } from './diary/diary.component';
import { DiaryListComponent } from './diary/diary-list/diary-list.component';
import { DiaryEntryComponent } from './diary/diary-entry/diary-entry.component';
import { ComponentsModule } from '../components/components.module';

@NgModule(
  {
    imports: [
      TopMenuModule,
      MatInputModule,
      FormsModule,
      MatButtonModule,
      OverlayModule,
      CommonModule,
      MatIconModule,
      TranslateModule,
      MatDialogModule,
      MatDatepickerModule,
      ComponentsModule
    ],
    exports: [
      SortIndicatorComponent,
      LoadingMessageComponent,
      NumberFormatPipe,
      DiaryComponent
    ],
    declarations: [
      NumberFormatPipe,
      ConfirmDialogComponent,
      SearchProjectDialogComponent,
      YesnocancelDialogComponent,
      OkDialogComponent,
      UserSelectDialogComponent,
      SortIndicatorComponent,
      SelectMonthDialogComponent,
      UserMailDialogComponent,
      LoadingMessageComponent,
      DiaryComponent,
      DiaryListComponent,
      DiaryEntryComponent,
    ],
  },
)
export class SharedModule{}
