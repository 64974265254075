import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/core/state/app-state";
import { isNullOrUndefined } from "src/app/shared/utilities";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-site-reporting-project",
  templateUrl: "./site-reporting-project.component.html",
  styleUrls: ["./site-reporting-project.component.scss"],
})
export class SiteReportingProjectComponent implements OnInit {
  public project_code: string = null;
  public initial_project_code: string = null;

  @Output() project_set: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public dialog: MatDialog,
    private _store: Store<AppState>,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._route.queryParams.subscribe((params) => {
      var project = params["project"];
      this.initial_project_code = project;
      this.project_code = project;
    });
  }

  public onProjectChoosen(project_code: string): void {
    this.project_code = project_code;

    this.on_project_changed();
  }

  private on_project_changed(): void {
    if (!isNullOrUndefined(this.project_code)) {
      this.project_set.emit(this.project_code);
    }
  }
}
