<h1 mat-dialog-title class="v_text_header" style="margin-bottom: 0;">{{data.title}}</h1>
<div mat-dialog-content>
  <mat-calendar
    startView="year"
    (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event)"
    (selectedChange)="choosenDate($event)"
    [startAt]="data.initialDate"
  ></mat-calendar>
</div>
<div class="container-fluid" style="margin-bottom: 10px;">
  <div class="row" mat-dialog-actions>
    <div class="col-12 d-flex justify-content-center align-items-center">
      <button class="standard-button-action" mat-button
              style="width: 90%; text-transform: uppercase;"
              (click)="select_all()"
              *ngIf="data.allow_select_all"
      >
      {{'label.all'|translate}}
      </button>
      <button class="standard-button-action" mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
    </div>
  </div>
</div>
