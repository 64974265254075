<div class="container-fluid payment_box component-shadow" (click)="navigate()">
  <div class="row" style="height: 100%;">
    <div class="col-12" style="height: 100%;">
      <div class="container-fluid" style="padding: 0; height: 100%;">
        <div class="row" style="height: 50%;">
          <div class="col-12 d-flex justify-content-start align-items-start" style="height: 100%; padding-top: 15px; text-transform: uppercase; font-weight: 600;
font-size: 13px;">
            <img src="{{icon}}" class="h-3/4" alt="icon"/>
            <div *ngIf="counter > 0" class="d-flex justify-content-center align-items-center counter">{{counter}}</div>
            <div *ngIf="important > 0"  class="d-flex justify-content-center align-items-center important">{{important}}</div>
          </div>
        </div>
        <div class="row" style="height: 50%;">
          <div class="col-12 d-flex justify-content-start align-items-end" style="height: 100%; padding-bottom: 10px; text-transform: uppercase; font-weight: 600;
font-size: 13px;">
            <span [class]="calculatedColor" style='color: var(--color-theme-900)'>{{moduleName | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
