import { NgModule } from '@angular/core';
import { MonthSelectorComponent } from './month-selector/month-selector.component';
import { CompanySelectorComponent } from './company-selector/company-selector.component';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { MessageNotificationComponent } from './notification/message-notification/message-notification.component';
import { NumericInputModule } from './numeric-keyboard';
import { NotificationBoxComponent } from './notification-box/notification-box.component';
import { NotificationBoxIconComponent } from './notification-box/notification-box-icon/notification-box-icon.component';
import {
  MessageNotificationComponentItemComponent
} from './notification/message-notification/message-notification-component-item/message-notification-component-item.component';
import { SiteReportingProjectComponent } from './site-reporting/site-reporting-project/site-reporting-project.component';
import {
  SiteReportingSummaryCategoryComponent
} from './site-reporting/site-reporting-summary-category/site-reporting-summary-category.component';
import { SiteReportingSummaryComponent } from './site-reporting/site-reporting-summary/site-reporting-summary.component';
import { ProjectChooserComponent } from './project-chooser/project-chooser.component';
import {
  SiteReportingProjectModificationComponent
} from './site-reporting/site-reporting-project-modification/site-reporting-project-modification.component';
import { SiteReportingProjectStateComponent } from './site-reporting/site-reporting-project-state/site-reporting-project-state.component';
import { PageSelectorComponent } from './page-selector/page-selector.component';
import { SelectMonthDialogComponent } from '../shared/select-month-dialog/select-month-dialog.component';
import { UserMailDialogComponent } from '../shared/user-mail-dialog/user-mail-dialog.component';
import { UserSelectDialogComponent } from '../shared/user-select-dialog/user-select-dialog.component';
import { OkDialogComponent } from '../shared/ok-dialog/ok-dialog.component';
import { YesnocancelDialogComponent } from '../shared/yesnocancel-dialog/yesnocancel-dialog.component';
import { SearchProjectDialogComponent } from '../shared/search-project-dialog/search-project-dialog.component';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule(
  {
    imports: [
      NumericInputModule,
      CommonModule,
      TranslateModule,
      MatIconModule,
      MatDatepickerModule,
    ],
    declarations: [
      MonthSelectorComponent,
      CompanySelectorComponent,
      NumbersOnlyDirective,
      MessageNotificationComponent,
      NotificationBoxComponent,
      NotificationBoxIconComponent,
      MessageNotificationComponentItemComponent,
      SiteReportingProjectComponent,
      SiteReportingSummaryCategoryComponent,
      SiteReportingSummaryComponent,
      ProjectChooserComponent,
      SiteReportingProjectModificationComponent,
      SiteReportingProjectStateComponent,
      PageSelectorComponent,
    ],
    exports: [
      MonthSelectorComponent,
      CompanySelectorComponent,
      NotificationBoxComponent,
      NumbersOnlyDirective,
      SiteReportingProjectStateComponent,
      SiteReportingProjectComponent,
      SiteReportingProjectModificationComponent,
      SiteReportingSummaryComponent,
      SiteReportingSummaryCategoryComponent,
      PageSelectorComponent
    ],
    entryComponents: [
      ConfirmDialogComponent,
      SearchProjectDialogComponent,
      YesnocancelDialogComponent,
      OkDialogComponent,
      UserSelectDialogComponent,
      UserMailDialogComponent,
      SelectMonthDialogComponent,
    ],
  },
)
export class ComponentsModule{}
