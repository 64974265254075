/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExaImportResourceCostRequest } from '../../models/exa-import-resource-cost-request';

export interface ImportCosts$Params {
  body: ExaImportResourceCostRequest;
}

export function importCosts(
  http: HttpClient,
  rootUrl: string,
  params: ImportCosts$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, importCosts.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http
    .request(rb.build({ responseType: 'json', accept: '*/*', context }))
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      }),
    );
}

importCosts.PATH = '/api/exp/import/resource_cost';
