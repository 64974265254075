<h1 mat-dialog-title class="v_text_header">{{data.title | translate}}</h1>
<div mat-dialog-content>
  <p class="v_field_label_theme">{{data.content | translate}}</p>
</div>
<div class="container-fluid">
  <div class="row" mat-dialog-actions>
    <div class="col-6 d-flex justify-content-end align-items-center">
      <button class="standard-button-cancel" mat-button (click)="onNoClick()">{{'label.cancel'|translate}}</button>
    </div>
    <div class="col-6 d-flex justify-content-center align-items-center text-center">
      <button class="standard-button-action text-center" mat-button [mat-dialog-close]="true" cdkFocusInitial style="min-width: 120px;">{{'label.confirm' | translate }}</button>
    </div>
  </div>
</div>
