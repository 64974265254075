/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CmmMessageReadResponse } from '../models/cmm-message-read-response';
import { CmmMessageStatsResponse } from '../models/cmm-message-stats-response';

/**
 * Cma Message Read
 */
@Injectable({
  providedIn: 'root',
})
class MessageReadService extends __BaseService {
  static readonly readUsingGET1Path = '/api/cmm/message/{identifier}';
  static readonly messagesStatsUsingGETPath = '/api/cmm/messages/stats';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * read
   * @param params The `MessageReadService.ReadUsingGET1Params` containing the following parameters:
   *
   * - `identifier`: identifier
   *
   * - `header`: header
   *
   * @return OK
   */
  readUsingGET1Response(params: MessageReadService.ReadUsingGET1Params): __Observable<__StrictHttpResponse<CmmMessageReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/cmm/message/${encodeURIComponent(String(params.identifier))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmMessageReadResponse>;
      })
    );
  }
  /**
   * read
   * @param params The `MessageReadService.ReadUsingGET1Params` containing the following parameters:
   *
   * - `identifier`: identifier
   *
   * - `header`: header
   *
   * @return OK
   */
  readUsingGET1(params: MessageReadService.ReadUsingGET1Params): __Observable<CmmMessageReadResponse> {
    return this.readUsingGET1Response(params).pipe(
      __map(_r => _r.body as CmmMessageReadResponse)
    );
  }

  /**
   * messagesStats
   * @param header header
   * @return OK
   */
  messagesStatsUsingGETResponse(header?: string): __Observable<__StrictHttpResponse<CmmMessageStatsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (header != null) __headers = __headers.set('header', header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/cmm/messages/stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmMessageStatsResponse>;
      })
    );
  }
  /**
   * messagesStats
   * @param header header
   * @return OK
   */
  messagesStatsUsingGET(header?: string): __Observable<CmmMessageStatsResponse> {
    return this.messagesStatsUsingGETResponse(header).pipe(
      __map(_r => _r.body as CmmMessageStatsResponse)
    );
  }
}

module MessageReadService {

  /**
   * Parameters for readUsingGET1
   */
  export interface ReadUsingGET1Params {

    /**
     * identifier
     */
    identifier: string;

    /**
     * header
     */
    header?: string;
  }
}

export { MessageReadService }
