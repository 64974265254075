/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PrmJobTaskReadResponse } from '../../models/prm-job-task-read-response';

export interface ReadResponseResponseEntity1$Params {
  group: string;
  no: string;
  type: string;
}

export function readResponseResponseEntity1(http: HttpClient, rootUrl: string, params: ReadResponseResponseEntity1$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobTaskReadResponse>> {
  const rb = new RequestBuilder(rootUrl, readResponseResponseEntity1.PATH, 'get');
  if (params) {
    rb.path('group', params.group, {});
    rb.path('no', params.no, {});
    rb.path('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PrmJobTaskReadResponse>;
    })
  );
}

readResponseResponseEntity1.PATH = '/api/prj/jobtask/{group}/{no}/{type}';
