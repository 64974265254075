/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { materialsRead } from '../fn/report-materials-read/materials-read';
import { MaterialsRead$Params } from '../fn/report-materials-read/materials-read';
import { NvmQueryReportAvailableMaterialsResponse } from '../models/nvm-query-report-available-materials-response';

@Injectable({ providedIn: 'root' })
export class ReportMaterialsReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `materialsRead()` */
  static readonly MaterialsReadPath = '/api/nav/materials/{jobNo}/{category}/{range}';

  /**
   * Read entries for the category in specified job and return unique list of available tasks and used descriptions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `materialsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  materialsRead$Response(params: MaterialsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryReportAvailableMaterialsResponse>> {
    return materialsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * Read entries for the category in specified job and return unique list of available tasks and used descriptions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `materialsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  materialsRead(params: MaterialsRead$Params, context?: HttpContext): Observable<NvmQueryReportAvailableMaterialsResponse> {
    return this.materialsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryReportAvailableMaterialsResponse>): NvmQueryReportAvailableMaterialsResponse => r.body)
    );
  }

}
