import { CmmNotificationReadResponse } from './../../api/cmm/models/cmm-notification-read-response';
import { Component, OnInit } from '@angular/core';
import { User } from '../../core/service/user/user';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/state/app-state';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { ABSENCEACCEPT } from '../../core/state/reducers/absenceaccept.reducer';
import { AbsenceReadService } from '../../api/hld/services/absence-read.service';
import { SnackMessageService } from '../../core/service/snack-message.service';
import { ErrorHandlerService } from '../../core/service/error-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _moment from 'moment';
import { PraProjectReadService } from '../../api/prj/services';

import { TsmReadTaskListCountResponse } from 'src/app/api/tsk/models';
import { MessageReadService } from 'src/app/api/cmm/services/message-read.service';
import { NotificationReadService } from 'src/app/api/cmm/services';
import { TsaReadTaskListCountService } from 'src/app/api/tsk/services';
import { StatsService } from 'src/app/api/ehs/services';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  public loginInProgress = true;
  public columns = 4;
  public unacceptedAbsences = 0;
  public unacceptedTimesheets = 0;
  public numOfMessage = 0;
  public numOfUnreadZPW = 0
  public numOfImportantMessage = 0;
  public awaitingTasks = 0;
  private _user: User;
  public isFlagUp = false;

  public visibleModules = {
    work: true,
    work_v2: false,
    administration: true,
    holidays: true,
    holidays_v2: true,
    holidaysAcceptance: true,
    message: true,
    projects: false,
    report: false,
    acceptance: false,
    siteReportingTest: false,
    volumeOfOrders: false,
    operations: false,
    reports: false,
    inventory: false,
    workerPage: false,
    task: false,
    hr: false,
    ehs: false
  };
  private _project = '';
  bannerMessages: Array<any> = undefined;

  constructor(
    private notificationReadService: NotificationReadService,
    private _messageReadService: MessageReadService,
    private _store: Store<AppState>,
    private _snackMessage: SnackMessageService,
    private _errorHandler: ErrorHandlerService,
    private _absenceReadService: AbsenceReadService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _projectReadService: PraProjectReadService,
    private _taskCountReadService: TsaReadTaskListCountService,
    private _statService: StatsService
  ) { }

  goto(module) {
    if (this.visibleModules.work_v2 && module == 'WORK')
      this.on_work_v2_selected();
    else if (this.visibleModules.work && module == 'WORK')
      this.on_work_selected();
    else this._router.navigateByUrl(module);
  }

  ngOnInit() {
    this.readNumOfZPW()
    this.readNotifications();
    this.loadMessagesStat();
    this._store.select('project').subscribe((project: string) => {
      this._project = project;
    });

    this._store.select('absenceaccept').subscribe((value) => {
      this.unacceptedAbsences = value;
    });

    this._route.queryParams.subscribe((params) => {
      this._store.select('user').subscribe((user: User) => {
        this.loginInProgress = !user.logged();
        this.visibleModules.holidaysAcceptance = user.absenceAcceptor();
        this.visibleModules.volumeOfOrders = user.inRole('UI_VOLUME_OF_ORDERS');
        this.visibleModules.projects = user.inRole('ENGINEER') || user.inRole('MANAGER');
        this.visibleModules.acceptance = user.inRole('FN_ACCEPT_TIMESHEET');
        this.visibleModules.administration = user.inRole('UI_ADMINISTRATOR');
        this.visibleModules.operations = user.inRole('UI_OPERATIONS');
        this.visibleModules.reports = user.inRole('UI_REPORTS');
        this.visibleModules.inventory = user.inRole('UI_INVENTORY');
        this.visibleModules.workerPage = user.inRole('UI_WORKER_PAGE');
        this.visibleModules.work_v2 = user.inRole('UI_WORK_V2');
        this.visibleModules.task = user.inRole('UI_TASK');
        this.visibleModules.message = user.inRole('UI_MESSAGE');
        this.visibleModules.hr = user.inRole('MODULE_HR');
        this.visibleModules.ehs = user.inRole('MODULE_EHS'); //Zmienić gdy już bedzie ok
        this.visibleModules.siteReportingTest = user.inRole(
          'UI_SHOW_SITE_REPORTING_TEST',
        );
        this._user = user;

        if (this._user.logged()) {
          this.checkAwaitingAbsences();

          if (this.visibleModules.acceptance) {
            this.checkAwaitingAccept();
          }

          if (this.visibleModules.task) {
            this.checkAwaitingTasks();
          }

          this.visibleModules.report = true;
        }
        this.columns = this.columnCount();
      });
    });
  }

  private columnCount(): number {
    let result = 0;

    if (this.visibleModules.acceptance) {
      result++;
    }
    if (this.visibleModules.holidaysAcceptance) {
      result++;
    }
    if (this.visibleModules.holidays) {
      result++;
    }
    if (this.visibleModules.holidays_v2) {
      result++;
    }
    if (this.visibleModules.report) {
      result++;
    }
    if (this.visibleModules.projects) {
      result++;
    }
    if (this.visibleModules.work_v2) {
      result++;
    }
    if (this.visibleModules.work) {
      result++;
    }
    if (this.visibleModules.reports) {
      result++;
    }
    if (this.visibleModules.operations) {
      result++;
    }
    if (this.visibleModules.administration) {
      result++;
    }
    if (this.visibleModules.siteReportingTest) {
      result++;
    }
    if (this.visibleModules.volumeOfOrders) {
      result++;
    }
    if (this.visibleModules.message) {
      result++;
    }

    if (this.visibleModules.task) {
      result++;
    }
    if (this.visibleModules.workerPage) {
      result++;
    }
    if (this.visibleModules.inventory) {
      result++;
    }
    if (this.visibleModules.hr) {
      result++;
    }
    if (this.visibleModules.ehs) {
      result++;
    }

    if (result >= 5) {
      result = 3;
    } else if (result === 3) {
      result = 4;
    } else if (result === 2) {
      result = 6;
    } else if (result === 1) {
      result = 12;
    }
    return result;
  }

  readNumOfZPW(){
    this._statService.readIncidentStats({}).subscribe(elem =>{
      this.numOfUnreadZPW = elem.unread
    })
  }

  readNotifications() {
    this.notificationReadService
      .readNotificationsUsingGET('')
      .subscribe((result: CmmNotificationReadResponse) => {
        this.bannerMessages = result.notifications;
      });
  }

  private checkAwaitingAbsences() {
    this._absenceReadService
      .read1({
        user: this._user.login(),
        state: ['NEW', 'CANCEL_PENDING'],
      })
      .subscribe(
        (result) => {
          if (!isNullOrUndefined(result.absences)) {
            this._store.dispatch({
              type: ABSENCEACCEPT,
              payload: {
                number: result.absences.length,
              },
            });
          }
        },
        (error) => {
          const errorCode = this._errorHandler.errorCode(error);

          if (errorCode === 'UNKNOWN_CODE') {
            this._snackMessage.communicateError(errorCode);
          } else {
            this._snackMessage.communicateError('ERR.' + errorCode);
          }
        },
      );
  }

  private checkAwaitingAccept() {
    this._projectReadService.countUnaccepted().subscribe(
      (result) => {
        this.unacceptedTimesheets = result.count;
      },
      (error) => {
        const errorCode = this._errorHandler.errorCode(error);

        if (errorCode === 'UNKNOWN_CODE') {
          this._snackMessage.communicateError(errorCode);
        } else {
          this._snackMessage.communicateError('ERR.' + errorCode);
        }
      },
    );
  }

  private checkAwaitingTasks() {
    this._taskCountReadService.readListCount().subscribe(
      (result: TsmReadTaskListCountResponse) => {
        this.awaitingTasks = result.count;
      },
      (error: any) => {
        this._snackMessage.communicateError(
          this._errorHandler.errorCode(error),
        );
      },
    );
  }

  on_work_selected() {
    this._router.navigate(['work/standard'], {
      queryParams: {
        project: this._project,
        date: _moment(new Date()).format('YYYY-MM-DD'),
      },
    });
  }

  on_work_v2_selected() {
    this._router.navigate(['work/standard'], {
      queryParams: {
        project: this._project,
        date: _moment(new Date()).format('YYYY-MM-DD'),
      },
    });
  }

  private loadMessagesStat(): void {
    this._messageReadService.messagesStatsUsingGET().subscribe(
      (result) => {
        this.numOfImportantMessage = result.important;
        this.numOfMessage = result.all;
      },
      (error: any) => {
        this._snackMessage.communicateError(
          this._errorHandler.errorCode(error),
        );
      },
    );
  }
}
