/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { accept } from '../fn/absence-write/accept';
import { Accept$Params } from '../fn/absence-write/accept';
import { cancelAbsence } from '../fn/absence-write/cancel-absence';
import { CancelAbsence$Params } from '../fn/absence-write/cancel-absence';
import { cancelAbsenceRetract } from '../fn/absence-write/cancel-absence-retract';
import { CancelAbsenceRetract$Params } from '../fn/absence-write/cancel-absence-retract';
import { create1 } from '../fn/absence-write/create-1';
import { Create1$Params } from '../fn/absence-write/create-1';
import { delete1 } from '../fn/absence-write/delete-1';
import { Delete1$Params } from '../fn/absence-write/delete-1';
import { HlmAbsenceAcceptWriteResponse } from '../models/hlm-absence-accept-write-response';
import { HlmAbsenceCancelResponse } from '../models/hlm-absence-cancel-response';
import { HlmAbsenceCancelRetractResponse } from '../models/hlm-absence-cancel-retract-response';
import { HlmAbsenceDeleteWriteResponse } from '../models/hlm-absence-delete-write-response';
import { HlmRegisterAbsenceResponse } from '../models/hlm-register-absence-response';
import { HlmUpdateAbsenceResponse } from '../models/hlm-update-absence-response';
import { update } from '../fn/absence-write/update';
import { Update$Params } from '../fn/absence-write/update';

@Injectable({ providedIn: 'root' })
export class AbsenceWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accept()` */
  static readonly AcceptPath = '/api/hld/absence/{user}/accept';

  /**
   * Change state of the inquire for the absence. The change can be to ACCEPTED or REJECTED, ACCEPT_RETRACTION, REJECT_RETRACTION.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accept()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accept$Response(params: Accept$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmAbsenceAcceptWriteResponse>> {
    return accept(this.http, this.rootUrl, params, context);
  }

  /**
   * Change state of the inquire for the absence. The change can be to ACCEPTED or REJECTED, ACCEPT_RETRACTION, REJECT_RETRACTION.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accept$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accept(params: Accept$Params, context?: HttpContext): Observable<HlmAbsenceAcceptWriteResponse> {
    return this.accept$Response(params, context).pipe(
      map((r: StrictHttpResponse<HlmAbsenceAcceptWriteResponse>): HlmAbsenceAcceptWriteResponse => r.body)
    );
  }

  /** Path part for operation `update()` */
  static readonly UpdatePath = '/api/hld/absence/{uid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmUpdateAbsenceResponse>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: Update$Params, context?: HttpContext): Observable<HlmUpdateAbsenceResponse> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<HlmUpdateAbsenceResponse>): HlmUpdateAbsenceResponse => r.body)
    );
  }

  /** Path part for operation `delete1()` */
  static readonly Delete1Path = '/api/hld/absence/{uid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete1()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete1$Response(params: Delete1$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmAbsenceDeleteWriteResponse>> {
    return delete1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete1(params: Delete1$Params, context?: HttpContext): Observable<HlmAbsenceDeleteWriteResponse> {
    return this.delete1$Response(params, context).pipe(
      map((r: StrictHttpResponse<HlmAbsenceDeleteWriteResponse>): HlmAbsenceDeleteWriteResponse => r.body)
    );
  }

  /** Path part for operation `cancelAbsence()` */
  static readonly CancelAbsencePath = '/api/hld/absence/{uid}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelAbsence()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelAbsence$Response(params: CancelAbsence$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmAbsenceCancelResponse>> {
    return cancelAbsence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelAbsence$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cancelAbsence(params: CancelAbsence$Params, context?: HttpContext): Observable<HlmAbsenceCancelResponse> {
    return this.cancelAbsence$Response(params, context).pipe(
      map((r: StrictHttpResponse<HlmAbsenceCancelResponse>): HlmAbsenceCancelResponse => r.body)
    );
  }

  /** Path part for operation `cancelAbsenceRetract()` */
  static readonly CancelAbsenceRetractPath = '/api/hld/absence/{uid}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelAbsenceRetract()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelAbsenceRetract$Response(params: CancelAbsenceRetract$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmAbsenceCancelRetractResponse>> {
    return cancelAbsenceRetract(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelAbsenceRetract$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelAbsenceRetract(params: CancelAbsenceRetract$Params, context?: HttpContext): Observable<HlmAbsenceCancelRetractResponse> {
    return this.cancelAbsenceRetract$Response(params, context).pipe(
      map((r: StrictHttpResponse<HlmAbsenceCancelRetractResponse>): HlmAbsenceCancelRetractResponse => r.body)
    );
  }

  /** Path part for operation `create1()` */
  static readonly Create1Path = '/api/hld/absence';

  /**
   * Register new absence for the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1$Response(params: Create1$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmRegisterAbsenceResponse>> {
    return create1(this.http, this.rootUrl, params, context);
  }

  /**
   * Register new absence for the user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1(params: Create1$Params, context?: HttpContext): Observable<HlmRegisterAbsenceResponse> {
    return this.create1$Response(params, context).pipe(
      map((r: StrictHttpResponse<HlmRegisterAbsenceResponse>): HlmRegisterAbsenceResponse => r.body)
    );
  }

}
