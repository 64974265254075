/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OpmSystemPermissionWriteResponse } from '../models/opm-system-permission-write-response';
import { OpmSystemPermissionWriteRequest } from '../models/opm-system-permission-write-request';
import { OpmSystemPermissionUpdateResponse } from '../models/opm-system-permission-update-response';
import { OpmSystemPermissionUpdateRequest } from '../models/opm-system-permission-update-request';

/**
 * Opa Command System Permission Update
 */
@Injectable({
  providedIn: 'root',
})
class SystemPermissionWriteService extends __BaseService {
  static readonly createSystemPermissionUsingPOSTPath = '/api/opp/system/permission';
  static readonly updateSystemPermissionUsingPUTPath = '/api/opp/system/permission/{permission}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * createSystemPermission
   * @param params The `SystemPermissionWriteService.CreateSystemPermissionUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `header`: header
   *
   * @return OK
   */
  createSystemPermissionUsingPOSTResponse(params: SystemPermissionWriteService.CreateSystemPermissionUsingPOSTParams): __Observable<__StrictHttpResponse<OpmSystemPermissionWriteResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/opp/system/permission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmSystemPermissionWriteResponse>;
      })
    );
  }
  /**
   * createSystemPermission
   * @param params The `SystemPermissionWriteService.CreateSystemPermissionUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `header`: header
   *
   * @return OK
   */
  createSystemPermissionUsingPOST(params: SystemPermissionWriteService.CreateSystemPermissionUsingPOSTParams): __Observable<OpmSystemPermissionWriteResponse> {
    return this.createSystemPermissionUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as OpmSystemPermissionWriteResponse)
    );
  }

  /**
   * updateSystemPermission
   * @param params The `SystemPermissionWriteService.UpdateSystemPermissionUsingPUTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `permission`: permission
   *
   * - `header`: header
   *
   * @return OK
   */
  updateSystemPermissionUsingPUTResponse(params: SystemPermissionWriteService.UpdateSystemPermissionUsingPUTParams): __Observable<__StrictHttpResponse<OpmSystemPermissionUpdateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/opp/system/permission/${encodeURIComponent(String(params.permission))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmSystemPermissionUpdateResponse>;
      })
    );
  }
  /**
   * updateSystemPermission
   * @param params The `SystemPermissionWriteService.UpdateSystemPermissionUsingPUTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `permission`: permission
   *
   * - `header`: header
   *
   * @return OK
   */
  updateSystemPermissionUsingPUT(params: SystemPermissionWriteService.UpdateSystemPermissionUsingPUTParams): __Observable<OpmSystemPermissionUpdateResponse> {
    return this.updateSystemPermissionUsingPUTResponse(params).pipe(
      __map(_r => _r.body as OpmSystemPermissionUpdateResponse)
    );
  }
}

module SystemPermissionWriteService {

  /**
   * Parameters for createSystemPermissionUsingPOST
   */
  export interface CreateSystemPermissionUsingPOSTParams {

    /**
     * request
     */
    request: OpmSystemPermissionWriteRequest;

    /**
     * header
     */
    header?: string;
  }

  /**
   * Parameters for updateSystemPermissionUsingPUT
   */
  export interface UpdateSystemPermissionUsingPUTParams {

    /**
     * request
     */
    request: OpmSystemPermissionUpdateRequest;

    /**
     * permission
     */
    permission: string;

    /**
     * header
     */
    header?: string;
  }
}

export { SystemPermissionWriteService }
