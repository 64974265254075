import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app-state';
import { GlobalSettings } from '../state/global-settings.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {
  private readonly isMobileResolution: boolean;
  public currency: string = null;

  constructor(private _store: Store<AppState>) {
    this._store
      .select('global_settings')
      .subscribe((global_settings: GlobalSettings) => {
        this.currency = global_settings.currency;
      });

    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }
}
