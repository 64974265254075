/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { fetchActivePeriod } from '../fn/period/fetch-active-period';
import { FetchActivePeriod$Params } from '../fn/period/fetch-active-period';
import { PeriodDto } from '../models/period-dto';
import { readPeriods } from '../fn/period/read-periods';
import { ReadPeriods$Params } from '../fn/period/read-periods';
import { ReadPeriods200ResponseDto } from '../models/read-periods-200-response-dto';


/**
 * the Period API
 */
@Injectable({ providedIn: 'root' })
export class PeriodService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readPeriods()` */
  static readonly ReadPeriodsPath = '/api/cfg/period/{period_code}';

  /**
   * Read all periods by code.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  readPeriods$Response(params: ReadPeriods$Params, context?: HttpContext): Observable<StrictHttpResponse<ReadPeriods200ResponseDto>> {
    return readPeriods(this.http, this.rootUrl, params, context);
  }

  /**
   * Read all periods by code.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readPeriods(params: ReadPeriods$Params, context?: HttpContext): Observable<ReadPeriods200ResponseDto> {
    return this.readPeriods$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReadPeriods200ResponseDto>): ReadPeriods200ResponseDto => r.body)
    );
  }

  /** Path part for operation `fetchActivePeriod()` */
  static readonly FetchActivePeriodPath = '/api/cfg/period/{period_code}/active';

  /**
   * Fetch active period.
   *
   * Fetches the active period based on the provided period code.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchActivePeriod()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchActivePeriod$Response(params: FetchActivePeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<PeriodDto>> {
    return fetchActivePeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch active period.
   *
   * Fetches the active period based on the provided period code.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchActivePeriod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchActivePeriod(params: FetchActivePeriod$Params, context?: HttpContext): Observable<PeriodDto> {
    return this.fetchActivePeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<PeriodDto>): PeriodDto => r.body)
    );
  }

}
