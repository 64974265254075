/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { FileWriteService } from './services/file-write.service';
import { FileReadService } from './services/file-read.service';
import { NotificationFlagReadService } from './services/notification-flag-read.service';
import { MessageWriteService } from './services/message-write.service';
import { MessageReadService } from './services/message-read.service';
import { MessagesReadService } from './services/messages-read.service';
import { NotificationReadService } from './services/notification-read.service';
import { NotificationWriteService } from './services/notification-write.service';
import { TemplateWriteService } from './services/template-write.service';
import { TranslationReadService } from './services/translation-read.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    FileWriteService,
    FileReadService,
    NotificationFlagReadService,
    MessageWriteService,
    MessageReadService,
    MessagesReadService,
    NotificationReadService,
    NotificationWriteService,
    TemplateWriteService,
    TranslationReadService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
