/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/nav-write/create';
import { Create$Params } from '../fn/nav-write/create';
import { NvmCommandManualTransactionResponse } from '../models/nvm-command-manual-transaction-response';
import { NvmCommandNavResponse } from '../models/nvm-command-nav-response';
import { NvmCommandProcessJobJournalResponse } from '../models/nvm-command-process-job-journal-response';
import { NvmCommandRetractTransactionGroupResponse } from '../models/nvm-command-retract-transaction-group-response';
import { NvmCommandTransactionRegisterResponse } from '../models/nvm-command-transaction-register-response';
import { NvmCommandTransactionRestartResponse } from '../models/nvm-command-transaction-restart-response';
import { processJobJournal } from '../fn/nav-write/process-job-journal';
import { ProcessJobJournal$Params } from '../fn/nav-write/process-job-journal';
import { register } from '../fn/nav-write/register';
import { Register$Params } from '../fn/nav-write/register';
import { retractTransactionGroup } from '../fn/nav-write/retract-transaction-group';
import { RetractTransactionGroup$Params } from '../fn/nav-write/retract-transaction-group';
import { send } from '../fn/nav-write/send';
import { Send$Params } from '../fn/nav-write/send';
import { send1 } from '../fn/nav-write/send-1';
import { Send1$Params } from '../fn/nav-write/send-1';

@Injectable({ providedIn: 'root' })
export class NavWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `send()` */
  static readonly SendPath = '/api/nav/{transaction}/send';

  /**
   * Send the transaction to the NAV. To send all available transaction provide ALL.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `send()` instead.
   *
   * This method doesn't expect any request body.
   */
  send$Response(params: Send$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandNavResponse>> {
    return send(this.http, this.rootUrl, params, context);
  }

  /**
   * Send the transaction to the NAV. To send all available transaction provide ALL.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `send$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  send(params: Send$Params, context?: HttpContext): Observable<NvmCommandNavResponse> {
    return this.send$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmCommandNavResponse>): NvmCommandNavResponse => r.body)
    );
  }

  /** Path part for operation `send1()` */
  static readonly Send1Path = '/api/nav/transaction/restart';

  /**
   * Send the transaction to the NAV. To send all available transaction provide ALL.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `send1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  send1$Response(params: Send1$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandTransactionRestartResponse>> {
    return send1(this.http, this.rootUrl, params, context);
  }

  /**
   * Send the transaction to the NAV. To send all available transaction provide ALL.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `send1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  send1(params: Send1$Params, context?: HttpContext): Observable<NvmCommandTransactionRestartResponse> {
    return this.send1$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmCommandTransactionRestartResponse>): NvmCommandTransactionRestartResponse => r.body)
    );
  }

  /** Path part for operation `retractTransactionGroup()` */
  static readonly RetractTransactionGroupPath = '/api/nav/transaction/group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retractTransactionGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retractTransactionGroup$Response(params: RetractTransactionGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandRetractTransactionGroupResponse>> {
    return retractTransactionGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `retractTransactionGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retractTransactionGroup(params: RetractTransactionGroup$Params, context?: HttpContext): Observable<NvmCommandRetractTransactionGroupResponse> {
    return this.retractTransactionGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmCommandRetractTransactionGroupResponse>): NvmCommandRetractTransactionGroupResponse => r.body)
    );
  }

  /** Path part for operation `processJobJournal()` */
  static readonly ProcessJobJournalPath = '/api/nav/job-journal/restart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processJobJournal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  processJobJournal$Response(params: ProcessJobJournal$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandProcessJobJournalResponse>> {
    return processJobJournal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processJobJournal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  processJobJournal(params: ProcessJobJournal$Params, context?: HttpContext): Observable<NvmCommandProcessJobJournalResponse> {
    return this.processJobJournal$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmCommandProcessJobJournalResponse>): NvmCommandProcessJobJournalResponse => r.body)
    );
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/api/nav/transaction/create';

  /**
   * Create new transaction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandManualTransactionResponse>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new transaction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<NvmCommandManualTransactionResponse> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmCommandManualTransactionResponse>): NvmCommandManualTransactionResponse => r.body)
    );
  }

  /** Path part for operation `register()` */
  static readonly RegisterPath = '/api/nav/transaction/association';

  /**
   * Register new transaction for the day. If the transaction is already registered it will be associated with new transaction, though.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `register()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  register$Response(params: Register$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandTransactionRegisterResponse>> {
    return register(this.http, this.rootUrl, params, context);
  }

  /**
   * Register new transaction for the day. If the transaction is already registered it will be associated with new transaction, though.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `register$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  register(params: Register$Params, context?: HttpContext): Observable<NvmCommandTransactionRegisterResponse> {
    return this.register$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmCommandTransactionRegisterResponse>): NvmCommandTransactionRegisterResponse => r.body)
    );
  }

}
