/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create1 } from '../fn/timesheet-resource-write/create-1';
import { Create1$Params } from '../fn/timesheet-resource-write/create-1';
import { TmmEntryResourceCreateResponse } from '../models/tmm-entry-resource-create-response';
import { TmmEntryResourceUpdateResponse } from '../models/tmm-entry-resource-update-response';
import { update1 } from '../fn/timesheet-resource-write/update-1';
import { Update1$Params } from '../fn/timesheet-resource-write/update-1';

@Injectable({ providedIn: 'root' })
export class TimesheetResourceWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `update1()` */
  static readonly Update1Path = '/api/tms/resource/entry/{uid}';

  /**
   * Create new timesheet entry for resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1$Response(params: Update1$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryResourceUpdateResponse>> {
    return update1(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new timesheet entry for resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1(params: Update1$Params, context?: HttpContext): Observable<TmmEntryResourceUpdateResponse> {
    return this.update1$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryResourceUpdateResponse>): TmmEntryResourceUpdateResponse => r.body)
    );
  }

  /** Path part for operation `create1()` */
  static readonly Create1Path = '/api/tms/resource';

  /**
   * Create new timesheet entry for resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1$Response(params: Create1$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryResourceCreateResponse>> {
    return create1(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new timesheet entry for resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1(params: Create1$Params, context?: HttpContext): Observable<TmmEntryResourceCreateResponse> {
    return this.create1$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryResourceCreateResponse>): TmmEntryResourceCreateResponse => r.body)
    );
  }

}
