import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PrmJobDiaryEntry } from 'src/app/api/prj/models';

import { ErrorHandlerService } from 'src/app/core/service/error-handler.service';
import { SnackMessageService } from 'src/app/core/service/snack-message.service';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import * as _moment from 'moment';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/state/app-state';
import { User } from 'src/app/core/service/user/user';
import { CommentReadService, CommentWriteService } from 'src/app/api/ehs/services';
import { PermissionActiveReadService } from 'src/app/api/opp/services/permission-active-read.service';
import { PraJobDiaryService } from 'src/app/api/prj/services';

@Component({
  selector: 'app-diary-list',
  templateUrl: './diary-list.component.html',
  styleUrls: ['./diary-list.component.scss'],
})
export class DiaryListComponent implements OnInit {
  private _jobNo: string = undefined;
  private _incidentId: string = undefined;
  public entries: PrmJobDiaryEntryExtended[] = undefined;
  public direction = 'desc';
  public control_mode = 'view';
  public entry_in_edit: string = undefined;
  public new_entry: PrmJobDiaryEntryExtended = undefined;
  public updated_entry: PrmJobDiaryEntryExtended = undefined;
  @Output() entryModified: EventEmitter<string> = new EventEmitter();
  isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  public allow_edit = false;
  public can_comment = false;
  constructor(
    private _jobDiaryService: PraJobDiaryService,
    private _commentWriteService: CommentWriteService,
    private _commentReadService: CommentReadService,
    private _snackMessage: SnackMessageService,
    private _errorHandler: ErrorHandlerService,
    private _permissionService: PermissionActiveReadService,
    private _store: Store<AppState>,
  ) {
    this._store.select('user').subscribe((user: User) => {
      this.allow_edit = user.logged() && user.inRole('UI_EDIT_PROJECT_DIARY');
    });
    this._permissionService.systemPermissionActiveRead({
      permission: ['EHS_COMMENT_ON_ALL_INCIDENTS']
    }).subscribe(res => {
      if(res.length) {
        this.can_comment = true;
      }
    })
  }
  @Input() editable = true;

  @Input()
  set jobNo(val: any) {
    this._jobNo = val;
    this.load_list();
  }

  @Input()
  set incidentId(val: any) {
    this._incidentId = val;
    this.load_list();
  }

  ngOnInit(): void {
    // pass
  }

  public dateChangedForUpdate(date: Date): void {
    this.updated_entry.entry.created = _moment(date).toISOString();
  }

  public dateChanged(date: Date): void {
    this.new_entry.entry.created = _moment(date).toISOString();
  }

  public create_new_entry(): void {
    this.new_entry = new PrmJobDiaryEntryExtended();
    this.new_entry.entry = {
      uid: this._incidentId ? this._incidentId : '',
      message: '',
      author: undefined,
      created: undefined,
    };

    this.control_mode = 'new';
  }

  public edit_entry(entry: PrmJobDiaryEntry): void {
    this.updated_entry = new PrmJobDiaryEntryExtended();
    this.updated_entry.entry = entry;
    this.updated_entry.formatted_message =
      this.updated_entry.entry.message.replace(/\n/g, '<br>');

    this.entry_in_edit = entry.uid;

    this.control_mode = 'edit';
  }

  public cancel_new(): void {
    this.control_mode = 'view';
  }

  public save_new(): void {
    if (this._jobNo) {
      this.save_new_project();
     }
     else if (this._incidentId) {
       this.save_new_incident();
     }
  }

  public save_new_project(): void {
    this._jobDiaryService
      .create4({
        body: {
          entry: {
            author: this.new_entry.entry.author,
            created: _moment(this.new_entry.entry.created).format('YYYY-MM-DD'),
            message: this.new_entry.entry.message,
            uid: this.new_entry.entry.uid
          },
        },
        jobNo: this._jobNo,
      })
      .subscribe(
        (result) => {
          this.entryModified.emit(this._jobNo);
          this.new_entry.entry.uid = result.uid;

          if (isNullOrUndefined(this.entries)) {
            this.entries = [];
          }

          this.new_entry.formatted_message =
            this.new_entry.entry.message.replace(/\n/g, '<br>');
          this.entries.push(this.new_entry);
          this.sort_list();
          this.control_mode = 'view';
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error),
          );
        },
      );
  }

  public save_new_incident(): void {
    this._commentWriteService
      .storeComment({
        incidentId: this._incidentId,
        body: {
          message: this.new_entry.entry.message,
          reportedDateTime: this.new_entry.entry.created ? this.new_entry.entry.created : _moment().toISOString()
        }
      })
      .subscribe(
        (result) => {
          this.entryModified.emit(this._incidentId);
          this.new_entry.entry.uid = result.uid;

          if (isNullOrUndefined(this.entries)) {
            this.entries = [];
          }

          this.new_entry.formatted_message =
            this.new_entry.entry.message.replace(/\n/g, '<br>');
          this.entries.push(this.new_entry);
          this.sort_list();
          this.control_mode = 'view';
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error),
          );
        },
      );
  }

  private sort_list(): void {
    if (isNullOrUndefined(this.entries)) {
      this.entries = [];
    }

    if (this.direction == 'asc') {
      this.entries = this.entries.sort((a, b) =>
        a.entry.created < b.entry.created ? -1 : 1,
      );
    } else {
      this.entries = this.entries.sort((a, b) =>
        a.entry.created > b.entry.created ? -1 : 1,
      );
    }
  }
  public cancel_edit(): void {
    this.entry_in_edit = undefined;
    this.control_mode = 'view';
  }

  public delete_entry_confirmed(entry: PrmJobDiaryEntry): void {
    if (isNullOrUndefined(this.entries)) {
      this.entries = [];
    }

    if (this._jobNo) {
      this.delete_entry_project(entry);
     }
     else if (this._incidentId) {
      this.delete_entry_incident(entry);
     }
  }

  private delete_entry_project(entry: PrmJobDiaryEntry): void {
    this._jobDiaryService
      .delete1({
        jobNo: this._jobNo,
        diary: entry.uid,
      })
      .subscribe(
        () => {
          this.entryModified.emit(this._jobNo);
          this.control_mode = 'view';
          this.entries = this.entries.filter(
            (current) => current.entry.uid != entry.uid,
          );
          this.entry_in_edit = undefined;
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error),
          );
        },
      );
  }

  private delete_entry_incident(entry: PrmJobDiaryEntry): void {
    // method in API neeeded
    console.log('entry', entry)
  }

  public delete_entry_canceled(): void {
    this.control_mode = 'view';
  }

  public delete_entry(entry: PrmJobDiaryEntry): void {
    this.entry_in_edit = entry.uid;
    this.control_mode = 'delete';
  }

  public save_entry(entry: PrmJobDiaryEntry): void {
    if (isNullOrUndefined(this.entries)) {
      this.entries = [];
    }

    if (this._jobNo) {
     this.save_entry_project(entry);
    }
    else if (this._incidentId) {
      this.save_entry_incident(entry);
    }
  }

  public save_entry_project(entry: PrmJobDiaryEntry): void {
    this._jobDiaryService
      .update1({
        body: {
          entry: {
            author: this.updated_entry.entry.author,
            created: _moment(this.updated_entry.entry.created).format('YYYY-MM-DD'),
            message: this.updated_entry.entry.message,
            uid: this.updated_entry.entry.uid
          },
        },
        diary: entry.uid,
        jobNo: this._jobNo,
      })
      .subscribe(
        () => {
          this.entryModified.emit(this._jobNo);
          this.entries = this.entries.filter(
            (current) => current.entry.uid != entry.uid,
          );
          this.updated_entry.formatted_message =
            this.updated_entry.entry.message.replace(/\n/g, '<br>');
          this.entries.push(this.updated_entry);
          this.sort_list();
          this.control_mode = 'view';
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error),
          );
        },
      );
    this.entry_in_edit = undefined;
    this.control_mode = 'view';
  }

  public save_entry_incident(entry: PrmJobDiaryEntry): void {
    this._commentWriteService.updateIncidentComment({
      incidentId: this._incidentId,
      commentUid: entry.uid,
      body: {
        message: entry.message,
        reportedDateTime: entry.created
      }
    }).subscribe(() => {
        this.entryModified.emit(this._jobNo);
        this.entries = this.entries.filter(
          (current) => current.entry.uid != entry.uid,
        );
        this.updated_entry.formatted_message = this.updated_entry.entry.message.replace(/\n/g, '<br>');
        this.entries.push(this.updated_entry);
        this.sort_list();
        this.control_mode = 'view';
      },
      (error) => {
        this._snackMessage.communicateError(this._errorHandler.errorCode(error));
      },
    );

    this.entry_in_edit = undefined;
    this.control_mode = 'view';
  }

  public formatDate(eventTime: string): string {
    if (!isNullOrUndefined(eventTime)) {
      return _moment(eventTime).format('YYYY-MM-DD');
    } else {
      return '';
    }
  }

  public sortChanged(event: any): void {
    this.direction = event.direction;

    this.load_list();
  }

  private load_list(): void {
    this.entries = undefined;
    if (this._jobNo) {
      this.load_list_project();
    }
    else if (this._incidentId) {
      this.load_list_incident();
    }
  }

  private load_list_project(): void {
    this._jobDiaryService
      .read({
        jobNo: this._jobNo,
        offset: 0,
        max: 999999, // if the paging will be added change the value
        dateSort: this.direction,
      })
      .subscribe(
        (result) => {
          const newEntries: PrmJobDiaryEntryExtended[] = [];

          result.entries.forEach((entry) => {
            const item = new PrmJobDiaryEntryExtended();
            item.entry = entry;
            item.formatted_message = entry.message.replace(/\n/g, '<br />');

            newEntries.push(item);
          });

          this.entries = newEntries;
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error),
          );
        },
      );
  }

  private load_list_incident():void {
    this._commentReadService.listOfIncidentComments({incidentId: this._incidentId}).subscribe(
      (result) => {
        const newEntries: PrmJobDiaryEntryExtended[] = [];

        result.comments.forEach((entry) => {
          const item = new PrmJobDiaryEntryExtended();
          item.entry = {
            author: entry.author?.name + ' ' + entry.author?.surname,
            message: entry.message,
            created: entry.reportedDateTime,
            jobNo: entry.incidentUid,
            uid: entry.uid
          };
          item.formatted_message = entry.message.replace(/\n/g, '<br />');

          newEntries.push(item);
        });

        this.entries = newEntries;
      },
      (error) => {
        this._snackMessage.communicateError(
          this._errorHandler.errorCode(error),
        );
      },
    );
  }
}

export class PrmJobDiaryEntryExtended {
  public entry: PrmJobDiaryEntry;
  public formatted_message: string;
}
