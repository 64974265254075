import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackMessageService} from '../../core/service/snack-message.service';
import {ErrorHandlerService} from '../../core/service/error-handler.service';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { isNullOrUndefined } from "src/app/shared/utilities";
import {AcmAccountQueryAccount} from '../../api/acc/models/acm-account-query-account';
import {DialogData} from './dialog-data';
import { AccountService } from 'src/app/api/acc/services';

@Component({
  selector: 'app-user-select-dialog',
  templateUrl: './user-select-dialog.component.html',
  styleUrls: ['./user-select-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSelectDialogComponent {
  public ds: AccountDataSource = undefined;
  public no = '';
  public noOrName = '';
  @ViewChild('virtualView', {static: false}) input: CdkVirtualScrollViewport;
  private account: AcmAccountQueryAccount;

  constructor(
    public dialogRef: MatDialogRef<UserSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(AccountService) private _accountReadService: AccountService,
    @Inject(SnackMessageService) private snackMessageService: SnackMessageService,
    @Inject(ErrorHandlerService) private errorHandlerService: ErrorHandlerService
  ) {
    this.no = data.current_user;
    this.ds = new AccountDataSource(_accountReadService, snackMessageService, errorHandlerService, this);
    this.ds.searchFor(this.no, 0).then(
      result => {
        if (result > -1) {
          setTimeout(
            () => {
              this.input.scrollToIndex(result);
            },
            50
          );
        }
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    if (this.no !== '') {
      this.dialogRef.close(this.account);
    }
  }

  select_project(no: AcmAccountQueryAccount) {
    this.no = no.login;
    this.account = no;
  }

  execute_search() {
    this.ds.fetchPage(0);
    this.no = '';
  }

  scroll(): void {
    this.input.scrollToIndex(90);
  }
}

export class AccountDataSource extends DataSource<AcmAccountQueryAccount | undefined> {
  private _accountReadService: AccountService = undefined;
  private _snackMessageService: SnackMessageService;
  private _errorHandlerService: ErrorHandlerService;
  private _parent: UserSelectDialogComponent;

  constructor(
    _accountReadService: AccountService,
    snackMessageService: SnackMessageService,
    errorHandlerService: ErrorHandlerService,
    parent: UserSelectDialogComponent
  ) {
    super();
    this._accountReadService = _accountReadService;
    this._snackMessageService = snackMessageService;
    this._errorHandlerService = errorHandlerService;
    this._parent = parent;
  }

  private _length = 100000;
  private _pageSize = 100;
  private _cachedData = Array.from<AcmAccountQueryAccount>({length: this._length});
  private _fetchedPages = new Set<number>();
  private _dataStream = new BehaviorSubject<(AcmAccountQueryAccount | undefined)[]>(this._cachedData);
  private _subscription = new Subscription();

  connect(collectionViewer: CollectionViewer): Observable<(AcmAccountQueryAccount | undefined)[]> {
    this._subscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this._getPageForIndex(range.start);
      const endPage = this._getPageForIndex(range.end - 1);
      for (let i = startPage; i <= endPage; i++) {
        this._fetchPage(i).then(result => {
        });
      }
    }));
    return this._dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize);
  }

  public fetchPage(page: number): void {
    this._fetchedPages = new Set<number>();
    this._cachedData = Array.from<AcmAccountQueryAccount>({length: this._length});
    this._fetchPage(page).then(result => {
    });
  }

  private _fetchPage(page: number): Promise<number> {
    return new Promise<number>(
      (resolve, reject) => {
        if (this._fetchedPages.has(page)) {
          resolve(page);
        }
        this._fetchedPages.add(page);

        this._accountReadService.query(
          {
            max: this._pageSize,
            page: page,
            name: this._parent.noOrName,
            role: this._parent.data.role,
          }
        ).subscribe(
          success => {
            if (this._length !== success.max) {
              this._length = success.max;
              this._cachedData = Array.from<AcmAccountQueryAccount>({length: this._length});
            }

            this._cachedData.splice(
              page * this._pageSize,
              this._pageSize,
              ...success.accounts
            );

            this._dataStream.next(this._cachedData);

            if (success.accounts.length === 0) {
              reject();
            } else {
              resolve(page);
            }
          }, error => {
            this._snackMessageService.communicateError(this._errorHandlerService.errorCode(error));
            reject();
          }
        );
      }
    );
  }

  searchFor(no: string, start_page: number): Promise<number> {
    return new Promise<number>(
      ((resolve, reject) => {
        this._fetchPage(start_page).then(
          result => {
            let found_index = -1;
            let at_index = 0;

            this._cachedData.forEach(
              record => {
                if (!isNullOrUndefined(record)) {
                  if (record.login === no) {
                    found_index = at_index;
                  }
                }

                at_index = at_index + 1;
              }
            );

            if (found_index > -1) {
              resolve(found_index);
            } else {
              this.searchFor(no, start_page + 1).then(
                rec_result => {
                  resolve(rec_result);
                }
              );
            }
          }
        );
      })
    );
  }
}
