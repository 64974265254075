import { Component, OnInit, Input } from '@angular/core';
import { ReportSiteResultReadService } from 'src/app/api/nav/services';
import { SnackMessageService } from 'src/app/core/service/snack-message.service';
import { ErrorHandlerService } from 'src/app/core/service/error-handler.service';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { NvmReportSiteResultItem } from 'src/app/api/nav/models';
import { AmountFormatter } from 'src/app/pages/project-page/amount-formatter';
import { Store } from '@ngrx/store';
import { GlobalSettings } from 'src/app/core/state/global-settings.model';
import { AppState } from 'src/app/core/state/app-state';
import { ApplicationStateService } from 'src/app/core/service/application-state.service';

@Component({
  selector: 'app-site-reporting-summary',
  templateUrl: './site-reporting-summary.component.html',
  styleUrls: ['./site-reporting-summary.component.scss'],
})
export class SiteReportingSummaryComponent implements OnInit {
  private _jobNo: string = null;
  private data: Array<NvmReportSiteResultItem>;
  public bonus: NvmReportSiteResultItem = undefined;
  private _range: string = null;
  private _currency: string = null;

  constructor(
    private _service: ReportSiteResultReadService,
    private _snackMessage: SnackMessageService,
    private _errorHandler: ErrorHandlerService,
    private _applicationState: ApplicationStateService
  ) {
    this._currency = this._applicationState.currency;
  }

  private loadCategory(): void {
    if (
      !isNullOrUndefined(this._jobNo) &&
      this._jobNo !== '' &&
      this._currency !== null
    ) {
      this.readCategory();
    }
  }

  @Input()
  set jobNo(value: string) {
    this._jobNo = value;

    this.loadCategory();
  }

  ngOnInit() {}

  @Input()
  set range(value: string) {
    this._range = value;

    this.loadCategory();
  }

  private readCategory(): void {
    this._service
      .categoryRead1({
        jobNo: this._jobNo,
        category: 'bonus',
        range: this._range,
      })
      .subscribe(
        (result) => {
          this.data = result.items;

          this.data
            .filter((item) => {
              return item.key === 'bonus';
            })
            .forEach((item) => {
              this.bonus = item;
            });
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error)
          );
        }
      );
  }

  private symbol(
    item: NvmReportSiteResultItem,
    default_symbol: string
  ): string {
    if (!isNullOrUndefined(item.symbol) && item.symbol !== '') {
      return item.symbol;
    } else {
      return default_symbol;
    }
  }

  public formatValue(item: NvmReportSiteResultItem): any {
    if (item.format === 'percent') {
      return item.value + ' ' + this.symbol(item, '%');
    } else if (item.format === 'number') {
      return (
        new AmountFormatter().format(Number.parseFloat(item.value)) +
        ' ' +
        this.symbol(item, this._currency)
      );
    } else {
      return item.value;
    }
  }
}
