/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CfqConfigurationQueryResponse } from '../models/cfq-configuration-query-response';
import { parameterRead } from '../fn/cfa-parameter-read/parameter-read';
import { ParameterRead$Params } from '../fn/cfa-parameter-read/parameter-read';

@Injectable({ providedIn: 'root' })
export class CfaParameterReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `parameterRead()` */
  static readonly ParameterReadPath = '/api/cfg/configuration/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parameterRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  parameterRead$Response(params: ParameterRead$Params, context?: HttpContext): Observable<StrictHttpResponse<CfqConfigurationQueryResponse>> {
    return parameterRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parameterRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parameterRead(params: ParameterRead$Params, context?: HttpContext): Observable<CfqConfigurationQueryResponse> {
    return this.parameterRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<CfqConfigurationQueryResponse>): CfqConfigurationQueryResponse => r.body)
    );
  }

}
