import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SearchProjectDialogComponent } from "src/app/shared/search-project-dialog/search-project-dialog.component";
import { PraProjectReadService } from "src/app/api/prj/services";
import { isNullOrUndefined } from "src/app/shared/utilities";
import { SnackMessageService } from "src/app/core/service/snack-message.service";
import { ErrorHandlerService } from "src/app/core/service/error-handler.service";

@Component({
  selector: "app-project-chooser",
  templateUrl: "./project-chooser.component.html",
  styleUrls: ["./project-chooser.component.scss"],
})
export class ProjectChooserComponent implements OnInit {
  public project_name = "";
  public _project_code = "";
  @Input() limited_projects = false;
  @Input() read_only = false;
  @Output() project_choosen: EventEmitter<string> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private _project_read: PraProjectReadService,
    private _snackMessage: SnackMessageService,
    private _errorHandler: ErrorHandlerService
  ) {}

  ngOnInit() {
    if (!isNullOrUndefined(this._project_code) && this._project_code !== "") {
      this.read_project_details();
    }
  }

  @Input()
  set project_code(value: string) {
    this._project_code = value;
    this.project_choosen.emit(this._project_code);
    this.read_project_details();
  }

  public open_project_picker(): void {
    if (this.read_only) {
      return;
    }

    const ref = this.dialog.open(SearchProjectDialogComponent, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
      panelClass: "mat-dialog-container-custom",
      data: {
        current_project: this._project_code,
        limited_projects: this.limited_projects,
      },
    });

    ref.afterClosed().subscribe((result) => {
      if (result.no) {
        if (this.project_code !== result.no) {
          this.project_code = result.no;
        } else if (this.project_name === "") {
          this.read_project_details();
        }
      }
    });
  }

  public read_project_details(): void {
    this._project_read
      .readResponseResponseEntity({
        projectId: this._project_code
      })
      .subscribe(
        (result) => {
          if (!isNullOrUndefined(result.project)) {
            this.project_name = result.project.name;
          }
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error)
          );
        }
      );
  }
}
