import { Component, OnInit, Input } from "@angular/core";
import {
  ReportSiteResultReadService,
  ReportSiteResultWriteService,
} from "../../../api/nav/services";
import { SnackMessageService } from "../../../core/service/snack-message.service";
import { ErrorHandlerService } from "../../../core/service/error-handler.service";
import { NvmReportSiteResultItem } from "../../../api/nav/models";
import { AmountFormatter } from "src/app/pages/project-page/amount-formatter";
import { isNullOrUndefined } from "src/app/shared/utilities";
import { SiteReportingSummaryIdentification } from "./site-reporting-summary-identification";
import { AppState } from "src/app/core/state/app-state";
import { Store } from "@ngrx/store";
import { User } from "src/app/core/service/user/user";
import { Utilities } from "src/app/shared/utilities";
import {DeviceDetectorService} from "ngx-device-detector";
import { ApplicationStateService } from "src/app/core/service/application-state.service";
import * as _moment from 'moment';

@Component({
  selector: "app-site-reporting-summary-category",
  templateUrl: "./site-reporting-summary-category.component.html",
  styleUrls: ["./site-reporting-summary-category.component.scss"],
})
export class SiteReportingSummaryCategoryComponent implements OnInit {
  @Input() title: string;

  public _identification: SiteReportingSummaryIdentification = null;

  public data: Array<NvmReportSiteResultItemVeil>;
  public rows: Array<Array<NvmReportSiteResultItemVeil>>;
  public update = _moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  public allowEdit = false;
  public editableFields = 0;
  public changes = false;
  public _range: string = null;
  public isMobile = false;
  private _currency: string = null;

  constructor(
    private _service: ReportSiteResultReadService,
    private _serviceWrite: ReportSiteResultWriteService,
    private _snackMessage: SnackMessageService,
    private _errorHandler: ErrorHandlerService,
    private _store: Store<AppState>,
    private _deviceDetectorService: DeviceDetectorService,
    private _applicationState: ApplicationStateService
  ) {
    this.isMobile = this._deviceDetectorService.isMobile();
    this._currency = this._applicationState.currency;
  }

  ngOnInit() {}

  @Input()
  set range(value: string) {
    this._range = value;

    if (!isNullOrUndefined(this._identification)) {
      this.readCategory();
    }
  }

  @Input()
  set identification(value: SiteReportingSummaryIdentification) {
    this._identification = value;
    this.readCategory();
  }

  private searchEntry(
    key: string,
    data: Array<NvmReportSiteResultItemVeil>
  ): NvmReportSiteResultItemVeil {
    var result: NvmReportSiteResultItemVeil = undefined;

    data.forEach((item) => {
      if (item.item.key === key) {
        result = item;
      }
    });

    return result;
  }

  private buildRow(
    keys: string[],
    data: Array<NvmReportSiteResultItemVeil>
  ): Array<NvmReportSiteResultItemVeil> {
    var result = new Array<NvmReportSiteResultItemVeil>();

    keys.forEach((key) => {
      var found = this.searchEntry(key, data);

      if (!isNullOrUndefined(found)) {
        result.push(found);
      }
    });

    return result;
  }

  public readCategory(): void {
    this.update = '-';

    this._service
      .categoryRead1({
        jobNo: this._identification.project,
        category: this._identification.category,
        range: this._range,
      })
      .subscribe(
        (result) => {
          this.data = [];
          this.update = _moment(result.updated).format("YYYY-MM-DD HH:mm:ss");
          result.items.forEach((record) => {
            const newItem = new NvmReportSiteResultItemVeil();
            newItem.item = record;
            this.data.push(newItem);
          });

          this.rows = new Array<Array<NvmReportSiteResultItemVeil>>();

          if (this._identification.category === "forecast") {
            this.rows.push(this.buildRow(["cy_revenue", "margin"], this.data));
            this.rows.push(this.buildRow(["cost"], this.data));
          } else if (this._identification.category === "budget") {
            this.rows.push(this.buildRow(["sell", "risks"], this.data));
            this.rows.push(this.buildRow(["costs", "margin"], this.data));
          } else if (this._identification.category === "realized") {
            this.rows.push(this.buildRow(["revenue", "margin"], this.data));
            this.rows.push(this.buildRow(["costs"], this.data));
          } else if (this._identification.category === "budget_forecast") {
            this.rows.push(this.buildRow(["revenue", "margin"], this.data));
            this.rows.push(this.buildRow(["costs"], this.data));
          }

          this._store.select("user").subscribe((user: User) => {
            if (user.logged()) {
              for (var idx = 0; idx < this.data.length; idx++) {
                this.data[idx].allowEdit = this.allowToEdit(
                  user,
                  this.data[idx]
                );
              }

              this.rows.forEach((row) => {
                row.forEach((data) => {
                  data.allowEdit = this.allowToEdit(user, data);
                });
              });
            }

            this.editableFields = 0;
            this.data.forEach((item) => {
              if (item.allowEdit) {
                this.editableFields = this.editableFields + 1;
              }
            });
          });
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error)
          );
        }
      );
  }

  public markAsDirty(key: string): void {
    this.changes = true;
  }

  private allowToEdit(user: User, item: NvmReportSiteResultItemVeil): boolean {
    var result = false;

    if (!isNullOrUndefined(item.item.editByOwnerInRole)) {
      item.item.editByOwnerInRole.forEach((role) => {
        if (user.inRole(role)) {
          if (item.item.jobOwner === user.login()) {
            result = true;
          }
        }
      });
    }

    if (!isNullOrUndefined(item.item.editByAnyInRole)) {
      item.item.editByAnyInRole.forEach((role) => {
        if (user.inRole(role)) {
          result = true;
        }
      });
    }

    return result;
  }

  private symbol(
    item: NvmReportSiteResultItem,
    default_symbol: string
  ): string {
    if (default_symbol === this._currency) {
      return "";
    } else if (!isNullOrUndefined(item.symbol) && item.symbol !== "") {
      return item.symbol;
    } else {
      return default_symbol;
    }
  }

  public saveChanges(): void {
    var tq: Array<NvmReportSiteResultItem> = [];

    this.data.forEach((row) => {
      if (row.allowEdit) {
        var input: any = document.getElementById(
          this._identification.category + "_" + row.item.key
        );
        row.item.value = input.value;

        tq.push(row.item);
      }
    });

    this._serviceWrite
      .categoryWrite({
        body: {
          items: tq,
        },
        jobNo: this._identification.project,
        category: this._identification.category,
      })
      .subscribe(
        () => {
          this._snackMessage.communicateSuccess("message.updated");
          this.changes = false;
          this.readCategory();
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error)
          );
        }
      );
  }

  public formatValue(item: NvmReportSiteResultItem): any {
    if (item.format === "percent") {
      return (
        new Utilities().dropDecimalPart(item.value) + this.symbol(item, "%")
      );
    } else if (item.format === "number") {
      return (
        new AmountFormatter().format(Number.parseFloat(item.value)) +
        this.symbol(item, this._currency)
      );
    } else {
      return item.value;
    }
  }
}

export class NvmReportSiteResultItemVeil {
  public item: NvmReportSiteResultItem;
  public update = new Date();
  public title: string;
  public allowEdit = false;
}
