import { isNullOrUndefined } from "src/app/shared/utilities";
import {NumberFormatPipe} from '../../shared/number-format.pipe';

export class AmountFormatter {
  format($event: number): string {
    if (!isNullOrUndefined($event)) {
      const result = Math.round($event);
      const formatted = String(new NumberFormatPipe().transform(String(result)));
      return formatted.substr(0, formatted.length - 3);
    } else {
      return undefined;
    }
  }
  
  formatFullAmount($event: number): string {
    if (!isNullOrUndefined($event)) {
      const result = Math.round(($event + Number.EPSILON) * 100) / 100;
      // const result = Math.round($event);
      const formatted = String(new NumberFormatPipe().transform(String(result)));
      return formatted;
    } else {
      return undefined;
    }
  }
}
