/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TmmEntryMaximumAcceptableResponse } from '../../models/tmm-entry-maximum-acceptable-response';

export interface Acceptable$Params {
  project: string;
  day: number;
  month: number;
  year: number;
  user: string;
}

export function acceptable(http: HttpClient, rootUrl: string, params: Acceptable$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryMaximumAcceptableResponse>> {
  const rb = new RequestBuilder(rootUrl, acceptable.PATH, 'get');
  if (params) {
    rb.query('project', params.project, {});
    rb.query('day', params.day, {});
    rb.query('month', params.month, {});
    rb.query('year', params.year, {});
    rb.query('user', params.user, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TmmEntryMaximumAcceptableResponse>;
    })
  );
}

acceptable.PATH = '/api/tms/timesheet/acceptable';
