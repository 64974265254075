import { Injector } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { AcmLoginResponse } from '../../../api/acc/models/acm-login-response';
import { LOG_USER } from '../../state/reducers/user.reducer';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { UserDefault } from './user-default';
import { LAST_PROJECT } from '../../state/reducers/project.reducer';
import { LAST_RESOURCE } from '../../state/reducers/resource.reducer';
import { SnackMessageService } from '../snack-message.service';
import { Router } from '@angular/router';
import { AccountService, LoginService } from 'src/app/api/acc/services';

export class UserCookieStorage {
  // -----------------------------------------------------------------------------------------------------------------
  // Object state
  // -----------------------------------------------------------------------------------------------------------------
  private _cookieService: CookieService;
  private _snackMessage: SnackMessageService;
  private _router: Router;

  // -----------------------------------------------------------------------------------------------------------------
  // Constructors
  // -----------------------------------------------------------------------------------------------------------------
  constructor(private _injector: Injector) {
    this._cookieService = _injector.get(CookieService);
    this._snackMessage = _injector.get(SnackMessageService);
    this._router = _injector.get(Router);
  }

  // -----------------------------------------------------------------------------------------------------------------
  // Business logic
  // -----------------------------------------------------------------------------------------------------------------
  public store(login: string, token: string): void {
    this._cookieService.set('aarsleff_session', token, 7);
    this._cookieService.set('aarsleff_login', login, 7);
  }

  // -----------------------------------------------------------------------------------------------------------------
  public restore(): void {
    const _customerService: LoginService =
      this._injector.get(LoginService);
    const _store: any = this._injector.get(Store);

    if (
      !isNullOrUndefined(this._cookieService.get('aarsleff_login')) &&
      this._cookieService.get('aarsleff_login') !== ''
    ) {
      _customerService
        .login({
          body: {
            login: this._cookieService.get('aarsleff_login'),
            password: '',
          },
        })
        .subscribe(
          (result: AcmLoginResponse) => {
            let mail = '';
            if (!isNullOrUndefined(result.preferences)) {
              _store.dispatch({
                type: LAST_PROJECT,
                payload: {
                  project: result.preferences['project'],
                },
              });
              _store.dispatch({
                type: LAST_RESOURCE,
                payload: {
                  resource: result.preferences['resource'],
                },
              });

              mail = result.preferences['user_mail'];
            }

            _store.dispatch({
              type: LOG_USER,
              payload: {
                mail: this._cookieService.get('aarsleff_login'),
                account: new UserDefault(
                  this._cookieService.get('aarsleff_login'),
                  result.token,
                  result.roles,
                  result.name,
                  result.surname,
                  result.acceptor,
                  result.absenceAcceptor,
                  mail,
                  result.siteRole
                ),
                token: result.token,
              },
            });

            this.store(this._cookieService.get('aarsleff_login'), result.token);
          },
          (error: any) => {
            let handled = false;

            if (!isNullOrUndefined(error.error)) {
              if (!isNullOrUndefined(error.error.result)) {
                if (!isNullOrUndefined(error.error.result.code)) {
                  let code;
                  code = error.error.result.code;

                  if (code === 'ACC_RELOGIN') {
                    this._router.navigate(['/login']);
                    handled = true;
                  }
                }
              }
            }

            if (!handled) {
              this._snackMessage.communicateError('message.login.failed');
            }
          },
        );
    }
  }

  // -----------------------------------------------------------------------------------------------------------------
  clear() {
    this._cookieService.delete('aarsleff_session');
    this._cookieService.delete('aarsleff_login');
  }
}
