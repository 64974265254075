import { CustomAction } from '../state.model';

export const SET_URL = 'SET_URL';

export function contexthelp(url = '/', action: CustomAction) {
  switch (action.type) {
    case SET_URL: {
      return action.payload.url;
    }
    default: {
      return url;
    }
  }
}
