/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { executeOperation } from '../fn/ina-execute-operation/execute-operation';
import { ExecuteOperation$Params } from '../fn/ina-execute-operation/execute-operation';
import { InmExecuteOperationResponse } from '../models/inm-execute-operation-response';

@Injectable({ providedIn: 'root' })
export class InaExecuteOperationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `executeOperation()` */
  static readonly ExecuteOperationPath = '/api/inv/inventory/operation';

  /**
   * Execute operation on the inventory item. In the parameters, you need to provide the item number, quantity, and reason for the operation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeOperation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeOperation$Response(params: ExecuteOperation$Params, context?: HttpContext): Observable<StrictHttpResponse<InmExecuteOperationResponse>> {
    return executeOperation(this.http, this.rootUrl, params, context);
  }

  /**
   * Execute operation on the inventory item. In the parameters, you need to provide the item number, quantity, and reason for the operation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeOperation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeOperation(params: ExecuteOperation$Params, context?: HttpContext): Observable<InmExecuteOperationResponse> {
    return this.executeOperation$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmExecuteOperationResponse>): InmExecuteOperationResponse => r.body)
    );
  }

}
