/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { TimesheetWriteService } from './services/timesheet-write.service';
import { TimesheetResourceWriteService } from './services/timesheet-resource-write.service';
import { TimesheetReadService } from './services/timesheet-read.service';
import { EmployeeStatementReadService } from './services/employee-statement-read.service';
import { CalendarWriteService } from './services/calendar-write.service';
import { TmaDirectMessageWriteService } from './services/tma-direct-message-write.service';
import { TmaDirectMessageReadService } from './services/tma-direct-message-read.service';
import { ConfigurationWriteService } from './services/configuration-write.service';
import { TimesheetDelegacyReadService } from './services/timesheet-delegacy-read.service';
import { EmployeeStatementWriteService } from './services/employee-statement-write.service';
import { CostReadService } from './services/cost-read.service';
import { ConfigurationReadService } from './services/configuration-read.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    TimesheetWriteService,
    TimesheetResourceWriteService,
    TimesheetReadService,
    EmployeeStatementReadService,
    CalendarWriteService,
    TmaDirectMessageWriteService,
    TmaDirectMessageReadService,
    ConfigurationWriteService,
    TimesheetDelegacyReadService,
    EmployeeStatementWriteService,
    CostReadService,
    ConfigurationReadService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
