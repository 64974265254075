import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: ['./page-selector.component.scss'],
})
export class PageSelectorComponent implements OnInit {
  @Input() maxPage;
  @Input() pageRange = 10;
  @Input() selectedPageEm;
  @Output() selectedPageChange = new EventEmitter();
  public Arr = Array;
  constructor() {}

  ngOnInit() {
    if (this.pageRange > 10) this.pageRange = 10;
    if (this.maxPage < this.pageRange) this.pageRange = this.maxPage;
  }

  selectPage(pageNo) {
    if (pageNo > 0 && pageNo <= this.maxPage) {
      this.selectedPageEm = pageNo;
      this.selectedPageChange.emit(pageNo);
    }
  }
}
