/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExmExportOptimaRequest } from '../../models/exm-export-optima-request';
import { ExmExportOptimaResponse } from '../../models/exm-export-optima-response';

export interface ExportOpitma$Params {
  year: number;
  month: number;
  dryRun?: boolean;
  body: ExmExportOptimaRequest;
}

export function exportOpitma(
  http: HttpClient,
  rootUrl: string,
  params: ExportOpitma$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<ExmExportOptimaResponse>> {
  const rb = new RequestBuilder(rootUrl, exportOpitma.PATH, 'put');
  if (params) {
    rb.query('year', params.year, {});
    rb.query('month', params.month, {});
    rb.query('dryRun', params.dryRun, {});
    rb.body(params.body, 'application/json');
  }

  return http
    .request(rb.build({ responseType: 'json', accept: '*/*', context }))
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExmExportOptimaResponse>;
      }),
    );
}

exportOpitma.PATH = '/api/exp/export/optima';
