/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InmInventoryChangeCategoryRequest } from '../../models/inm-inventory-change-category-request';
import { InmInventoryChangeCategoryResponse } from '../../models/inm-inventory-change-category-response';

export interface ChangeCategory$Params {
  inventoryUid: string;
      body: InmInventoryChangeCategoryRequest
}

export function changeCategory(http: HttpClient, rootUrl: string, params: ChangeCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryChangeCategoryResponse>> {
  const rb = new RequestBuilder(rootUrl, changeCategory.PATH, 'put');
  if (params) {
    rb.path('inventoryUid', params.inventoryUid, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InmInventoryChangeCategoryResponse>;
    })
  );
}

changeCategory.PATH = '/api/inv/inventory/{inventoryUid}/change-category';
