import { UUID } from 'angular2-uuid';
import { User } from './user';

export class UserAnonymous implements User {
  updateMail(mail: string): void {
    throw new Error('User is not logged');
  }

  displayInfo(): string {
    return '';
  }

  name(): string {
    return '';
  }

  surname(): string {
    return '';
  }

  siteRole(): string {
    return '';
  }

  logged(): boolean {
    return false;
  }

  login(): string {
    throw new Error('User is not logged');
  }

  inRole(roleName: string): boolean {
    return false;
  }

  absenceAcceptor(): boolean {
    return false;
  }

  acceptor(): boolean {
    return false;
  }

  mail(): string {
    return '';
  }

  update_mail(mail: string): void { }

  roles(): Array<String> {
    return undefined;
  }

  sessionUid(): string {
    return UUID.UUID();
  }
  authorizationToken(): string {
    return null;
  }
}
