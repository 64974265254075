/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CmmSupportedMessagesResponse } from '../models/cmm-supported-messages-response';
import { directMessage1 } from '../fn/exi-direct-message-read/direct-message-1';
import { DirectMessage1$Params } from '../fn/exi-direct-message-read/direct-message-1';

@Injectable({ providedIn: 'root' })
export class ExiDirectMessageReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `directMessage1()` */
  static readonly DirectMessage1Path = '/api/sys/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `directMessage1()` instead.
   *
   * This method doesn't expect any request body.
   */
  directMessage1$Response(params?: DirectMessage1$Params, context?: HttpContext): Observable<StrictHttpResponse<CmmSupportedMessagesResponse>> {
    return directMessage1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `directMessage1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  directMessage1(params?: DirectMessage1$Params, context?: HttpContext): Observable<CmmSupportedMessagesResponse> {
    return this.directMessage1$Response(params, context).pipe(
      map((r: StrictHttpResponse<CmmSupportedMessagesResponse>): CmmSupportedMessagesResponse => r.body)
    );
  }

}
