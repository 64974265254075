/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { claimTask } from '../fn/tsa-claim-task/claim-task';
import { ClaimTask$Params } from '../fn/tsa-claim-task/claim-task';
import { TsmClaimTaskResponse } from '../models/tsm-claim-task-response';

@Injectable({ providedIn: 'root' })
export class TsaClaimTaskService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `claimTask()` */
  static readonly ClaimTaskPath = '/api/tsk/task/{id}/claim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimTask$Response(params: ClaimTask$Params, context?: HttpContext): Observable<StrictHttpResponse<TsmClaimTaskResponse>> {
    return claimTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  claimTask(params: ClaimTask$Params, context?: HttpContext): Observable<TsmClaimTaskResponse> {
    return this.claimTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TsmClaimTaskResponse>): TsmClaimTaskResponse => r.body)
    );
  }

}
