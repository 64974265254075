/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { NvmQueryProjectsCostsResponse } from '../models/nvm-query-projects-costs-response';
import { NvmQueryProjectsResponse } from '../models/nvm-query-projects-response';
import { readProjects } from '../fn/projects-costs-read/read-projects';
import { ReadProjects$Params } from '../fn/projects-costs-read/read-projects';
import { readProjects1 } from '../fn/projects-costs-read/read-projects-1';
import { ReadProjects1$Params } from '../fn/projects-costs-read/read-projects-1';

@Injectable({ providedIn: 'root' })
export class ProjectsCostsReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readProjects()` */
  static readonly ReadProjectsPath = '/api/nav/projectcosts';

  /**
   * The servic/projectcostse return list of projects which have costs registered. The list is filtered to the projects in which the calling user is set as a responsible user. The user is read from the authentiction token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  readProjects$Response(params?: ReadProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryProjectsResponse>> {
    return readProjects(this.http, this.rootUrl, params, context);
  }

  /**
   * The servic/projectcostse return list of projects which have costs registered. The list is filtered to the projects in which the calling user is set as a responsible user. The user is read from the authentiction token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readProjects(params?: ReadProjects$Params, context?: HttpContext): Observable<NvmQueryProjectsResponse> {
    return this.readProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryProjectsResponse>): NvmQueryProjectsResponse => r.body)
    );
  }

  /** Path part for operation `readProjects1()` */
  static readonly ReadProjects1Path = '/api/nav/projectcosts/{project}';

  /**
   * The service return list of costs for the project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readProjects1()` instead.
   *
   * This method doesn't expect any request body.
   */
  readProjects1$Response(params: ReadProjects1$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryProjectsCostsResponse>> {
    return readProjects1(this.http, this.rootUrl, params, context);
  }

  /**
   * The service return list of costs for the project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readProjects1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readProjects1(params: ReadProjects1$Params, context?: HttpContext): Observable<NvmQueryProjectsCostsResponse> {
    return this.readProjects1$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryProjectsCostsResponse>): NvmQueryProjectsCostsResponse => r.body)
    );
  }

}
