import { HttpRequest } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { UserProfile } from './core/service/user-profile.service';
import { EnvParamsService } from './shared/env_params.service';

export function initializer_new(
  keycloak: KeycloakService,
  userProfile: UserProfile,
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      resolve(null);
    });
  };
}

export function initializer(
  keycloak: KeycloakService,
  envParamsService: EnvParamsService,
  userProfile: UserProfile,
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        // alert(window.location.href);
        //
        const configuration = await envParamsService.loadNavItems();
        const result = await keycloak.init({
          config: {
            url: configuration.url,
            realm: configuration.realm,
            clientId: configuration.client,
          },
          loadUserProfileAtStartUp: true,
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: true,
          },
          bearerExcludedUrls: ['/assets'],
          shouldAddToken: (request: HttpRequest<unknown>) => {
            return true;
          },
        });

        if (result) {
          keycloak.loadUserProfile().then((user) => {
            keycloak.getToken().then((token) => {
              userProfile.logUser(user, token);
            });
          });
        }
        resolve(resolve);
      } catch (error) {
        reject(error);
      }
    });
  };
}
