/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { configuration } from '../fn/configuration-read/configuration';
import { Configuration$Params } from '../fn/configuration-read/configuration';
import { NvmQueryConfigurationResponse } from '../models/nvm-query-configuration-response';

@Injectable({ providedIn: 'root' })
export class ConfigurationReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `configuration()` */
  static readonly ConfigurationPath = '/api/nav/configuration/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `configuration()` instead.
   *
   * This method doesn't expect any request body.
   */
  configuration$Response(params: Configuration$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryConfigurationResponse>> {
    return configuration(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `configuration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  configuration(params: Configuration$Params, context?: HttpContext): Observable<NvmQueryConfigurationResponse> {
    return this.configuration$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryConfigurationResponse>): NvmQueryConfigurationResponse => r.body)
    );
  }

}
