/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create3 } from '../fn/pra-job-write/create-3';
import { Create3$Params } from '../fn/pra-job-write/create-3';
import { PrmJobCreateResponse } from '../models/prm-job-create-response';
import { PrmJobUpdateResponse } from '../models/prm-job-update-response';
import { update } from '../fn/pra-job-write/update';
import { Update$Params } from '../fn/pra-job-write/update';

@Injectable({ providedIn: 'root' })
export class PraJobWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `update()` */
  static readonly UpdatePath = '/api/prj/job/{no}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobUpdateResponse>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: Update$Params, context?: HttpContext): Observable<PrmJobUpdateResponse> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobUpdateResponse>): PrmJobUpdateResponse => r.body)
    );
  }

  /** Path part for operation `create3()` */
  static readonly Create3Path = '/api/prj/job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create3$Response(params: Create3$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobCreateResponse>> {
    return create3(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create3(params: Create3$Params, context?: HttpContext): Observable<PrmJobCreateResponse> {
    return this.create3$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobCreateResponse>): PrmJobCreateResponse => r.body)
    );
  }

}
