import { CustomAction } from '../state.model';

export const SET_APP_VERSION = 'SET_APP_VERSION';
export const VERSION_APP = 'develop_2024-12-12_17:42';

export function version(state = '', action: CustomAction) {
  switch (action.type) {
    case SET_APP_VERSION:
      return VERSION_APP;
    default:
      return state;
  }
}
