/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Rpa Report Attachment Read
 */
@Injectable({
  providedIn: 'root',
})
class ReportAttachmentReadService extends __BaseService {
  static readonly downloadXLSXUsingGETPath = '/api/rep/execution/{executionUid}/attachment';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ReportAttachmentReadService.DownloadXLSXUsingGETParams` containing the following parameters:
   *
   * - `executionUid`: executionUid
   *
   * - `header`: header
   */
  downloadXLSXUsingGETResponse(params: ReportAttachmentReadService.DownloadXLSXUsingGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/rep/execution/${params.executionUid}/attachment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReportAttachmentReadService.DownloadXLSXUsingGETParams` containing the following parameters:
   *
   * - `executionUid`: executionUid
   *
   * - `header`: header
   */
  downloadXLSXUsingGET(params: ReportAttachmentReadService.DownloadXLSXUsingGETParams): __Observable<null> {
    return this.downloadXLSXUsingGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ReportAttachmentReadService {

  /**
   * Parameters for downloadXLSXUsingGET
   */
  export interface DownloadXLSXUsingGETParams {

    /**
     * executionUid
     */
    executionUid: string;

    /**
     * header
     */
    header?: any;
  }
}

export { ReportAttachmentReadService }
