import { GlobalSettings } from '../global-settings.model';
import { CustomAction } from '../state.model';

export const GS_SET_CURRENCY = 'GS_SET_CURRENCY';
export const GS_SET_CONFIG = 'GS_SET_CONFIG';

export function global_settings(
  current_settings: GlobalSettings = {
    currency: ''
  },
  action: CustomAction
) {
  switch (action.type) {
    case GS_SET_CURRENCY:
      return {
        currency: action.payload.currency
      };
    default:
      return current_settings;
  }
}
