import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export function isNullOrUndefined(value: any): boolean {
  return value === undefined || value === null;
}
export class Utilities {
  public isNotBlank(value: any): boolean {
    return !isNullOrUndefined(value) && value !== '';
  }

  public isNotEmpty(any_array: any[]): boolean {
    if (!isNullOrUndefined(any_array)) {
      return any_array.length > 0;
    }

    return false;
  }

  format_number(text: any): string {
    if (Number.parseInt(text) < 10) {
      return '0' + text;
    } else {
      return text;
    }
  }

  clean_number(number: any): any {
    if (!isNullOrUndefined(number)) {
      const input: string = number.toString();
      if (input.indexOf(',') > -1) {
        if (input.length - input.indexOf(',') > 3) {
          return input.substr(0, input.indexOf(',') + 3);
        }
      }
      return input.replace(/,/, '.');
    } else {
      return null;
    }
  }

  replaceAmount(field, form: FormGroup) {
    const amount: string = form.get(field).value + '';

    if (!isNullOrUndefined(amount) && 'null' !== amount) {
      let newValue = amount.replace(/\./, ',');
      const splited = newValue.split(',');
      if (splited.length > 1) {
        newValue = splited[0] + ',' + splited[1];
      }
    }
  }

  dropDotSemicolon(field, $event: KeyboardEvent) {
    if ($event.key === ',') {
      $event.preventDefault();
    }
    if ($event.key === '.') {
      $event.preventDefault();
    }
  }

  dropDot(field, $event: KeyboardEvent) {
    if ($event.key === '.') {
      $event.preventDefault();
    }
  }

  roundUpNumber(number: any): any {
    if (number === null) {
      return null;
    }

    //  replace , with .
    var result = number.replace(/,/, '.');

    //  parse to float
    result = parseFloat(result);

    //  round up
    result = Math.ceil(result);

    return result;
  }

  dropDecimalPart(number: any): any {
    if (!isNullOrUndefined(number)) {
      if (number.indexOf(',') > -1) {
        return number.substr(0, number.indexOf(','));
      } else {
        if (number.indexOf('.') > -1) {
          return number.substr(0, number.indexOf('.'));
        } else {
          return number;
        }
      }
    } else {
      return null;
    }
  }

  prepare_number(number: any): any {
    if (number === 'NaN') {
      return null;
    }
    if (!isNullOrUndefined(number)) {
      const input: string = number.toString();
      let result = input.replace(/\./, ',');

      if (result.indexOf(',') > -1) {
        if (result.indexOf(',') === result.length - 2) {
          result = result + '0';
        }
      }

      return result;
    } else {
      return null;
    }
  }
  getDayOfWeek(date: Date): { short: string; full: string } {
    let DayOfWeek = new Date(date).getDay();
    switch (DayOfWeek) {
      case 1:
        return {
          short: 'label.day_of_week.monday_short',
          full: 'label.day_of_week.monday',
        };
      case 2:
        return {
          short: 'label.day_of_week.tuesday_short',
          full: 'label.day_of_week.tuesday',
        };
      case 3:
        return {
          short: 'label.day_of_week.wednesday_short',
          full: 'label.day_of_week.wednesday',
        };
      case 4:
        return {
          short: 'label.day_of_week.thursday_short',
          full: 'label.day_of_week.thursday',
        };
      case 5:
        return {
          short: 'label.day_of_week.friday_short',
          full: 'label.day_of_week.friday',
        };
      case 6:
        return {
          short: 'label.day_of_week.saturday_short',
          full: 'label.day_of_week.saturday',
        };
      case 0:
        return {
          short: 'label.day_of_week.sunday_short',
          full: 'label.day_of_week.sunday',
        };
      default:
        return {
          short: '-',
          full: '-',
        };
    }
  }
}
