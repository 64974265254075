import {Component, Inject, OnInit} from '@angular/core';
import {CmsNotificationService} from '../notification/cms-notification.service';
import {CmmNotification} from '../../api/cmm/models/cmm-notification';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import { isNullOrUndefined } from "src/app/shared/utilities";
import {DeviceDetectorService} from 'ngx-device-detector';
import { NotificationFlagReadService } from 'src/app/api/cmm/services';
import { CmmNotificationFlagReadResponse } from 'src/app/api/cmm/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.scss']
})
export class NotificationBoxComponent implements OnInit {
  public notifications: Array<CmmNotification> = [];
  public counter = 0;
  private cache = [];
  public is_open = false;
  public isMobile = false;
  public isFlagUp = false;

  constructor(
    private service: CmsNotificationService,
    private _notificationFlagService: NotificationFlagReadService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _deviceDetectorService: DeviceDetectorService,
    private _router: Router
  ) {
    this.cache = storage.get('message_cache');
    this.isMobile = this._deviceDetectorService.isMobile();
    if (isNullOrUndefined(this.cache)) {
      this.cache = [];
    }
  }

  ngOnInit() {
    this.loadMessages();
  }

  public open_list(): void {
    this.is_open = true;
  }

  public close_list(): void {
    this.is_open = false;
  }

  public viewInbox(): void {
    this._router.navigateByUrl('/message');
  }
  private loadMessages() {
    this._notificationFlagService.flagReadUsingGET().subscribe(
      (response: CmmNotificationFlagReadResponse) => {
        this.isFlagUp = response.active;
      }, (error: any) => {
        console.error((error));
      }
    );

    this.service.start(
      (notifications: Array<CmmNotification>) => {
        this.notifications = notifications.filter(
          item => {
            return this.cache.indexOf(item.uuid) === -1;
          }
        );

        this.counter = this.notifications.length;
      }
    );
  }

  hideMessage(uuid: string) {
    this.cache[this.cache.length] = uuid;
    this.dumpCache();
    this.loadMessages();
  }

  private dumpCache(): void {
    this.storage.set('message_cache', this.cache);
  }
}
