/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PrmServiceWorkTypeReadResponse } from '../models/prm-service-work-type-read-response';
import { readServiceWorkType } from '../fn/service-work-type-read/read-service-work-type';
import { ReadServiceWorkType$Params } from '../fn/service-work-type-read/read-service-work-type';
import { readServiceWorkType1 } from '../fn/service-work-type-read/read-service-work-type-1';
import { ReadServiceWorkType1$Params } from '../fn/service-work-type-read/read-service-work-type-1';

@Injectable({ providedIn: 'root' })
export class ServiceWorkTypeReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readServiceWorkType()` */
  static readonly ReadServiceWorkTypePath = '/api/prj/worktype';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readServiceWorkType()` instead.
   *
   * This method doesn't expect any request body.
   */
  readServiceWorkType$Response(params?: ReadServiceWorkType$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmServiceWorkTypeReadResponse>> {
    return readServiceWorkType(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readServiceWorkType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readServiceWorkType(params?: ReadServiceWorkType$Params, context?: HttpContext): Observable<PrmServiceWorkTypeReadResponse> {
    return this.readServiceWorkType$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmServiceWorkTypeReadResponse>): PrmServiceWorkTypeReadResponse => r.body)
    );
  }

  /** Path part for operation `readServiceWorkType1()` */
  static readonly ReadServiceWorkType1Path = '/api/prj/worktype/{technology}/read';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readServiceWorkType1()` instead.
   *
   * This method doesn't expect any request body.
   */
  readServiceWorkType1$Response(params: ReadServiceWorkType1$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmServiceWorkTypeReadResponse>> {
    return readServiceWorkType1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readServiceWorkType1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readServiceWorkType1(params: ReadServiceWorkType1$Params, context?: HttpContext): Observable<PrmServiceWorkTypeReadResponse> {
    return this.readServiceWorkType1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmServiceWorkTypeReadResponse>): PrmServiceWorkTypeReadResponse => r.body)
    );
  }

}
