/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PraJobJournalProjectCostsReadResponse } from '../models/pra-job-journal-project-costs-read-response';
import { readProjects1 } from '../fn/job-journal-read/read-projects-1';
import { ReadProjects1$Params } from '../fn/job-journal-read/read-projects-1';

@Injectable({ providedIn: 'root' })
export class JobJournalReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readProjects1()` */
  static readonly ReadProjects1Path = '/api/prj/jobjournal/project/{project}/jobs';

  /**
   * The service return list of projects which have costs registered. The list is filtered to the projects in which the calling user is set as a responsible user. The user is read from the authentiction token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readProjects1()` instead.
   *
   * This method doesn't expect any request body.
   */
  readProjects1$Response(params: ReadProjects1$Params, context?: HttpContext): Observable<StrictHttpResponse<PraJobJournalProjectCostsReadResponse>> {
    return readProjects1(this.http, this.rootUrl, params, context);
  }

  /**
   * The service return list of projects which have costs registered. The list is filtered to the projects in which the calling user is set as a responsible user. The user is read from the authentiction token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readProjects1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readProjects1(params: ReadProjects1$Params, context?: HttpContext): Observable<PraJobJournalProjectCostsReadResponse> {
    return this.readProjects1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PraJobJournalProjectCostsReadResponse>): PraJobJournalProjectCostsReadResponse => r.body)
    );
  }

}
