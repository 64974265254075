import { ApplicationRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import { concat, Subject, timer } from 'rxjs';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, first, takeUntil, map } from 'rxjs/operators';

@Injectable()
export class UpdatesService implements OnDestroy {
  private _unsb$ = new Subject();

  constructor(
    private _swUpdate: SwUpdate,
    appRef: ApplicationRef,
    private _ngZoene: NgZone,
  ) {
    if (this._swUpdate.isEnabled) {
      console.log('sw enabled');
      // Allow the app to stabilize first, before starting polling for updates.
      const appIsStable$ = appRef.isStable.pipe(
        first((isStable) => isStable === true),
      );
      const everySixHours$ = timer(0, 1000 * 60 * 60);
      const everySixHoursOnceAppIsStable$ = concat(
        appIsStable$,
        everySixHours$,
      );

      everySixHoursOnceAppIsStable$
        .pipe(takeUntil(this._unsb$))
        .subscribe(() => {
          this._swUpdate
            .checkForUpdate()
            .then((rs) => console.log('checked', rs));
        });

      this._swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => {
            console.log('evt', evt);
            return evt.type === 'VERSION_READY';
          }),
          map((evt) => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          })),
        )
        .subscribe((_) => {
          console.log('update available');
          this._swUpdate
            .activateUpdate()
            .then(() => document.location.reload());
        });
    } else {
      console.log('sw update is disabled');
    }
  }

  checkForUpdate() {
    this._swUpdate.checkForUpdate().then((rs) => console.log('checked', rs));
  }

  ngOnDestroy(): void {
    this._unsb$.next();
    this._unsb$.complete();
  }
}
