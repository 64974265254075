/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { executeImport } from '../fn/vacancy-limits/execute-import';
import { ExecuteImport$Params } from '../fn/vacancy-limits/execute-import';
import { importAdvancePayments } from '../fn/vacancy-limits/import-advance-payments';
import { ImportAdvancePayments$Params } from '../fn/vacancy-limits/import-advance-payments';
import { ImportFileChangeRequestDto } from '../models/import-file-change-request-dto';
import { ImportInstanceDto } from '../models/import-instance-dto';
import { importVacancyLimits } from '../fn/vacancy-limits/import-vacancy-limits';
import { ImportVacancyLimits$Params } from '../fn/vacancy-limits/import-vacancy-limits';
import { ImportVacancyLimits200ResponseDto } from '../models/import-vacancy-limits-200-response-dto';
import { readImportInformations } from '../fn/vacancy-limits/read-import-informations';
import { ReadImportInformations$Params } from '../fn/vacancy-limits/read-import-informations';
import { uploadLimitsFile } from '../fn/vacancy-limits/upload-limits-file';
import { UploadLimitsFile$Params } from '../fn/vacancy-limits/upload-limits-file';
import { UploadLimitsFile200ResponseDto } from '../models/upload-limits-file-200-response-dto';


/**
 * the VacancyLimits API
 */
@Injectable({ providedIn: 'root' })
export class VacancyLimitsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `importVacancyLimits()` */
  static readonly ImportVacancyLimitsPath = '/api/exp/import/vacancy/limits';

  /**
   * Import vacancy limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importVacancyLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  importVacancyLimits$Response(params?: ImportVacancyLimits$Params, context?: HttpContext): Observable<StrictHttpResponse<ImportVacancyLimits200ResponseDto>> {
    return importVacancyLimits(this.http, this.rootUrl, params, context);
  }

  /**
   * Import vacancy limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importVacancyLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importVacancyLimits(params?: ImportVacancyLimits$Params, context?: HttpContext): Observable<ImportVacancyLimits200ResponseDto> {
    return this.importVacancyLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImportVacancyLimits200ResponseDto>): ImportVacancyLimits200ResponseDto => r.body)
    );
  }

  /** Path part for operation `executeImport()` */
  static readonly ExecuteImportPath = '/api/exp/import/vacancy/file/{fileUid}/imported';

  /**
   * Execute change on the file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeImport()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeImport$Response(params: ExecuteImport$Params, context?: HttpContext): Observable<StrictHttpResponse<ImportFileChangeRequestDto>> {
    return executeImport(this.http, this.rootUrl, params, context);
  }

  /**
   * Execute change on the file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeImport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeImport(params: ExecuteImport$Params, context?: HttpContext): Observable<ImportFileChangeRequestDto> {
    return this.executeImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImportFileChangeRequestDto>): ImportFileChangeRequestDto => r.body)
    );
  }

  /** Path part for operation `importAdvancePayments()` */
  static readonly ImportAdvancePaymentsPath = '/api/exp/import/inventory/advance/payments';

  /**
   * Import advance payments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importAdvancePayments()` instead.
   *
   * This method doesn't expect any request body.
   */
  importAdvancePayments$Response(params?: ImportAdvancePayments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return importAdvancePayments(this.http, this.rootUrl, params, context);
  }

  /**
   * Import advance payments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importAdvancePayments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importAdvancePayments(params?: ImportAdvancePayments$Params, context?: HttpContext): Observable<void> {
    return this.importAdvancePayments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `uploadLimitsFile()` */
  static readonly UploadLimitsFilePath = '/api/exp/import/vacancy/file';

  /**
   * Upload file to be imported by the vacancy limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadLimitsFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadLimitsFile$Response(params?: UploadLimitsFile$Params, context?: HttpContext): Observable<StrictHttpResponse<UploadLimitsFile200ResponseDto>> {
    return uploadLimitsFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload file to be imported by the vacancy limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadLimitsFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadLimitsFile(params?: UploadLimitsFile$Params, context?: HttpContext): Observable<UploadLimitsFile200ResponseDto> {
    return this.uploadLimitsFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<UploadLimitsFile200ResponseDto>): UploadLimitsFile200ResponseDto => r.body)
    );
  }

  /** Path part for operation `readImportInformations()` */
  static readonly ReadImportInformationsPath = '/api/exp/import/vacancy/info';

  /**
   * Read details about the import instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readImportInformations()` instead.
   *
   * This method doesn't expect any request body.
   */
  readImportInformations$Response(params?: ReadImportInformations$Params, context?: HttpContext): Observable<StrictHttpResponse<ImportInstanceDto>> {
    return readImportInformations(this.http, this.rootUrl, params, context);
  }

  /**
   * Read details about the import instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readImportInformations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readImportInformations(params?: ReadImportInformations$Params, context?: HttpContext): Observable<ImportInstanceDto> {
    return this.readImportInformations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImportInstanceDto>): ImportInstanceDto => r.body)
    );
  }

}
