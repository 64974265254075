/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PrmJobGroupAreaUpdateResponse } from '../models/prm-job-group-area-update-response';
import { updateAreaInGroup } from '../fn/pra-job-group-area-update-write/update-area-in-group';
import { UpdateAreaInGroup$Params } from '../fn/pra-job-group-area-update-write/update-area-in-group';

@Injectable({ providedIn: 'root' })
export class PraJobGroupAreaUpdateWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAreaInGroup()` */
  static readonly UpdateAreaInGroupPath = '/api/prj/job/group/{groupJobNo}/area/{jobNo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAreaInGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAreaInGroup$Response(params: UpdateAreaInGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobGroupAreaUpdateResponse>> {
    return updateAreaInGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAreaInGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAreaInGroup(params: UpdateAreaInGroup$Params, context?: HttpContext): Observable<PrmJobGroupAreaUpdateResponse> {
    return this.updateAreaInGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobGroupAreaUpdateResponse>): PrmJobGroupAreaUpdateResponse => r.body)
    );
  }

}
