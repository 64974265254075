import {User} from '../core/service/user/user';
import { isNullOrUndefined } from "src/app/shared/utilities";

export class GlobalConstants {
  public static user: User;

  static logged() {
    return !isNullOrUndefined(this.user) && this.user.logged();
  }
  static roles() {
    if (this.logged()) {
      return this.user.roles();
    } else {
      return [];
    }
  }
}
