import { NgModule } from '@angular/core';
import { TopMenuComponent } from './top-menu.component';
import { CommonModule } from '@angular/common';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule(
  {
    exports: [
      TopMenuComponent,
    ],
    declarations: [
      TopMenuComponent,
    ],
    imports: [
      CommonModule,
      RouterModule,
      MatIconModule,
      TranslateModule,
      MatMenuModule,
    ],
  },
)
export class TopMenuModule{}

