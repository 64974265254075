/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { echoTest } from '../fn/system/echo-test';
import { EchoTest$Params } from '../fn/system/echo-test';
import { postTest } from '../fn/system/post-test';
import { PostTest$Params } from '../fn/system/post-test';

@Injectable({ providedIn: 'root' })
export class SystemService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postTest()` */
  static readonly PostTestPath = '/api/acc/logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTest$Response(params: PostTest$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return postTest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postTest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTest(params: PostTest$Params, context?: HttpContext): Observable<string> {
    return this.postTest$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `echoTest()` */
  static readonly EchoTestPath = '/api/acc/echo';

  /**
   * Echo service to test connectivity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `echoTest()` instead.
   *
   * This method doesn't expect any request body.
   */
  echoTest$Response(params?: EchoTest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return echoTest(this.http, this.rootUrl, params, context);
  }

  /**
   * Echo service to test connectivity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `echoTest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  echoTest(params?: EchoTest$Params, context?: HttpContext): Observable<void> {
    return this.echoTest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
