import {Component, Input, OnInit} from '@angular/core';
import {CmmNotification} from '../../../../api/cmm/models/cmm-notification';

@Component({
  selector: 'app-message-notification-component-item',
  templateUrl: './message-notification-component-item.component.html',
  styleUrls: ['./message-notification-component-item.component.scss']
})
export class MessageNotificationComponentItemComponent implements OnInit {
  @Input() notification: CmmNotification;
  public is_open = false;

  constructor() { }

  ngOnInit() {
  }

}
