/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create2 } from '../fn/pra-job-journal-write/create-2';
import { Create2$Params } from '../fn/pra-job-journal-write/create-2';
import { deleteJournal } from '../fn/pra-job-journal-write/delete-journal';
import { DeleteJournal$Params } from '../fn/pra-job-journal-write/delete-journal';
import { PrmJobJournalCreateResponse } from '../models/prm-job-journal-create-response';
import { PrmJobJournalDeleteResponse } from '../models/prm-job-journal-delete-response';
import { PrmJobJournalUpdateResponse } from '../models/prm-job-journal-update-response';
import { updateJournal } from '../fn/pra-job-journal-write/update-journal';
import { UpdateJournal$Params } from '../fn/pra-job-journal-write/update-journal';

@Injectable({ providedIn: 'root' })
export class PraJobJournalWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `create2()` */
  static readonly Create2Path = '/api/prj/jobjournal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create2$Response(params: Create2$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobJournalCreateResponse>> {
    return create2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create2(params: Create2$Params, context?: HttpContext): Observable<PrmJobJournalCreateResponse> {
    return this.create2$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobJournalCreateResponse>): PrmJobJournalCreateResponse => r.body)
    );
  }

  /** Path part for operation `updateJournal()` */
  static readonly UpdateJournalPath = '/api/prj/jobjournal/{uid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateJournal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateJournal$Response(params: UpdateJournal$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobJournalUpdateResponse>> {
    return updateJournal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateJournal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateJournal(params: UpdateJournal$Params, context?: HttpContext): Observable<PrmJobJournalUpdateResponse> {
    return this.updateJournal$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobJournalUpdateResponse>): PrmJobJournalUpdateResponse => r.body)
    );
  }

  /** Path part for operation `deleteJournal()` */
  static readonly DeleteJournalPath = '/api/prj/jobjournal/{uid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteJournal()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteJournal$Response(params: DeleteJournal$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobJournalDeleteResponse>> {
    return deleteJournal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteJournal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteJournal(params: DeleteJournal$Params, context?: HttpContext): Observable<PrmJobJournalDeleteResponse> {
    return this.deleteJournal$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobJournalDeleteResponse>): PrmJobJournalDeleteResponse => r.body)
    );
  }

}
