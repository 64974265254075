/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create4 } from '../fn/pra-job-diary/create-4';
import { Create4$Params } from '../fn/pra-job-diary/create-4';
import { delete1 } from '../fn/pra-job-diary/delete-1';
import { Delete1$Params } from '../fn/pra-job-diary/delete-1';
import { PrmJobDiaryCreateResponse } from '../models/prm-job-diary-create-response';
import { PrmJobDiaryDeleteResponse } from '../models/prm-job-diary-delete-response';
import { PrmJobDiaryReadResponse } from '../models/prm-job-diary-read-response';
import { PrmJobDiaryUpdateResponse } from '../models/prm-job-diary-update-response';
import { read } from '../fn/pra-job-diary/read';
import { Read$Params } from '../fn/pra-job-diary/read';
import { update1 } from '../fn/pra-job-diary/update-1';
import { Update1$Params } from '../fn/pra-job-diary/update-1';

@Injectable({ providedIn: 'root' })
export class PraJobDiaryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `update1()` */
  static readonly Update1Path = '/api/prj/job/{jobNo}/diary/{diary}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1$Response(params: Update1$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobDiaryUpdateResponse>> {
    return update1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update1(params: Update1$Params, context?: HttpContext): Observable<PrmJobDiaryUpdateResponse> {
    return this.update1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobDiaryUpdateResponse>): PrmJobDiaryUpdateResponse => r.body)
    );
  }

  /** Path part for operation `delete1()` */
  static readonly Delete1Path = '/api/prj/job/{jobNo}/diary/{diary}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete1()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete1$Response(params: Delete1$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobDiaryDeleteResponse>> {
    return delete1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete1(params: Delete1$Params, context?: HttpContext): Observable<PrmJobDiaryDeleteResponse> {
    return this.delete1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobDiaryDeleteResponse>): PrmJobDiaryDeleteResponse => r.body)
    );
  }

  /** Path part for operation `read()` */
  static readonly ReadPath = '/api/prj/job/{jobNo}/diary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `read()` instead.
   *
   * This method doesn't expect any request body.
   */
  read$Response(params: Read$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobDiaryReadResponse>> {
    return read(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `read$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  read(params: Read$Params, context?: HttpContext): Observable<PrmJobDiaryReadResponse> {
    return this.read$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobDiaryReadResponse>): PrmJobDiaryReadResponse => r.body)
    );
  }

  /** Path part for operation `create4()` */
  static readonly Create4Path = '/api/prj/job/{jobNo}/diary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create4()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create4$Response(params: Create4$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobDiaryCreateResponse>> {
    return create4(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create4$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create4(params: Create4$Params, context?: HttpContext): Observable<PrmJobDiaryCreateResponse> {
    return this.create4$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobDiaryCreateResponse>): PrmJobDiaryCreateResponse => r.body)
    );
  }

}
