import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _flag: boolean;

  constructor() {
    this._flag = false;
  }

  setBackClicked(flag: boolean) {
    this._flag = flag;
  }

  getBackClicked() {
    return this._flag;
  }
}
