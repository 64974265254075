/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExmImportResourcesResponse } from '../../models/exm-import-resources-response';

export interface ImportResources1$Params { }

export function importResources1(
  http: HttpClient,
  rootUrl: string,
  params?: ImportResources1$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<ExmImportResourcesResponse>> {
  const rb = new RequestBuilder(rootUrl, importResources1.PATH, 'put');
  if (params) {
  }

  return http
    .request(rb.build({ responseType: 'json', accept: '*/*', context }))
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExmImportResourcesResponse>;
      }),
    );
}

importResources1.PATH = '/api/exp/import/resources';
