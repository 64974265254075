import { User } from './user';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { UUID } from 'angular2-uuid';

export class UserDefault implements User {
  private _session_id: string = undefined;
  // -----------------------------------------------------------------------------------------------------------------
  // Object state
  // -----------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------
  // Constructors
  // -----------------------------------------------------------------------------------------------------------------
  constructor(
    private _user: string,
    private _token: string,
    private _roles: string[],
    private _name: string,
    private _surname: string,
    private _acceptor: boolean,
    private _absenceAcceptor: boolean,
    private _mail: string,
    private _siteRole: string
  ) { }

  // -----------------------------------------------------------------------------------------------------------------
  // Business logic
  // -----------------------------------------------------------------------------------------------------------------
  displayInfo(): string {
    return this._name + ' ' + this._surname;
  }

  name(): string {
    return this._name;
  }

  surname(): string {
    return this._surname;
  }

  // -----------------------------------------------------------------------------------------------------------------
  logged(): boolean {
    return true;
  }

  // -----------------------------------------------------------------------------------------------------------------
  login(): string {
    let result = this._user + '@test.pl';

    if (!isNullOrUndefined(result)) {
      if (result.indexOf('@') > -1) {
        result = result.substr(0, result.indexOf('@'));
      }
    }

    return this._user;
  }

  // -----------------------------------------------------------------------------------------------------------------

  public roles(): Array<String> {
    return this._roles;
  }

  siteRole(): string {
    return this._siteRole;
  }

  inRole(roleName: string): boolean {
    let result = false;

    if (!isNullOrUndefined(this._roles) && this._roles.length > 0) {
      this._roles.forEach((role) => {
        if (role === roleName) {
          result = true;
        }
      });
    }
    return result;
  }

  mail(): string {
    return this._mail;
  }

  update_mail(mail: string): void {
    this._mail = mail;
  }

  absenceAcceptor(): boolean {
    return this._absenceAcceptor;
  }

  acceptor(): boolean {
    return this._acceptor;
  }

  sessionUid(): string {
    if (this._session_id == undefined) {
      this._session_id = UUID.UUID();
    }

    return this._session_id;
  }

  authorizationToken(): string {
    return this._token;
  }
}
