/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OpmSystemPermissionReadResponse } from '../models/opm-system-permission-read-response';
import { OpmSystemPermissionDefinitionsReadResponse } from '../models/opm-system-permission-definitions-read-response';
import { OpmSystemPermissionConfigurationUsersReadResponse } from '../models/opm-system-permission-configuration-users-read-response';
import { OpmSystemPermissionForJobReadResponse } from '../models/opm-system-permission-for-job-read-response';
import { OpmSystemPermissionForJobsReadResponse } from '../models/opm-system-permission-for-jobs-read-response';

/**
 * Opa Query System Permission Configuration Users Read
 */
@Injectable({
  providedIn: 'root',
})
class SystemPermissionReadService extends __BaseService {
  static readonly readPermissionUsingGETPath = '/api/opp/system/permission';
  static readonly readDefinitionsUsingGETPath = '/api/opp/system/permission/configuration/definitions';
  static readonly usersReadUsingGETPath = '/api/opp/system/permission/configuration/users';
  static readonly readPermissionForJobUsingGETPath = '/api/opp/system/permission/job/{jobId}';
  static readonly readPermissionForJobUsingGET1Path = '/api/opp/system/permission/jobs';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * readPermission
   * @param params The `SystemPermissionReadService.ReadPermissionUsingGETParams` containing the following parameters:
   *
   * - `account`: account
   *
   * - `header`: header
   *
   * @return OK
   */
  readPermissionUsingGETResponse(params: SystemPermissionReadService.ReadPermissionUsingGETParams): __Observable<__StrictHttpResponse<OpmSystemPermissionReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.account != null) __params = __params.set('account', params.account.toString());
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/opp/system/permission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmSystemPermissionReadResponse>;
      })
    );
  }
  /**
   * readPermission
   * @param params The `SystemPermissionReadService.ReadPermissionUsingGETParams` containing the following parameters:
   *
   * - `account`: account
   *
   * - `header`: header
   *
   * @return OK
   */
  readPermissionUsingGET(params: SystemPermissionReadService.ReadPermissionUsingGETParams): __Observable<OpmSystemPermissionReadResponse> {
    return this.readPermissionUsingGETResponse(params).pipe(
      __map(_r => _r.body as OpmSystemPermissionReadResponse)
    );
  }

  /**
   * readDefinitions
   * @param header header
   * @return OK
   */
  readDefinitionsUsingGETResponse(header?: string): __Observable<__StrictHttpResponse<OpmSystemPermissionDefinitionsReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (header != null) __headers = __headers.set('header', header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/opp/system/permission/configuration/definitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmSystemPermissionDefinitionsReadResponse>;
      })
    );
  }
  /**
   * readDefinitions
   * @param header header
   * @return OK
   */
  readDefinitionsUsingGET(header?: string): __Observable<OpmSystemPermissionDefinitionsReadResponse> {
    return this.readDefinitionsUsingGETResponse(header).pipe(
      __map(_r => _r.body as OpmSystemPermissionDefinitionsReadResponse)
    );
  }

  /**
   * usersRead
   * @param params The `SystemPermissionReadService.UsersReadUsingGETParams` containing the following parameters:
   *
   * - `page`: page
   *
   * - `max`: max
   *
   * - `header`: header
   *
   * @return OK
   */
  usersReadUsingGETResponse(params: SystemPermissionReadService.UsersReadUsingGETParams): __Observable<__StrictHttpResponse<OpmSystemPermissionConfigurationUsersReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.max != null) __params = __params.set('max', params.max.toString());
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/opp/system/permission/configuration/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmSystemPermissionConfigurationUsersReadResponse>;
      })
    );
  }
  /**
   * usersRead
   * @param params The `SystemPermissionReadService.UsersReadUsingGETParams` containing the following parameters:
   *
   * - `page`: page
   *
   * - `max`: max
   *
   * - `header`: header
   *
   * @return OK
   */
  usersReadUsingGET(params: SystemPermissionReadService.UsersReadUsingGETParams): __Observable<OpmSystemPermissionConfigurationUsersReadResponse> {
    return this.usersReadUsingGETResponse(params).pipe(
      __map(_r => _r.body as OpmSystemPermissionConfigurationUsersReadResponse)
    );
  }

  /**
   * readPermissionForJob
   * @param params The `SystemPermissionReadService.ReadPermissionForJobUsingGETParams` containing the following parameters:
   *
   * - `jobId`: jobId
   *
   * - `withInherited`: withInherited
   *
   * - `header`: header
   *
   * @return OK
   */
  readPermissionForJobUsingGETResponse(params: SystemPermissionReadService.ReadPermissionForJobUsingGETParams): __Observable<__StrictHttpResponse<OpmSystemPermissionForJobReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.withInherited != null) __params = __params.set('withInherited', params.withInherited.toString());
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/opp/system/permission/job/${encodeURIComponent(String(params.jobId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmSystemPermissionForJobReadResponse>;
      })
    );
  }
  /**
   * readPermissionForJob
   * @param params The `SystemPermissionReadService.ReadPermissionForJobUsingGETParams` containing the following parameters:
   *
   * - `jobId`: jobId
   *
   * - `withInherited`: withInherited
   *
   * - `header`: header
   *
   * @return OK
   */
  readPermissionForJobUsingGET(params: SystemPermissionReadService.ReadPermissionForJobUsingGETParams): __Observable<OpmSystemPermissionForJobReadResponse> {
    return this.readPermissionForJobUsingGETResponse(params).pipe(
      __map(_r => _r.body as OpmSystemPermissionForJobReadResponse)
    );
  }

  /**
   * readPermissionForJob
   * @param params The `SystemPermissionReadService.ReadPermissionForJobUsingGET1Params` containing the following parameters:
   *
   * - `jobsId`: jobsId
   *
   * - `header`: header
   *
   * @return OK
   */
  readPermissionForJobUsingGET1Response(params: SystemPermissionReadService.ReadPermissionForJobUsingGET1Params): __Observable<__StrictHttpResponse<OpmSystemPermissionForJobsReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.jobsId || []).forEach(val => {if (val != null) __params = __params.append('jobsId', val.toString())});
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/opp/system/permission/jobs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmSystemPermissionForJobsReadResponse>;
      })
    );
  }
  /**
   * readPermissionForJob
   * @param params The `SystemPermissionReadService.ReadPermissionForJobUsingGET1Params` containing the following parameters:
   *
   * - `jobsId`: jobsId
   *
   * - `header`: header
   *
   * @return OK
   */
  readPermissionForJobUsingGET1(params: SystemPermissionReadService.ReadPermissionForJobUsingGET1Params): __Observable<OpmSystemPermissionForJobsReadResponse> {
    return this.readPermissionForJobUsingGET1Response(params).pipe(
      __map(_r => _r.body as OpmSystemPermissionForJobsReadResponse)
    );
  }
}

module SystemPermissionReadService {

  /**
   * Parameters for readPermissionUsingGET
   */
  export interface ReadPermissionUsingGETParams {

    /**
     * account
     */
    account: string;

    /**
     * header
     */
    header?: string;
  }

  /**
   * Parameters for usersReadUsingGET
   */
  export interface UsersReadUsingGETParams {

    /**
     * page
     */
    page: number;

    /**
     * max
     */
    max: number;

    /**
     * header
     */
    header?: string;
  }

  /**
   * Parameters for readPermissionForJobUsingGET
   */
  export interface ReadPermissionForJobUsingGETParams {

    /**
     * jobId
     */
    jobId: string;

    /**
     * withInherited
     */
    withInherited?: boolean;

    /**
     * header
     */
    header?: string;
  }

  /**
   * Parameters for readPermissionForJobUsingGET1
   */
  export interface ReadPermissionForJobUsingGET1Params {

    /**
     * jobsId
     */
    jobsId: Array<string>;

    /**
     * header
     */
    header?: string;
  }
}

export { SystemPermissionReadService }
