/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReadLimits200ResponseDto } from '../../models/read-limits-200-response-dto';

export interface ReadLimits$Params {

/**
 * Return list of limits for choosen years, if the parameter won't be present the service will use system configuration
 */
  year?: Array<string>;

/**
 * Sort results by the field, direction-field, direction asc, desc
 */
  sort?: string;

/**
 * Number of results on a page
 */
  max?: number;

/**
 * Page number to read, first page is 1
 */
  page?: number;

/**
 * Filter to query the backedn, each entry has three parts name-comparator-value, to search entries for user Jan we can use name-eq-Jan
 */
  filter?: Array<string>;
}

export function readLimits(http: HttpClient, rootUrl: string, params?: ReadLimits$Params, context?: HttpContext): Observable<StrictHttpResponse<ReadLimits200ResponseDto>> {
  const rb = new RequestBuilder(rootUrl, readLimits.PATH, 'get');
  if (params) {
    rb.query('year', params.year, {});
    rb.query('sort', params.sort, {});
    rb.query('max', params.max, {});
    rb.query('page', params.page, {});
    rb.query('filter', params.filter, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ReadLimits200ResponseDto>;
    })
  );
}

readLimits.PATH = '/api/hld/limit';
