/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { accountWithSiteRole } from '../fn/siterole/account-with-site-role';
import { AccountWithSiteRole$Params } from '../fn/siterole/account-with-site-role';
import { AcmReadWithSiteRoleResponse } from '../models/acm-read-with-site-role-response';
import { AcmUpdateSiteRoleResponse } from '../models/acm-update-site-role-response';
import { updateRole } from '../fn/siterole/update-role';
import { UpdateRole$Params } from '../fn/siterole/update-role';

@Injectable({ providedIn: 'root' })
export class SiteroleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateRole()` */
  static readonly UpdateRolePath = '/api/acc/account/{account}/siterole/{role}';

  /**
   * Update user siterole
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRole$Response(params: UpdateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmUpdateSiteRoleResponse>> {
    return updateRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Update user siterole
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateRole(params: UpdateRole$Params, context?: HttpContext): Observable<AcmUpdateSiteRoleResponse> {
    return this.updateRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmUpdateSiteRoleResponse>): AcmUpdateSiteRoleResponse => r.body)
    );
  }

  /** Path part for operation `accountWithSiteRole()` */
  static readonly AccountWithSiteRolePath = '/api/acc/account/siterole';

  /**
   * List of account that are in any role for the site
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountWithSiteRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountWithSiteRole$Response(params?: AccountWithSiteRole$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmReadWithSiteRoleResponse>> {
    return accountWithSiteRole(this.http, this.rootUrl, params, context);
  }

  /**
   * List of account that are in any role for the site
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountWithSiteRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountWithSiteRole(params?: AccountWithSiteRole$Params, context?: HttpContext): Observable<AcmReadWithSiteRoleResponse> {
    return this.accountWithSiteRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmReadWithSiteRoleResponse>): AcmReadWithSiteRoleResponse => r.body)
    );
  }

}
