<div class="container-fluid">
  <div class="row" *ngFor="let notification of notifications; let idx = index;" style="padding-bottom: 10px;">
    <div class="col-12 d-flex justify-content-center" style="margin-left: 0px; margin-right: 0px; padding: 0px;">
      <div class="item_rounded" style="width: 100%; max-width: 600px; margin-left: 0px; margin-right: 0px;">
        <div class="container-fluid" style="margin-left: 0px; margin-right: 0px;">
          <div class="row">
            <div class="col-12">
              <app-message-notification-component-item [notification]="notification"
              ></app-message-notification-component-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
