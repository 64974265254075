import {Injectable} from '@angular/core';
import {NotificationReadService} from '../../api/cmm/services/notification-read.service';
import {ErrorHandlerService} from '../../core/service/error-handler.service';
import {SnackMessageService} from '../../core/service/snack-message.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../core/state/app-state';
import {User} from '../../core/service/user/user';

@Injectable({
  providedIn: 'root'
})
export class CmsNotificationService {
  private _user: User;

  constructor(
    private _store: Store<AppState>,
    private notificationReadService: NotificationReadService,
    private _errorHandler: ErrorHandlerService,
    private _snackMessage: SnackMessageService
  ) {
  }

  start(callback) {
    const object = this;

    this._store.select('user').subscribe(
      (user: User) => {
        this._user = user;

        if (this._user.logged()) {
          this.notificationReadService.readNotificationsUsingGET("").subscribe(
            result => {
              callback(result.notifications);
            }, error => {
              object._snackMessage.communicateError(
                object._errorHandler.errorCode(error)
              );
            }
          );
        }
      }
    );
  }
}
