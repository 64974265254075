/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NvmQueryProjectsResponse } from '../../models/nvm-query-projects-response';

export interface ReadProjects$Params {
  summary?: boolean;
  filters?: string;
  sortBy?: string;
  sortOrder?: string;
  page?: number;
  max?: number;
}

export function readProjects(http: HttpClient, rootUrl: string, params?: ReadProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryProjectsResponse>> {
  const rb = new RequestBuilder(rootUrl, readProjects.PATH, 'get');
  if (params) {
    rb.query('summary', params.summary, {});
    rb.query('filters', params.filters, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('page', params.page, {});
    rb.query('max', params.max, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<NvmQueryProjectsResponse>;
    })
  );
}

readProjects.PATH = '/api/nav/projectcosts';
