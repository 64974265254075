/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CmmTemplateRenderResponse } from '../models/cmm-template-render-response';
import { CmmTemplateRenderRequest } from '../models/cmm-template-render-request';

/**
 * Cma Template Render
 */
@Injectable({
  providedIn: 'root',
})
class TemplateWriteService extends __BaseService {
  static readonly renderTemplateUsingPOSTPath = '/api/cmm/template/{name}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * renderTemplate
   * @param params The `TemplateWriteService.RenderTemplateUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `name`: name
   *
   * - `header`: header
   *
   * @return OK
   */
  renderTemplateUsingPOSTResponse(params: TemplateWriteService.RenderTemplateUsingPOSTParams): __Observable<__StrictHttpResponse<CmmTemplateRenderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/cmm/template/${encodeURIComponent(String(params.name))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmTemplateRenderResponse>;
      })
    );
  }
  /**
   * renderTemplate
   * @param params The `TemplateWriteService.RenderTemplateUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `name`: name
   *
   * - `header`: header
   *
   * @return OK
   */
  renderTemplateUsingPOST(params: TemplateWriteService.RenderTemplateUsingPOSTParams): __Observable<CmmTemplateRenderResponse> {
    return this.renderTemplateUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as CmmTemplateRenderResponse)
    );
  }
}

module TemplateWriteService {

  /**
   * Parameters for renderTemplateUsingPOST
   */
  export interface RenderTemplateUsingPOSTParams {

    /**
     * request
     */
    request: CmmTemplateRenderRequest;

    /**
     * name
     */
    name: string;

    /**
     * header
     */
    header?: string;
  }
}

export { TemplateWriteService }
