/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExaResourceCostCompositeWriteResponse } from '../models/exa-resource-cost-composite-write-response';
import { ExaResourceCostWriteResponse } from '../models/exa-resource-cost-write-response';
import { importCosts } from '../fn/resource-cost-write/import-costs';
import { ImportCosts$Params } from '../fn/resource-cost-write/import-costs';
import { importResource } from '../fn/resource-cost-write/import-resource';
import { ImportResource$Params } from '../fn/resource-cost-write/import-resource';
import { importResources } from '../fn/resource-cost-write/import-resources';
import { ImportResources$Params } from '../fn/resource-cost-write/import-resources';
import { importWorkType } from '../fn/resource-cost-write/import-work-type';
import { ImportWorkType$Params } from '../fn/resource-cost-write/import-work-type';
import { resource } from '../fn/resource-cost-write/resource';
import { Resource$Params } from '../fn/resource-cost-write/resource';
import { resourceComponent } from '../fn/resource-cost-write/resource-component';
import { ResourceComponent$Params } from '../fn/resource-cost-write/resource-component';

@Injectable({ providedIn: 'root' })
export class ResourceCostWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `importWorkType()` */
  static readonly ImportWorkTypePath = '/api/exp/import/work_type';

  /**
   * Import resource costs from the CSV file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importWorkType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importWorkType$Response(params: ImportWorkType$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return importWorkType(this.http, this.rootUrl, params, context);
  }

  /**
   * Import resource costs from the CSV file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importWorkType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importWorkType(params: ImportWorkType$Params, context?: HttpContext): Observable<string> {
    return this.importWorkType$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `importResources()` */
  static readonly ImportResourcesPath = '/api/exp/import/technology_resource_cost';

  /**
   * Import technology resource costs from the CSV file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importResources()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importResources$Response(params: ImportResources$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return importResources(this.http, this.rootUrl, params, context);
  }

  /**
   * Import technology resource costs from the CSV file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importResources$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importResources(params: ImportResources$Params, context?: HttpContext): Observable<string> {
    return this.importResources$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `importResource()` */
  static readonly ImportResourcePath = '/api/exp/import/resource_cost_component';

  /**
   * Import resource costs component from the CSV file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importResource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importResource$Response(params: ImportResource$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return importResource(this.http, this.rootUrl, params, context);
  }

  /**
   * Import resource costs component from the CSV file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importResource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importResource(params: ImportResource$Params, context?: HttpContext): Observable<string> {
    return this.importResource$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `importCosts()` */
  static readonly ImportCostsPath = '/api/exp/import/resource_cost';

  /**
   * Import resource costs from the CSV file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCosts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importCosts$Response(params: ImportCosts$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return importCosts(this.http, this.rootUrl, params, context);
  }

  /**
   * Import resource costs from the CSV file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importCosts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importCosts(params: ImportCosts$Params, context?: HttpContext): Observable<string> {
    return this.importCosts$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `resourceComponent()` */
  static readonly ResourceComponentPath = '/api/exp/resource_component';

  /**
   * Import resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceComponent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceComponent$Response(params: ResourceComponent$Params, context?: HttpContext): Observable<StrictHttpResponse<ExaResourceCostCompositeWriteResponse>> {
    return resourceComponent(this.http, this.rootUrl, params, context);
  }

  /**
   * Import resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resourceComponent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceComponent(params: ResourceComponent$Params, context?: HttpContext): Observable<ExaResourceCostCompositeWriteResponse> {
    return this.resourceComponent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExaResourceCostCompositeWriteResponse>): ExaResourceCostCompositeWriteResponse => r.body)
    );
  }

  /** Path part for operation `resource()` */
  static readonly ResourcePath = '/api/exp/resource';

  /**
   * Import resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resource$Response(params: Resource$Params, context?: HttpContext): Observable<StrictHttpResponse<ExaResourceCostWriteResponse>> {
    return resource(this.http, this.rootUrl, params, context);
  }

  /**
   * Import resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resource(params: Resource$Params, context?: HttpContext): Observable<ExaResourceCostWriteResponse> {
    return this.resource$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExaResourceCostWriteResponse>): ExaResourceCostWriteResponse => r.body)
    );
  }

}
