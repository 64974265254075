/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NvmCommandCostLockRestartRequest } from '../../models/nvm-command-cost-lock-restart-request';
import { NvmCommandCostLockRestartResponse } from '../../models/nvm-command-cost-lock-restart-response';

export interface RestartCost$Params {
      body: NvmCommandCostLockRestartRequest
}

export function restartCost(http: HttpClient, rootUrl: string, params: RestartCost$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandCostLockRestartResponse>> {
  const rb = new RequestBuilder(rootUrl, restartCost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<NvmCommandCostLockRestartResponse>;
    })
  );
}

restartCost.PATH = '/api/nav/lock/restart';
