import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-notification-box-icon',
  templateUrl: './notification-box-icon.component.html',
  styleUrls: ['./notification-box-icon.component.scss']
})
export class NotificationBoxIconComponent implements OnInit {
  @Input() counter = 0;
  @Input() is_open = false;
  @Input() icon: string = undefined;
  
  constructor() { }

  ngOnInit() {
  }

}
