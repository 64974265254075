import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectMonthDialogData } from './select-month-dialog-data';
import * as _moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/state/app-state';

@Component({
  selector: 'app-select-month-dialog',
  templateUrl: './select-month-dialog.component.html',
  styleUrls: ['./select-month-dialog.component.scss'],
})
export class SelectMonthDialogComponent {
  public current = new SelectedYearMonth();

  constructor(
    public dialogRef: MatDialogRef<SelectMonthDialogComponent>,
    private _store: Store<AppState>,
    private _dateAdapter: DateAdapter<any>,
    @Inject(MAT_DIALOG_DATA) public data: SelectMonthDialogData,
  ) {
    this._store.select('language').subscribe((language) => {
      this._dateAdapter.setLocale(language);
    });
  }

  onOkClick(): void {
    this.dialogRef.close();
  }

  chosenYearHandler(normalizedYear: _moment.Moment) {
    this.current.year = normalizedYear.year();
  }

  chosenMonthHandler(normalizedMonth: _moment.Moment) {
    this.current.year = normalizedMonth.year();
    this.current.month = normalizedMonth.month();
    this.current.asDate = normalizedMonth;
    this.dialogRef.close(this.current);
  }

  public select_all(): void {
    this.dialogRef.close(null);
  }

  choosenDate($event: _moment.Moment) {
    this.current.year = $event.year();
    this.current.month = $event.month();
    this.current.asDate = $event;
    this.dialogRef.close(this.current);
  }
}

export class SelectedYearMonth {
  public year: number;
  public month: number;
  public asDate: _moment.Moment;
}
