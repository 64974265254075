<h1 mat-dialog-title class="v_text_header">{{data.title | translate}}</h1>
<div mat-dialog-content>
  <p class="v_field_label_theme">{{data.content | translate}}</p>
</div>
<div class="container-fluid">
  <div class="row" mat-dialog-actions>
    <div class="col-4 d-flex justify-content-end align-items-center">
      <button class="standard-button-cancel" mat-button (click)="onCancelClick()">{{'label.cancel' | translate }}</button>
    </div>
    <div class="col-4 d-flex justify-content-start align-items-center">
      <button class="standard-button-cancel" mat-button (click)="onNoClick()">{{'label.no' | translate}}</button>
    </div>
    <div class="col-4 d-flex justify-content-start align-items-center">
      <button class="standard-button-action" mat-button (click)="onYesClick()" cdkFocusInitial>{{'label.yes'| translate}}</button>
    </div>
  </div>
</div>
