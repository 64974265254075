/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assign } from '../fn/pra-project-write/assign';
import { Assign$Params } from '../fn/pra-project-write/assign';
import { create1 } from '../fn/pra-project-write/create-1';
import { Create1$Params } from '../fn/pra-project-write/create-1';
import { delete$ } from '../fn/pra-project-write/delete';
import { Delete$Params } from '../fn/pra-project-write/delete';
import { PrmProjectAssignResponse } from '../models/prm-project-assign-response';
import { PrmProjectCreateResponse } from '../models/prm-project-create-response';

@Injectable({ providedIn: 'root' })
export class PraProjectWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `assign()` */
  static readonly AssignPath = '/api/prj/project/{project}/assign/{account}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assign()` instead.
   *
   * This method doesn't expect any request body.
   */
  assign$Response(params: Assign$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmProjectAssignResponse>> {
    return assign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assign$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assign(params: Assign$Params, context?: HttpContext): Observable<PrmProjectAssignResponse> {
    return this.assign$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmProjectAssignResponse>): PrmProjectAssignResponse => r.body)
    );
  }

  /** Path part for operation `delete()` */
  static readonly DeletePath = '/api/prj/project/{project}/assign/{account}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmProjectAssignResponse>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<PrmProjectAssignResponse> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmProjectAssignResponse>): PrmProjectAssignResponse => r.body)
    );
  }

  /** Path part for operation `create1()` */
  static readonly Create1Path = '/api/prj/project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1$Response(params: Create1$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmProjectCreateResponse>> {
    return create1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create1(params: Create1$Params, context?: HttpContext): Observable<PrmProjectCreateResponse> {
    return this.create1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmProjectCreateResponse>): PrmProjectCreateResponse => r.body)
    );
  }

}
