/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createUserLimit } from '../fn/limit/create-user-limit';
import { CreateUserLimit$Params } from '../fn/limit/create-user-limit';
import { deleteUserLimit } from '../fn/limit/delete-user-limit';
import { DeleteUserLimit$Params } from '../fn/limit/delete-user-limit';
import { readLimits } from '../fn/limit/read-limits';
import { ReadLimits$Params } from '../fn/limit/read-limits';
import { ReadLimits200ResponseDto } from '../models/read-limits-200-response-dto';
import { updateUserLimit } from '../fn/limit/update-user-limit';
import { UpdateUserLimit$Params } from '../fn/limit/update-user-limit';


/**
 * the Limit API
 */
@Injectable({ providedIn: 'root' })
export class LimitService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateUserLimit()` */
  static readonly UpdateUserLimitPath = '/api/hld/limit/{hrcode}/{year}/{absence_type}';

  /**
   * Update an user limit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserLimit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserLimit$Response(params: UpdateUserLimit$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateUserLimit(this.http, this.rootUrl, params, context);
  }

  /**
   * Update an user limit.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserLimit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserLimit(params: UpdateUserLimit$Params, context?: HttpContext): Observable<void> {
    return this.updateUserLimit$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteUserLimit()` */
  static readonly DeleteUserLimitPath = '/api/hld/limit/{hrcode}/{year}/{absence_type}';

  /**
   * Delete an user limit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserLimit()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserLimit$Response(params: DeleteUserLimit$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUserLimit(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an user limit.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserLimit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserLimit(params: DeleteUserLimit$Params, context?: HttpContext): Observable<void> {
    return this.deleteUserLimit$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `readLimits()` */
  static readonly ReadLimitsPath = '/api/hld/limit';

  /**
   * Read all limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  readLimits$Response(params?: ReadLimits$Params, context?: HttpContext): Observable<StrictHttpResponse<ReadLimits200ResponseDto>> {
    return readLimits(this.http, this.rootUrl, params, context);
  }

  /**
   * Read all limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readLimits(params?: ReadLimits$Params, context?: HttpContext): Observable<ReadLimits200ResponseDto> {
    return this.readLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReadLimits200ResponseDto>): ReadLimits200ResponseDto => r.body)
    );
  }

  /** Path part for operation `createUserLimit()` */
  static readonly CreateUserLimitPath = '/api/hld/limit';

  /**
   * Create an user limit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserLimit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserLimit$Response(params: CreateUserLimit$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createUserLimit(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an user limit.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUserLimit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserLimit(params: CreateUserLimit$Params, context?: HttpContext): Observable<void> {
    return this.createUserLimit$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
