/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { NvmQueryReportAvailableResourcesResponse } from '../models/nvm-query-report-available-resources-response';
import { resourcesRead } from '../fn/report-resources-read/resources-read';
import { ResourcesRead$Params } from '../fn/report-resources-read/resources-read';

@Injectable({ providedIn: 'root' })
export class ReportResourcesReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `resourcesRead()` */
  static readonly ResourcesReadPath = '/api/nav/resources/{jobNo}/{category}/{range}';

  /**
   * Read entries for the category in specified job and return unique list of available tasks and used descriptions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourcesRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourcesRead$Response(params: ResourcesRead$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryReportAvailableResourcesResponse>> {
    return resourcesRead(this.http, this.rootUrl, params, context);
  }

  /**
   * Read entries for the category in specified job and return unique list of available tasks and used descriptions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resourcesRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourcesRead(params: ResourcesRead$Params, context?: HttpContext): Observable<NvmQueryReportAvailableResourcesResponse> {
    return this.resourcesRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryReportAvailableResourcesResponse>): NvmQueryReportAvailableResourcesResponse => r.body)
    );
  }

}
