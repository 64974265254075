import {CustomAction} from '../state.model';

export const ABSENCEACCEPT = 'ABSENCEACCEPT';

export function absenceaccept(state: string = '', action: CustomAction) {
  switch (action.type) {
    case ABSENCEACCEPT:
      return action.payload.number;
    default:
      return state;
  }
}
