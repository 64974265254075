/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InmInventoryWitdrawResponse } from '../../models/inm-inventory-witdraw-response';
import { InmInventoryWithdrawRequest } from '../../models/inm-inventory-withdraw-request';

export interface Withdraw$Params {
      body: InmInventoryWithdrawRequest
}

export function withdraw(http: HttpClient, rootUrl: string, params: Withdraw$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryWitdrawResponse>> {
  const rb = new RequestBuilder(rootUrl, withdraw.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InmInventoryWitdrawResponse>;
    })
  );
}

withdraw.PATH = '/api/inv/inventory/withdraw';
