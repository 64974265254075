/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NvmQueryTransactionRequest } from '../../models/nvm-query-transaction-request';
import { NvmQueryTransactionResponse } from '../../models/nvm-query-transaction-response';

export interface Query$Params {
      body: NvmQueryTransactionRequest
}

export function query(http: HttpClient, rootUrl: string, params: Query$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryTransactionResponse>> {
  const rb = new RequestBuilder(rootUrl, query.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<NvmQueryTransactionResponse>;
    })
  );
}

query.PATH = '/api/nav/query/transactions';
