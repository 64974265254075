import { Injectable } from '@angular/core';

import { SnackMessageService } from '../core/service/snack-message.service';
import { ErrorHandlerService } from '../core/service/error-handler.service';
import { TmmCostDictionaryItem } from '../api/tms/models';
import { CostReadService } from '../api/tms/services';

@Injectable({
  providedIn: 'root',
})
export class DictionaryServiceService {
  public costTypes: TmmCostDictionaryItem[];

  constructor(
    private _costReadService: CostReadService,
    private _snackMessage: SnackMessageService,
    private _errorHandler: ErrorHandlerService,
  ) {}

  public initialize(): void {
    this._costReadService.dictionary().subscribe(
      (result) => {
        this.costTypes = result.items;
      },
      (error) => {
        const errorCode = this._errorHandler.errorCode(error);

        if (errorCode === 'UNKNOWN_CODE') {
          this._snackMessage.communicateError(errorCode);
        } else {
          this._snackMessage.communicateError('ERR.' + errorCode);
        }
      },
    );
  }
}
