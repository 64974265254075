/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PrmJobRelationDeleteResponse } from '../../models/prm-job-relation-delete-response';

export interface DeleteRelation$Params {
  jobNo: string;
  relationType: string;
}

export function deleteRelation(http: HttpClient, rootUrl: string, params: DeleteRelation$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobRelationDeleteResponse>> {
  const rb = new RequestBuilder(rootUrl, deleteRelation.PATH, 'delete');
  if (params) {
    rb.path('jobNo', params.jobNo, {});
    rb.path('relationType', params.relationType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PrmJobRelationDeleteResponse>;
    })
  );
}

deleteRelation.PATH = '/api/prj/job/{jobNo}/relation/{relationType}';
