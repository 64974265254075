/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NvmCommandProcessJobJournalRequest } from '../../models/nvm-command-process-job-journal-request';
import { NvmCommandProcessJobJournalResponse } from '../../models/nvm-command-process-job-journal-response';

export interface ProcessJobJournal$Params {
      body: NvmCommandProcessJobJournalRequest
}

export function processJobJournal(http: HttpClient, rootUrl: string, params: ProcessJobJournal$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmCommandProcessJobJournalResponse>> {
  const rb = new RequestBuilder(rootUrl, processJobJournal.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<NvmCommandProcessJobJournalResponse>;
    })
  );
}

processJobJournal.PATH = '/api/nav/job-journal/restart';
