/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InmInventoryLedgerListResponse } from '../../models/inm-inventory-ledger-list-response';

export interface InventoryLedgerEntries$Params {
  itemNo: string;
}

export function inventoryLedgerEntries(http: HttpClient, rootUrl: string, params: InventoryLedgerEntries$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryLedgerListResponse>> {
  const rb = new RequestBuilder(rootUrl, inventoryLedgerEntries.PATH, 'get');
  if (params) {
    rb.query('itemNo', params.itemNo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InmInventoryLedgerListResponse>;
    })
  );
}

inventoryLedgerEntries.PATH = '/api/inv/inventory/ledger/entries';
