import { Component, Inject, OnInit } from '@angular/core';
import { DialogData } from '../user-select-dialog/dialog-data';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNullOrUndefined } from '../utilities';

@Component({
  selector: 'app-loading-message',
  templateUrl: './loading-message.component.html',
  styleUrls: ['./loading-message.component.scss']
})
export class LoadingMessageComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {content: string}
  ) { }

  content = "message.loading"

  ngOnInit() {
    if(!isNullOrUndefined(this.data.content)) this.content = this.data.content
  }

}
