<app-numeric-input
  [layout]="layout"
  *ngIf="isMobile"
  [entertext]="'OK'"
  [placeholder]="placeholderLabel | translate"
  (focus)="showBottomPad()"
  (blur)="onLostBlur()"
  [disabled]="false"
  (ngModelChange)="onModelChange($event)"
  [(ngModel)]="value"
  [ngModelOptions]="{ standalone: true }"
  [id]="controlId"
></app-numeric-input>

<span class="field_label" *ngIf="isMobile">
  <span *ngIf="value != ''">{{ placeholderLabel | translate }}</span>
</span>
