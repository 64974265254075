/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { delete2 } from '../fn/calendar-write/delete-2';
import { Delete2$Params } from '../fn/calendar-write/delete-2';
import { TmmCalendarWriteResponse } from '../models/tmm-calendar-write-response';
import { write } from '../fn/calendar-write/write';
import { Write$Params } from '../fn/calendar-write/write';

@Injectable({ providedIn: 'root' })
export class CalendarWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `write()` */
  static readonly WritePath = '/api/tms/holidays';

  /**
   * Store list of holidays.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `write()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  write$Response(params: Write$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmCalendarWriteResponse>> {
    return write(this.http, this.rootUrl, params, context);
  }

  /**
   * Store list of holidays.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `write$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  write(params: Write$Params, context?: HttpContext): Observable<TmmCalendarWriteResponse> {
    return this.write$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmCalendarWriteResponse>): TmmCalendarWriteResponse => r.body)
    );
  }

  /** Path part for operation `delete2()` */
  static readonly Delete2Path = '/api/tms/holidays';

  /**
   * Store list of holidays.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delete2$Response(params: Delete2$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmCalendarWriteResponse>> {
    return delete2(this.http, this.rootUrl, params, context);
  }

  /**
   * Store list of holidays.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delete2(params: Delete2$Params, context?: HttpContext): Observable<TmmCalendarWriteResponse> {
    return this.delete2$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmCalendarWriteResponse>): TmmCalendarWriteResponse => r.body)
    );
  }

}
