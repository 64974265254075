/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PraJobJournalProjectCostsReadResponse } from '../../models/pra-job-journal-project-costs-read-response';

export interface ReadProjects1$Params {
  project: string;
  month: string;
  year: string;
}

export function readProjects1(http: HttpClient, rootUrl: string, params: ReadProjects1$Params, context?: HttpContext): Observable<StrictHttpResponse<PraJobJournalProjectCostsReadResponse>> {
  const rb = new RequestBuilder(rootUrl, readProjects1.PATH, 'get');
  if (params) {
    rb.path('project', params.project, {});
    rb.query('month', params.month, {});
    rb.query('year', params.year, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PraJobJournalProjectCostsReadResponse>;
    })
  );
}

readProjects1.PATH = '/api/prj/jobjournal/project/{project}/jobs';
