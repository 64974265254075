<div class="container-fluid summary_box">
  <div class="row summary_header clickable" (click)="open=!open" >
    <div *ngIf="jobNo" class="col-10 d-flex justify-content-start align-items-center">
      Project comments
    </div>
    <div *ngIf="incidentId" class="col-10 d-flex justify-content-start align-items-center">
      {{ 'label.ehs.comments' | translate }}
    </div>
    <div class="col-2 d-flex justify-content-end align-items-center clickable">
      <mat-icon *ngIf="!open">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="open">arrow_drop_up</mat-icon>
    </div>
  </div>
</div>

<div class="container-fluid no_margins_paddings" *ngIf="open && show_list">
  <div class="col-12 no_margins_paddings">
    <app-diary-list [jobNo]="jobNo" [incidentId]="incidentId" (entryModified)="onEntryModified($event)" [editable]="editable"></app-diary-list>
  </div>
</div>

<div class="container-fluid no_margins_paddings" *ngIf="open && show_edit">
  <div class="col-12 no_margins_paddings">
    <app-diary-entry></app-diary-entry>
  </div>
</div>
