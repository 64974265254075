/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { InmInventoryEntityLedgerListResponse } from '../models/inm-inventory-entity-ledger-list-response';
import { InmInventoryItemReadResponse } from '../models/inm-inventory-item-read-response';
import { InmInventoryItemsListReadResponse } from '../models/inm-inventory-items-list-read-response';
import { InmInventoryItemTransferReadResponse } from '../models/inm-inventory-item-transfer-read-response';
import { InmInventoryItemUsageReadResponse } from '../models/inm-inventory-item-usage-read-response';
import { InmInventoryLedgerListResponse } from '../models/inm-inventory-ledger-list-response';
import { InmInventoryLedgerResponse } from '../models/inm-inventory-ledger-response';
import { InmInventoryNoAvailableResponse } from '../models/inm-inventory-no-available-response';
import { InmReadHistoryForEntityResponse } from '../models/inm-read-history-for-entity-response';
import { InmTransferCommentsReadResponse } from '../models/inm-transfer-comments-read-response';
import { InvAccountInventoryListResponse } from '../models/inv-account-inventory-list-response';
import { inventoryItemsList } from '../fn/inventory-read/inventory-items-list';
import { InventoryItemsList$Params } from '../fn/inventory-read/inventory-items-list';
import { inventoryLedgerEntries } from '../fn/inventory-read/inventory-ledger-entries';
import { InventoryLedgerEntries$Params } from '../fn/inventory-read/inventory-ledger-entries';
import { inventoryLedgerForEntity } from '../fn/inventory-read/inventory-ledger-for-entity';
import { InventoryLedgerForEntity$Params } from '../fn/inventory-read/inventory-ledger-for-entity';
import { inventoryNoAvailable } from '../fn/inventory-read/inventory-no-available';
import { InventoryNoAvailable$Params } from '../fn/inventory-read/inventory-no-available';
import { inventoryTransfer } from '../fn/inventory-read/inventory-transfer';
import { InventoryTransfer$Params } from '../fn/inventory-read/inventory-transfer';
import { inventoryUsage } from '../fn/inventory-read/inventory-usage';
import { InventoryUsage$Params } from '../fn/inventory-read/inventory-usage';
import { listForAccount } from '../fn/inventory-read/list-for-account';
import { ListForAccount$Params } from '../fn/inventory-read/list-for-account';
import { readHistoryForEntity } from '../fn/inventory-read/read-history-for-entity';
import { ReadHistoryForEntity$Params } from '../fn/inventory-read/read-history-for-entity';
import { readItem } from '../fn/inventory-read/read-item';
import { ReadItem$Params } from '../fn/inventory-read/read-item';
import { readLedgerEntryDetails } from '../fn/inventory-read/read-ledger-entry-details';
import { ReadLedgerEntryDetails$Params } from '../fn/inventory-read/read-ledger-entry-details';
import { readTransferComments } from '../fn/inventory-read/read-transfer-comments';
import { ReadTransferComments$Params } from '../fn/inventory-read/read-transfer-comments';

@Injectable({ providedIn: 'root' })
export class InventoryReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readItem()` */
  static readonly ReadItemPath = '/api/inv/inventory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  readItem$Response(params: ReadItem$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryItemReadResponse>> {
    return readItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readItem(params: ReadItem$Params, context?: HttpContext): Observable<InmInventoryItemReadResponse> {
    return this.readItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryItemReadResponse>): InmInventoryItemReadResponse => r.body)
    );
  }

  /** Path part for operation `inventoryTransfer()` */
  static readonly InventoryTransferPath = '/api/inv/inventory/transfer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryTransfer()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryTransfer$Response(params: InventoryTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryItemTransferReadResponse>> {
    return inventoryTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryTransfer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryTransfer(params: InventoryTransfer$Params, context?: HttpContext): Observable<InmInventoryItemTransferReadResponse> {
    return this.inventoryTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryItemTransferReadResponse>): InmInventoryItemTransferReadResponse => r.body)
    );
  }

  /** Path part for operation `readTransferComments()` */
  static readonly ReadTransferCommentsPath = '/api/inv/transfer/{uid}/comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readTransferComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTransferComments$Response(params: ReadTransferComments$Params, context?: HttpContext): Observable<StrictHttpResponse<InmTransferCommentsReadResponse>> {
    return readTransferComments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readTransferComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTransferComments(params: ReadTransferComments$Params, context?: HttpContext): Observable<InmTransferCommentsReadResponse> {
    return this.readTransferComments$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmTransferCommentsReadResponse>): InmTransferCommentsReadResponse => r.body)
    );
  }

  /** Path part for operation `inventoryUsage()` */
  static readonly InventoryUsagePath = '/api/inv/inventory/usage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryUsage()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryUsage$Response(params: InventoryUsage$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryItemUsageReadResponse>> {
    return inventoryUsage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryUsage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryUsage(params: InventoryUsage$Params, context?: HttpContext): Observable<InmInventoryItemUsageReadResponse> {
    return this.inventoryUsage$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryItemUsageReadResponse>): InmInventoryItemUsageReadResponse => r.body)
    );
  }

  /** Path part for operation `inventoryItemsList()` */
  static readonly InventoryItemsListPath = '/api/inv/inventory/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryItemsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryItemsList$Response(params: InventoryItemsList$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryItemsListReadResponse>> {
    return inventoryItemsList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryItemsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryItemsList(params: InventoryItemsList$Params, context?: HttpContext): Observable<InmInventoryItemsListReadResponse> {
    return this.inventoryItemsList$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryItemsListReadResponse>): InmInventoryItemsListReadResponse => r.body)
    );
  }

  /** Path part for operation `readLedgerEntryDetails()` */
  static readonly ReadLedgerEntryDetailsPath = '/api/inv/inventory/ledger/{ledgerUid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readLedgerEntryDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  readLedgerEntryDetails$Response(params: ReadLedgerEntryDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryLedgerResponse>> {
    return readLedgerEntryDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readLedgerEntryDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readLedgerEntryDetails(params: ReadLedgerEntryDetails$Params, context?: HttpContext): Observable<InmInventoryLedgerResponse> {
    return this.readLedgerEntryDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryLedgerResponse>): InmInventoryLedgerResponse => r.body)
    );
  }

  /** Path part for operation `inventoryLedgerEntries()` */
  static readonly InventoryLedgerEntriesPath = '/api/inv/inventory/ledger/entries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryLedgerEntries()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryLedgerEntries$Response(params: InventoryLedgerEntries$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryLedgerListResponse>> {
    return inventoryLedgerEntries(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryLedgerEntries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryLedgerEntries(params: InventoryLedgerEntries$Params, context?: HttpContext): Observable<InmInventoryLedgerListResponse> {
    return this.inventoryLedgerEntries$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryLedgerListResponse>): InmInventoryLedgerListResponse => r.body)
    );
  }

  /** Path part for operation `inventoryLedgerForEntity()` */
  static readonly InventoryLedgerForEntityPath = '/api/inv/inventory/entity/ledger/entries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryLedgerForEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryLedgerForEntity$Response(params: InventoryLedgerForEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryEntityLedgerListResponse>> {
    return inventoryLedgerForEntity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryLedgerForEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryLedgerForEntity(params: InventoryLedgerForEntity$Params, context?: HttpContext): Observable<InmInventoryEntityLedgerListResponse> {
    return this.inventoryLedgerForEntity$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryEntityLedgerListResponse>): InmInventoryEntityLedgerListResponse => r.body)
    );
  }

  /** Path part for operation `inventoryNoAvailable()` */
  static readonly InventoryNoAvailablePath = '/api/inv/inventory/available';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryNoAvailable()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryNoAvailable$Response(params: InventoryNoAvailable$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryNoAvailableResponse>> {
    return inventoryNoAvailable(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryNoAvailable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryNoAvailable(params: InventoryNoAvailable$Params, context?: HttpContext): Observable<InmInventoryNoAvailableResponse> {
    return this.inventoryNoAvailable$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryNoAvailableResponse>): InmInventoryNoAvailableResponse => r.body)
    );
  }

  /** Path part for operation `readHistoryForEntity()` */
  static readonly ReadHistoryForEntityPath = '/api/inv/entity/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readHistoryForEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  readHistoryForEntity$Response(params: ReadHistoryForEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<InmReadHistoryForEntityResponse>> {
    return readHistoryForEntity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readHistoryForEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readHistoryForEntity(params: ReadHistoryForEntity$Params, context?: HttpContext): Observable<InmReadHistoryForEntityResponse> {
    return this.readHistoryForEntity$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmReadHistoryForEntityResponse>): InmReadHistoryForEntityResponse => r.body)
    );
  }

  /** Path part for operation `listForAccount()` */
  static readonly ListForAccountPath = '/api/inv/account/inventories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listForAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  listForAccount$Response(params: ListForAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<InvAccountInventoryListResponse>> {
    return listForAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listForAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listForAccount(params: ListForAccount$Params, context?: HttpContext): Observable<InvAccountInventoryListResponse> {
    return this.listForAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvAccountInventoryListResponse>): InvAccountInventoryListResponse => r.body)
    );
  }

}
