/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AcmAccountHomeAddressResponse } from '../models/acm-account-home-address-response';
import { AcmUpdateAddressResponse } from '../models/acm-update-address-response';
import { address } from '../fn/address/address';
import { Address$Params } from '../fn/address/address';
import { address1 } from '../fn/address/address-1';
import { Address1$Params } from '../fn/address/address-1';

@Injectable({ providedIn: 'root' })
export class AddressService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `address1()` */
  static readonly Address1Path = '/api/acc/account/{account}/address';

  /**
   * Read account home address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `address1()` instead.
   *
   * This method doesn't expect any request body.
   */
  address1$Response(params: Address1$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountHomeAddressResponse>> {
    return address1(this.http, this.rootUrl, params, context);
  }

  /**
   * Read account home address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `address1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  address1(params: Address1$Params, context?: HttpContext): Observable<AcmAccountHomeAddressResponse> {
    return this.address1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountHomeAddressResponse>): AcmAccountHomeAddressResponse => r.body)
    );
  }

  /** Path part for operation `address()` */
  static readonly AddressPath = '/api/acc/account/{account}/address';

  /**
   * Update or create home address of the account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `address()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  address$Response(params: Address$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmUpdateAddressResponse>> {
    return address(this.http, this.rootUrl, params, context);
  }

  /**
   * Update or create home address of the account
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `address$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  address(params: Address$Params, context?: HttpContext): Observable<AcmUpdateAddressResponse> {
    return this.address$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmUpdateAddressResponse>): AcmUpdateAddressResponse => r.body)
    );
  }

}
