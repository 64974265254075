/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AcmAccountAssignMapRoleResponse } from '../models/acm-account-assign-map-role-response';
import { AcmAccountRemoveMapRoleResponse } from '../models/acm-account-remove-map-role-response';
import { assignMapRole } from '../fn/maprole/assign-map-role';
import { AssignMapRole$Params } from '../fn/maprole/assign-map-role';
import { removeMapRole } from '../fn/maprole/remove-map-role';
import { RemoveMapRole$Params } from '../fn/maprole/remove-map-role';

@Injectable({ providedIn: 'root' })
export class MaproleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `assignMapRole()` */
  static readonly AssignMapRolePath = '/api/acc/account/{account}/maprole/{role}';

  /**
   * Assign the role for user in the user map role table.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignMapRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignMapRole$Response(params: AssignMapRole$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountAssignMapRoleResponse>> {
    return assignMapRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Assign the role for user in the user map role table.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignMapRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignMapRole(params: AssignMapRole$Params, context?: HttpContext): Observable<AcmAccountAssignMapRoleResponse> {
    return this.assignMapRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountAssignMapRoleResponse>): AcmAccountAssignMapRoleResponse => r.body)
    );
  }

  /** Path part for operation `removeMapRole()` */
  static readonly RemoveMapRolePath = '/api/acc/account/{account}/maprole/{role}';

  /**
   * Remove the role from the user map role table.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeMapRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMapRole$Response(params: RemoveMapRole$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountRemoveMapRoleResponse>> {
    return removeMapRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove the role from the user map role table.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeMapRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMapRole(params: RemoveMapRole$Params, context?: HttpContext): Observable<AcmAccountRemoveMapRoleResponse> {
    return this.removeMapRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountRemoveMapRoleResponse>): AcmAccountRemoveMapRoleResponse => r.body)
    );
  }

}
