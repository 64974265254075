/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PraJobJournalProjectsReadResponse } from '../models/pra-job-journal-projects-read-response';
import { readProjects } from '../fn/pra-job-journal-projects-read/read-projects';
import { ReadProjects$Params } from '../fn/pra-job-journal-projects-read/read-projects';

@Injectable({ providedIn: 'root' })
export class PraJobJournalProjectsReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readProjects()` */
  static readonly ReadProjectsPath = '/api/prj/jobjournal/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  readProjects$Response(params?: ReadProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<PraJobJournalProjectsReadResponse>> {
    return readProjects(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readProjects(params?: ReadProjects$Params, context?: HttpContext): Observable<PraJobJournalProjectsReadResponse> {
    return this.readProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<PraJobJournalProjectsReadResponse>): PraJobJournalProjectsReadResponse => r.body)
    );
  }

}
