/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { approval } from '../fn/timesheet-write/approval';
import { Approval$Params } from '../fn/timesheet-write/approval';
import { archvie } from '../fn/timesheet-write/archvie';
import { Archvie$Params } from '../fn/timesheet-write/archvie';
import { create } from '../fn/timesheet-write/create';
import { Create$Params } from '../fn/timesheet-write/create';
import { delete$ } from '../fn/timesheet-write/delete';
import { Delete$Params } from '../fn/timesheet-write/delete';
import { distance } from '../fn/timesheet-write/distance';
import { Distance$Params } from '../fn/timesheet-write/distance';
import { exportEntryWrite } from '../fn/timesheet-write/export-entry-write';
import { ExportEntryWrite$Params } from '../fn/timesheet-write/export-entry-write';
import { TmmEntryArchiveResponse } from '../models/tmm-entry-archive-response';
import { TmmEntryCreateResponse } from '../models/tmm-entry-create-response';
import { TmmEntryDeleteResponse } from '../models/tmm-entry-delete-response';
import { TmmEntryDistanceWriteResponse } from '../models/tmm-entry-distance-write-response';
import { TmmEntryStateUpdateResponse } from '../models/tmm-entry-state-update-response';
import { TmmEntryUpdateResponse } from '../models/tmm-entry-update-response';
import { TmmExportEntryWriteResponse } from '../models/tmm-export-entry-write-response';
import { update } from '../fn/timesheet-write/update';
import { Update$Params } from '../fn/timesheet-write/update';

@Injectable({ providedIn: 'root' })
export class TimesheetWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `distance()` */
  static readonly DistancePath = '/api/tms/timesheet/{timesheetUid}/transit/{transitUid}';

  /**
   * Update existing transit entry and store distance calculated and passed in the input.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  distance$Response(params: Distance$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryDistanceWriteResponse>> {
    return distance(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing transit entry and store distance calculated and passed in the input.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  distance(params: Distance$Params, context?: HttpContext): Observable<TmmEntryDistanceWriteResponse> {
    return this.distance$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryDistanceWriteResponse>): TmmEntryDistanceWriteResponse => r.body)
    );
  }

  /** Path part for operation `approval()` */
  static readonly ApprovalPath = '/api/tms/timesheet/{state}';

  /**
   * Update state.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approval()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approval$Response(params: Approval$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryStateUpdateResponse>> {
    return approval(this.http, this.rootUrl, params, context);
  }

  /**
   * Update state.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approval$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approval(params: Approval$Params, context?: HttpContext): Observable<TmmEntryStateUpdateResponse> {
    return this.approval$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryStateUpdateResponse>): TmmEntryStateUpdateResponse => r.body)
    );
  }

  /** Path part for operation `update()` */
  static readonly UpdatePath = '/api/tms/timesheet/entry/{currentEntryUID}';

  /**
   * Update entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryUpdateResponse>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * Update entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: Update$Params, context?: HttpContext): Observable<TmmEntryUpdateResponse> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryUpdateResponse>): TmmEntryUpdateResponse => r.body)
    );
  }

  /** Path part for operation `archvie()` */
  static readonly ArchviePath = '/api/tms/timesheet/archive';

  /**
   * Archive the entry. THe entry will be stored in the database  with different state but removed from any other projections in other domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `archvie()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  archvie$Response(params: Archvie$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryArchiveResponse>> {
    return archvie(this.http, this.rootUrl, params, context);
  }

  /**
   * Archive the entry. THe entry will be stored in the database  with different state but removed from any other projections in other domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `archvie$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  archvie(params: Archvie$Params, context?: HttpContext): Observable<TmmEntryArchiveResponse> {
    return this.archvie$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryArchiveResponse>): TmmEntryArchiveResponse => r.body)
    );
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/api/tms/timesheet';

  /**
   * Create new entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryCreateResponse>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<TmmEntryCreateResponse> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryCreateResponse>): TmmEntryCreateResponse => r.body)
    );
  }

  /** Path part for operation `exportEntryWrite()` */
  static readonly ExportEntryWritePath = '/api/tms/timesheet/{uid}/export';

  /**
   * Export an entry. The API can .
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportEntryWrite()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportEntryWrite$Response(params: ExportEntryWrite$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmExportEntryWriteResponse>> {
    return exportEntryWrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Export an entry. The API can .
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportEntryWrite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportEntryWrite(params: ExportEntryWrite$Params, context?: HttpContext): Observable<TmmExportEntryWriteResponse> {
    return this.exportEntryWrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmExportEntryWriteResponse>): TmmExportEntryWriteResponse => r.body)
    );
  }

  /** Path part for operation `delete()` */
  static readonly DeletePath = '/api/tms/timesheet/entry/{uid}';

  /**
   * Delete entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryDeleteResponse>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete entry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<TmmEntryDeleteResponse> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryDeleteResponse>): TmmEntryDeleteResponse => r.body)
    );
  }

}
