/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExmConfigurationWriteRequest } from '../../models/exm-configuration-write-request';
import { ExmConfigurationWriteResponse } from '../../models/exm-configuration-write-response';

export interface ConfigureTsRegistration$Params {
  body: ExmConfigurationWriteRequest;
}

export function configureTsRegistration(
  http: HttpClient,
  rootUrl: string,
  params: ConfigureTsRegistration$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<ExmConfigurationWriteResponse>> {
  const rb = new RequestBuilder(rootUrl, configureTsRegistration.PATH, 'patch');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http
    .request(rb.build({ responseType: 'json', accept: '*/*', context }))
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExmConfigurationWriteResponse>;
      }),
    );
}

configureTsRegistration.PATH = '/api/exp/configuration';
