import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
})
export class BannerComponent implements OnInit {
  constructor() { }
  @Input() msgDetail: any;
  @Output() closeEvent: EventEmitter<string> = new EventEmitter();
  getScreenWidth: number;
  body: string = '';
  bodyLenght: number = 0;
  ngOnInit(): void {
    this.body = this.msgDetail?.body;
    this.getScreenWidth = window.innerWidth;

    if (this.msgDetail.icon === undefined) this.msgDetail.icon = 'warning';
  }

  goto() {
    this.closeEvent.emit('report');
  }
}
