/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { completeTask } from '../fn/tsa-complete-task/complete-task';
import { CompleteTask$Params } from '../fn/tsa-complete-task/complete-task';
import { TsmCompleteTaskResponse } from '../models/tsm-complete-task-response';

@Injectable({ providedIn: 'root' })
export class TsaCompleteTaskService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `completeTask()` */
  static readonly CompleteTaskPath = '/api/tsk/task/{id}/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeTask$Response(params: CompleteTask$Params, context?: HttpContext): Observable<StrictHttpResponse<TsmCompleteTaskResponse>> {
    return completeTask(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  completeTask(params: CompleteTask$Params, context?: HttpContext): Observable<TsmCompleteTaskResponse> {
    return this.completeTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<TsmCompleteTaskResponse>): TsmCompleteTaskResponse => r.body)
    );
  }

}
