/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { SuperiorService } from './services/superior.service';
import { SiteroleService } from './services/siterole.service';
import { MaproleService } from './services/maprole.service';
import { AddressService } from './services/address.service';
import { PreferenceService } from './services/preference.service';
import { SystemService } from './services/system.service';
import { LoginService } from './services/login.service';
import { AcaAccountImpersonateService } from './services/aca-account-impersonate.service';
import { RoleService } from './services/role.service';
import { PermissionService } from './services/permission.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    SuperiorService,
    SiteroleService,
    MaproleService,
    AddressService,
    PreferenceService,
    SystemService,
    LoginService,
    AcaAccountImpersonateService,
    RoleService,
    PermissionService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
