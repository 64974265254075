import { UserAnonymous } from '../../service/user/user-anonymous';
import { User } from '../../service/user/user';
import { CustomAction } from '../state.model';

export const LOG_USER = 'LOG_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export function user(
  currentUser: User = new UserAnonymous(),
  action: CustomAction,
) {
  switch (action.type) {
    case LOGOUT_USER:
      return new UserAnonymous();
    case LOG_USER: {
      return action.payload.account;
    }

    default:
      return currentUser;
  }
}
