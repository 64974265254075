import {
  Component,
  HostListener,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/core/service/user/user';
import { AppState } from 'src/app/core/state/app-state';
import * as _moment from 'moment';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.css'],
})
export class SidebarMenuComponent implements OnInit {
  private _project = '';
  constructor(
    private _store: Store<AppState>,
    private _t: TranslateService,
    private _router: Router,
  ) {
    _router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationEnd) {
        let url = val.url.substring(1, 99);
        this.menusidebar.forEach((a) => {
          if (url === a.URL) {
            a.active = true;
          } else {
            a.active = false;
          }
        });
        this.menusidebarS.forEach((a) => {
          if (url === a.URL) {
            a.active = true;
          } else {
            a.active = false;
          }
        });
        this.menusidebarB.forEach((a) => {
          if (url === a.URL) {
            a.active = true;
          } else {
            a.active = false;
          }
        });
        this.changeMenuElem();
      }
    });
  }
  menusidebarS: Array<menu> = [];
  menusidebarB: Array<menu> = [];
  showMore: boolean = false;
  activeIconmenuS: boolean = false;
  showDescription: boolean = false;
  innerHeight: number = 0;
  userLogged: boolean = false;
  test = 0;
  _user: User;
  @Output() iconOnly = new EventEmitter<boolean>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.changeMenuElem();
  }

  menusidebar: Array<menu> = [
    {
      Id: 'home',
      Desc: this.t('label.home'),
      IconUrl: 'assets/icons/icons_projects.png',
      show: true,
      URL: '',
    },
    {
      Id: 'work_v2',
      Desc: this.t('label.work'),
      IconUrl: 'assets/icons/icons_work.png',
      show: false,
      URL: 'work',
      queryParams: {
        date: _moment(new Date()).format('YYYY-MM-DD'),
        project: this._project,
      },
    },
    {
      Id: 'work',
      Desc: this.t('label.work'),
      IconUrl: 'assets/icons/icons_work.png',
      show: true,
      URL: 'work/standard',
      queryParams: {
        date: _moment(new Date()).format('YYYY-MM-DD'),
        project: this._project,
      },
    },
    {
      Id: 'projects',
      Desc: this.t('label.projects'),
      IconUrl: 'assets/icons/icons_projects.png',
      show: false,
      URL: 'project/home',
    },
    {
      Id: 'task',
      Desc: this.t('module.task'),
      IconUrl: 'assets/icons/icons_reports.png',
      show: false,
      URL: 'task',
    },
    {
      Id: 'message',
      Desc: this.t('label.messages'),
      IconUrl: 'assets/icons/icons_message.png',
      show: false,
      URL: 'message',
      active: true,
    },
    {
      Id: 'report',
      Desc: this.t('label.reports'),
      IconUrl: 'assets/icons/icons_reports.png',
      show: true,
      URL: 'report',
    },
    {
      Id: 'holidays',
      Desc: this.t('label.absence'),
      IconUrl: 'assets/icons/icons_holidays.png',
      show: true,
      URL: 'holiday',
    },
    {
      Id: 'acceptance',
      Desc: this.t('label.accept'),
      IconUrl: 'assets/icons/icons_accept.png',
      show: false,
      URL: 'project/accept',
    },
    {
      Id: 'holidaysAcceptance',
      Desc: this.t('label.leave.acceptance'),
      IconUrl: 'assets/icons/icons_accept_holidays.png',
      show: false,
      URL: 'holiday/accept',
    },
    {
      Id: 'volumeOfOrders',
      Desc: this.t('label.voo'),
      IconUrl: 'assets/icons/icons_reports.png',
      show: false,
      URL: 'voo',
    },
    {
      Id: 'administration',
      Desc: this.t('label.administration'),
      IconUrl: 'assets/icons/icons_work.png',
      show: false,
      URL: 'administration/home',
    },
    {
      Id: 'hr',
      Desc: this.t('module.hr'),
      IconUrl: 'assets/icons/icons_holidays.png',
      show: false,
      URL: 'hr',
    },
    {
      Id: 'operations',
      Desc: this.t('label.header.operations'),
      IconUrl: 'assets/icons/icons_work.png',
      show: false,
      URL: 'operations',
    },
    {
      Id: 'reports',
      Desc: this.t('label.header.reports'),
      IconUrl: 'assets/icons/icons_reports.png',
      show: false,
      URL: 'reports',
    },
    {
      Id: 'inventory',
      Desc: this.t('module.inventory'),
      IconUrl: 'assets/icons/icons_reports.png',
      show: false,
      URL: 'inventory',
    },
    {
      Id: 'workerPage',
      Desc: this.t('module.worker.page'),
      IconUrl: 'assets/icons/icons_reports.png',
      show: false,
      URL: 'worokerhome',
    },
  ];

  ngOnInit(): void {
    this.menusidebarB = this.menusidebar.slice(0)
    this.innerHeight = window.innerHeight
    this.checkRoles()
    this.changeMenuElem()     

  }

  t(label) {
    return this._t.instant(label);
  }
  goto(url, queryParams) {
    this._router.navigate([url], { queryParams: queryParams });

    this.showMore = false;
  }

  checkRoles() {
    this._store.select('user').subscribe((user: User) => {
      this.menusidebar.forEach((item) => {
        if (item.Desc) {
        }
      });
      console.log(1);
      this.menusidebar.find((a) => a.Id == 'holidaysAcceptance').show =
        user.absenceAcceptor();
      this.menusidebar.find((a) => a.Id == 'volumeOfOrders').show = user.inRole(
        'UI_VOLUME_OF_ORDERS',
      );
      this.menusidebar.find((a) => a.Id == 'projects').show =
        user.inRole('ENGINEER') || user.inRole('MANAGER');
      this.menusidebar.find((a) => a.Id == 'acceptance').show = user.inRole(
        'FN_ACCEPT_TIMESHEET',
      );
      this.menusidebar.find((a) => a.Id == 'administration').show =
        user.inRole('UI_ADMINISTRATOR');
      this.menusidebar.find((a) => a.Id == 'operations').show =
        user.inRole('UI_OPERATIONS');
      this.menusidebar.find((a) => a.Id == 'reports').show =
        user.inRole('UI_REPORTS');
      this.menusidebar.find((a) => a.Id == 'inventory').show =
        user.inRole('UI_INVENTORY');
      this.menusidebar.find((a) => a.Id == 'workerPage').show =
        user.inRole('UI_WORKER_PAGE');
      this.menusidebar.find((a) => a.Id == 'work_v2').show =
        user.inRole('UI_WORK_V2');
      this.menusidebar.find((a) => a.Id == 'task').show =
        user.inRole('UI_TASK');
      this.menusidebar.find((a) => a.Id == 'message').show =
        user.inRole('UI_MESSAGE');
      this.menusidebar.find((a) => a.Id == 'hr').show =
        user.inRole('MODULE_HR');
      this.menusidebar.find((a) => a.Id == 'siteReportingTest').show =
        user.inRole('UI_SHOW_SITE_REPORTING_TEST');
      this._user = user;
      this.userLogged = true;
    });
  }

  changeMenuElem() {
    this.menusidebarS = [];
    this.menusidebarB = this.menusidebar.filter((a) => a.show).slice(0);
    this.innerHeight = window.innerHeight;
    let menuLen = this.menusidebar.length * 49;
    if (this.innerHeight - 50 < menuLen) {
      this.menusidebarS.push(
        ...this.menusidebarB.splice(
          this.menusidebar.length -
          Math.floor((menuLen - (this.innerHeight - 144)) / 48),
        ),
      );
    }
    this.activeIconmenuS = this.menusidebarS.some((a) => a.active === true);
    if (this.menusidebarS.length == 1) this.showMore = false;
  }

  toggleShowDescription() {
    this.showDescription = !this.showDescription;
    this.iconOnly.emit(!this.showDescription);
    this.changeMenuElem();
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }
}

interface menu {
  Id: string;
  Desc: string;
  IconUrl: string;
  show: boolean;
  active?: boolean;
  URL: string;
  queryParams?: any;
}
