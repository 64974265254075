/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CmmDirectMessageRequest } from '../../models/cmm-direct-message-request';
import { CmmDirectMessageResponse } from '../../models/cmm-direct-message-response';

export interface DirectMessage$Params {
  body: CmmDirectMessageRequest;
}

export function directMessage(
  http: HttpClient,
  rootUrl: string,
  params: DirectMessage$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<CmmDirectMessageResponse>> {
  const rb = new RequestBuilder(rootUrl, directMessage.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http
    .request(rb.build({ responseType: 'json', accept: '*/*', context }))
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CmmDirectMessageResponse>;
      }),
    );
}

directMessage.PATH = '/api/sys/message';
