import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { AppState } from '../core/state/app-state';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { LOGOUT_USER } from '../core/state/reducers/user.reducer';
import { UserCookieStorage } from '../core/service/user/user-cookie-storage';
import { User } from '../core/service/user/user';
import { isNullOrUndefined } from 'src/app/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptorService implements HttpInterceptor {
  private __session_id: string = undefined;
  private __auth_token: string = undefined;

  constructor(
    private _cookieService: CookieService,
    private _store: Store<AppState>,
    private _injector: Injector,
    private _router: Router,
  ) {
    this._store.select('user').subscribe((user: User) => {
      if (user.logged()) {
        this.__auth_token = user.authorizationToken();
      } else {
        this.__auth_token = undefined;
      }
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Apply the headers
    let token = this._cookieService.get('aarsleff_session');

    if (!isNullOrUndefined(this.__auth_token)) {
      token = this.__auth_token;
    }

    if (
      !isNullOrUndefined(token) &&
      token !== '' &&
      req.headers.get('Authorization') == null
    ) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }
    if (this.__session_id === undefined) {
      this.__session_id = UUID.UUID();
      // replace all - with empty string
      this.__session_id = this.__session_id.replace(/-/g, '');
    }

    req = req.clone({
      setHeaders: {
        'x-session-id': this.__session_id,
        'X-CALLID': UUID.UUID(),
      },
    });

    // Also handle errors globally
    return next.handle(req).pipe(
      tap(
        (x) => x,
        (err) => {
          // Handle this err
          if (err.status !== 200) {
            if (err.status === 401 || err.status === 403) {
              this._store.dispatch({
                type: LOGOUT_USER,
                payload: {},
              });
              new UserCookieStorage(this._injector).clear();
              this._router.navigateByUrl('/login');
            }
            console.error(
              `Error performing request, status code = ${err.status}`,
            );
          }
        },
      ),
    );
  }
}
