import {CustomAction} from '../state.model';

export const LAST_PROJECT = 'LAST_PROJECT';

export function project(state: string = '', action: CustomAction) {
  switch (action.type) {
    case LAST_PROJECT:
      return action.payload.project;
    default:
      return state;
  }
}
