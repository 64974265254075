/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { InmInventoryAnalitycsResponse } from '../models/inm-inventory-analitycs-response';
import { readAnalytics } from '../fn/analytics-read/read-analytics';
import { ReadAnalytics$Params } from '../fn/analytics-read/read-analytics';

@Injectable({ providedIn: 'root' })
export class AnalyticsReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readAnalytics()` */
  static readonly ReadAnalyticsPath = '/api/inv/inventory/analytics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readAnalytics()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  readAnalytics$Response(params?: ReadAnalytics$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryAnalitycsResponse>> {
    return readAnalytics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readAnalytics$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  readAnalytics(params?: ReadAnalytics$Params, context?: HttpContext): Observable<InmInventoryAnalitycsResponse> {
    return this.readAnalytics$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryAnalitycsResponse>): InmInventoryAnalitycsResponse => r.body)
    );
  }

}
