import { PermissionModel } from "../permission.model";
import { CustomAction } from "../state.model";

export const PERMISSION_REGISTER = "PERMISSION_REGISTER";

export function permission(
  permissions: PermissionModel = new PermissionModel([]), action: CustomAction
) {
  switch (action.type) {
    case PERMISSION_REGISTER:
      return new PermissionModel(action.payload.permissions);
    default:
      return permissions;
  }
}
