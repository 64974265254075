<div
  class="container-fluid d-flex flex-column no_margins_paddings"
  style="height: 100%"
>
  <div class="row no_margins_paddings">
    <div class="col no_margins_paddings">
      <app-top-menu
        [show_logo]="false"
        [show_menu]="false"
        [show_back_button]="true"
        background_color="#ffffff"
        [description]="'label.chose_project' | translate"
        (back_pressed)="onNoClick()"
      >
      </app-top-menu>
      <div
        class="container-fluid border-top border-bottom"
        style="height: 70px"
      >
        <div class="row" style="height: 100%">
          <div class="col-9 d-flex justify-content-start align-items-center">
            <input
              [placeholder]="'label.project_or_name' | translate"
              [(ngModel)]="noOrName"
              (keydown.enter)="execute_search()"
              style="width: 100%; height: 100%"
            />
          </div>
          <div class="col-3 d-flex justify-content-center align-items-center">
            <button
              cdkFocusInitial
              class="standard-button-action"
              mat-button
              (click)="execute_search()"
            >
              {{ 'label.search' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row flex-fill no_margins_paddings">
    <div class="col d-flex flex-column no_margins_paddings">
      <cdk-virtual-scroll-viewport
        itemSize="50"
        class="example-viewport h-100"
        #virtualView
      >
        <div *cdkVirtualFor="let item of ds" class="example-item">
          <div
            class="container-fluid"
            class="outer_container clickable"
            (click)="select_project(item)"
            [ngClass]="{ selected_row: item?.no == no?.no }"
          >
            <div class="row" style="padding-left: 15px; padding-right: 15px">
              <div
                class="col-11"
                style="
                  padding: 0px;
                  margin: 0px;
                  border-bottom: #dee2e6 1px solid;
                "
              >
                <div class="container-fluid" class="inner_container">
                  <div class="row">
                    <div class="col-12 v_text_descriptive">
                      {{ item?.parentJobNo }} {{ item?.parentJobName }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 v_text_header">
                      {{ item?.no }}
                      {{ item?.name || 'label.download' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-1 d-flex align-items-center justify-content-center"
                style="
                  padding: 0px;
                  margin: 0px;
                  border-bottom: #dee2e6 1px solid;
                "
                [ngClass]="{ selected_row_inner: item?.no == no }"
              >
                <mat-icon *ngIf="item?.no == no">check</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  <div class="row no_margins_paddings">
    <div class="col no_margins_paddings">
      <div
        class="container-fluid standard-button-action clickable"
        style="height: 60px"
        [ngClass]="{ 'standard-button-disabled': no == '' }"
        (click)="onYesClick()"
      >
        <div class="row" style="height: 60px">
          <div class="col-12 d-flex justify-content-center align-items-center">
            {{ 'label.choose' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
