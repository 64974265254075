/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Default Exception Handler
 */
@Injectable({
  providedIn: 'root',
})
class DefaultExceptionHandlerService extends __BaseService {
  static readonly handleMethodArgumentNotValidExceptionUsingGETPath = '/';
  static readonly handleMethodArgumentNotValidExceptionUsingHEADPath = '/';
  static readonly handleMethodArgumentNotValidExceptionUsingPOSTPath = '/';
  static readonly handleMethodArgumentNotValidExceptionUsingPUTPath = '/';
  static readonly handleMethodArgumentNotValidExceptionUsingDELETEPath = '/';
  static readonly handleMethodArgumentNotValidExceptionUsingOPTIONSPath = '/';
  static readonly handleMethodArgumentNotValidExceptionUsingPATCHPath = '/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingGETParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingGETResponse(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.target != null) __params = __params.set('target', params.target.toString());
    if (params.suppressed0StackTrace0NativeMethod != null) __params = __params.set('suppressed[0].stackTrace[0].nativeMethod', params.suppressed0StackTrace0NativeMethod.toString());
    if (params.suppressed0StackTrace0ModuleVersion != null) __params = __params.set('suppressed[0].stackTrace[0].moduleVersion', params.suppressed0StackTrace0ModuleVersion.toString());
    if (params.suppressed0StackTrace0ModuleName != null) __params = __params.set('suppressed[0].stackTrace[0].moduleName', params.suppressed0StackTrace0ModuleName.toString());
    if (params.suppressed0StackTrace0MethodName != null) __params = __params.set('suppressed[0].stackTrace[0].methodName', params.suppressed0StackTrace0MethodName.toString());
    if (params.suppressed0StackTrace0LineNumber != null) __params = __params.set('suppressed[0].stackTrace[0].lineNumber', params.suppressed0StackTrace0LineNumber.toString());
    if (params.suppressed0StackTrace0FileName != null) __params = __params.set('suppressed[0].stackTrace[0].fileName', params.suppressed0StackTrace0FileName.toString());
    if (params.suppressed0StackTrace0ClassName != null) __params = __params.set('suppressed[0].stackTrace[0].className', params.suppressed0StackTrace0ClassName.toString());
    if (params.suppressed0StackTrace0ClassLoaderName != null) __params = __params.set('suppressed[0].stackTrace[0].classLoaderName', params.suppressed0StackTrace0ClassLoaderName.toString());
    if (params.suppressed0Message != null) __params = __params.set('suppressed[0].message', params.suppressed0Message.toString());
    if (params.suppressed0LocalizedMessage != null) __params = __params.set('suppressed[0].localizedMessage', params.suppressed0LocalizedMessage.toString());
    if (params.suppressed0Cause != null) __params = __params.set('suppressed[0].cause', params.suppressed0Cause.toString());
    (params.suppressedFields || []).forEach(val => {if (val != null) __params = __params.append('suppressedFields', val.toString())});
    if (params.stackTrace0NativeMethod != null) __params = __params.set('stackTrace[0].nativeMethod', params.stackTrace0NativeMethod.toString());
    if (params.stackTrace0ModuleVersion != null) __params = __params.set('stackTrace[0].moduleVersion', params.stackTrace0ModuleVersion.toString());
    if (params.stackTrace0ModuleName != null) __params = __params.set('stackTrace[0].moduleName', params.stackTrace0ModuleName.toString());
    if (params.stackTrace0MethodName != null) __params = __params.set('stackTrace[0].methodName', params.stackTrace0MethodName.toString());
    if (params.stackTrace0LineNumber != null) __params = __params.set('stackTrace[0].lineNumber', params.stackTrace0LineNumber.toString());
    if (params.stackTrace0FileName != null) __params = __params.set('stackTrace[0].fileName', params.stackTrace0FileName.toString());
    if (params.stackTrace0ClassName != null) __params = __params.set('stackTrace[0].className', params.stackTrace0ClassName.toString());
    if (params.stackTrace0ClassLoaderName != null) __params = __params.set('stackTrace[0].classLoaderName', params.stackTrace0ClassLoaderName.toString());
    if (params.parameterTypeIndexForCurrentLevel != null) __params = __params.set('parameter.typeIndexForCurrentLevel', params.parameterTypeIndexForCurrentLevel.toString());
    if (params.parameterParameterName != null) __params = __params.set('parameter.parameterName', params.parameterParameterName.toString());
    if (params.parameterParameterIndex != null) __params = __params.set('parameter.parameterIndex', params.parameterParameterIndex.toString());
    if (params.parameterParameter != null) __params = __params.set('parameter.parameter', params.parameterParameter.toString());
    if (params.parameterOptional != null) __params = __params.set('parameter.optional', params.parameterOptional.toString());
    if (params.parameterNestingLevel != null) __params = __params.set('parameter.nestingLevel', params.parameterNestingLevel.toString());
    if (params.parameterNestedGenericParameterType != null) __params = __params.set('parameter.nestedGenericParameterType', params.parameterNestedGenericParameterType.toString());
    if (params.parameterMethod != null) __params = __params.set('parameter.method', params.parameterMethod.toString());
    if (params.parameterMember != null) __params = __params.set('parameter.member', params.parameterMember.toString());
    if (params.parameterGenericParameterType != null) __params = __params.set('parameter.genericParameterType', params.parameterGenericParameterType.toString());
    if (params.parameterExecutable != null) __params = __params.set('parameter.executable', params.parameterExecutable.toString());
    if (params.parameterConstructor != null) __params = __params.set('parameter.constructor', params.parameterConstructor.toString());
    if (params.parameterAnnotatedElement != null) __params = __params.set('parameter.annotatedElement', params.parameterAnnotatedElement.toString());
    if (params.objectName != null) __params = __params.set('objectName', params.objectName.toString());
    if (params.nestedPath != null) __params = __params.set('nestedPath', params.nestedPath.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.localizedMessage != null) __params = __params.set('localizedMessage', params.localizedMessage.toString());
    if (params.globalErrors0ObjectName != null) __params = __params.set('globalErrors[0].objectName', params.globalErrors0ObjectName.toString());
    if (params.globalErrors0DefaultMessage != null) __params = __params.set('globalErrors[0].defaultMessage', params.globalErrors0DefaultMessage.toString());
    (params.globalErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].codes', val.toString())});
    if (params.globalErrors0Code != null) __params = __params.set('globalErrors[0].code', params.globalErrors0Code.toString());
    (params.globalErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].arguments', val.toString())});
    if (params.globalErrorCount != null) __params = __params.set('globalErrorCount', params.globalErrorCount.toString());
    if (params.globalErrorObjectName != null) __params = __params.set('globalError.objectName', params.globalErrorObjectName.toString());
    if (params.globalErrorDefaultMessage != null) __params = __params.set('globalError.defaultMessage', params.globalErrorDefaultMessage.toString());
    (params.globalErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('globalError.codes', val.toString())});
    if (params.globalErrorCode != null) __params = __params.set('globalError.code', params.globalErrorCode.toString());
    (params.globalErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('globalError.arguments', val.toString())});
    if (params.fieldErrors0RejectedValue != null) __params = __params.set('fieldErrors[0].rejectedValue', params.fieldErrors0RejectedValue.toString());
    if (params.fieldErrors0ObjectName != null) __params = __params.set('fieldErrors[0].objectName', params.fieldErrors0ObjectName.toString());
    if (params.fieldErrors0Field != null) __params = __params.set('fieldErrors[0].field', params.fieldErrors0Field.toString());
    if (params.fieldErrors0DefaultMessage != null) __params = __params.set('fieldErrors[0].defaultMessage', params.fieldErrors0DefaultMessage.toString());
    (params.fieldErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].codes', val.toString())});
    if (params.fieldErrors0Code != null) __params = __params.set('fieldErrors[0].code', params.fieldErrors0Code.toString());
    if (params.fieldErrors0BindingFailure != null) __params = __params.set('fieldErrors[0].bindingFailure', params.fieldErrors0BindingFailure.toString());
    (params.fieldErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].arguments', val.toString())});
    if (params.fieldErrorCount != null) __params = __params.set('fieldErrorCount', params.fieldErrorCount.toString());
    if (params.fieldErrorRejectedValue != null) __params = __params.set('fieldError.rejectedValue', params.fieldErrorRejectedValue.toString());
    if (params.fieldErrorObjectName != null) __params = __params.set('fieldError.objectName', params.fieldErrorObjectName.toString());
    if (params.fieldErrorField != null) __params = __params.set('fieldError.field', params.fieldErrorField.toString());
    if (params.fieldErrorDefaultMessage != null) __params = __params.set('fieldError.defaultMessage', params.fieldErrorDefaultMessage.toString());
    (params.fieldErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('fieldError.codes', val.toString())});
    if (params.fieldErrorCode != null) __params = __params.set('fieldError.code', params.fieldErrorCode.toString());
    if (params.fieldErrorBindingFailure != null) __params = __params.set('fieldError.bindingFailure', params.fieldErrorBindingFailure.toString());
    (params.fieldErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('fieldError.arguments', val.toString())});
    if (params.errorCount != null) __params = __params.set('errorCount', params.errorCount.toString());
    if (params.cause != null) __params = __params.set('cause', params.cause.toString());
    if (params.allErrors0ObjectName != null) __params = __params.set('allErrors[0].objectName', params.allErrors0ObjectName.toString());
    if (params.allErrors0DefaultMessage != null) __params = __params.set('allErrors[0].defaultMessage', params.allErrors0DefaultMessage.toString());
    (params.allErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].codes', val.toString())});
    if (params.allErrors0Code != null) __params = __params.set('allErrors[0].code', params.allErrors0Code.toString());
    (params.allErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].arguments', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingGETParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingGET(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingGETParams): __Observable<null> {
    return this.handleMethodArgumentNotValidExceptionUsingGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingHEADParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingHEADResponse(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingHEADParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.target != null) __params = __params.set('target', params.target.toString());
    if (params.suppressed0StackTrace0NativeMethod != null) __params = __params.set('suppressed[0].stackTrace[0].nativeMethod', params.suppressed0StackTrace0NativeMethod.toString());
    if (params.suppressed0StackTrace0ModuleVersion != null) __params = __params.set('suppressed[0].stackTrace[0].moduleVersion', params.suppressed0StackTrace0ModuleVersion.toString());
    if (params.suppressed0StackTrace0ModuleName != null) __params = __params.set('suppressed[0].stackTrace[0].moduleName', params.suppressed0StackTrace0ModuleName.toString());
    if (params.suppressed0StackTrace0MethodName != null) __params = __params.set('suppressed[0].stackTrace[0].methodName', params.suppressed0StackTrace0MethodName.toString());
    if (params.suppressed0StackTrace0LineNumber != null) __params = __params.set('suppressed[0].stackTrace[0].lineNumber', params.suppressed0StackTrace0LineNumber.toString());
    if (params.suppressed0StackTrace0FileName != null) __params = __params.set('suppressed[0].stackTrace[0].fileName', params.suppressed0StackTrace0FileName.toString());
    if (params.suppressed0StackTrace0ClassName != null) __params = __params.set('suppressed[0].stackTrace[0].className', params.suppressed0StackTrace0ClassName.toString());
    if (params.suppressed0StackTrace0ClassLoaderName != null) __params = __params.set('suppressed[0].stackTrace[0].classLoaderName', params.suppressed0StackTrace0ClassLoaderName.toString());
    if (params.suppressed0Message != null) __params = __params.set('suppressed[0].message', params.suppressed0Message.toString());
    if (params.suppressed0LocalizedMessage != null) __params = __params.set('suppressed[0].localizedMessage', params.suppressed0LocalizedMessage.toString());
    if (params.suppressed0Cause != null) __params = __params.set('suppressed[0].cause', params.suppressed0Cause.toString());
    (params.suppressedFields || []).forEach(val => {if (val != null) __params = __params.append('suppressedFields', val.toString())});
    if (params.stackTrace0NativeMethod != null) __params = __params.set('stackTrace[0].nativeMethod', params.stackTrace0NativeMethod.toString());
    if (params.stackTrace0ModuleVersion != null) __params = __params.set('stackTrace[0].moduleVersion', params.stackTrace0ModuleVersion.toString());
    if (params.stackTrace0ModuleName != null) __params = __params.set('stackTrace[0].moduleName', params.stackTrace0ModuleName.toString());
    if (params.stackTrace0MethodName != null) __params = __params.set('stackTrace[0].methodName', params.stackTrace0MethodName.toString());
    if (params.stackTrace0LineNumber != null) __params = __params.set('stackTrace[0].lineNumber', params.stackTrace0LineNumber.toString());
    if (params.stackTrace0FileName != null) __params = __params.set('stackTrace[0].fileName', params.stackTrace0FileName.toString());
    if (params.stackTrace0ClassName != null) __params = __params.set('stackTrace[0].className', params.stackTrace0ClassName.toString());
    if (params.stackTrace0ClassLoaderName != null) __params = __params.set('stackTrace[0].classLoaderName', params.stackTrace0ClassLoaderName.toString());
    if (params.parameterTypeIndexForCurrentLevel != null) __params = __params.set('parameter.typeIndexForCurrentLevel', params.parameterTypeIndexForCurrentLevel.toString());
    if (params.parameterParameterName != null) __params = __params.set('parameter.parameterName', params.parameterParameterName.toString());
    if (params.parameterParameterIndex != null) __params = __params.set('parameter.parameterIndex', params.parameterParameterIndex.toString());
    if (params.parameterParameter != null) __params = __params.set('parameter.parameter', params.parameterParameter.toString());
    if (params.parameterOptional != null) __params = __params.set('parameter.optional', params.parameterOptional.toString());
    if (params.parameterNestingLevel != null) __params = __params.set('parameter.nestingLevel', params.parameterNestingLevel.toString());
    if (params.parameterNestedGenericParameterType != null) __params = __params.set('parameter.nestedGenericParameterType', params.parameterNestedGenericParameterType.toString());
    if (params.parameterMethod != null) __params = __params.set('parameter.method', params.parameterMethod.toString());
    if (params.parameterMember != null) __params = __params.set('parameter.member', params.parameterMember.toString());
    if (params.parameterGenericParameterType != null) __params = __params.set('parameter.genericParameterType', params.parameterGenericParameterType.toString());
    if (params.parameterExecutable != null) __params = __params.set('parameter.executable', params.parameterExecutable.toString());
    if (params.parameterConstructor != null) __params = __params.set('parameter.constructor', params.parameterConstructor.toString());
    if (params.parameterAnnotatedElement != null) __params = __params.set('parameter.annotatedElement', params.parameterAnnotatedElement.toString());
    if (params.objectName != null) __params = __params.set('objectName', params.objectName.toString());
    if (params.nestedPath != null) __params = __params.set('nestedPath', params.nestedPath.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.localizedMessage != null) __params = __params.set('localizedMessage', params.localizedMessage.toString());
    if (params.globalErrors0ObjectName != null) __params = __params.set('globalErrors[0].objectName', params.globalErrors0ObjectName.toString());
    if (params.globalErrors0DefaultMessage != null) __params = __params.set('globalErrors[0].defaultMessage', params.globalErrors0DefaultMessage.toString());
    (params.globalErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].codes', val.toString())});
    if (params.globalErrors0Code != null) __params = __params.set('globalErrors[0].code', params.globalErrors0Code.toString());
    (params.globalErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].arguments', val.toString())});
    if (params.globalErrorCount != null) __params = __params.set('globalErrorCount', params.globalErrorCount.toString());
    if (params.globalErrorObjectName != null) __params = __params.set('globalError.objectName', params.globalErrorObjectName.toString());
    if (params.globalErrorDefaultMessage != null) __params = __params.set('globalError.defaultMessage', params.globalErrorDefaultMessage.toString());
    (params.globalErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('globalError.codes', val.toString())});
    if (params.globalErrorCode != null) __params = __params.set('globalError.code', params.globalErrorCode.toString());
    (params.globalErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('globalError.arguments', val.toString())});
    if (params.fieldErrors0RejectedValue != null) __params = __params.set('fieldErrors[0].rejectedValue', params.fieldErrors0RejectedValue.toString());
    if (params.fieldErrors0ObjectName != null) __params = __params.set('fieldErrors[0].objectName', params.fieldErrors0ObjectName.toString());
    if (params.fieldErrors0Field != null) __params = __params.set('fieldErrors[0].field', params.fieldErrors0Field.toString());
    if (params.fieldErrors0DefaultMessage != null) __params = __params.set('fieldErrors[0].defaultMessage', params.fieldErrors0DefaultMessage.toString());
    (params.fieldErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].codes', val.toString())});
    if (params.fieldErrors0Code != null) __params = __params.set('fieldErrors[0].code', params.fieldErrors0Code.toString());
    if (params.fieldErrors0BindingFailure != null) __params = __params.set('fieldErrors[0].bindingFailure', params.fieldErrors0BindingFailure.toString());
    (params.fieldErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].arguments', val.toString())});
    if (params.fieldErrorCount != null) __params = __params.set('fieldErrorCount', params.fieldErrorCount.toString());
    if (params.fieldErrorRejectedValue != null) __params = __params.set('fieldError.rejectedValue', params.fieldErrorRejectedValue.toString());
    if (params.fieldErrorObjectName != null) __params = __params.set('fieldError.objectName', params.fieldErrorObjectName.toString());
    if (params.fieldErrorField != null) __params = __params.set('fieldError.field', params.fieldErrorField.toString());
    if (params.fieldErrorDefaultMessage != null) __params = __params.set('fieldError.defaultMessage', params.fieldErrorDefaultMessage.toString());
    (params.fieldErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('fieldError.codes', val.toString())});
    if (params.fieldErrorCode != null) __params = __params.set('fieldError.code', params.fieldErrorCode.toString());
    if (params.fieldErrorBindingFailure != null) __params = __params.set('fieldError.bindingFailure', params.fieldErrorBindingFailure.toString());
    (params.fieldErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('fieldError.arguments', val.toString())});
    if (params.errorCount != null) __params = __params.set('errorCount', params.errorCount.toString());
    if (params.cause != null) __params = __params.set('cause', params.cause.toString());
    if (params.allErrors0ObjectName != null) __params = __params.set('allErrors[0].objectName', params.allErrors0ObjectName.toString());
    if (params.allErrors0DefaultMessage != null) __params = __params.set('allErrors[0].defaultMessage', params.allErrors0DefaultMessage.toString());
    (params.allErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].codes', val.toString())});
    if (params.allErrors0Code != null) __params = __params.set('allErrors[0].code', params.allErrors0Code.toString());
    (params.allErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].arguments', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingHEADParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingHEAD(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingHEADParams): __Observable<null> {
    return this.handleMethodArgumentNotValidExceptionUsingHEADResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPOSTParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingPOSTResponse(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.target != null) __params = __params.set('target', params.target.toString());
    if (params.suppressed0StackTrace0NativeMethod != null) __params = __params.set('suppressed[0].stackTrace[0].nativeMethod', params.suppressed0StackTrace0NativeMethod.toString());
    if (params.suppressed0StackTrace0ModuleVersion != null) __params = __params.set('suppressed[0].stackTrace[0].moduleVersion', params.suppressed0StackTrace0ModuleVersion.toString());
    if (params.suppressed0StackTrace0ModuleName != null) __params = __params.set('suppressed[0].stackTrace[0].moduleName', params.suppressed0StackTrace0ModuleName.toString());
    if (params.suppressed0StackTrace0MethodName != null) __params = __params.set('suppressed[0].stackTrace[0].methodName', params.suppressed0StackTrace0MethodName.toString());
    if (params.suppressed0StackTrace0LineNumber != null) __params = __params.set('suppressed[0].stackTrace[0].lineNumber', params.suppressed0StackTrace0LineNumber.toString());
    if (params.suppressed0StackTrace0FileName != null) __params = __params.set('suppressed[0].stackTrace[0].fileName', params.suppressed0StackTrace0FileName.toString());
    if (params.suppressed0StackTrace0ClassName != null) __params = __params.set('suppressed[0].stackTrace[0].className', params.suppressed0StackTrace0ClassName.toString());
    if (params.suppressed0StackTrace0ClassLoaderName != null) __params = __params.set('suppressed[0].stackTrace[0].classLoaderName', params.suppressed0StackTrace0ClassLoaderName.toString());
    if (params.suppressed0Message != null) __params = __params.set('suppressed[0].message', params.suppressed0Message.toString());
    if (params.suppressed0LocalizedMessage != null) __params = __params.set('suppressed[0].localizedMessage', params.suppressed0LocalizedMessage.toString());
    if (params.suppressed0Cause != null) __params = __params.set('suppressed[0].cause', params.suppressed0Cause.toString());
    (params.suppressedFields || []).forEach(val => {if (val != null) __params = __params.append('suppressedFields', val.toString())});
    if (params.stackTrace0NativeMethod != null) __params = __params.set('stackTrace[0].nativeMethod', params.stackTrace0NativeMethod.toString());
    if (params.stackTrace0ModuleVersion != null) __params = __params.set('stackTrace[0].moduleVersion', params.stackTrace0ModuleVersion.toString());
    if (params.stackTrace0ModuleName != null) __params = __params.set('stackTrace[0].moduleName', params.stackTrace0ModuleName.toString());
    if (params.stackTrace0MethodName != null) __params = __params.set('stackTrace[0].methodName', params.stackTrace0MethodName.toString());
    if (params.stackTrace0LineNumber != null) __params = __params.set('stackTrace[0].lineNumber', params.stackTrace0LineNumber.toString());
    if (params.stackTrace0FileName != null) __params = __params.set('stackTrace[0].fileName', params.stackTrace0FileName.toString());
    if (params.stackTrace0ClassName != null) __params = __params.set('stackTrace[0].className', params.stackTrace0ClassName.toString());
    if (params.stackTrace0ClassLoaderName != null) __params = __params.set('stackTrace[0].classLoaderName', params.stackTrace0ClassLoaderName.toString());
    if (params.parameterTypeIndexForCurrentLevel != null) __params = __params.set('parameter.typeIndexForCurrentLevel', params.parameterTypeIndexForCurrentLevel.toString());
    if (params.parameterParameterName != null) __params = __params.set('parameter.parameterName', params.parameterParameterName.toString());
    if (params.parameterParameterIndex != null) __params = __params.set('parameter.parameterIndex', params.parameterParameterIndex.toString());
    if (params.parameterParameter != null) __params = __params.set('parameter.parameter', params.parameterParameter.toString());
    if (params.parameterOptional != null) __params = __params.set('parameter.optional', params.parameterOptional.toString());
    if (params.parameterNestingLevel != null) __params = __params.set('parameter.nestingLevel', params.parameterNestingLevel.toString());
    if (params.parameterNestedGenericParameterType != null) __params = __params.set('parameter.nestedGenericParameterType', params.parameterNestedGenericParameterType.toString());
    if (params.parameterMethod != null) __params = __params.set('parameter.method', params.parameterMethod.toString());
    if (params.parameterMember != null) __params = __params.set('parameter.member', params.parameterMember.toString());
    if (params.parameterGenericParameterType != null) __params = __params.set('parameter.genericParameterType', params.parameterGenericParameterType.toString());
    if (params.parameterExecutable != null) __params = __params.set('parameter.executable', params.parameterExecutable.toString());
    if (params.parameterConstructor != null) __params = __params.set('parameter.constructor', params.parameterConstructor.toString());
    if (params.parameterAnnotatedElement != null) __params = __params.set('parameter.annotatedElement', params.parameterAnnotatedElement.toString());
    if (params.objectName != null) __params = __params.set('objectName', params.objectName.toString());
    if (params.nestedPath != null) __params = __params.set('nestedPath', params.nestedPath.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.localizedMessage != null) __params = __params.set('localizedMessage', params.localizedMessage.toString());
    if (params.globalErrors0ObjectName != null) __params = __params.set('globalErrors[0].objectName', params.globalErrors0ObjectName.toString());
    if (params.globalErrors0DefaultMessage != null) __params = __params.set('globalErrors[0].defaultMessage', params.globalErrors0DefaultMessage.toString());
    (params.globalErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].codes', val.toString())});
    if (params.globalErrors0Code != null) __params = __params.set('globalErrors[0].code', params.globalErrors0Code.toString());
    (params.globalErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].arguments', val.toString())});
    if (params.globalErrorCount != null) __params = __params.set('globalErrorCount', params.globalErrorCount.toString());
    if (params.globalErrorObjectName != null) __params = __params.set('globalError.objectName', params.globalErrorObjectName.toString());
    if (params.globalErrorDefaultMessage != null) __params = __params.set('globalError.defaultMessage', params.globalErrorDefaultMessage.toString());
    (params.globalErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('globalError.codes', val.toString())});
    if (params.globalErrorCode != null) __params = __params.set('globalError.code', params.globalErrorCode.toString());
    (params.globalErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('globalError.arguments', val.toString())});
    if (params.fieldErrors0RejectedValue != null) __params = __params.set('fieldErrors[0].rejectedValue', params.fieldErrors0RejectedValue.toString());
    if (params.fieldErrors0ObjectName != null) __params = __params.set('fieldErrors[0].objectName', params.fieldErrors0ObjectName.toString());
    if (params.fieldErrors0Field != null) __params = __params.set('fieldErrors[0].field', params.fieldErrors0Field.toString());
    if (params.fieldErrors0DefaultMessage != null) __params = __params.set('fieldErrors[0].defaultMessage', params.fieldErrors0DefaultMessage.toString());
    (params.fieldErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].codes', val.toString())});
    if (params.fieldErrors0Code != null) __params = __params.set('fieldErrors[0].code', params.fieldErrors0Code.toString());
    if (params.fieldErrors0BindingFailure != null) __params = __params.set('fieldErrors[0].bindingFailure', params.fieldErrors0BindingFailure.toString());
    (params.fieldErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].arguments', val.toString())});
    if (params.fieldErrorCount != null) __params = __params.set('fieldErrorCount', params.fieldErrorCount.toString());
    if (params.fieldErrorRejectedValue != null) __params = __params.set('fieldError.rejectedValue', params.fieldErrorRejectedValue.toString());
    if (params.fieldErrorObjectName != null) __params = __params.set('fieldError.objectName', params.fieldErrorObjectName.toString());
    if (params.fieldErrorField != null) __params = __params.set('fieldError.field', params.fieldErrorField.toString());
    if (params.fieldErrorDefaultMessage != null) __params = __params.set('fieldError.defaultMessage', params.fieldErrorDefaultMessage.toString());
    (params.fieldErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('fieldError.codes', val.toString())});
    if (params.fieldErrorCode != null) __params = __params.set('fieldError.code', params.fieldErrorCode.toString());
    if (params.fieldErrorBindingFailure != null) __params = __params.set('fieldError.bindingFailure', params.fieldErrorBindingFailure.toString());
    (params.fieldErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('fieldError.arguments', val.toString())});
    if (params.errorCount != null) __params = __params.set('errorCount', params.errorCount.toString());
    if (params.cause != null) __params = __params.set('cause', params.cause.toString());
    if (params.allErrors0ObjectName != null) __params = __params.set('allErrors[0].objectName', params.allErrors0ObjectName.toString());
    if (params.allErrors0DefaultMessage != null) __params = __params.set('allErrors[0].defaultMessage', params.allErrors0DefaultMessage.toString());
    (params.allErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].codes', val.toString())});
    if (params.allErrors0Code != null) __params = __params.set('allErrors[0].code', params.allErrors0Code.toString());
    (params.allErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].arguments', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPOSTParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingPOST(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPOSTParams): __Observable<null> {
    return this.handleMethodArgumentNotValidExceptionUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPUTParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingPUTResponse(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPUTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.target != null) __params = __params.set('target', params.target.toString());
    if (params.suppressed0StackTrace0NativeMethod != null) __params = __params.set('suppressed[0].stackTrace[0].nativeMethod', params.suppressed0StackTrace0NativeMethod.toString());
    if (params.suppressed0StackTrace0ModuleVersion != null) __params = __params.set('suppressed[0].stackTrace[0].moduleVersion', params.suppressed0StackTrace0ModuleVersion.toString());
    if (params.suppressed0StackTrace0ModuleName != null) __params = __params.set('suppressed[0].stackTrace[0].moduleName', params.suppressed0StackTrace0ModuleName.toString());
    if (params.suppressed0StackTrace0MethodName != null) __params = __params.set('suppressed[0].stackTrace[0].methodName', params.suppressed0StackTrace0MethodName.toString());
    if (params.suppressed0StackTrace0LineNumber != null) __params = __params.set('suppressed[0].stackTrace[0].lineNumber', params.suppressed0StackTrace0LineNumber.toString());
    if (params.suppressed0StackTrace0FileName != null) __params = __params.set('suppressed[0].stackTrace[0].fileName', params.suppressed0StackTrace0FileName.toString());
    if (params.suppressed0StackTrace0ClassName != null) __params = __params.set('suppressed[0].stackTrace[0].className', params.suppressed0StackTrace0ClassName.toString());
    if (params.suppressed0StackTrace0ClassLoaderName != null) __params = __params.set('suppressed[0].stackTrace[0].classLoaderName', params.suppressed0StackTrace0ClassLoaderName.toString());
    if (params.suppressed0Message != null) __params = __params.set('suppressed[0].message', params.suppressed0Message.toString());
    if (params.suppressed0LocalizedMessage != null) __params = __params.set('suppressed[0].localizedMessage', params.suppressed0LocalizedMessage.toString());
    if (params.suppressed0Cause != null) __params = __params.set('suppressed[0].cause', params.suppressed0Cause.toString());
    (params.suppressedFields || []).forEach(val => {if (val != null) __params = __params.append('suppressedFields', val.toString())});
    if (params.stackTrace0NativeMethod != null) __params = __params.set('stackTrace[0].nativeMethod', params.stackTrace0NativeMethod.toString());
    if (params.stackTrace0ModuleVersion != null) __params = __params.set('stackTrace[0].moduleVersion', params.stackTrace0ModuleVersion.toString());
    if (params.stackTrace0ModuleName != null) __params = __params.set('stackTrace[0].moduleName', params.stackTrace0ModuleName.toString());
    if (params.stackTrace0MethodName != null) __params = __params.set('stackTrace[0].methodName', params.stackTrace0MethodName.toString());
    if (params.stackTrace0LineNumber != null) __params = __params.set('stackTrace[0].lineNumber', params.stackTrace0LineNumber.toString());
    if (params.stackTrace0FileName != null) __params = __params.set('stackTrace[0].fileName', params.stackTrace0FileName.toString());
    if (params.stackTrace0ClassName != null) __params = __params.set('stackTrace[0].className', params.stackTrace0ClassName.toString());
    if (params.stackTrace0ClassLoaderName != null) __params = __params.set('stackTrace[0].classLoaderName', params.stackTrace0ClassLoaderName.toString());
    if (params.parameterTypeIndexForCurrentLevel != null) __params = __params.set('parameter.typeIndexForCurrentLevel', params.parameterTypeIndexForCurrentLevel.toString());
    if (params.parameterParameterName != null) __params = __params.set('parameter.parameterName', params.parameterParameterName.toString());
    if (params.parameterParameterIndex != null) __params = __params.set('parameter.parameterIndex', params.parameterParameterIndex.toString());
    if (params.parameterParameter != null) __params = __params.set('parameter.parameter', params.parameterParameter.toString());
    if (params.parameterOptional != null) __params = __params.set('parameter.optional', params.parameterOptional.toString());
    if (params.parameterNestingLevel != null) __params = __params.set('parameter.nestingLevel', params.parameterNestingLevel.toString());
    if (params.parameterNestedGenericParameterType != null) __params = __params.set('parameter.nestedGenericParameterType', params.parameterNestedGenericParameterType.toString());
    if (params.parameterMethod != null) __params = __params.set('parameter.method', params.parameterMethod.toString());
    if (params.parameterMember != null) __params = __params.set('parameter.member', params.parameterMember.toString());
    if (params.parameterGenericParameterType != null) __params = __params.set('parameter.genericParameterType', params.parameterGenericParameterType.toString());
    if (params.parameterExecutable != null) __params = __params.set('parameter.executable', params.parameterExecutable.toString());
    if (params.parameterConstructor != null) __params = __params.set('parameter.constructor', params.parameterConstructor.toString());
    if (params.parameterAnnotatedElement != null) __params = __params.set('parameter.annotatedElement', params.parameterAnnotatedElement.toString());
    if (params.objectName != null) __params = __params.set('objectName', params.objectName.toString());
    if (params.nestedPath != null) __params = __params.set('nestedPath', params.nestedPath.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.localizedMessage != null) __params = __params.set('localizedMessage', params.localizedMessage.toString());
    if (params.globalErrors0ObjectName != null) __params = __params.set('globalErrors[0].objectName', params.globalErrors0ObjectName.toString());
    if (params.globalErrors0DefaultMessage != null) __params = __params.set('globalErrors[0].defaultMessage', params.globalErrors0DefaultMessage.toString());
    (params.globalErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].codes', val.toString())});
    if (params.globalErrors0Code != null) __params = __params.set('globalErrors[0].code', params.globalErrors0Code.toString());
    (params.globalErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].arguments', val.toString())});
    if (params.globalErrorCount != null) __params = __params.set('globalErrorCount', params.globalErrorCount.toString());
    if (params.globalErrorObjectName != null) __params = __params.set('globalError.objectName', params.globalErrorObjectName.toString());
    if (params.globalErrorDefaultMessage != null) __params = __params.set('globalError.defaultMessage', params.globalErrorDefaultMessage.toString());
    (params.globalErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('globalError.codes', val.toString())});
    if (params.globalErrorCode != null) __params = __params.set('globalError.code', params.globalErrorCode.toString());
    (params.globalErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('globalError.arguments', val.toString())});
    if (params.fieldErrors0RejectedValue != null) __params = __params.set('fieldErrors[0].rejectedValue', params.fieldErrors0RejectedValue.toString());
    if (params.fieldErrors0ObjectName != null) __params = __params.set('fieldErrors[0].objectName', params.fieldErrors0ObjectName.toString());
    if (params.fieldErrors0Field != null) __params = __params.set('fieldErrors[0].field', params.fieldErrors0Field.toString());
    if (params.fieldErrors0DefaultMessage != null) __params = __params.set('fieldErrors[0].defaultMessage', params.fieldErrors0DefaultMessage.toString());
    (params.fieldErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].codes', val.toString())});
    if (params.fieldErrors0Code != null) __params = __params.set('fieldErrors[0].code', params.fieldErrors0Code.toString());
    if (params.fieldErrors0BindingFailure != null) __params = __params.set('fieldErrors[0].bindingFailure', params.fieldErrors0BindingFailure.toString());
    (params.fieldErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].arguments', val.toString())});
    if (params.fieldErrorCount != null) __params = __params.set('fieldErrorCount', params.fieldErrorCount.toString());
    if (params.fieldErrorRejectedValue != null) __params = __params.set('fieldError.rejectedValue', params.fieldErrorRejectedValue.toString());
    if (params.fieldErrorObjectName != null) __params = __params.set('fieldError.objectName', params.fieldErrorObjectName.toString());
    if (params.fieldErrorField != null) __params = __params.set('fieldError.field', params.fieldErrorField.toString());
    if (params.fieldErrorDefaultMessage != null) __params = __params.set('fieldError.defaultMessage', params.fieldErrorDefaultMessage.toString());
    (params.fieldErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('fieldError.codes', val.toString())});
    if (params.fieldErrorCode != null) __params = __params.set('fieldError.code', params.fieldErrorCode.toString());
    if (params.fieldErrorBindingFailure != null) __params = __params.set('fieldError.bindingFailure', params.fieldErrorBindingFailure.toString());
    (params.fieldErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('fieldError.arguments', val.toString())});
    if (params.errorCount != null) __params = __params.set('errorCount', params.errorCount.toString());
    if (params.cause != null) __params = __params.set('cause', params.cause.toString());
    if (params.allErrors0ObjectName != null) __params = __params.set('allErrors[0].objectName', params.allErrors0ObjectName.toString());
    if (params.allErrors0DefaultMessage != null) __params = __params.set('allErrors[0].defaultMessage', params.allErrors0DefaultMessage.toString());
    (params.allErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].codes', val.toString())});
    if (params.allErrors0Code != null) __params = __params.set('allErrors[0].code', params.allErrors0Code.toString());
    (params.allErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].arguments', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPUTParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingPUT(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPUTParams): __Observable<null> {
    return this.handleMethodArgumentNotValidExceptionUsingPUTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingDELETEParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingDELETEResponse(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingDELETEParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.target != null) __params = __params.set('target', params.target.toString());
    if (params.suppressed0StackTrace0NativeMethod != null) __params = __params.set('suppressed[0].stackTrace[0].nativeMethod', params.suppressed0StackTrace0NativeMethod.toString());
    if (params.suppressed0StackTrace0ModuleVersion != null) __params = __params.set('suppressed[0].stackTrace[0].moduleVersion', params.suppressed0StackTrace0ModuleVersion.toString());
    if (params.suppressed0StackTrace0ModuleName != null) __params = __params.set('suppressed[0].stackTrace[0].moduleName', params.suppressed0StackTrace0ModuleName.toString());
    if (params.suppressed0StackTrace0MethodName != null) __params = __params.set('suppressed[0].stackTrace[0].methodName', params.suppressed0StackTrace0MethodName.toString());
    if (params.suppressed0StackTrace0LineNumber != null) __params = __params.set('suppressed[0].stackTrace[0].lineNumber', params.suppressed0StackTrace0LineNumber.toString());
    if (params.suppressed0StackTrace0FileName != null) __params = __params.set('suppressed[0].stackTrace[0].fileName', params.suppressed0StackTrace0FileName.toString());
    if (params.suppressed0StackTrace0ClassName != null) __params = __params.set('suppressed[0].stackTrace[0].className', params.suppressed0StackTrace0ClassName.toString());
    if (params.suppressed0StackTrace0ClassLoaderName != null) __params = __params.set('suppressed[0].stackTrace[0].classLoaderName', params.suppressed0StackTrace0ClassLoaderName.toString());
    if (params.suppressed0Message != null) __params = __params.set('suppressed[0].message', params.suppressed0Message.toString());
    if (params.suppressed0LocalizedMessage != null) __params = __params.set('suppressed[0].localizedMessage', params.suppressed0LocalizedMessage.toString());
    if (params.suppressed0Cause != null) __params = __params.set('suppressed[0].cause', params.suppressed0Cause.toString());
    (params.suppressedFields || []).forEach(val => {if (val != null) __params = __params.append('suppressedFields', val.toString())});
    if (params.stackTrace0NativeMethod != null) __params = __params.set('stackTrace[0].nativeMethod', params.stackTrace0NativeMethod.toString());
    if (params.stackTrace0ModuleVersion != null) __params = __params.set('stackTrace[0].moduleVersion', params.stackTrace0ModuleVersion.toString());
    if (params.stackTrace0ModuleName != null) __params = __params.set('stackTrace[0].moduleName', params.stackTrace0ModuleName.toString());
    if (params.stackTrace0MethodName != null) __params = __params.set('stackTrace[0].methodName', params.stackTrace0MethodName.toString());
    if (params.stackTrace0LineNumber != null) __params = __params.set('stackTrace[0].lineNumber', params.stackTrace0LineNumber.toString());
    if (params.stackTrace0FileName != null) __params = __params.set('stackTrace[0].fileName', params.stackTrace0FileName.toString());
    if (params.stackTrace0ClassName != null) __params = __params.set('stackTrace[0].className', params.stackTrace0ClassName.toString());
    if (params.stackTrace0ClassLoaderName != null) __params = __params.set('stackTrace[0].classLoaderName', params.stackTrace0ClassLoaderName.toString());
    if (params.parameterTypeIndexForCurrentLevel != null) __params = __params.set('parameter.typeIndexForCurrentLevel', params.parameterTypeIndexForCurrentLevel.toString());
    if (params.parameterParameterName != null) __params = __params.set('parameter.parameterName', params.parameterParameterName.toString());
    if (params.parameterParameterIndex != null) __params = __params.set('parameter.parameterIndex', params.parameterParameterIndex.toString());
    if (params.parameterParameter != null) __params = __params.set('parameter.parameter', params.parameterParameter.toString());
    if (params.parameterOptional != null) __params = __params.set('parameter.optional', params.parameterOptional.toString());
    if (params.parameterNestingLevel != null) __params = __params.set('parameter.nestingLevel', params.parameterNestingLevel.toString());
    if (params.parameterNestedGenericParameterType != null) __params = __params.set('parameter.nestedGenericParameterType', params.parameterNestedGenericParameterType.toString());
    if (params.parameterMethod != null) __params = __params.set('parameter.method', params.parameterMethod.toString());
    if (params.parameterMember != null) __params = __params.set('parameter.member', params.parameterMember.toString());
    if (params.parameterGenericParameterType != null) __params = __params.set('parameter.genericParameterType', params.parameterGenericParameterType.toString());
    if (params.parameterExecutable != null) __params = __params.set('parameter.executable', params.parameterExecutable.toString());
    if (params.parameterConstructor != null) __params = __params.set('parameter.constructor', params.parameterConstructor.toString());
    if (params.parameterAnnotatedElement != null) __params = __params.set('parameter.annotatedElement', params.parameterAnnotatedElement.toString());
    if (params.objectName != null) __params = __params.set('objectName', params.objectName.toString());
    if (params.nestedPath != null) __params = __params.set('nestedPath', params.nestedPath.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.localizedMessage != null) __params = __params.set('localizedMessage', params.localizedMessage.toString());
    if (params.globalErrors0ObjectName != null) __params = __params.set('globalErrors[0].objectName', params.globalErrors0ObjectName.toString());
    if (params.globalErrors0DefaultMessage != null) __params = __params.set('globalErrors[0].defaultMessage', params.globalErrors0DefaultMessage.toString());
    (params.globalErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].codes', val.toString())});
    if (params.globalErrors0Code != null) __params = __params.set('globalErrors[0].code', params.globalErrors0Code.toString());
    (params.globalErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].arguments', val.toString())});
    if (params.globalErrorCount != null) __params = __params.set('globalErrorCount', params.globalErrorCount.toString());
    if (params.globalErrorObjectName != null) __params = __params.set('globalError.objectName', params.globalErrorObjectName.toString());
    if (params.globalErrorDefaultMessage != null) __params = __params.set('globalError.defaultMessage', params.globalErrorDefaultMessage.toString());
    (params.globalErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('globalError.codes', val.toString())});
    if (params.globalErrorCode != null) __params = __params.set('globalError.code', params.globalErrorCode.toString());
    (params.globalErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('globalError.arguments', val.toString())});
    if (params.fieldErrors0RejectedValue != null) __params = __params.set('fieldErrors[0].rejectedValue', params.fieldErrors0RejectedValue.toString());
    if (params.fieldErrors0ObjectName != null) __params = __params.set('fieldErrors[0].objectName', params.fieldErrors0ObjectName.toString());
    if (params.fieldErrors0Field != null) __params = __params.set('fieldErrors[0].field', params.fieldErrors0Field.toString());
    if (params.fieldErrors0DefaultMessage != null) __params = __params.set('fieldErrors[0].defaultMessage', params.fieldErrors0DefaultMessage.toString());
    (params.fieldErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].codes', val.toString())});
    if (params.fieldErrors0Code != null) __params = __params.set('fieldErrors[0].code', params.fieldErrors0Code.toString());
    if (params.fieldErrors0BindingFailure != null) __params = __params.set('fieldErrors[0].bindingFailure', params.fieldErrors0BindingFailure.toString());
    (params.fieldErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].arguments', val.toString())});
    if (params.fieldErrorCount != null) __params = __params.set('fieldErrorCount', params.fieldErrorCount.toString());
    if (params.fieldErrorRejectedValue != null) __params = __params.set('fieldError.rejectedValue', params.fieldErrorRejectedValue.toString());
    if (params.fieldErrorObjectName != null) __params = __params.set('fieldError.objectName', params.fieldErrorObjectName.toString());
    if (params.fieldErrorField != null) __params = __params.set('fieldError.field', params.fieldErrorField.toString());
    if (params.fieldErrorDefaultMessage != null) __params = __params.set('fieldError.defaultMessage', params.fieldErrorDefaultMessage.toString());
    (params.fieldErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('fieldError.codes', val.toString())});
    if (params.fieldErrorCode != null) __params = __params.set('fieldError.code', params.fieldErrorCode.toString());
    if (params.fieldErrorBindingFailure != null) __params = __params.set('fieldError.bindingFailure', params.fieldErrorBindingFailure.toString());
    (params.fieldErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('fieldError.arguments', val.toString())});
    if (params.errorCount != null) __params = __params.set('errorCount', params.errorCount.toString());
    if (params.cause != null) __params = __params.set('cause', params.cause.toString());
    if (params.allErrors0ObjectName != null) __params = __params.set('allErrors[0].objectName', params.allErrors0ObjectName.toString());
    if (params.allErrors0DefaultMessage != null) __params = __params.set('allErrors[0].defaultMessage', params.allErrors0DefaultMessage.toString());
    (params.allErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].codes', val.toString())});
    if (params.allErrors0Code != null) __params = __params.set('allErrors[0].code', params.allErrors0Code.toString());
    (params.allErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].arguments', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingDELETEParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingDELETE(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingDELETEParams): __Observable<null> {
    return this.handleMethodArgumentNotValidExceptionUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingOPTIONSParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingOPTIONSResponse(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingOPTIONSParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.target != null) __params = __params.set('target', params.target.toString());
    if (params.suppressed0StackTrace0NativeMethod != null) __params = __params.set('suppressed[0].stackTrace[0].nativeMethod', params.suppressed0StackTrace0NativeMethod.toString());
    if (params.suppressed0StackTrace0ModuleVersion != null) __params = __params.set('suppressed[0].stackTrace[0].moduleVersion', params.suppressed0StackTrace0ModuleVersion.toString());
    if (params.suppressed0StackTrace0ModuleName != null) __params = __params.set('suppressed[0].stackTrace[0].moduleName', params.suppressed0StackTrace0ModuleName.toString());
    if (params.suppressed0StackTrace0MethodName != null) __params = __params.set('suppressed[0].stackTrace[0].methodName', params.suppressed0StackTrace0MethodName.toString());
    if (params.suppressed0StackTrace0LineNumber != null) __params = __params.set('suppressed[0].stackTrace[0].lineNumber', params.suppressed0StackTrace0LineNumber.toString());
    if (params.suppressed0StackTrace0FileName != null) __params = __params.set('suppressed[0].stackTrace[0].fileName', params.suppressed0StackTrace0FileName.toString());
    if (params.suppressed0StackTrace0ClassName != null) __params = __params.set('suppressed[0].stackTrace[0].className', params.suppressed0StackTrace0ClassName.toString());
    if (params.suppressed0StackTrace0ClassLoaderName != null) __params = __params.set('suppressed[0].stackTrace[0].classLoaderName', params.suppressed0StackTrace0ClassLoaderName.toString());
    if (params.suppressed0Message != null) __params = __params.set('suppressed[0].message', params.suppressed0Message.toString());
    if (params.suppressed0LocalizedMessage != null) __params = __params.set('suppressed[0].localizedMessage', params.suppressed0LocalizedMessage.toString());
    if (params.suppressed0Cause != null) __params = __params.set('suppressed[0].cause', params.suppressed0Cause.toString());
    (params.suppressedFields || []).forEach(val => {if (val != null) __params = __params.append('suppressedFields', val.toString())});
    if (params.stackTrace0NativeMethod != null) __params = __params.set('stackTrace[0].nativeMethod', params.stackTrace0NativeMethod.toString());
    if (params.stackTrace0ModuleVersion != null) __params = __params.set('stackTrace[0].moduleVersion', params.stackTrace0ModuleVersion.toString());
    if (params.stackTrace0ModuleName != null) __params = __params.set('stackTrace[0].moduleName', params.stackTrace0ModuleName.toString());
    if (params.stackTrace0MethodName != null) __params = __params.set('stackTrace[0].methodName', params.stackTrace0MethodName.toString());
    if (params.stackTrace0LineNumber != null) __params = __params.set('stackTrace[0].lineNumber', params.stackTrace0LineNumber.toString());
    if (params.stackTrace0FileName != null) __params = __params.set('stackTrace[0].fileName', params.stackTrace0FileName.toString());
    if (params.stackTrace0ClassName != null) __params = __params.set('stackTrace[0].className', params.stackTrace0ClassName.toString());
    if (params.stackTrace0ClassLoaderName != null) __params = __params.set('stackTrace[0].classLoaderName', params.stackTrace0ClassLoaderName.toString());
    if (params.parameterTypeIndexForCurrentLevel != null) __params = __params.set('parameter.typeIndexForCurrentLevel', params.parameterTypeIndexForCurrentLevel.toString());
    if (params.parameterParameterName != null) __params = __params.set('parameter.parameterName', params.parameterParameterName.toString());
    if (params.parameterParameterIndex != null) __params = __params.set('parameter.parameterIndex', params.parameterParameterIndex.toString());
    if (params.parameterParameter != null) __params = __params.set('parameter.parameter', params.parameterParameter.toString());
    if (params.parameterOptional != null) __params = __params.set('parameter.optional', params.parameterOptional.toString());
    if (params.parameterNestingLevel != null) __params = __params.set('parameter.nestingLevel', params.parameterNestingLevel.toString());
    if (params.parameterNestedGenericParameterType != null) __params = __params.set('parameter.nestedGenericParameterType', params.parameterNestedGenericParameterType.toString());
    if (params.parameterMethod != null) __params = __params.set('parameter.method', params.parameterMethod.toString());
    if (params.parameterMember != null) __params = __params.set('parameter.member', params.parameterMember.toString());
    if (params.parameterGenericParameterType != null) __params = __params.set('parameter.genericParameterType', params.parameterGenericParameterType.toString());
    if (params.parameterExecutable != null) __params = __params.set('parameter.executable', params.parameterExecutable.toString());
    if (params.parameterConstructor != null) __params = __params.set('parameter.constructor', params.parameterConstructor.toString());
    if (params.parameterAnnotatedElement != null) __params = __params.set('parameter.annotatedElement', params.parameterAnnotatedElement.toString());
    if (params.objectName != null) __params = __params.set('objectName', params.objectName.toString());
    if (params.nestedPath != null) __params = __params.set('nestedPath', params.nestedPath.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.localizedMessage != null) __params = __params.set('localizedMessage', params.localizedMessage.toString());
    if (params.globalErrors0ObjectName != null) __params = __params.set('globalErrors[0].objectName', params.globalErrors0ObjectName.toString());
    if (params.globalErrors0DefaultMessage != null) __params = __params.set('globalErrors[0].defaultMessage', params.globalErrors0DefaultMessage.toString());
    (params.globalErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].codes', val.toString())});
    if (params.globalErrors0Code != null) __params = __params.set('globalErrors[0].code', params.globalErrors0Code.toString());
    (params.globalErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].arguments', val.toString())});
    if (params.globalErrorCount != null) __params = __params.set('globalErrorCount', params.globalErrorCount.toString());
    if (params.globalErrorObjectName != null) __params = __params.set('globalError.objectName', params.globalErrorObjectName.toString());
    if (params.globalErrorDefaultMessage != null) __params = __params.set('globalError.defaultMessage', params.globalErrorDefaultMessage.toString());
    (params.globalErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('globalError.codes', val.toString())});
    if (params.globalErrorCode != null) __params = __params.set('globalError.code', params.globalErrorCode.toString());
    (params.globalErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('globalError.arguments', val.toString())});
    if (params.fieldErrors0RejectedValue != null) __params = __params.set('fieldErrors[0].rejectedValue', params.fieldErrors0RejectedValue.toString());
    if (params.fieldErrors0ObjectName != null) __params = __params.set('fieldErrors[0].objectName', params.fieldErrors0ObjectName.toString());
    if (params.fieldErrors0Field != null) __params = __params.set('fieldErrors[0].field', params.fieldErrors0Field.toString());
    if (params.fieldErrors0DefaultMessage != null) __params = __params.set('fieldErrors[0].defaultMessage', params.fieldErrors0DefaultMessage.toString());
    (params.fieldErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].codes', val.toString())});
    if (params.fieldErrors0Code != null) __params = __params.set('fieldErrors[0].code', params.fieldErrors0Code.toString());
    if (params.fieldErrors0BindingFailure != null) __params = __params.set('fieldErrors[0].bindingFailure', params.fieldErrors0BindingFailure.toString());
    (params.fieldErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].arguments', val.toString())});
    if (params.fieldErrorCount != null) __params = __params.set('fieldErrorCount', params.fieldErrorCount.toString());
    if (params.fieldErrorRejectedValue != null) __params = __params.set('fieldError.rejectedValue', params.fieldErrorRejectedValue.toString());
    if (params.fieldErrorObjectName != null) __params = __params.set('fieldError.objectName', params.fieldErrorObjectName.toString());
    if (params.fieldErrorField != null) __params = __params.set('fieldError.field', params.fieldErrorField.toString());
    if (params.fieldErrorDefaultMessage != null) __params = __params.set('fieldError.defaultMessage', params.fieldErrorDefaultMessage.toString());
    (params.fieldErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('fieldError.codes', val.toString())});
    if (params.fieldErrorCode != null) __params = __params.set('fieldError.code', params.fieldErrorCode.toString());
    if (params.fieldErrorBindingFailure != null) __params = __params.set('fieldError.bindingFailure', params.fieldErrorBindingFailure.toString());
    (params.fieldErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('fieldError.arguments', val.toString())});
    if (params.errorCount != null) __params = __params.set('errorCount', params.errorCount.toString());
    if (params.cause != null) __params = __params.set('cause', params.cause.toString());
    if (params.allErrors0ObjectName != null) __params = __params.set('allErrors[0].objectName', params.allErrors0ObjectName.toString());
    if (params.allErrors0DefaultMessage != null) __params = __params.set('allErrors[0].defaultMessage', params.allErrors0DefaultMessage.toString());
    (params.allErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].codes', val.toString())});
    if (params.allErrors0Code != null) __params = __params.set('allErrors[0].code', params.allErrors0Code.toString());
    (params.allErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].arguments', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingOPTIONSParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingOPTIONS(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingOPTIONSParams): __Observable<null> {
    return this.handleMethodArgumentNotValidExceptionUsingOPTIONSResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPATCHParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingPATCHResponse(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPATCHParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.target != null) __params = __params.set('target', params.target.toString());
    if (params.suppressed0StackTrace0NativeMethod != null) __params = __params.set('suppressed[0].stackTrace[0].nativeMethod', params.suppressed0StackTrace0NativeMethod.toString());
    if (params.suppressed0StackTrace0ModuleVersion != null) __params = __params.set('suppressed[0].stackTrace[0].moduleVersion', params.suppressed0StackTrace0ModuleVersion.toString());
    if (params.suppressed0StackTrace0ModuleName != null) __params = __params.set('suppressed[0].stackTrace[0].moduleName', params.suppressed0StackTrace0ModuleName.toString());
    if (params.suppressed0StackTrace0MethodName != null) __params = __params.set('suppressed[0].stackTrace[0].methodName', params.suppressed0StackTrace0MethodName.toString());
    if (params.suppressed0StackTrace0LineNumber != null) __params = __params.set('suppressed[0].stackTrace[0].lineNumber', params.suppressed0StackTrace0LineNumber.toString());
    if (params.suppressed0StackTrace0FileName != null) __params = __params.set('suppressed[0].stackTrace[0].fileName', params.suppressed0StackTrace0FileName.toString());
    if (params.suppressed0StackTrace0ClassName != null) __params = __params.set('suppressed[0].stackTrace[0].className', params.suppressed0StackTrace0ClassName.toString());
    if (params.suppressed0StackTrace0ClassLoaderName != null) __params = __params.set('suppressed[0].stackTrace[0].classLoaderName', params.suppressed0StackTrace0ClassLoaderName.toString());
    if (params.suppressed0Message != null) __params = __params.set('suppressed[0].message', params.suppressed0Message.toString());
    if (params.suppressed0LocalizedMessage != null) __params = __params.set('suppressed[0].localizedMessage', params.suppressed0LocalizedMessage.toString());
    if (params.suppressed0Cause != null) __params = __params.set('suppressed[0].cause', params.suppressed0Cause.toString());
    (params.suppressedFields || []).forEach(val => {if (val != null) __params = __params.append('suppressedFields', val.toString())});
    if (params.stackTrace0NativeMethod != null) __params = __params.set('stackTrace[0].nativeMethod', params.stackTrace0NativeMethod.toString());
    if (params.stackTrace0ModuleVersion != null) __params = __params.set('stackTrace[0].moduleVersion', params.stackTrace0ModuleVersion.toString());
    if (params.stackTrace0ModuleName != null) __params = __params.set('stackTrace[0].moduleName', params.stackTrace0ModuleName.toString());
    if (params.stackTrace0MethodName != null) __params = __params.set('stackTrace[0].methodName', params.stackTrace0MethodName.toString());
    if (params.stackTrace0LineNumber != null) __params = __params.set('stackTrace[0].lineNumber', params.stackTrace0LineNumber.toString());
    if (params.stackTrace0FileName != null) __params = __params.set('stackTrace[0].fileName', params.stackTrace0FileName.toString());
    if (params.stackTrace0ClassName != null) __params = __params.set('stackTrace[0].className', params.stackTrace0ClassName.toString());
    if (params.stackTrace0ClassLoaderName != null) __params = __params.set('stackTrace[0].classLoaderName', params.stackTrace0ClassLoaderName.toString());
    if (params.parameterTypeIndexForCurrentLevel != null) __params = __params.set('parameter.typeIndexForCurrentLevel', params.parameterTypeIndexForCurrentLevel.toString());
    if (params.parameterParameterName != null) __params = __params.set('parameter.parameterName', params.parameterParameterName.toString());
    if (params.parameterParameterIndex != null) __params = __params.set('parameter.parameterIndex', params.parameterParameterIndex.toString());
    if (params.parameterParameter != null) __params = __params.set('parameter.parameter', params.parameterParameter.toString());
    if (params.parameterOptional != null) __params = __params.set('parameter.optional', params.parameterOptional.toString());
    if (params.parameterNestingLevel != null) __params = __params.set('parameter.nestingLevel', params.parameterNestingLevel.toString());
    if (params.parameterNestedGenericParameterType != null) __params = __params.set('parameter.nestedGenericParameterType', params.parameterNestedGenericParameterType.toString());
    if (params.parameterMethod != null) __params = __params.set('parameter.method', params.parameterMethod.toString());
    if (params.parameterMember != null) __params = __params.set('parameter.member', params.parameterMember.toString());
    if (params.parameterGenericParameterType != null) __params = __params.set('parameter.genericParameterType', params.parameterGenericParameterType.toString());
    if (params.parameterExecutable != null) __params = __params.set('parameter.executable', params.parameterExecutable.toString());
    if (params.parameterConstructor != null) __params = __params.set('parameter.constructor', params.parameterConstructor.toString());
    if (params.parameterAnnotatedElement != null) __params = __params.set('parameter.annotatedElement', params.parameterAnnotatedElement.toString());
    if (params.objectName != null) __params = __params.set('objectName', params.objectName.toString());
    if (params.nestedPath != null) __params = __params.set('nestedPath', params.nestedPath.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.localizedMessage != null) __params = __params.set('localizedMessage', params.localizedMessage.toString());
    if (params.globalErrors0ObjectName != null) __params = __params.set('globalErrors[0].objectName', params.globalErrors0ObjectName.toString());
    if (params.globalErrors0DefaultMessage != null) __params = __params.set('globalErrors[0].defaultMessage', params.globalErrors0DefaultMessage.toString());
    (params.globalErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].codes', val.toString())});
    if (params.globalErrors0Code != null) __params = __params.set('globalErrors[0].code', params.globalErrors0Code.toString());
    (params.globalErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('globalErrors[0].arguments', val.toString())});
    if (params.globalErrorCount != null) __params = __params.set('globalErrorCount', params.globalErrorCount.toString());
    if (params.globalErrorObjectName != null) __params = __params.set('globalError.objectName', params.globalErrorObjectName.toString());
    if (params.globalErrorDefaultMessage != null) __params = __params.set('globalError.defaultMessage', params.globalErrorDefaultMessage.toString());
    (params.globalErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('globalError.codes', val.toString())});
    if (params.globalErrorCode != null) __params = __params.set('globalError.code', params.globalErrorCode.toString());
    (params.globalErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('globalError.arguments', val.toString())});
    if (params.fieldErrors0RejectedValue != null) __params = __params.set('fieldErrors[0].rejectedValue', params.fieldErrors0RejectedValue.toString());
    if (params.fieldErrors0ObjectName != null) __params = __params.set('fieldErrors[0].objectName', params.fieldErrors0ObjectName.toString());
    if (params.fieldErrors0Field != null) __params = __params.set('fieldErrors[0].field', params.fieldErrors0Field.toString());
    if (params.fieldErrors0DefaultMessage != null) __params = __params.set('fieldErrors[0].defaultMessage', params.fieldErrors0DefaultMessage.toString());
    (params.fieldErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].codes', val.toString())});
    if (params.fieldErrors0Code != null) __params = __params.set('fieldErrors[0].code', params.fieldErrors0Code.toString());
    if (params.fieldErrors0BindingFailure != null) __params = __params.set('fieldErrors[0].bindingFailure', params.fieldErrors0BindingFailure.toString());
    (params.fieldErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('fieldErrors[0].arguments', val.toString())});
    if (params.fieldErrorCount != null) __params = __params.set('fieldErrorCount', params.fieldErrorCount.toString());
    if (params.fieldErrorRejectedValue != null) __params = __params.set('fieldError.rejectedValue', params.fieldErrorRejectedValue.toString());
    if (params.fieldErrorObjectName != null) __params = __params.set('fieldError.objectName', params.fieldErrorObjectName.toString());
    if (params.fieldErrorField != null) __params = __params.set('fieldError.field', params.fieldErrorField.toString());
    if (params.fieldErrorDefaultMessage != null) __params = __params.set('fieldError.defaultMessage', params.fieldErrorDefaultMessage.toString());
    (params.fieldErrorCodes || []).forEach(val => {if (val != null) __params = __params.append('fieldError.codes', val.toString())});
    if (params.fieldErrorCode != null) __params = __params.set('fieldError.code', params.fieldErrorCode.toString());
    if (params.fieldErrorBindingFailure != null) __params = __params.set('fieldError.bindingFailure', params.fieldErrorBindingFailure.toString());
    (params.fieldErrorArguments || []).forEach(val => {if (val != null) __params = __params.append('fieldError.arguments', val.toString())});
    if (params.errorCount != null) __params = __params.set('errorCount', params.errorCount.toString());
    if (params.cause != null) __params = __params.set('cause', params.cause.toString());
    if (params.allErrors0ObjectName != null) __params = __params.set('allErrors[0].objectName', params.allErrors0ObjectName.toString());
    if (params.allErrors0DefaultMessage != null) __params = __params.set('allErrors[0].defaultMessage', params.allErrors0DefaultMessage.toString());
    (params.allErrors0Codes || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].codes', val.toString())});
    if (params.allErrors0Code != null) __params = __params.set('allErrors[0].code', params.allErrors0Code.toString());
    (params.allErrors0Arguments || []).forEach(val => {if (val != null) __params = __params.append('allErrors[0].arguments', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * handleMethodArgumentNotValidException
   * @param params The `DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPATCHParams` containing the following parameters:
   *
   * - `target`:
   *
   * - `suppressed[0].stackTrace[0].nativeMethod`:
   *
   * - `suppressed[0].stackTrace[0].moduleVersion`:
   *
   * - `suppressed[0].stackTrace[0].moduleName`:
   *
   * - `suppressed[0].stackTrace[0].methodName`:
   *
   * - `suppressed[0].stackTrace[0].lineNumber`:
   *
   * - `suppressed[0].stackTrace[0].fileName`:
   *
   * - `suppressed[0].stackTrace[0].className`:
   *
   * - `suppressed[0].stackTrace[0].classLoaderName`:
   *
   * - `suppressed[0].message`:
   *
   * - `suppressed[0].localizedMessage`:
   *
   * - `suppressed[0].cause`:
   *
   * - `suppressedFields`:
   *
   * - `stackTrace[0].nativeMethod`:
   *
   * - `stackTrace[0].moduleVersion`:
   *
   * - `stackTrace[0].moduleName`:
   *
   * - `stackTrace[0].methodName`:
   *
   * - `stackTrace[0].lineNumber`:
   *
   * - `stackTrace[0].fileName`:
   *
   * - `stackTrace[0].className`:
   *
   * - `stackTrace[0].classLoaderName`:
   *
   * - `parameter.typeIndexForCurrentLevel`:
   *
   * - `parameter.parameterName`:
   *
   * - `parameter.parameterIndex`:
   *
   * - `parameter.parameter`:
   *
   * - `parameter.optional`:
   *
   * - `parameter.nestingLevel`:
   *
   * - `parameter.nestedGenericParameterType`:
   *
   * - `parameter.method`:
   *
   * - `parameter.member`:
   *
   * - `parameter.genericParameterType`:
   *
   * - `parameter.executable`:
   *
   * - `parameter.constructor`:
   *
   * - `parameter.annotatedElement`:
   *
   * - `objectName`:
   *
   * - `nestedPath`:
   *
   * - `model`:
   *
   * - `message`:
   *
   * - `localizedMessage`:
   *
   * - `globalErrors[0].objectName`:
   *
   * - `globalErrors[0].defaultMessage`:
   *
   * - `globalErrors[0].codes`:
   *
   * - `globalErrors[0].code`:
   *
   * - `globalErrors[0].arguments`:
   *
   * - `globalErrorCount`:
   *
   * - `globalError.objectName`:
   *
   * - `globalError.defaultMessage`:
   *
   * - `globalError.codes`:
   *
   * - `globalError.code`:
   *
   * - `globalError.arguments`:
   *
   * - `fieldErrors[0].rejectedValue`:
   *
   * - `fieldErrors[0].objectName`:
   *
   * - `fieldErrors[0].field`:
   *
   * - `fieldErrors[0].defaultMessage`:
   *
   * - `fieldErrors[0].codes`:
   *
   * - `fieldErrors[0].code`:
   *
   * - `fieldErrors[0].bindingFailure`:
   *
   * - `fieldErrors[0].arguments`:
   *
   * - `fieldErrorCount`:
   *
   * - `fieldError.rejectedValue`:
   *
   * - `fieldError.objectName`:
   *
   * - `fieldError.field`:
   *
   * - `fieldError.defaultMessage`:
   *
   * - `fieldError.codes`:
   *
   * - `fieldError.code`:
   *
   * - `fieldError.bindingFailure`:
   *
   * - `fieldError.arguments`:
   *
   * - `errorCount`:
   *
   * - `cause`:
   *
   * - `allErrors[0].objectName`:
   *
   * - `allErrors[0].defaultMessage`:
   *
   * - `allErrors[0].codes`:
   *
   * - `allErrors[0].code`:
   *
   * - `allErrors[0].arguments`:
   */
  handleMethodArgumentNotValidExceptionUsingPATCH(params: DefaultExceptionHandlerService.HandleMethodArgumentNotValidExceptionUsingPATCHParams): __Observable<null> {
    return this.handleMethodArgumentNotValidExceptionUsingPATCHResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DefaultExceptionHandlerService {

  /**
   * Parameters for handleMethodArgumentNotValidExceptionUsingGET
   */
  export interface HandleMethodArgumentNotValidExceptionUsingGETParams {
    target?: {};
    suppressed0StackTrace0NativeMethod?: boolean;
    suppressed0StackTrace0ModuleVersion?: string;
    suppressed0StackTrace0ModuleName?: string;
    suppressed0StackTrace0MethodName?: string;
    suppressed0StackTrace0LineNumber?: number;
    suppressed0StackTrace0FileName?: string;
    suppressed0StackTrace0ClassName?: string;
    suppressed0StackTrace0ClassLoaderName?: string;
    suppressed0Message?: string;
    suppressed0LocalizedMessage?: string;
    suppressed0Cause?: string;
    suppressedFields?: Array<string>;
    stackTrace0NativeMethod?: boolean;
    stackTrace0ModuleVersion?: string;
    stackTrace0ModuleName?: string;
    stackTrace0MethodName?: string;
    stackTrace0LineNumber?: number;
    stackTrace0FileName?: string;
    stackTrace0ClassName?: string;
    stackTrace0ClassLoaderName?: string;
    parameterTypeIndexForCurrentLevel?: number;
    parameterParameterName?: string;
    parameterParameterIndex?: number;
    parameterParameter?: string;
    parameterOptional?: boolean;
    parameterNestingLevel?: number;
    parameterNestedGenericParameterType?: string;
    parameterMethod?: string;
    parameterMember?: string;
    parameterGenericParameterType?: string;
    parameterExecutable?: string;
    parameterConstructor?: string;
    parameterAnnotatedElement?: string;
    objectName?: string;
    nestedPath?: string;
    model?: {};
    message?: string;
    localizedMessage?: string;
    globalErrors0ObjectName?: string;
    globalErrors0DefaultMessage?: string;
    globalErrors0Codes?: Array<string>;
    globalErrors0Code?: string;
    globalErrors0Arguments?: Array<{}>;
    globalErrorCount?: number;
    globalErrorObjectName?: string;
    globalErrorDefaultMessage?: string;
    globalErrorCodes?: Array<string>;
    globalErrorCode?: string;
    globalErrorArguments?: Array<{}>;
    fieldErrors0RejectedValue?: {};
    fieldErrors0ObjectName?: string;
    fieldErrors0Field?: string;
    fieldErrors0DefaultMessage?: string;
    fieldErrors0Codes?: Array<string>;
    fieldErrors0Code?: string;
    fieldErrors0BindingFailure?: boolean;
    fieldErrors0Arguments?: Array<{}>;
    fieldErrorCount?: number;
    fieldErrorRejectedValue?: {};
    fieldErrorObjectName?: string;
    fieldErrorField?: string;
    fieldErrorDefaultMessage?: string;
    fieldErrorCodes?: Array<string>;
    fieldErrorCode?: string;
    fieldErrorBindingFailure?: boolean;
    fieldErrorArguments?: Array<{}>;
    errorCount?: number;
    cause?: string;
    allErrors0ObjectName?: string;
    allErrors0DefaultMessage?: string;
    allErrors0Codes?: Array<string>;
    allErrors0Code?: string;
    allErrors0Arguments?: Array<{}>;
  }

  /**
   * Parameters for handleMethodArgumentNotValidExceptionUsingHEAD
   */
  export interface HandleMethodArgumentNotValidExceptionUsingHEADParams {
    target?: {};
    suppressed0StackTrace0NativeMethod?: boolean;
    suppressed0StackTrace0ModuleVersion?: string;
    suppressed0StackTrace0ModuleName?: string;
    suppressed0StackTrace0MethodName?: string;
    suppressed0StackTrace0LineNumber?: number;
    suppressed0StackTrace0FileName?: string;
    suppressed0StackTrace0ClassName?: string;
    suppressed0StackTrace0ClassLoaderName?: string;
    suppressed0Message?: string;
    suppressed0LocalizedMessage?: string;
    suppressed0Cause?: string;
    suppressedFields?: Array<string>;
    stackTrace0NativeMethod?: boolean;
    stackTrace0ModuleVersion?: string;
    stackTrace0ModuleName?: string;
    stackTrace0MethodName?: string;
    stackTrace0LineNumber?: number;
    stackTrace0FileName?: string;
    stackTrace0ClassName?: string;
    stackTrace0ClassLoaderName?: string;
    parameterTypeIndexForCurrentLevel?: number;
    parameterParameterName?: string;
    parameterParameterIndex?: number;
    parameterParameter?: string;
    parameterOptional?: boolean;
    parameterNestingLevel?: number;
    parameterNestedGenericParameterType?: string;
    parameterMethod?: string;
    parameterMember?: string;
    parameterGenericParameterType?: string;
    parameterExecutable?: string;
    parameterConstructor?: string;
    parameterAnnotatedElement?: string;
    objectName?: string;
    nestedPath?: string;
    model?: {};
    message?: string;
    localizedMessage?: string;
    globalErrors0ObjectName?: string;
    globalErrors0DefaultMessage?: string;
    globalErrors0Codes?: Array<string>;
    globalErrors0Code?: string;
    globalErrors0Arguments?: Array<{}>;
    globalErrorCount?: number;
    globalErrorObjectName?: string;
    globalErrorDefaultMessage?: string;
    globalErrorCodes?: Array<string>;
    globalErrorCode?: string;
    globalErrorArguments?: Array<{}>;
    fieldErrors0RejectedValue?: {};
    fieldErrors0ObjectName?: string;
    fieldErrors0Field?: string;
    fieldErrors0DefaultMessage?: string;
    fieldErrors0Codes?: Array<string>;
    fieldErrors0Code?: string;
    fieldErrors0BindingFailure?: boolean;
    fieldErrors0Arguments?: Array<{}>;
    fieldErrorCount?: number;
    fieldErrorRejectedValue?: {};
    fieldErrorObjectName?: string;
    fieldErrorField?: string;
    fieldErrorDefaultMessage?: string;
    fieldErrorCodes?: Array<string>;
    fieldErrorCode?: string;
    fieldErrorBindingFailure?: boolean;
    fieldErrorArguments?: Array<{}>;
    errorCount?: number;
    cause?: string;
    allErrors0ObjectName?: string;
    allErrors0DefaultMessage?: string;
    allErrors0Codes?: Array<string>;
    allErrors0Code?: string;
    allErrors0Arguments?: Array<{}>;
  }

  /**
   * Parameters for handleMethodArgumentNotValidExceptionUsingPOST
   */
  export interface HandleMethodArgumentNotValidExceptionUsingPOSTParams {
    target?: {};
    suppressed0StackTrace0NativeMethod?: boolean;
    suppressed0StackTrace0ModuleVersion?: string;
    suppressed0StackTrace0ModuleName?: string;
    suppressed0StackTrace0MethodName?: string;
    suppressed0StackTrace0LineNumber?: number;
    suppressed0StackTrace0FileName?: string;
    suppressed0StackTrace0ClassName?: string;
    suppressed0StackTrace0ClassLoaderName?: string;
    suppressed0Message?: string;
    suppressed0LocalizedMessage?: string;
    suppressed0Cause?: string;
    suppressedFields?: Array<string>;
    stackTrace0NativeMethod?: boolean;
    stackTrace0ModuleVersion?: string;
    stackTrace0ModuleName?: string;
    stackTrace0MethodName?: string;
    stackTrace0LineNumber?: number;
    stackTrace0FileName?: string;
    stackTrace0ClassName?: string;
    stackTrace0ClassLoaderName?: string;
    parameterTypeIndexForCurrentLevel?: number;
    parameterParameterName?: string;
    parameterParameterIndex?: number;
    parameterParameter?: string;
    parameterOptional?: boolean;
    parameterNestingLevel?: number;
    parameterNestedGenericParameterType?: string;
    parameterMethod?: string;
    parameterMember?: string;
    parameterGenericParameterType?: string;
    parameterExecutable?: string;
    parameterConstructor?: string;
    parameterAnnotatedElement?: string;
    objectName?: string;
    nestedPath?: string;
    model?: {};
    message?: string;
    localizedMessage?: string;
    globalErrors0ObjectName?: string;
    globalErrors0DefaultMessage?: string;
    globalErrors0Codes?: Array<string>;
    globalErrors0Code?: string;
    globalErrors0Arguments?: Array<{}>;
    globalErrorCount?: number;
    globalErrorObjectName?: string;
    globalErrorDefaultMessage?: string;
    globalErrorCodes?: Array<string>;
    globalErrorCode?: string;
    globalErrorArguments?: Array<{}>;
    fieldErrors0RejectedValue?: {};
    fieldErrors0ObjectName?: string;
    fieldErrors0Field?: string;
    fieldErrors0DefaultMessage?: string;
    fieldErrors0Codes?: Array<string>;
    fieldErrors0Code?: string;
    fieldErrors0BindingFailure?: boolean;
    fieldErrors0Arguments?: Array<{}>;
    fieldErrorCount?: number;
    fieldErrorRejectedValue?: {};
    fieldErrorObjectName?: string;
    fieldErrorField?: string;
    fieldErrorDefaultMessage?: string;
    fieldErrorCodes?: Array<string>;
    fieldErrorCode?: string;
    fieldErrorBindingFailure?: boolean;
    fieldErrorArguments?: Array<{}>;
    errorCount?: number;
    cause?: string;
    allErrors0ObjectName?: string;
    allErrors0DefaultMessage?: string;
    allErrors0Codes?: Array<string>;
    allErrors0Code?: string;
    allErrors0Arguments?: Array<{}>;
  }

  /**
   * Parameters for handleMethodArgumentNotValidExceptionUsingPUT
   */
  export interface HandleMethodArgumentNotValidExceptionUsingPUTParams {
    target?: {};
    suppressed0StackTrace0NativeMethod?: boolean;
    suppressed0StackTrace0ModuleVersion?: string;
    suppressed0StackTrace0ModuleName?: string;
    suppressed0StackTrace0MethodName?: string;
    suppressed0StackTrace0LineNumber?: number;
    suppressed0StackTrace0FileName?: string;
    suppressed0StackTrace0ClassName?: string;
    suppressed0StackTrace0ClassLoaderName?: string;
    suppressed0Message?: string;
    suppressed0LocalizedMessage?: string;
    suppressed0Cause?: string;
    suppressedFields?: Array<string>;
    stackTrace0NativeMethod?: boolean;
    stackTrace0ModuleVersion?: string;
    stackTrace0ModuleName?: string;
    stackTrace0MethodName?: string;
    stackTrace0LineNumber?: number;
    stackTrace0FileName?: string;
    stackTrace0ClassName?: string;
    stackTrace0ClassLoaderName?: string;
    parameterTypeIndexForCurrentLevel?: number;
    parameterParameterName?: string;
    parameterParameterIndex?: number;
    parameterParameter?: string;
    parameterOptional?: boolean;
    parameterNestingLevel?: number;
    parameterNestedGenericParameterType?: string;
    parameterMethod?: string;
    parameterMember?: string;
    parameterGenericParameterType?: string;
    parameterExecutable?: string;
    parameterConstructor?: string;
    parameterAnnotatedElement?: string;
    objectName?: string;
    nestedPath?: string;
    model?: {};
    message?: string;
    localizedMessage?: string;
    globalErrors0ObjectName?: string;
    globalErrors0DefaultMessage?: string;
    globalErrors0Codes?: Array<string>;
    globalErrors0Code?: string;
    globalErrors0Arguments?: Array<{}>;
    globalErrorCount?: number;
    globalErrorObjectName?: string;
    globalErrorDefaultMessage?: string;
    globalErrorCodes?: Array<string>;
    globalErrorCode?: string;
    globalErrorArguments?: Array<{}>;
    fieldErrors0RejectedValue?: {};
    fieldErrors0ObjectName?: string;
    fieldErrors0Field?: string;
    fieldErrors0DefaultMessage?: string;
    fieldErrors0Codes?: Array<string>;
    fieldErrors0Code?: string;
    fieldErrors0BindingFailure?: boolean;
    fieldErrors0Arguments?: Array<{}>;
    fieldErrorCount?: number;
    fieldErrorRejectedValue?: {};
    fieldErrorObjectName?: string;
    fieldErrorField?: string;
    fieldErrorDefaultMessage?: string;
    fieldErrorCodes?: Array<string>;
    fieldErrorCode?: string;
    fieldErrorBindingFailure?: boolean;
    fieldErrorArguments?: Array<{}>;
    errorCount?: number;
    cause?: string;
    allErrors0ObjectName?: string;
    allErrors0DefaultMessage?: string;
    allErrors0Codes?: Array<string>;
    allErrors0Code?: string;
    allErrors0Arguments?: Array<{}>;
  }

  /**
   * Parameters for handleMethodArgumentNotValidExceptionUsingDELETE
   */
  export interface HandleMethodArgumentNotValidExceptionUsingDELETEParams {
    target?: {};
    suppressed0StackTrace0NativeMethod?: boolean;
    suppressed0StackTrace0ModuleVersion?: string;
    suppressed0StackTrace0ModuleName?: string;
    suppressed0StackTrace0MethodName?: string;
    suppressed0StackTrace0LineNumber?: number;
    suppressed0StackTrace0FileName?: string;
    suppressed0StackTrace0ClassName?: string;
    suppressed0StackTrace0ClassLoaderName?: string;
    suppressed0Message?: string;
    suppressed0LocalizedMessage?: string;
    suppressed0Cause?: string;
    suppressedFields?: Array<string>;
    stackTrace0NativeMethod?: boolean;
    stackTrace0ModuleVersion?: string;
    stackTrace0ModuleName?: string;
    stackTrace0MethodName?: string;
    stackTrace0LineNumber?: number;
    stackTrace0FileName?: string;
    stackTrace0ClassName?: string;
    stackTrace0ClassLoaderName?: string;
    parameterTypeIndexForCurrentLevel?: number;
    parameterParameterName?: string;
    parameterParameterIndex?: number;
    parameterParameter?: string;
    parameterOptional?: boolean;
    parameterNestingLevel?: number;
    parameterNestedGenericParameterType?: string;
    parameterMethod?: string;
    parameterMember?: string;
    parameterGenericParameterType?: string;
    parameterExecutable?: string;
    parameterConstructor?: string;
    parameterAnnotatedElement?: string;
    objectName?: string;
    nestedPath?: string;
    model?: {};
    message?: string;
    localizedMessage?: string;
    globalErrors0ObjectName?: string;
    globalErrors0DefaultMessage?: string;
    globalErrors0Codes?: Array<string>;
    globalErrors0Code?: string;
    globalErrors0Arguments?: Array<{}>;
    globalErrorCount?: number;
    globalErrorObjectName?: string;
    globalErrorDefaultMessage?: string;
    globalErrorCodes?: Array<string>;
    globalErrorCode?: string;
    globalErrorArguments?: Array<{}>;
    fieldErrors0RejectedValue?: {};
    fieldErrors0ObjectName?: string;
    fieldErrors0Field?: string;
    fieldErrors0DefaultMessage?: string;
    fieldErrors0Codes?: Array<string>;
    fieldErrors0Code?: string;
    fieldErrors0BindingFailure?: boolean;
    fieldErrors0Arguments?: Array<{}>;
    fieldErrorCount?: number;
    fieldErrorRejectedValue?: {};
    fieldErrorObjectName?: string;
    fieldErrorField?: string;
    fieldErrorDefaultMessage?: string;
    fieldErrorCodes?: Array<string>;
    fieldErrorCode?: string;
    fieldErrorBindingFailure?: boolean;
    fieldErrorArguments?: Array<{}>;
    errorCount?: number;
    cause?: string;
    allErrors0ObjectName?: string;
    allErrors0DefaultMessage?: string;
    allErrors0Codes?: Array<string>;
    allErrors0Code?: string;
    allErrors0Arguments?: Array<{}>;
  }

  /**
   * Parameters for handleMethodArgumentNotValidExceptionUsingOPTIONS
   */
  export interface HandleMethodArgumentNotValidExceptionUsingOPTIONSParams {
    target?: {};
    suppressed0StackTrace0NativeMethod?: boolean;
    suppressed0StackTrace0ModuleVersion?: string;
    suppressed0StackTrace0ModuleName?: string;
    suppressed0StackTrace0MethodName?: string;
    suppressed0StackTrace0LineNumber?: number;
    suppressed0StackTrace0FileName?: string;
    suppressed0StackTrace0ClassName?: string;
    suppressed0StackTrace0ClassLoaderName?: string;
    suppressed0Message?: string;
    suppressed0LocalizedMessage?: string;
    suppressed0Cause?: string;
    suppressedFields?: Array<string>;
    stackTrace0NativeMethod?: boolean;
    stackTrace0ModuleVersion?: string;
    stackTrace0ModuleName?: string;
    stackTrace0MethodName?: string;
    stackTrace0LineNumber?: number;
    stackTrace0FileName?: string;
    stackTrace0ClassName?: string;
    stackTrace0ClassLoaderName?: string;
    parameterTypeIndexForCurrentLevel?: number;
    parameterParameterName?: string;
    parameterParameterIndex?: number;
    parameterParameter?: string;
    parameterOptional?: boolean;
    parameterNestingLevel?: number;
    parameterNestedGenericParameterType?: string;
    parameterMethod?: string;
    parameterMember?: string;
    parameterGenericParameterType?: string;
    parameterExecutable?: string;
    parameterConstructor?: string;
    parameterAnnotatedElement?: string;
    objectName?: string;
    nestedPath?: string;
    model?: {};
    message?: string;
    localizedMessage?: string;
    globalErrors0ObjectName?: string;
    globalErrors0DefaultMessage?: string;
    globalErrors0Codes?: Array<string>;
    globalErrors0Code?: string;
    globalErrors0Arguments?: Array<{}>;
    globalErrorCount?: number;
    globalErrorObjectName?: string;
    globalErrorDefaultMessage?: string;
    globalErrorCodes?: Array<string>;
    globalErrorCode?: string;
    globalErrorArguments?: Array<{}>;
    fieldErrors0RejectedValue?: {};
    fieldErrors0ObjectName?: string;
    fieldErrors0Field?: string;
    fieldErrors0DefaultMessage?: string;
    fieldErrors0Codes?: Array<string>;
    fieldErrors0Code?: string;
    fieldErrors0BindingFailure?: boolean;
    fieldErrors0Arguments?: Array<{}>;
    fieldErrorCount?: number;
    fieldErrorRejectedValue?: {};
    fieldErrorObjectName?: string;
    fieldErrorField?: string;
    fieldErrorDefaultMessage?: string;
    fieldErrorCodes?: Array<string>;
    fieldErrorCode?: string;
    fieldErrorBindingFailure?: boolean;
    fieldErrorArguments?: Array<{}>;
    errorCount?: number;
    cause?: string;
    allErrors0ObjectName?: string;
    allErrors0DefaultMessage?: string;
    allErrors0Codes?: Array<string>;
    allErrors0Code?: string;
    allErrors0Arguments?: Array<{}>;
  }

  /**
   * Parameters for handleMethodArgumentNotValidExceptionUsingPATCH
   */
  export interface HandleMethodArgumentNotValidExceptionUsingPATCHParams {
    target?: {};
    suppressed0StackTrace0NativeMethod?: boolean;
    suppressed0StackTrace0ModuleVersion?: string;
    suppressed0StackTrace0ModuleName?: string;
    suppressed0StackTrace0MethodName?: string;
    suppressed0StackTrace0LineNumber?: number;
    suppressed0StackTrace0FileName?: string;
    suppressed0StackTrace0ClassName?: string;
    suppressed0StackTrace0ClassLoaderName?: string;
    suppressed0Message?: string;
    suppressed0LocalizedMessage?: string;
    suppressed0Cause?: string;
    suppressedFields?: Array<string>;
    stackTrace0NativeMethod?: boolean;
    stackTrace0ModuleVersion?: string;
    stackTrace0ModuleName?: string;
    stackTrace0MethodName?: string;
    stackTrace0LineNumber?: number;
    stackTrace0FileName?: string;
    stackTrace0ClassName?: string;
    stackTrace0ClassLoaderName?: string;
    parameterTypeIndexForCurrentLevel?: number;
    parameterParameterName?: string;
    parameterParameterIndex?: number;
    parameterParameter?: string;
    parameterOptional?: boolean;
    parameterNestingLevel?: number;
    parameterNestedGenericParameterType?: string;
    parameterMethod?: string;
    parameterMember?: string;
    parameterGenericParameterType?: string;
    parameterExecutable?: string;
    parameterConstructor?: string;
    parameterAnnotatedElement?: string;
    objectName?: string;
    nestedPath?: string;
    model?: {};
    message?: string;
    localizedMessage?: string;
    globalErrors0ObjectName?: string;
    globalErrors0DefaultMessage?: string;
    globalErrors0Codes?: Array<string>;
    globalErrors0Code?: string;
    globalErrors0Arguments?: Array<{}>;
    globalErrorCount?: number;
    globalErrorObjectName?: string;
    globalErrorDefaultMessage?: string;
    globalErrorCodes?: Array<string>;
    globalErrorCode?: string;
    globalErrorArguments?: Array<{}>;
    fieldErrors0RejectedValue?: {};
    fieldErrors0ObjectName?: string;
    fieldErrors0Field?: string;
    fieldErrors0DefaultMessage?: string;
    fieldErrors0Codes?: Array<string>;
    fieldErrors0Code?: string;
    fieldErrors0BindingFailure?: boolean;
    fieldErrors0Arguments?: Array<{}>;
    fieldErrorCount?: number;
    fieldErrorRejectedValue?: {};
    fieldErrorObjectName?: string;
    fieldErrorField?: string;
    fieldErrorDefaultMessage?: string;
    fieldErrorCodes?: Array<string>;
    fieldErrorCode?: string;
    fieldErrorBindingFailure?: boolean;
    fieldErrorArguments?: Array<{}>;
    errorCount?: number;
    cause?: string;
    allErrors0ObjectName?: string;
    allErrors0DefaultMessage?: string;
    allErrors0Codes?: Array<string>;
    allErrors0Code?: string;
    allErrors0Arguments?: Array<{}>;
  }
}

export { DefaultExceptionHandlerService }
