import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {NavigationService} from './navigation.service';
import { isNullOrUndefined } from "src/app/shared/utilities";

@Injectable({
  providedIn: 'root'
})
export class NavigationGuard implements CanDeactivate<any> {
  constructor(private someNavigationService: NavigationService) {
  }

  canDeactivate(component: any) {
    // will prevent user from going back
    if (this.someNavigationService.getBackClicked()) {
      this.someNavigationService.setBackClicked(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);

      if (!isNullOrUndefined(component.close_return)) {
        component.close_return();
      } else {
        console.warn('The component does not support close_return.');
        console.warn(component);
      }
      return false;
    }
    return true;
  }
}
