<span
  class="
    clickable
    text-skin-muted_base
    text-table_header
    font-table_header
    whitespace-nowrap
  "
  (click)="changeSort('asc')"
  *ngIf="current.direction == 'none' && allowSort"
  >{{ label | translate
  }}<mat-icon style="font-size: 10px">remove</mat-icon></span
>
<span
  class="
    clickable
    text-skin-muted_base
    text-table_header
    font-table_header
    whitespace-nowrap
  "
  (click)="changeSort('desc')"
  *ngIf="current.direction == 'asc' && allowSort"
  >{{ label | translate }}<mat-icon>arrow_drop_up</mat-icon></span
>
<span
  class="
    clickable
    text-skin-muted_base
    text-table_header
    font-table_header
    whitespace-nowrap
  "
  (click)="changeSort('asc')"
  *ngIf="current.direction == 'desc' && allowSort"
  >{{ label | translate }}<mat-icon>arrow_drop_down</mat-icon></span
>
<span
  *ngIf="!allowSort"
  class="
    text-skin-muted_base
    text-table_header
    font-table_header
    whitespace-nowrap
  "
  >{{ label | translate }}</span
>
