<span class="clickable" (click)="is_open = !is_open">
<div class="date">
  <table>
    <tr>
      <td width="95%">{{notification.from?.substr(0, 10)}}</td>
      <td>
        <div *ngIf="!is_open" class="clickable section_label">
          <mat-icon>arrow_drop_up</mat-icon>
        </div>
        <div *ngIf="is_open" class="clickable section_label">
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </td>
    </tr>
  </table>
</div>
<div class="title">{{notification.title}}</div>
<div class="description" *ngIf="is_open">{{notification.body}}</div>
</span>
