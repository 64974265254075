/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { InmCategoriesReadResponse } from '../models/inm-categories-read-response';
import { InmConfigurationJobsResponse } from '../models/inm-configuration-jobs-response';
import { InmConfigurationNotificationReadResponse } from '../models/inm-configuration-notification-read-response';
import { InmConfigurationResourcesResponse } from '../models/inm-configuration-resources-response';
import { InmConfigurationTaskReadResponse } from '../models/inm-configuration-task-read-response';
import { InmConfigurationVisibilityReadResponse } from '../models/inm-configuration-visibility-read-response';
import { InmInventoryDefinitionReadResponse } from '../models/inm-inventory-definition-read-response';
import { noficiation } from '../fn/configuration-read/noficiation';
import { Noficiation$Params } from '../fn/configuration-read/noficiation';
import { readCategoriesLabels } from '../fn/configuration-read/read-categories-labels';
import { ReadCategoriesLabels$Params } from '../fn/configuration-read/read-categories-labels';
import { readInventoryDefinition } from '../fn/configuration-read/read-inventory-definition';
import { ReadInventoryDefinition$Params } from '../fn/configuration-read/read-inventory-definition';
import { readJobs } from '../fn/configuration-read/read-jobs';
import { ReadJobs$Params } from '../fn/configuration-read/read-jobs';
import { resources } from '../fn/configuration-read/resources';
import { Resources$Params } from '../fn/configuration-read/resources';
import { task } from '../fn/configuration-read/task';
import { Task$Params } from '../fn/configuration-read/task';
import { visibility } from '../fn/configuration-read/visibility';
import { Visibility$Params } from '../fn/configuration-read/visibility';

@Injectable({ providedIn: 'root' })
export class ConfigurationReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readInventoryDefinition()` */
  static readonly ReadInventoryDefinitionPath = '/api/inv/inventory/configuration/{operationType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readInventoryDefinition()` instead.
   *
   * This method doesn't expect any request body.
   */
  readInventoryDefinition$Response(params: ReadInventoryDefinition$Params, context?: HttpContext): Observable<StrictHttpResponse<InmInventoryDefinitionReadResponse>> {
    return readInventoryDefinition(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readInventoryDefinition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readInventoryDefinition(params: ReadInventoryDefinition$Params, context?: HttpContext): Observable<InmInventoryDefinitionReadResponse> {
    return this.readInventoryDefinition$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmInventoryDefinitionReadResponse>): InmInventoryDefinitionReadResponse => r.body)
    );
  }

  /** Path part for operation `task()` */
  static readonly TaskPath = '/api/inv/configuration/{categoryCode}/{operationType}/task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `task()` instead.
   *
   * This method doesn't expect any request body.
   */
  task$Response(params: Task$Params, context?: HttpContext): Observable<StrictHttpResponse<InmConfigurationTaskReadResponse>> {
    return task(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `task$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  task(params: Task$Params, context?: HttpContext): Observable<InmConfigurationTaskReadResponse> {
    return this.task$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmConfigurationTaskReadResponse>): InmConfigurationTaskReadResponse => r.body)
    );
  }

  /** Path part for operation `noficiation()` */
  static readonly NoficiationPath = '/api/inv/configuration/{categoryCode}/{operationType}/notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `noficiation()` instead.
   *
   * This method doesn't expect any request body.
   */
  noficiation$Response(params: Noficiation$Params, context?: HttpContext): Observable<StrictHttpResponse<InmConfigurationNotificationReadResponse>> {
    return noficiation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `noficiation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  noficiation(params: Noficiation$Params, context?: HttpContext): Observable<InmConfigurationNotificationReadResponse> {
    return this.noficiation$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmConfigurationNotificationReadResponse>): InmConfigurationNotificationReadResponse => r.body)
    );
  }

  /** Path part for operation `visibility()` */
  static readonly VisibilityPath = '/api/inv/configuration/{categoryCode}/visibility';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `visibility()` instead.
   *
   * This method doesn't expect any request body.
   */
  visibility$Response(params: Visibility$Params, context?: HttpContext): Observable<StrictHttpResponse<InmConfigurationVisibilityReadResponse>> {
    return visibility(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `visibility$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  visibility(params: Visibility$Params, context?: HttpContext): Observable<InmConfigurationVisibilityReadResponse> {
    return this.visibility$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmConfigurationVisibilityReadResponse>): InmConfigurationVisibilityReadResponse => r.body)
    );
  }

  /** Path part for operation `resources()` */
  static readonly ResourcesPath = '/api/inv/configuration/resources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resources()` instead.
   *
   * This method doesn't expect any request body.
   */
  resources$Response(params?: Resources$Params, context?: HttpContext): Observable<StrictHttpResponse<InmConfigurationResourcesResponse>> {
    return resources(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resources(params?: Resources$Params, context?: HttpContext): Observable<InmConfigurationResourcesResponse> {
    return this.resources$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmConfigurationResourcesResponse>): InmConfigurationResourcesResponse => r.body)
    );
  }

  /** Path part for operation `readJobs()` */
  static readonly ReadJobsPath = '/api/inv/configuration/jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  readJobs$Response(params?: ReadJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<InmConfigurationJobsResponse>> {
    return readJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readJobs(params?: ReadJobs$Params, context?: HttpContext): Observable<InmConfigurationJobsResponse> {
    return this.readJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmConfigurationJobsResponse>): InmConfigurationJobsResponse => r.body)
    );
  }

  /** Path part for operation `readCategoriesLabels()` */
  static readonly ReadCategoriesLabelsPath = '/api/inv/configuration/categories/labels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readCategoriesLabels()` instead.
   *
   * This method doesn't expect any request body.
   */
  readCategoriesLabels$Response(params?: ReadCategoriesLabels$Params, context?: HttpContext): Observable<StrictHttpResponse<InmCategoriesReadResponse>> {
    return readCategoriesLabels(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readCategoriesLabels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readCategoriesLabels(params?: ReadCategoriesLabels$Params, context?: HttpContext): Observable<InmCategoriesReadResponse> {
    return this.readCategoriesLabels$Response(params, context).pipe(
      map((r: StrictHttpResponse<InmCategoriesReadResponse>): InmCategoriesReadResponse => r.body)
    );
  }

}
