/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { lock1 } from '../fn/pra-distributed-processing-register/lock-1';
import { Lock1$Params } from '../fn/pra-distributed-processing-register/lock-1';
import { PrmDistributedProcessingRegisterResponse } from '../models/prm-distributed-processing-register-response';

@Injectable({ providedIn: 'root' })
export class PraDistributedProcessingRegisterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lock1()` */
  static readonly Lock1Path = '/api/prj/dprc/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lock1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lock1$Response(params: Lock1$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmDistributedProcessingRegisterResponse>> {
    return lock1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lock1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  lock1(params: Lock1$Params, context?: HttpContext): Observable<PrmDistributedProcessingRegisterResponse> {
    return this.lock1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmDistributedProcessingRegisterResponse>): PrmDistributedProcessingRegisterResponse => r.body)
    );
  }

}
