/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { jobTaskList } from '../fn/nav-job-task-list-read/job-task-list';
import { JobTaskList$Params } from '../fn/nav-job-task-list-read/job-task-list';
import { NvmQueryJobTaskListResponse } from '../models/nvm-query-job-task-list-response';

@Injectable({ providedIn: 'root' })
export class NavJobTaskListReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `jobTaskList()` */
  static readonly JobTaskListPath = '/api/nav/jobtask/{project}/{tab}';

  /**
   * Read list of tasks for the tab.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `jobTaskList()` instead.
   *
   * This method doesn't expect any request body.
   */
  jobTaskList$Response(params: JobTaskList$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryJobTaskListResponse>> {
    return jobTaskList(this.http, this.rootUrl, params, context);
  }

  /**
   * Read list of tasks for the tab.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `jobTaskList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  jobTaskList(params: JobTaskList$Params, context?: HttpContext): Observable<NvmQueryJobTaskListResponse> {
    return this.jobTaskList$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryJobTaskListResponse>): NvmQueryJobTaskListResponse => r.body)
    );
  }

}
