import { UUID } from 'angular2-uuid';
import {CustomAction} from '../state.model';

export const INIT_SESSION_ID = 'INIT_SESSION_ID';

export function session(state: string = '', action: CustomAction) {
  switch (action.type) {
    case INIT_SESSION_ID:
      return UUID.UUID();
    default:
      return state;
  }
}
