/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { LimitService } from './services/limit.service';
import { DictionaryReadService } from './services/dictionary-read.service';
import { DictionaryWriteService } from './services/dictionary-write.service';
import { AbsenceReadService } from './services/absence-read.service';
import { AbsenceWriteService } from './services/absence-write.service';
import { CommandModifyConfigurationService } from './services/command-modify-configuration.service';
import { AbsenceExcelReadService } from './services/absence-excel-read.service';
import { UserService } from './services/user.service';
import { ValidationService } from './services/validation.service';
import { CalculateService } from './services/calculate.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    LimitService,
    DictionaryReadService,
    DictionaryWriteService,
    AbsenceReadService,
    AbsenceWriteService,
    CommandModifyConfigurationService,
    AbsenceExcelReadService,
    UserService,
    ValidationService,
    CalculateService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
