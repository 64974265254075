import { CustomAction } from '../state.model';
import { SystemConfiguration } from '../configuration.model';

export const GS_SET_CONFIG = 'GS_SET_CONFIG';

export function Config(
  configuration: SystemConfiguration = new SystemConfiguration(),
  action: CustomAction,
) {
  switch (action.type) {
    case GS_SET_CONFIG:
      return action.payload.configuration;
    default:
      return configuration;
  }
}
