/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DomDocumentDownloadUrlResponse } from '../../models/dom-document-download-url-response';

export interface TechnicalSignedUrl$Params {
  uri: string;
  'X-Authorization'?: string;
}

export function technicalSignedUrl(http: HttpClient, rootUrl: string, params: TechnicalSignedUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<DomDocumentDownloadUrlResponse>> {
  const rb = new RequestBuilder(rootUrl, technicalSignedUrl.PATH, 'get');
  if (params) {
    rb.path('uri', params.uri, {});
    rb.header('X-Authorization', params['X-Authorization'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DomDocumentDownloadUrlResponse>;
    })
  );
}

technicalSignedUrl.PATH = '/api/dcm/{uri}/signed-url/technical';
