/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentDeletedListResponse } from '../models/document-deleted-list-response';
import { documentDeleteImported } from '../fn/documents/document-delete-imported';
import { DocumentDeleteImported$Params } from '../fn/documents/document-delete-imported';
import { documentExport } from '../fn/documents/document-export';
import { DocumentExport$Params } from '../fn/documents/document-export';
import { documentImport } from '../fn/documents/document-import';
import { DocumentImport$Params } from '../fn/documents/document-import';
import { DocumentListResponse } from '../models/document-list-response';
import { DomDocumentDownloadUrlResponse } from '../models/dom-document-download-url-response';
import { DomDocumentMetadataResponse } from '../models/dom-document-metadata-response';
import { DomDocumentUploadResponse } from '../models/dom-document-upload-response';
import { getContent$Any } from '../fn/documents/get-content-any';
import { GetContent$Any$Params } from '../fn/documents/get-content-any';
import { getContent$Json } from '../fn/documents/get-content-json';
import { GetContent$Json$Params } from '../fn/documents/get-content-json';
import { getMetadata } from '../fn/documents/get-metadata';
import { GetMetadata$Params } from '../fn/documents/get-metadata';
import { getSignedUrl } from '../fn/documents/get-signed-url';
import { GetSignedUrl$Params } from '../fn/documents/get-signed-url';
import { technicalMetadata } from '../fn/documents/technical-metadata';
import { TechnicalMetadata$Params } from '../fn/documents/technical-metadata';
import { technicalSignedUrl } from '../fn/documents/technical-signed-url';
import { TechnicalSignedUrl$Params } from '../fn/documents/technical-signed-url';
import { upload } from '../fn/documents/upload';
import { Upload$Params } from '../fn/documents/upload';


/**
 * the Documents API
 */
@Injectable({ providedIn: 'root' })
export class DocumentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `documentDeleteImported()` */
  static readonly DocumentDeleteImportedPath = '/api/dcm/bulk-delete';

  /**
   * import document metadata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentDeleteImported()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentDeleteImported$Response(params: DocumentDeleteImported$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDeletedListResponse>> {
    return documentDeleteImported(this.http, this.rootUrl, params, context);
  }

  /**
   * import document metadata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentDeleteImported$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentDeleteImported(params: DocumentDeleteImported$Params, context?: HttpContext): Observable<DocumentDeletedListResponse> {
    return this.documentDeleteImported$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDeletedListResponse>): DocumentDeletedListResponse => r.body)
    );
  }

  /** Path part for operation `upload()` */
  static readonly UploadPath = '/api/dcm/upload';

  /**
   * Upload new document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upload()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upload$Response(params: Upload$Params, context?: HttpContext): Observable<StrictHttpResponse<DomDocumentUploadResponse>> {
    return upload(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload new document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upload(params: Upload$Params, context?: HttpContext): Observable<DomDocumentUploadResponse> {
    return this.upload$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomDocumentUploadResponse>): DomDocumentUploadResponse => r.body)
    );
  }

  /** Path part for operation `documentImport()` */
  static readonly DocumentImportPath = '/api/dcm/import';

  /**
   * import document metadata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentImport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentImport$Response(params: DocumentImport$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentListResponse>> {
    return documentImport(this.http, this.rootUrl, params, context);
  }

  /**
   * import document metadata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentImport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentImport(params: DocumentImport$Params, context?: HttpContext): Observable<DocumentListResponse> {
    return this.documentImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentListResponse>): DocumentListResponse => r.body)
    );
  }

  /** Path part for operation `getSignedUrl()` */
  static readonly GetSignedUrlPath = '/api/dcm/{uri}/signed-url';

  /**
   * Get document signedURL to download content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSignedUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignedUrl$Response(params: GetSignedUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<DomDocumentDownloadUrlResponse>> {
    return getSignedUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * Get document signedURL to download content
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSignedUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignedUrl(params: GetSignedUrl$Params, context?: HttpContext): Observable<DomDocumentDownloadUrlResponse> {
    return this.getSignedUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomDocumentDownloadUrlResponse>): DomDocumentDownloadUrlResponse => r.body)
    );
  }

  /** Path part for operation `technicalSignedUrl()` */
  static readonly TechnicalSignedUrlPath = '/api/dcm/{uri}/signed-url/technical';

  /**
   * Get document signedURL to download content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `technicalSignedUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicalSignedUrl$Response(params: TechnicalSignedUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<DomDocumentDownloadUrlResponse>> {
    return technicalSignedUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * Get document signedURL to download content
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `technicalSignedUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicalSignedUrl(params: TechnicalSignedUrl$Params, context?: HttpContext): Observable<DomDocumentDownloadUrlResponse> {
    return this.technicalSignedUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomDocumentDownloadUrlResponse>): DomDocumentDownloadUrlResponse => r.body)
    );
  }

  /** Path part for operation `getMetadata()` */
  static readonly GetMetadataPath = '/api/dcm/{uri}/metadata';

  /**
   * Fetch document data by document uri.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetadata$Response(params: GetMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<DomDocumentMetadataResponse>> {
    return getMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch document data by document uri.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetadata(params: GetMetadata$Params, context?: HttpContext): Observable<DomDocumentMetadataResponse> {
    return this.getMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomDocumentMetadataResponse>): DomDocumentMetadataResponse => r.body)
    );
  }

  /** Path part for operation `technicalMetadata()` */
  static readonly TechnicalMetadataPath = '/api/dcm/{uri}/metadata/technical';

  /**
   * Fetch document data by document uri.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `technicalMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicalMetadata$Response(params: TechnicalMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<DomDocumentMetadataResponse>> {
    return technicalMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch document data by document uri.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `technicalMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  technicalMetadata(params: TechnicalMetadata$Params, context?: HttpContext): Observable<DomDocumentMetadataResponse> {
    return this.technicalMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomDocumentMetadataResponse>): DomDocumentMetadataResponse => r.body)
    );
  }

  /** Path part for operation `getContent()` */
  static readonly GetContentPath = '/api/dcm/{uri}/content';

  /**
   * Fetch document content by document uri.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContent$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Json$Response(params: GetContent$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getContent$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch document content by document uri.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContent$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Json(params: GetContent$Json$Params, context?: HttpContext): Observable<string> {
    return this.getContent$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Fetch document content by document uri.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContent$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Any$Response(params: GetContent$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getContent$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch document content by document uri.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContent$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Any(params: GetContent$Any$Params, context?: HttpContext): Observable<string> {
    return this.getContent$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `documentExport()` */
  static readonly DocumentExportPath = '/api/dcm/export';

  /**
   * export document metadata
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentExport$Response(params: DocumentExport$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentListResponse>> {
    return documentExport(this.http, this.rootUrl, params, context);
  }

  /**
   * export document metadata
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentExport(params: DocumentExport$Params, context?: HttpContext): Observable<DocumentListResponse> {
    return this.documentExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentListResponse>): DocumentListResponse => r.body)
    );
  }

}
