/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AddressReadService } from './services/address-read.service';
import { DistanceReadService } from './services/distance-read.service';
import { OperationWriteService } from './services/operation-write.service';
import { OperationListReadService } from './services/operation-list-read.service';
import { OperationReadService } from './services/operation-read.service';
import { SystemConfigurationReadService } from './services/system-configuration-read.service';
import { SystemPermissionReadService } from './services/system-permission-read.service';
import { SystemPermissionWriteService } from './services/system-permission-write.service';
import { SystemPermissionDeleteService } from './services/system-permission-delete.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AddressReadService,
    DistanceReadService,
    OperationWriteService,
    OperationListReadService,
    OperationReadService,
    SystemConfigurationReadService,
    SystemPermissionReadService,
    SystemPermissionWriteService,
    SystemPermissionDeleteService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
