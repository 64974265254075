import {ChangeDetectionStrategy, Component, Inject, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from './dialog-data';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {SnackMessageService} from '../../core/service/snack-message.service';
import {ErrorHandlerService} from '../../core/service/error-handler.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import { isNullOrUndefined } from "src/app/shared/utilities";
import {PrmProjectDetail} from 'src/app/api/prj/models';
import { PraProjectReadService } from 'src/app/api/prj/services';

@Component({
  selector: 'app-search-project-dialog',
  templateUrl: './search-project-dialog.component.html',
  styleUrls: ['./search-project-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchProjectDialogComponent {
  public ds: ProjectDataSource = undefined;
  public no;
  public noOrName = '';
  @ViewChild('virtualView', {static: false}) input: CdkVirtualScrollViewport;

  constructor(
    public dialogRef: MatDialogRef<SearchProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(PraProjectReadService) private projectReadService: PraProjectReadService,
    @Inject(SnackMessageService) private snackMessageService: SnackMessageService,
    @Inject(ErrorHandlerService) private errorHandlerService: ErrorHandlerService
  ) {
    this.no = data.current_project;
    this.ds = new ProjectDataSource(projectReadService, snackMessageService, errorHandlerService, this, this.data.limited_projects);

    if (!isNullOrUndefined(this.no) && this.no !== '') {
      this.ds.searchFor(this.no, 0).then(
        result => {
          if (result > -1) {
            setTimeout(
              () => {
                this.input.scrollToIndex(result);
              },
              50
            );
          }
        }
      );
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    if (this.no !== undefined) {
      this.dialogRef.close(this.no);
    }
  }

  select_project(no) {
    this.no = no;
  }

  execute_search() {
    this.ds.fetchPage(0);
    this.no = '';
  }

  scroll(): void {
    this.input.scrollToIndex(90);
  }
}

export class ProjectDataSource extends DataSource<PrmProjectDetail | undefined> {
  private _projectReadService: PraProjectReadService = undefined;
  private _snackMessageService: SnackMessageService;
  private _errorHandlerService: ErrorHandlerService;
  private _parent: SearchProjectDialogComponent;
  private _limited_projects: boolean;

  constructor(
    projectReadService: PraProjectReadService,
    snackMessageService: SnackMessageService,
    errorHandlerService: ErrorHandlerService,
    parent: SearchProjectDialogComponent,
    limited_projects: boolean
  ) {
    super();
    this._projectReadService = projectReadService;
    this._snackMessageService = snackMessageService;
    this._errorHandlerService = errorHandlerService;
    this._parent = parent;
    this._limited_projects = limited_projects;
  }

  private _length = 100000;
  private _pageSize = 100;
  private _cachedData = Array.from<PrmProjectDetail>({length: this._length});
  private _fetchedPages = new Set<number>();
  private _dataStream = new BehaviorSubject<(PrmProjectDetail | undefined)[]>(this._cachedData);
  private _subscription = new Subscription();

  connect(collectionViewer: CollectionViewer): Observable<(PrmProjectDetail | undefined)[]> {
    this._subscription.add(collectionViewer.viewChange.subscribe(range => {
      const startPage = this._getPageForIndex(range.start);
      const endPage = this._getPageForIndex(range.end - 1);
      for (let i = startPage; i <= endPage; i++) {
        this._fetchPage(i).then(result => {
        });
      }
    }));
    return this._dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize);
  }

  public fetchPage(page: number): void {
    this._fetchedPages = new Set<number>();
    this._cachedData = Array.from<PrmProjectDetail>({length: this._length});
    this._fetchPage(page).then(result => {
    });
  }

  private _fetchPage(page: number): Promise<number> {
    return new Promise<number>(
      (resolve, reject) => {
        if (this._fetchedPages.has(page)) {
          resolve(page);
        }
        this._fetchedPages.add(page);

        this._projectReadService.query(
          {
            size: this._pageSize,
            page: page,
            nameOrId: this._parent.noOrName,
            limitedProjects: this._limited_projects,
          }
        ).subscribe(
          success => {
            if (this._length !== success.max) {
              this._length = success.max;
              this._cachedData = Array.from<PrmProjectDetail>({length: this._length});
            }

            this._cachedData.splice(
              page * this._pageSize,
              this._pageSize,
              ...success.projects
            );

            this._dataStream.next(this._cachedData);

            if (success.projects.length === 0) {
              reject();
            } else {
              resolve(page);
            }
          }, error => {
            this._snackMessageService.communicateError(this._errorHandlerService.errorCode(error));
            reject();
          }
        );
      }
    );
  }

  searchFor(no: string, start_page: number): Promise<number> {
    return new Promise<number>(
      ((resolve, reject) => {
        this._fetchPage(start_page).then(
          result => {
            let found_index = -1;
            let at_index = 0;

            this._cachedData.forEach(
              record => {
                if (!isNullOrUndefined(record)) {
                  if (record.no === no) {
                    found_index = at_index;
                  }
                }

                at_index = at_index + 1;
              }
            );

            if (found_index > -1) {
              resolve(found_index);
            } else {
              this.searchFor(no, start_page + 1).then(
                rec_result => {
                  resolve(rec_result);
                }
              );
            }
          }
        );
      })
    );
  }
}
