/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CmmDirectMessageResponse } from '../models/cmm-direct-message-response';
import { directMessage } from '../fn/exi-direct-message-write/direct-message';
import { DirectMessage$Params } from '../fn/exi-direct-message-write/direct-message';

@Injectable({ providedIn: 'root' })
export class ExiDirectMessageWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `directMessage()` */
  static readonly DirectMessagePath = '/api/sys/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `directMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  directMessage$Response(params: DirectMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<CmmDirectMessageResponse>> {
    return directMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `directMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  directMessage(params: DirectMessage$Params, context?: HttpContext): Observable<CmmDirectMessageResponse> {
    return this.directMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<CmmDirectMessageResponse>): CmmDirectMessageResponse => r.body)
    );
  }

}
