/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HlmAbsenceAcceptWriteRequest } from '../../models/hlm-absence-accept-write-request';
import { HlmAbsenceAcceptWriteResponse } from '../../models/hlm-absence-accept-write-response';

export interface Accept$Params {
  user: string;
      body: HlmAbsenceAcceptWriteRequest
}

export function accept(http: HttpClient, rootUrl: string, params: Accept$Params, context?: HttpContext): Observable<StrictHttpResponse<HlmAbsenceAcceptWriteResponse>> {
  const rb = new RequestBuilder(rootUrl, accept.PATH, 'put');
  if (params) {
    rb.path('user', params.user, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<HlmAbsenceAcceptWriteResponse>;
    })
  );
}

accept.PATH = '/api/hld/absence/{user}/accept';
