/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteRelation } from '../fn/job-relation-write/delete-relation';
import { DeleteRelation$Params } from '../fn/job-relation-write/delete-relation';
import { PrmJobRelationDeleteResponse } from '../models/prm-job-relation-delete-response';

@Injectable({ providedIn: 'root' })
export class JobRelationWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deleteRelation()` */
  static readonly DeleteRelationPath = '/api/prj/job/{jobNo}/relation/{relationType}';

  /**
   * DELETE relation for the project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRelation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelation$Response(params: DeleteRelation$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobRelationDeleteResponse>> {
    return deleteRelation(this.http, this.rootUrl, params, context);
  }

  /**
   * DELETE relation for the project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRelation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelation(params: DeleteRelation$Params, context?: HttpContext): Observable<PrmJobRelationDeleteResponse> {
    return this.deleteRelation$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobRelationDeleteResponse>): PrmJobRelationDeleteResponse => r.body)
    );
  }

}
