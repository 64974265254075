<!-- hidden by desing -->
<div class="container-fluid summary_box" *ngIf="false">
  <div class="row summary_row">
    <div class="
        col-6
        d-flex
        align-items-center
        summary_label
        justify-content-start
      ">
      Bonus
    </div>
    <div class="col-6 d-flex align-items-center summary_value justify-content-end" *ngIf="bonus != undefined">
      {{ formatValue(bonus) }}
    </div>
  </div>
</div>
