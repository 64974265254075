<div class="container-fluid summary_box" *ngIf="!isMobile">
  <div class="row summary_header">
    <div class="col-8 d-flex justify-content-start align-items-center">
      {{ title | translate }}
    </div>
    <div class="col-4 d-flex align-items-center justify-content-center" *ngIf="editableFields > 0 && changes">
      <button class="clickable standard-button-action small_button" mat-raised-button (click)="saveChanges()">
        {{ 'label.save' | translate }}
      </button>
    </div>
  </div>
  <div class="row summary_row" *ngFor="let srow of data">
    <div class="col-4 summary_label d-flex align-items-center">
      {{ 'label.site_reporting.result.category.' + srow.item?.key | translate }}
    </div>
    <div [class]="
        'col-8 summary_value d-flex align-items-center ' + srow.item.className
      ">
      {{ formatValue(srow.item) }}
    </div>
  </div>
  <div class="row">
    <div class="col-12 justify-end items-center flex m-0 p-0">
      <span class="text-skin-muted_base text-descriptive ml-1">{{
        update
        }}</span>
      <mat-icon class="text-skin-muted_base cursor-pointer ml-1" (click)="readCategory()">refresh</mat-icon>
    </div>
  </div>
</div>

<div class="container-fluid summary_box" *ngIf="isMobile">
  <div class="row summary_header">
    <div class="col-12 d-flex justify-content-start align-items-center">
      {{ title | translate }}
    </div>
    <div class="col-12 d-flex align-items-center justify-content-center" *ngIf="editableFields > 0 && changes">
      <button class="clickable standard-button-action small_button" mat-raised-button (click)="saveChanges()">
        {{ 'label.save' | translate }}
      </button>
    </div>
  </div>
  <div class="row summary_row" *ngFor="let srow of rows">
    <div class="col-3 summary_label d-flex align-items-center" *ngIf="srow.length >= 1">
      {{
      'label.site_reporting.result.category.' + srow[0].item?.key | translate
      }}
    </div>
    <div class="col-3 summary_value d-flex align-items-center" *ngIf="srow.length >= 1 && srow[0].allowEdit == false">
      {{ formatValue(srow[0].item) }}
    </div>
    <div class="col-3 summary_value d-flex align-items-center cell_with_edit"
      *ngIf="srow.length >= 1 && srow[0].allowEdit == true">
      <input type="text" [id]="_identification.category + '_' + srow[0].item?.key"
        value="{{ formatValue(srow[0].item) }}" class="local_edit" (keyup)="markAsDirty(srow[0].item.key)" />
    </div>
    <div class="col-3 summary_label d-flex align-items-center" *ngIf="srow.length >= 2">
      {{
      'label.site_reporting.result.category.' + srow[1].item?.key | translate
      }}
    </div>
    <div [class]="
        'col-3 summary_value d-flex align-items-center ' +
        srow[1].item.className
      " *ngIf="srow.length >= 2">
      {{ formatValue(srow[1].item) }}
    </div>
  </div>
</div>
