/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PrmResourceListResponse } from '../../models/prm-resource-list-response';

export interface List$Params {
  type?: string;
  technology?: string;
  groupType?: string;
  jobNo?: string;
}

export function list(http: HttpClient, rootUrl: string, params?: List$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmResourceListResponse>> {
  const rb = new RequestBuilder(rootUrl, list.PATH, 'get');
  if (params) {
    rb.query('type', params.type, {});
    rb.query('technology', params.technology, {});
    rb.query('groupType', params.groupType, {});
    rb.query('jobNo', params.jobNo, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PrmResourceListResponse>;
    })
  );
}

list.PATH = '/api/rsc/resource';
