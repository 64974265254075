/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PrmJobTaskReadResponse } from '../../models/prm-job-task-read-response';

export interface ReadJobTasks$Params {
  no: string;
  min: number;
  max: number;
  all?: boolean;
}

export function readJobTasks(http: HttpClient, rootUrl: string, params: ReadJobTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobTaskReadResponse>> {
  const rb = new RequestBuilder(rootUrl, readJobTasks.PATH, 'get');
  if (params) {
    rb.path('no', params.no, {});
    rb.query('min', params.min, {});
    rb.query('max', params.max, {});
    rb.query('all', params.all, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PrmJobTaskReadResponse>;
    })
  );
}

readJobTasks.PATH = '/api/prj/jobtask/{no}';
