import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from './dialog-data';

@Component({
  selector: 'app-user-mail-dialog',
  templateUrl: './user-mail-dialog.component.html',
  styleUrls: ['./user-mail-dialog.component.scss']
})
export class UserMailDialogComponent {
  public mail: string;

  constructor(
    public dialogRef: MatDialogRef<UserMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.mail = data.mail;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.dialogRef.close();
  }
}
