export const ZERO  = '0';
export const ONE   = '1';
export const TWO   = '2';
export const THREE = '3';
export const FOUR  = '4';
export const FIVE  = '5';
export const SIX   = '6';
export const SEVEN = '7';
export const EIGHT = '8';
export const NINE  = '9';
export const DOT   = '.';
export const DEL   = 'del';
export const ENTER = 'enter';
export const ESC   = 'esc';
export const BLANK = '';
