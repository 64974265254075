<div class="container-fluid" style="padding-left: 5px;">
  <div class="row">
    <div class="col-12 v_text_descriptive">
      {{'label.date'|translate}}
    </div>
  </div>
  <div class="row" *ngIf="modification != undefined">
    <div class="col-12 main_information">
      {{modification.value}}
    </div>
  </div>
</div>
