<div class="container-fluid d-flex flex-column no_margins_paddings dialog_window" id="user_select">
  <div class="row no_margins_paddings">
    <div class="col no_margins_paddings">
      <app-top-menu
        [show_logo]="false"
        [show_menu]="false"
        [show_back_button]="true"
        background_color="#ffffff"
        [description]="'label.choose_user' | translate"
        (back_pressed)="onNoClick()"
      >
      </app-top-menu>
      <div class="container-fluid border-top border-bottom" style="height: 70px;">
        <div class="row">
          <div class="col-9">
            <mat-form-field class="input_maximized input_no_border">
              <input matInput placeholder="Login, imię lub nazwisko" [(ngModel)]="noOrName"
                     (keydown.enter)="execute_search()"
                     style="color: var(--color-text-base)"
                     autofocus>
            </mat-form-field>
          </div>
          <div class="col-3 d-flex justify-content-center align-items-center">
            <button cdkFocusInitial class="standard-button-action" mat-button (click)="execute_search()">Szukaj</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row flex-fill no_margins_paddings">
    <div class="col d-flex flex-column no_margins_paddings">
      <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport h-100" #virtualView>
        <div *cdkVirtualFor="let item of ds" class="example-item">
          <div class="container-fluid outer_container clickable" (click)="select_project(item)"
               [ngClass]="{'selected_row':(item?.login == no)}">
            <div class="row" style="padding-left: 15px; padding-right: 15px;">
              <div class="col-11" style="padding: 0; margin: 0; border-bottom: #dee2e6 1px solid;">
                <div class="container-fluid inner_container">
                  <div class="row">
                    <div class="col-12 v_text_descriptive">
                      {{item?.name + ' ' + item?.surname || 'Pobieram...'}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 v_text_header">
                      {{item?.login}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1 d-flex align-items-center justify-content-center"
                   style="padding: 0; margin: 0; border-bottom: #dee2e6 1px solid;"
                   [ngClass]="{'selected_row_inner':(item?.login == no)}">
                <mat-icon *ngIf="(item?.login == no)">check</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  <div class="row no_margins_paddings">
    <div class="col no_margins_paddings">
      <div class="container-fluid standard-button-action clickable" style="height: 60px;"
           [ngClass]="{'standard-button-disabled': (no == '')}" (click)="onYesClick()">
        <div class="row" style="height: 60px;">
          <div class="col-12 d-flex justify-content-center align-items-center">
            {{'label.choose' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



