/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { fetchIncident } from '../fn/incident/fetch-incident';
import { FetchIncident$Params } from '../fn/incident/fetch-incident';
import { FetchIncident200ResponseDto } from '../models/fetch-incident-200-response-dto';
import { listOfIncidents } from '../fn/incident/list-of-incidents';
import { ListOfIncidents$Params } from '../fn/incident/list-of-incidents';
import { ListOfIncidents200ResponseDto } from '../models/list-of-incidents-200-response-dto';
import { pathIncident } from '../fn/incident/path-incident';
import { PathIncident$Params } from '../fn/incident/path-incident';
import { storeIncident } from '../fn/incident/store-incident';
import { StoreIncident$Params } from '../fn/incident/store-incident';
import { StoreIncident200ResponseDto } from '../models/store-incident-200-response-dto';
import { updateIncident } from '../fn/incident/update-incident';
import { UpdateIncident$Params } from '../fn/incident/update-incident';


/**
 * the Incident API
 */
@Injectable({ providedIn: 'root' })
export class IncidentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fetchIncident()` */
  static readonly FetchIncidentPath = '/api/ehs/incident/{incidentId}';

  /**
   * Fetch incident.
   *
   * Endpoint for reading incident.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchIncident()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchIncident$Response(params: FetchIncident$Params, context?: HttpContext): Observable<StrictHttpResponse<FetchIncident200ResponseDto>> {
    return fetchIncident(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch incident.
   *
   * Endpoint for reading incident.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchIncident$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchIncident(params: FetchIncident$Params, context?: HttpContext): Observable<FetchIncident200ResponseDto> {
    return this.fetchIncident$Response(params, context).pipe(
      map((r: StrictHttpResponse<FetchIncident200ResponseDto>): FetchIncident200ResponseDto => r.body)
    );
  }

  /** Path part for operation `updateIncident()` */
  static readonly UpdateIncidentPath = '/api/ehs/incident/{incidentId}';

  /**
   * Update a potentially accident-related incident.
   *
   * Endpoint for updating incident potentially related to accidents.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIncident()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIncident$Response(params: UpdateIncident$Params, context?: HttpContext): Observable<StrictHttpResponse<StoreIncident200ResponseDto>> {
    return updateIncident(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a potentially accident-related incident.
   *
   * Endpoint for updating incident potentially related to accidents.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIncident$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIncident(params: UpdateIncident$Params, context?: HttpContext): Observable<StoreIncident200ResponseDto> {
    return this.updateIncident$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoreIncident200ResponseDto>): StoreIncident200ResponseDto => r.body)
    );
  }

  /** Path part for operation `pathIncident()` */
  static readonly PathIncidentPath = '/api/ehs/incident/{incidentId}';

  /**
   * Update part of the incident.
   *
   * Change incident data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pathIncident()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pathIncident$Response(params: PathIncident$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return pathIncident(this.http, this.rootUrl, params, context);
  }

  /**
   * Update part of the incident.
   *
   * Change incident data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pathIncident$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pathIncident(params: PathIncident$Params, context?: HttpContext): Observable<void> {
    return this.pathIncident$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `listOfIncidents()` */
  static readonly ListOfIncidentsPath = '/api/ehs/incident';

  /**
   * Fetch list of incidents.
   *
   * Endpoint for reading a list of incidents. The Endpoint returns incidents registered by logged user or all - depends on the permission and parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOfIncidents()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOfIncidents$Response(params?: ListOfIncidents$Params, context?: HttpContext): Observable<StrictHttpResponse<ListOfIncidents200ResponseDto>> {
    return listOfIncidents(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch list of incidents.
   *
   * Endpoint for reading a list of incidents. The Endpoint returns incidents registered by logged user or all - depends on the permission and parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOfIncidents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOfIncidents(params?: ListOfIncidents$Params, context?: HttpContext): Observable<ListOfIncidents200ResponseDto> {
    return this.listOfIncidents$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListOfIncidents200ResponseDto>): ListOfIncidents200ResponseDto => r.body)
    );
  }

  /** Path part for operation `storeIncident()` */
  static readonly StoreIncidentPath = '/api/ehs/incident';

  /**
   * Register a potentially accident-related incident.
   *
   * Endpoint for registering incidents potentially related to accidents.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeIncident()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeIncident$Response(params: StoreIncident$Params, context?: HttpContext): Observable<StrictHttpResponse<StoreIncident200ResponseDto>> {
    return storeIncident(this.http, this.rootUrl, params, context);
  }

  /**
   * Register a potentially accident-related incident.
   *
   * Endpoint for registering incidents potentially related to accidents.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeIncident$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeIncident(params: StoreIncident$Params, context?: HttpContext): Observable<StoreIncident200ResponseDto> {
    return this.storeIncident$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoreIncident200ResponseDto>): StoreIncident200ResponseDto => r.body)
    );
  }

}
