<div class="row v_text_descriptive mt-3" *ngIf="maxPage>1">
  <div class="col-12 d-flex justify-content-center align-item-center">
    <div (click)="selectPage(selectedPageEm - 1)" class="clickable v_text_descriptive_blue ">
      {{'label.previous' | translate}}
    </div>

    <div *ngIf="selectedPageEm<= pageRange/2" class="d-flex justify-contetn-center align-item-center clickable">
      <div *ngFor="let item of Arr(pageRange).fill(1), let i = index"   class="px-1 d-flex justify-contetn-center"(click)="selectPage(i + 1)">
        <div [ngClass]="{'selected': i+1 == selectedPageEm}" style="min-width: 14px;">
        {{i + 1}}
        </div>
      </div>
    </div>

    <div *ngIf="selectedPageEm>maxPage - pageRange/2" class="d-flex justify-contetn-center align-item-center clickable" >
      <div *ngFor="let item of Arr(pageRange).fill(1), let i = index"  class="px-1 d-flex justify-contetn-center">
        <div [ngClass]="{'selected': maxPage - pageRange + i + 1 == selectedPageEm}" (click)="selectPage(maxPage - pageRange + i + 1)" style="min-width: 14px;">
          {{maxPage - pageRange + i + 1}}
        </div>
      </div>
    </div>

    <div *ngIf="!(selectedPageEm>maxPage - pageRange/2) && !(selectedPageEm<=pageRange/2)" class="clickable d-flex justify-contetn-center align-item-center" >
      <div *ngFor="let item of Arr(pageRange).fill(1), let i = index"  class="px-1 d-flex justify-contetn-center" >
        <div [ngClass]="{'selected': selectedPageEm + i - 5 == selectedPageEm}" (click)="selectPage(selectedPageEm + i - 5)" style="min-width: 14px;">
          {{selectedPageEm + i - 5}}
        </div>
      </div>
    </div>
    <div (click)="selectPage(selectedPageEm + 1)" class="clickable v_text_descriptive_blue ">
      {{'label.next' | translate}}
    </div>
  </div>
</div>
