import { Component, Inject, Injector, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { TranslateService } from '@ngx-translate/core';
import { UserCookieStorage } from './core/service/user/user-cookie-storage';
import { StateComponent } from './core/state/state.component';
import { CookieService } from 'ngx-cookie-service';
import { SET_LANGUAGE } from './core/state/reducers/language.reducer';
import { AppState } from './core/state/app-state';
import { Store } from '@ngrx/store';
import { UpdatesService } from './core/service/update.service';
import { LocationStrategy } from '@angular/common';
import { NavigationService } from './shared/navigation.service';
import { DictionaryServiceService } from './shared/dictionary-service.service';
import { User } from './core/service/user/user';
import { GlobalConstants } from './shared/global-constants';
import { TranslationReadService } from './api/cmm/services/translation-read.service';
import { CmmTranslationResponse } from './api/cmm/models';
import { SnackMessageService } from './core/service/snack-message.service';
import { ErrorHandlerService } from './core/service/error-handler.service';
import {
  GS_SET_CONFIG,
  GS_SET_CURRENCY,
} from './core/state/reducers/global-settings.reducer';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { PermissionService } from './api/acc/services';
import { AcmPermissionsReadResponse } from './api/acc/models';
import { PERMISSION_REGISTER } from './core/state/reducers/permission.reducer';
import { SystemConfigurationReadService } from './api/opp/services';

import { SystemConfiguration } from './core/state/configuration.model';
import { OpmQuerySystemConfigurationReadResponse } from './api/opp/models';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'applicationv_outside';
  showMenu = false;
  configShowMenu = false;
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _translate: TranslateService,
    private _injector: Injector,
    private store: Store<AppState>,
    private _cookieService: CookieService,
    private _updates: UpdatesService,
    private location: LocationStrategy,
    private navigationService: NavigationService,
    private dictionaryService: DictionaryServiceService,
    private _snackMessage: SnackMessageService,
    private _errorHandler: ErrorHandlerService,
    private _translation: TranslationReadService,
    private _sysConfiguration: SystemConfigurationReadService,
    private _keycloakService: KeycloakService,
    private _accountPermission: PermissionService,
  ) {
    this.store.select('language').subscribe((lng: string) => {
      this._translate.use(lng);
    });

    var object = this;
    this.store
      .select('configuration')
      .subscribe((configuration: SystemConfiguration) => {
        this.configShowMenu = configuration.showMenu();
      });

    this.location.onPopState(() => {
      // set isBackButtonClicked to true.
      this.navigationService.setBackClicked(true);
      return false;
    });

    this.store.select('user').subscribe((user: User) => {
      GlobalConstants.user = user;
      this.showMenu = false;
      if (user.logged()) {
        this.showMenu = true && this.configShowMenu;
        this._accountPermission.permissions().subscribe(
          (result: AcmPermissionsReadResponse) => {
            this.store.dispatch({
              type: PERMISSION_REGISTER,
              payload: {
                permissions: result.permissions,
              },
            });
          },
          (error: any) => {
            this._snackMessage.communicateError(
              this._errorHandler.errorCode(error),
            );
          },
        );
      }
    });

    this.dictionaryService.initialize();
  }

  iconsOnly: boolean = true;
  ngOnInit(): void {
    this.selectLang('pl');
    this.getConfiguration();
    this._updates.checkForUpdate();
  }

  iconOnly(event) {
    this.iconsOnly = event;
  }

  private initializeApplication(): void {
    new UserCookieStorage(this._injector).restore();
    const state = new StateComponent(this.store, this._injector);
    state.init();
  }

  private initializeConfiguation(result: SystemConfiguration): void {
    this.store.dispatch({
      type: GS_SET_CONFIG,
      payload: {
        configuration: result,
      },
    });
    this.storage.set('configuration', result);
  }

  private initializeLanguage(result: any, language: string): void {
    this._translate.setTranslation(language, result.labels);
    this.store.dispatch({
      type: GS_SET_CURRENCY,
      payload: {
        currency: result.labels['settings.currency'],
      },
    });
    this.storage.set('language_cache_' + language, result);
    let calculated_language = result.labels['settings.language'];

    if (calculated_language === null || calculated_language === undefined) {
      calculated_language = language;
    }

    this.store.dispatch({
      type: SET_LANGUAGE,
      payload: {
        language: calculated_language,
      },
    });
  }

  private getConfiguration() {
    this._sysConfiguration
      .readSystemConfigurationsUsingGET({
        keys: ['timesheet_additional', 'work_report'],
      })
      .subscribe(
        (result: OpmQuerySystemConfigurationReadResponse) => {
          const systemConfiguration = new SystemConfiguration();

          systemConfiguration.report.show_foreman =
            result.configuration['work_report'].values.show_foreman === 'true';
          systemConfiguration.timesheet.show_foreman =
            result.configuration['timesheet_additional'].values.show_foreman ===
            'true';

          this.initializeConfiguation(systemConfiguration);
        },
        (error: any) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error),
          );
        },
      );
  }

  private selectLang(lang: string) {
    const language_cache = this.storage.get('language_cache_' + lang);

    if (language_cache !== null && language_cache !== undefined) {
      this.initializeLanguage(language_cache, lang);
    }

    this._translate.setDefaultLang(lang);
    this._translation
      .readTranslationUsingGET({
        language: lang,
      })
      .subscribe(
        (result: CmmTranslationResponse) => {
          this.initializeLanguage(result, lang);
          this.initializeApplication();
        },
        (error: any) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error),
          );
        },
      );
    // this._translate.setTranslation(LANG_PL_NAME, LANG_PL_TRANS);

    const storedLng = this._cookieService.get('USER_LNG');
    let evaluatedLng = lang;

    if (!isNullOrUndefined(storedLng) && storedLng !== '') {
      evaluatedLng = storedLng;
    }

    // evaluatedLng
    evaluatedLng = 'pl';
    this.store.dispatch({
      type: SET_LANGUAGE,
      payload: {
        language: evaluatedLng,
      },
    });
  }
}

export interface Food {
  value: string;
  viewValue: string;
}
