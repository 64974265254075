import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-diary",
  templateUrl: "./diary.component.html",
  styleUrls: ["./diary.component.scss"],
})
export class DiaryComponent implements OnInit {
  public open = true;
  public show_list = true;
  public show_edit = false;
  @Input() jobNo: string = null;
  @Input() incidentId: string = null;
  @Input() editable = true;
  @Output() entryModified: EventEmitter<string> = new EventEmitter();

  constructor() {
    //
  }

  ngOnInit(): void {
    //
  }

  public onEntryModified(job: string): void {
    this.entryModified.emit(job);
  }
}
