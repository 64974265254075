<table class="main_container v_frame_all">
  <tr class="tab_row stretch_mobile">
    <td class="header_value mobile_date" width="50px">
      <app-sort-indicator
        [direction]="direction"
        [key]="'date'"
        [label]="'label.diary.date' | translate"
        (sortChanged)="sortChanged($event)"
      ></app-sort-indicator>
    </td>
    <td *ngIf="!isMobile" class="header_value mobile_fill">{{ 'label.diary.description' | translate }}</td>
    <td *ngIf="isMobile && control_mode == 'new'"
      class="cell_value cell_box no_side_border"
      width="120px;"
      style="padding-left: 5px; padding-right: 5px"
    >
      <app-month-selector
        [fullDateSelector]="true"
        [backgroundColor]="'#f6f6f9'"
        [fontSize]="'15'"
        (dataChanged)="dateChanged($event)"
      ></app-month-selector>
    </td>
    <td class="header_value mobile_new no_left_border_sm_mob" width="50px;" *ngIf="editable && control_mode != 'new'">
      <div
        class="clickable light_blue_button_noborder"
        (click)="create_new_entry()"
        style="margin-left: 10px; margin-right: 10px"
        *ngIf="(control_mode == 'view' && allow_edit && !_incidentId) || (control_mode == 'view' && can_comment && !_jobNo)"
      >
        +
      </div>
    </td>
  </tr>
  <tr class="tab_row" *ngIf="control_mode == 'new'">
    <td *ngIf="!isMobile"
      class="cell_value cell_box"
      width="50px;"
      style="padding-left: 5px; padding-right: 5px"
    >
      <app-month-selector
        [fullDateSelector]="true"
        [backgroundColor]="'#f6f6f9'"
        [fontSize]="'15'"
        (dataChanged)="dateChanged($event)"
      ></app-month-selector>
    </td>
    <td class="cell_value mobile_fill">
      <textarea
        matInput
        style="width: 100%; background-color: #ffffff"
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="10"
        *ngIf="new_entry != undefined"
        class="v_frame_all"
        [(ngModel)]="new_entry.entry.message"
      ></textarea>
    </td>
    <td class="cell_value action_column message_mobile fixed_desktop_width">
      <button class="standard-button-cancel" mat-button (click)="cancel_new()">
        {{ 'label.cancel' | translate }}
      </button>
      <button
        class="standard-button-action"
        mat-button
        (click)="save_new()"
        [disabled]="
          new_entry.entry.message == undefined || new_entry.entry.message == ''
        "
      >
        {{ 'label.save' | translate }}
      </button>
    </td>
  </tr>
  <tr class="tab_row" *ngFor="let entry of entries">
    <td
      class="cell_value cell_box"
      width="50px;"
      style="padding-left: 5px; padding-right: 5px"
    >
      <span
        *ngIf="
          control_mode == 'view' ||
          entry.entry.uid != entry_in_edit ||
          control_mode == 'delete'
        "
        >{{ formatDate(entry.entry.created) }}</span
      >
      <app-month-selector
        *ngIf="control_mode == 'edit' && entry.entry.uid == entry_in_edit"
        [fullDateSelector]="true"
        [backgroundColor]="'#f6f6f9'"
        [fontSize]="'15'"
        [initialDate]="updated_entry.entry.created"
        (dataChanged)="dateChangedForUpdate($event)"
      ></app-month-selector>
      <span class="text_standard">
        {{ entry.entry.author }}
      </span>
    </td>
    <td *ngIf="!isMobile" [class]="control_mode == 'edit' ? 'comment_cell_value' : ''">
      <span
        *ngIf="
          control_mode == 'view' ||
          entry.entry.uid != entry_in_edit ||
          control_mode == 'delete'
        "
        [innerHtml]="entry.formatted_message"
      ></span>
      <textarea
        matInput
        style="width: 100%; background-color: #ffffff"
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="10"
        class="v_frame_all"
        *ngIf="control_mode == 'edit' && entry.entry.uid == entry_in_edit"
        [(ngModel)]="updated_entry.entry.message"
      ></textarea>
    </td>
    <td class="cell_value action_column" *ngIf="editable">
      <table>
        <tr>
          <td>
            <div
              class="clickable mat_icon_blue"
              (click)="edit_entry(entry.entry)"
              *ngIf="(control_mode == 'view' && allow_edit && !_incidentId) || (control_mode == 'view' && can_comment && !_jobNo)"
            >
              <mat-icon>edit</mat-icon>
            </div>
          </td>
          <td>
            <div
              class="clickable mat_icon_blue"
              (click)="delete_entry(entry.entry)"
              *ngIf="control_mode == 'view' && allow_edit && !_incidentId" 
            >
              <mat-icon>delete</mat-icon>
            </div>
          </td>
        </tr>
      </table>
      <button
        class="standard-button-cancel"
        mat-button
        (click)="cancel_edit()"
        *ngIf="entry.entry.uid == entry_in_edit && control_mode == 'edit'"
      >
        {{ 'label.cancel' | translate }}
      </button>
      <button
        class="standard-button-action"
        mat-button
        (click)="save_entry(entry.entry)"
        [disabled]="
          updated_entry.entry.message == undefined ||
          updated_entry.entry.message == ''
        "
        *ngIf="entry.entry.uid == entry_in_edit && control_mode == 'edit'"
      >
        {{ 'label.save' | translate }}
      </button>
      <table
        *ngIf="control_mode == 'delete' && entry.entry.uid == entry_in_edit"
        style="width: 100%"
      >
        <tr>
          <td colspan="2" style="text-align: center">
            {{ 'message.confirm.delete' | translate }}
          </td>
        </tr>
        <tr>
          <td style="text-align: center">
            <button
              class="standard-button-action"
              mat-button
              (click)="delete_entry_canceled()"
              *ngIf="entry.entry.uid == entry_in_edit"
            >
              {{ 'label.no' | translate }}
            </button>
          </td>
          <td style="text-align: center">
            <button
              class="standard-button-cancel-red"
              mat-button
              (click)="delete_entry_confirmed(entry.entry)"
              *ngIf="entry.entry.uid == entry_in_edit"
            >
              {{ 'label.yes' | translate }}
            </button>
          </td>
        </tr>
      </table>
    </td>
    <td *ngIf="isMobile" class="message_mobile">
      <span
        *ngIf="
          control_mode == 'view' ||
          entry.entry.uid != entry_in_edit ||
          control_mode == 'delete'
        "
        [innerHtml]="entry.formatted_message"
      ></span>
      <textarea
        matInput
        style="width: 100%; background-color: #ffffff"
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="10"
        class="v_frame_all"
        *ngIf="control_mode == 'edit' && entry.entry.uid == entry_in_edit"
        [(ngModel)]="updated_entry.entry.message"
      ></textarea>
    </td>

  </tr>
  <tr class="tab_row" *ngIf="entries !== undefined && entries.length == 0">
    <td class="cell_value" colspan="3" style="text-align: center">
      {{ 'message.nodata' | translate }}
    </td>
  </tr>
</table>
