import { SystemConfiguration } from './configuration.model';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { session } from './reducers/session.reducer';
import { version } from './reducers/version.reducer';
import { StoreModule } from '@ngrx/store';
import { token } from './reducers/token.reducer';
import { CookieService } from 'ngx-cookie-service';
import { language } from './reducers/language.reducer';
import { error } from './reducers/error.reducer';
import { Config } from './reducers/configuration.reducer';
import { user } from './reducers/user.reducer';
import { calendar } from './reducers/calendar.reducer';
import { project } from './reducers/project.reducer';
import { viewdate } from './reducers/viewdate.reducer';
import { resource } from './reducers/resource.reducer';
import { absenceaccept } from './reducers/absenceaccept.reducer';
import { global_settings } from './reducers/global-settings.reducer';
import { permission } from './reducers/permission.reducer';
import { contexthelp } from './reducers/contexthelp.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({
      session: session,
      version: version,
      token: token,
      language: language,
      contexthelp: contexthelp,
      calendar: calendar,
      error: error,
      user: user,
      viewdate: viewdate,
      project: project,
      resource: resource,
      absenceaccept: absenceaccept,
      configuration: Config,
      global_settings: global_settings,
      permission: permission,
    }),
  ],
  providers: [CookieService],
  declarations: [],
  exports: [],
  bootstrap: [],
})
export class StateModule {}
