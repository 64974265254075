import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { isNullOrUndefined } from "src/app/shared/utilities";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-sort-indicator",
  templateUrl: "./sort-indicator.component.html",
  styleUrls: ["./sort-indicator.component.scss"],
})
export class SortIndicatorComponent implements OnInit {
  private _direction = "none";
  @Input() key: string;
  @Input() allowSort: boolean = true;
  @Input() label: string;
  @Output() sortChanged: EventEmitter<SortDetails> = new EventEmitter();
  public current: SortDetails;
  private _cookieService: CookieService;

  constructor(_cookieService: CookieService) {
    this._cookieService = _cookieService;
  }

  ngOnInit() {
    this.current = new SortDetails(this.key, this._direction);
  }

  get direction(): any {
    return this._direction;
  }

  @Input()
  set direction(val: any) {
    if (this._direction !== val) {
      this._direction = val;
      this.current = new SortDetails(this.key, val);
    }
  }

  public changeSort(direction: string): void {
    this.current = new SortDetails(this.key, direction);

    if (!isNullOrUndefined(this.sortChanged)) {
      this.sortChanged.emit(this.current);
      this._cookieService.set("project_accept_list_sort_key", this.key, 7);
      this._cookieService.set(
        "project_accept_list_sort_direction",
        direction,
        7
      );
    }
  }
}

export class SortDetails {
  constructor(key: string, defaultDirection: string) {
    this.key = key;
    this.direction = defaultDirection;
  }

  public key: string;
  public direction: string;
}
