/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { StoreCommentRequestDto } from '../../models/store-comment-request-dto';
import { StoreIncident200ResponseDto } from '../../models/store-incident-200-response-dto';

export interface StoreComment$Params {

/**
 * Identification of incident
 */
  incidentId: string;
      body: StoreCommentRequestDto
}

export function storeComment(http: HttpClient, rootUrl: string, params: StoreComment$Params, context?: HttpContext): Observable<StrictHttpResponse<StoreIncident200ResponseDto>> {
  const rb = new RequestBuilder(rootUrl, storeComment.PATH, 'post');
  if (params) {
    rb.path('incidentId', params.incidentId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<StoreIncident200ResponseDto>;
    })
  );
}

storeComment.PATH = '/api/ehs/incident/{incidentId}/comment';
