import { APP_INITIALIZER } from '@angular/core';
import { AddEditProjectDialogComponent } from './shared/add-edit-project-dialog/add-edit-project-dialog.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {
  BrowserModule,
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { forwardRef, NgModule, Provider } from '@angular/core';
import localePl from '@angular/common/locales/pl';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { initializer_new } from './app.init';
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ApiModule as AccApiModule } from './api/acc/api.module';
import { ApiModule as DicApiModule } from './api/dic/api.module';
import { ApiModule as CmmApiModule } from './api/cmm/api.module';
import { ApiModule as PrjApiModule } from './api/prj/api.module';
import { ApiModule as TmsApiModule } from './api/tms/api.module';
import { ApiModule as HldApiModule } from './api/hld/api.module';
import { ApiModule as DcmApiModule } from './api/dcm/api.module';
import { ApiModule as ExcApiModule } from './api/exc/api.module';
import { ApiModule as EhsApiModule } from './api/ehs/api.module';
import { ApiModule as NavApiModule } from './api/nav/api.module';
import { ApiModule as OppApiModule } from './api/opp/api.module';
import { ApiModule as RepApiModule } from './api/rep/api.module';
import { ApiModule as TskApiModule } from './api/tsk/api.module';
import { ApiModule as InvApiModule } from './api/inv/api.module';
import { ApiModule as CtxApiModule } from './api/ctx/api.module';
import { ApiModule as CfgApiModule } from './api/cfg/api.module';
import { StateModule } from './core/state/state.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnackMessageService } from './core/service/snack-message.service';
import { ErrorHandlerService } from './core/service/error-handler.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { UpdatesService } from './core/service/update.service';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MatSelectFilterModule } from 'mat-select-filter';

import {
  SchemaFormModule,
  WidgetRegistry,
  DefaultWidgetRegistry,
} from 'ngx-schema-form';
import * as Hammer from 'hammerjs';
import { MatPaginatorModule } from '@angular/material/paginator';
// tslint:disable-next-line:max-line-length
import { BannerComponent } from './pages/home-page/banner/banner.component';

import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { AuthGuard } from './core/auth/auth-guard';
import { UserProfile } from './core/service/user-profile.service';
import { TopMenuModule } from './pages/top-menu/top-menu.module';
import { FooterModule } from './pages/footer/footer.module';
import { ApiInterceptorService } from './shared/api-interceptor.service';
import { NavigationGuard } from './shared/navigation.guard';
import { ComponentsModule } from './components/components.module';
import { SharedModule } from './shared/shared.module';
import { NumericInputModule } from './components/numeric-keyboard';
import { SearchProjectDialogComponent } from './shared/search-project-dialog/search-project-dialog.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { YesnocancelDialogComponent } from './shared/yesnocancel-dialog/yesnocancel-dialog.component';
import { OkDialogComponent } from './shared/ok-dialog/ok-dialog.component';
import { UserSelectDialogComponent } from './shared/user-select-dialog/user-select-dialog.component';
import { UserMailDialogComponent } from './shared/user-mail-dialog/user-mail-dialog.component';
import { SelectMonthDialogComponent } from './shared/select-month-dialog/select-month-dialog.component';
import { DataActionEditJobJournalComponent } from './pages/site-reporting/site-reporting-tab-data/data-action-edit-job-journal/data-action-edit-job-journal.component';
import { InventoryTransferComponent } from './pages/inventory-page/inventory-item/inventory-transfer/inventory-transfer.component';


registerLocaleData(localePl);

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptorService),
  multi: true,
};

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AddEditProjectDialogComponent,
    BannerComponent,
    SidebarMenuComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    SearchProjectDialogComponent,
    AddEditProjectDialogComponent,
    YesnocancelDialogComponent,
    OkDialogComponent,
    UserSelectDialogComponent,
    UserMailDialogComponent,
    SelectMonthDialogComponent,
    DataActionEditJobJournalComponent,
    InventoryTransferComponent
  ],
  imports: [
    TopMenuModule,
    FooterModule,
    KeycloakAngularModule,
    SchemaFormModule.forRoot(),
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/acc/logs',
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
    }),
    ScrollingModule,
    StorageServiceModule,
    AccApiModule.forRoot({ rootUrl: '' }),
    DicApiModule.forRoot({ rootUrl: '' }),
    CmmApiModule.forRoot({ rootUrl: '' }),
    PrjApiModule.forRoot({ rootUrl: '' }),
    TmsApiModule.forRoot({ rootUrl: '' }),
    HldApiModule.forRoot({ rootUrl: '' }),
    DcmApiModule.forRoot({ rootUrl: '' }),
    ExcApiModule.forRoot({ rootUrl: '' }),
    EhsApiModule.forRoot({ rootUrl: '' }),
    NavApiModule.forRoot({ rootUrl: '' }),
    OppApiModule.forRoot({ rootUrl: '' }),
    RepApiModule.forRoot({ rootUrl: '' }),
    TskApiModule.forRoot({ rootUrl: '' }),
    InvApiModule.forRoot({ rootUrl: '' }),
    CtxApiModule.forRoot({ rootUrl: '' }),
    CfgApiModule.forRoot({ rootUrl: '' }),
    BrowserModule,
    StateModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    HammerModule,
    MatButtonModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(
      [
        {
          path: '',
          component: HomePageComponent,
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        {
          path: 'help',
          loadChildren: () =>
            import('./pages/help-page/help-page.module').then(
              (m) => m.HelpPageModule,
            ),
          canDeactivate: [NavigationGuard],
        },
        {
          path: 'login',
          loadChildren: () =>
            import('./pages/login-page/login-page.module').then(
              (m) => m.LoginPageModule,
            ),
          canDeactivate: [NavigationGuard],
        },
        {
          path: 'holiday',
          loadChildren: () =>
            import('./pages/holiday/holiday.module').then(
              (m) => m.HolidayModule,
            ),
          canActivate: [AuthGuard],
          canDeactivate: [NavigationGuard],
        },
        {
          path: 'report',
          loadChildren: () =>
            import('./pages/report-page/report-page.module').then(
              (m) => m.ReportPageModule,
            ),
          canActivate: [AuthGuard],
          canDeactivate: [NavigationGuard],
        },
        {
          path: 'administration/home',
          loadChildren: () =>
            import('./pages/administration/administration.module').then(
              (m) => m.AdministrationModule,
            ),
          canActivate: [AuthGuard],
          canDeactivate: [NavigationGuard],
        },
        {
          path: 'hr',
          loadChildren: () =>
            import('./pages/hr/hr.module').then(
              (m) => m.HrModule,
            ),
          canActivate: [AuthGuard],
          canDeactivate: [NavigationGuard],
        },
        {
          path: 'ehs',
          loadChildren: () =>
            import('./pages/ehs/ehs.module').then(
              (m) => m.EhsModule,
            ),
          canActivate: [AuthGuard],
          canDeactivate: [NavigationGuard],
        },
        {
          path: 'project/home',
          loadChildren: () =>
            import('./pages/project-page/project-page.module').then(
              (m) => m.ProjectPageModule,
            ),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        
        {
          path: 'task',
          loadChildren: () =>
            import('./pages/tasks-page/task-page.module').then(
              (m) => m.TaskPageModule,
            ),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        {
          path: 'voo',
          loadChildren: () =>
            import('./pages/volume-of-orders/volume-of-orders.module').then(
              (m) => m.VolumeOfOrdersModule,
            ),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },

        {
          path: 'project/accept',
          loadChildren: () =>
            import(
              './pages/project-accept-page/project-accept-page.module'
            ).then((m) => m.ProjectAcceptPageModule),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        {
          path: 'timesheet/entry',
          loadChildren: () =>
            import('./pages/timesheet/timesheet.module').then(
              (m) => m.TimesheetModule,
            ),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        {
          path: 'work/standard',
          loadChildren: () =>
            import(
              './pages/work-item-standard-page/work-item-standard-page.module'
            ).then((m) => m.WorkItemStandardPageModule),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        {
          path: 'work/additional',
          loadChildren: () =>
            import(
              './pages/work-item-additional-page/work-item-additional-page.module'
            ).then((m) => m.WorkItemAdditionalPageModule),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        {
          path: 'operations',
          loadChildren: () =>
            import('./pages/operations/operations.module').then(
              (m) => m.OperationsModule,
            ),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        {
          path: 'reports',
          loadChildren: () =>
            import('./pages/reports/reports.module').then(
              (m) => m.ReportsModule,
            ),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        {
          path: 'message',
          loadChildren: () =>
            import('./pages/messages/messages.module').then(
              (m) => m.MessagesModule,
            ),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
        {
          path: 'inventory',
          loadChildren: () =>
            import('./pages/inventory-page/inventory.module').then(
              (m) => m.InventoryModule,
            ),
          canDeactivate: [NavigationGuard],
          canActivate: [AuthGuard],
        },
      ],
      { useHash: false },
    ),
    MatCardModule,
    MatIconModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
    }),
    ReactiveFormsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSlideToggleModule,
    MatTableModule,
    FormsModule,
    MatRadioModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSelectFilterModule,
    ComponentsModule,
    SharedModule,
    NumericInputModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer_new,
      deps: [KeycloakService, UserProfile],
      multi: true,
    },
    { provide: WidgetRegistry, useClass: DefaultWidgetRegistry },
    SnackMessageService,
    UserProfile,
    ErrorHandlerService,
    ApiInterceptorService,
    UpdatesService,
    // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]}, why this
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    API_INTERCEPTOR_PROVIDER,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
