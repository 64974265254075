/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { NvmQueryReportSiteTabResponse } from '../models/nvm-query-report-site-tab-response';
import { tabRead } from '../fn/report-site-tab-read/tab-read';
import { TabRead$Params } from '../fn/report-site-tab-read/tab-read';

@Injectable({ providedIn: 'root' })
export class ReportSiteTabReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tabRead()` */
  static readonly TabReadPath = '/api/nav/tab/{jobNo}/{category}';

  /**
   * Read configuration and content for the tab.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tabRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  tabRead$Response(params: TabRead$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryReportSiteTabResponse>> {
    return tabRead(this.http, this.rootUrl, params, context);
  }

  /**
   * Read configuration and content for the tab.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tabRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tabRead(params: TabRead$Params, context?: HttpContext): Observable<NvmQueryReportSiteTabResponse> {
    return this.tabRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryReportSiteTabResponse>): NvmQueryReportSiteTabResponse => r.body)
    );
  }

}
