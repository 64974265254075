import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackMessageService {
  constructor(
    private _snackBar: MatSnackBar,
    private _translate: TranslateService,
  ) { }

  private communicationFailed(): void {
    this._translate
      .get('message.communication.failed')
      .subscribe((translation: string) => {
        this._snackBar.open(translation, '', {
          duration: 5000,
          panelClass: ['red-snackbar'],
        });
      });
  }

  communicateSuccess(key: string) {
    this._translate.get(key).subscribe((translation: string) => {
      this._snackBar.open(translation, '', {
        duration: 5000,
        panelClass: ['green-snackbar'],
      });
    });
  }

  communicateSuccessWithComment(key: string, comment: string) {
    this._translate.get(key).subscribe((translation: string) => {
      this._snackBar.open(translation + ' ' + comment, '', {
        duration: 5000,
        panelClass: ['green-snackbar'],
      });
    });
  }

  communicateError(key: string, description?: string) {
    if (description === null || description === undefined) {
      description = '';
    }

    if (description.length > 0) {
      description = ' (' + description + ')';
    }

    if (key === 'UNKNOWN_CODE') {
      this.communicationFailed();
    } else {
      this._translate.get(key).subscribe((translation: string) => {
        this._snackBar.open(translation + description, '', {
          duration: 5000,
          panelClass: ['red-snackbar'],
        });
      });
    }
  }
}
