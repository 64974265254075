/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createJobGroup } from '../fn/pra-job-group-create/create-job-group';
import { CreateJobGroup$Params } from '../fn/pra-job-group-create/create-job-group';
import { PrmJobGroupCreateResponse } from '../models/prm-job-group-create-response';

@Injectable({ providedIn: 'root' })
export class PraJobGroupCreateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createJobGroup()` */
  static readonly CreateJobGroupPath = '/api/prj/job/group';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createJobGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createJobGroup$Response(params: CreateJobGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobGroupCreateResponse>> {
    return createJobGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createJobGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createJobGroup(params: CreateJobGroup$Params, context?: HttpContext): Observable<PrmJobGroupCreateResponse> {
    return this.createJobGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobGroupCreateResponse>): PrmJobGroupCreateResponse => r.body)
    );
  }

}
