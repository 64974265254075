<h1 mat-dialog-title class="v_text_header">{{data.title}}</h1>
<div mat-dialog-content>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-0">
      <div class="col-12 m-0 p-0 d-flex justify-content-start align-items-center v_field_label_theme">{{data.content}}</div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-12 m-0 p-0 d-flex justify-content-start align-items-center" style="padding-bottom: 10px;">
        <mat-form-field class="input_maximized">
          <input matInput [(ngModel)]="mail">
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" style="padding-bottom: 15px; padding-top: 15px;">
  <div class="row" mat-dialog-actions>
    <div class="col-6 d-flex justify-content-end align-items-center">
      <button class="standard-button-cancel" mat-button (click)="onNoClick()">{{'label.cancel' | translate }}</button>
    </div>
    <div class="col-6 d-flex justify-content-start align-items-center">
      <button class="standard-button-action" mat-button [mat-dialog-close]="mail" cdkFocusInitial>{{'label.send'| translate}}</button>
    </div>
  </div>
</div>
