/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ResourceCostWriteService } from './services/resource-cost-write.service';
import { VacancyLimitsService } from './services/vacancy-limits.service';
import { ImportWriteService } from './services/import-write.service';
import { ExportWriteService } from './services/export-write.service';
import { CalendarWriteService } from './services/calendar-write.service';
import { ExiDirectMessageReadService } from './services/exi-direct-message-read.service';
import { ExiDirectMessageWriteService } from './services/exi-direct-message-write.service';
import { ExaCommandImportJobService } from './services/exa-command-import-job.service';
import { FileWriteService } from './services/file-write.service';
import { ConfigurationWriteService } from './services/configuration-write.service';
import { ResourceCostReadService } from './services/resource-cost-read.service';
import { FileReadService } from './services/file-read.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ResourceCostWriteService,
    VacancyLimitsService,
    ImportWriteService,
    ExportWriteService,
    CalendarWriteService,
    ExiDirectMessageReadService,
    ExiDirectMessageWriteService,
    ExaCommandImportJobService,
    FileWriteService,
    ConfigurationWriteService,
    ResourceCostReadService,
    FileReadService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
