/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExmCommandImportJobResponse } from '../models/exm-command-import-job-response';
import { importJob } from '../fn/exa-command-import-job/import-job';
import { ImportJob$Params } from '../fn/exa-command-import-job/import-job';

@Injectable({ providedIn: 'root' })
export class ExaCommandImportJobService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `importJob()` */
  static readonly ImportJobPath = '/api/exp/import/job';

  /**
   * Import list of jobs to the system. If the job is already registered it will be updated.  The import component will check if the import has been already done by checking the md5 sum of the imported data with lastly received informations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importJob()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importJob$Response(params: ImportJob$Params, context?: HttpContext): Observable<StrictHttpResponse<ExmCommandImportJobResponse>> {
    return importJob(this.http, this.rootUrl, params, context);
  }

  /**
   * Import list of jobs to the system. If the job is already registered it will be updated.  The import component will check if the import has been already done by checking the md5 sum of the imported data with lastly received informations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importJob$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importJob(params: ImportJob$Params, context?: HttpContext): Observable<ExmCommandImportJobResponse> {
    return this.importJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExmCommandImportJobResponse>): ExmCommandImportJobResponse => r.body)
    );
  }

}
