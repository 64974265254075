/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CmmNotificationWriteResponse } from '../models/cmm-notification-write-response';
import { CmmNotificationWriteRequest } from '../models/cmm-notification-write-request';

/**
 * Cma Notification Write
 */
@Injectable({
  providedIn: 'root',
})
class NotificationWriteService extends __BaseService {
  static readonly createNotificationUsingPOSTPath = '/api/cmm/notification';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * createNotification
   * @param params The `NotificationWriteService.CreateNotificationUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `header`: header
   *
   * @return OK
   */
  createNotificationUsingPOSTResponse(params: NotificationWriteService.CreateNotificationUsingPOSTParams): __Observable<__StrictHttpResponse<CmmNotificationWriteResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/cmm/notification`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CmmNotificationWriteResponse>;
      })
    );
  }
  /**
   * createNotification
   * @param params The `NotificationWriteService.CreateNotificationUsingPOSTParams` containing the following parameters:
   *
   * - `request`: request
   *
   * - `header`: header
   *
   * @return OK
   */
  createNotificationUsingPOST(params: NotificationWriteService.CreateNotificationUsingPOSTParams): __Observable<CmmNotificationWriteResponse> {
    return this.createNotificationUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as CmmNotificationWriteResponse)
    );
  }
}

module NotificationWriteService {

  /**
   * Parameters for createNotificationUsingPOST
   */
  export interface CreateNotificationUsingPOSTParams {

    /**
     * request
     */
    request: CmmNotificationWriteRequest;

    /**
     * header
     */
    header: string;
  }
}

export { NotificationWriteService }
