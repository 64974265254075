/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { formReport } from '../fn/report-read/form-report';
import { FormReport$Params } from '../fn/report-read/form-report';
import { NvmQuerySiteReportResponse } from '../models/nvm-query-site-report-response';

@Injectable({ providedIn: 'root' })
export class ReportReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formReport()` */
  static readonly FormReportPath = '/api/nav/form/{formId}';

  /**
   * Return form definition.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  formReport$Response(params: FormReport$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQuerySiteReportResponse>> {
    return formReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Return form definition.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formReport(params: FormReport$Params, context?: HttpContext): Observable<NvmQuerySiteReportResponse> {
    return this.formReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQuerySiteReportResponse>): NvmQuerySiteReportResponse => r.body)
    );
  }

}
