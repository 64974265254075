<div class="container-fluid calendar_view_adjust bg-skin-header" [ngClass]="{
    'bg-skin-base': !transparent,
    'bg-skin-application': transparent
  }" [style.height]="'70px'">
  <div class="row" style="height: 70px">
    <div class="col-2 d-flex align-items-center justify-content-start" *ngIf="show_logo || show_back_button || back_route">
      <a routerLink="/" *ngIf="show_logo && showLogoConfig"><img src="/assets/logo.png" class="logo"
          style="height: 42px" alt="" /></a>
      <a [routerLink]="back_route" *ngIf="!show_logo && back_route" class="v_text_descriptive clickable"
        style="font-size: 18px; color: var(--color-theme-700) !important">
        <mat-icon>navigate_before</mat-icon>{{ 'label.back' | translate }}</a>
      <a (click)="emit_back()" *ngIf="show_back_button && !back_route" class="v_text_descriptive clickable"
        style="font-size: 18px; color: var(--color-theme-700) !important">
        <mat-icon>navigate_before</mat-icon>{{ 'label.back' | translate }}</a>
    </div>

    <div class=" d-flex flex-col justify-content-center align-items-center" 
    [ngClass]="{
      'col-8': show_logo || show_back_button || back_route,
      'col-12': !(show_logo || show_back_button || back_route)}">
      <span class="v_text_header text-skin-base cut_text_dots w-full text-center">{{
        description | translate
        }}</span>
      <span *ngIf="projectDetails" class="p-1 text-sm" style="white-space: nowrap">{{ projectDetails?.number }}: {{
        projectDetails?.site }}</span>
    </div>

    <div class="col-2 d-flex justify-content-end align-items-center" *ngIf="show_menu">
      <button [matMenuTriggerFor]="menu" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/">
          <span>Home</span>
        </button>
        <button mat-menu-item routerLink="/help">
          <span>{{ 'label.help' | translate }}</span>
        </button>
        <button (click)="updateApp()" mat-menu-item routerLink="/">
          <span>{{ 'label.update' | translate }}</span>
        </button>
        <button *ngIf="!logged" class="standard-button standard-button-action" mat-menu-item routerLink="login">
          {{ 'label.login' | translate }}
        </button>
        <button (click)="logout()" *ngIf="logged" class="standard-button standard-button-action" mat-menu-item
          routerLink="login">
          <span>{{ 'label.logout' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
