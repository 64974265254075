/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiDownload } from '../fn/employee-statement-read/api-download';
import { ApiDownload$Params } from '../fn/employee-statement-read/api-download';
import { send } from '../fn/employee-statement-read/send';
import { Send$Params } from '../fn/employee-statement-read/send';
import { statement1 } from '../fn/employee-statement-read/statement-1';
import { Statement1$Params } from '../fn/employee-statement-read/statement-1';
import { statementPdf } from '../fn/employee-statement-read/statement-pdf';
import { StatementPdf$Params } from '../fn/employee-statement-read/statement-pdf';
import { TmmEmployeeStatementResponse } from '../models/tmm-employee-statement-response';
import { TmmEmployeeStatementSendResponse } from '../models/tmm-employee-statement-send-response';

@Injectable({ providedIn: 'root' })
export class EmployeeStatementReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `statement1()` */
  static readonly Statement1Path = '/api/tms/statement/{user}/{year}/{month}';

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statement1()` instead.
   *
   * This method doesn't expect any request body.
   */
  statement1$Response(params: Statement1$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEmployeeStatementResponse>> {
    return statement1(this.http, this.rootUrl, params, context);
  }

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statement1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statement1(params: Statement1$Params, context?: HttpContext): Observable<TmmEmployeeStatementResponse> {
    return this.statement1$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEmployeeStatementResponse>): TmmEmployeeStatementResponse => r.body)
    );
  }

  /** Path part for operation `send()` */
  static readonly SendPath = '/api/tms/statement/{user}/{year}/{month}';

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `send()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  send$Response(params: Send$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEmployeeStatementSendResponse>> {
    return send(this.http, this.rootUrl, params, context);
  }

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `send$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  send(params: Send$Params, context?: HttpContext): Observable<TmmEmployeeStatementSendResponse> {
    return this.send$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEmployeeStatementSendResponse>): TmmEmployeeStatementSendResponse => r.body)
    );
  }

  /** Path part for operation `apiDownload()` */
  static readonly ApiDownloadPath = '/api/tms/statement/{report}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDownload$Response(params: ApiDownload$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return apiDownload(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDownload(params: ApiDownload$Params, context?: HttpContext): Observable<{
}> {
    return this.apiDownload$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `statementPdf()` */
  static readonly StatementPdfPath = '/api/tms/statement/pdf/{user}/{year}/{month}';

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statementPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  statementPdf$Response(params: StatementPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return statementPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statementPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statementPdf(params: StatementPdf$Params, context?: HttpContext): Observable<{
}> {
    return this.statementPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
