/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { accountRead } from '../fn/account/account-read';
import { AccountRead$Params } from '../fn/account/account-read';
import { AcmAccountCreateResponse } from '../models/acm-account-create-response';
import { AcmAccountDetailsResponse } from '../models/acm-account-details-response';
import { AcmAccountListResponse } from '../models/acm-account-list-response';
import { AcmAccountQueryResponse } from '../models/acm-account-query-response';
import { AcmAccountReadResponse } from '../models/acm-account-read-response';
import { AcmAccountUpdateResponse } from '../models/acm-account-update-response';
import { AcmQueryAccountsByRoleResponse } from '../models/acm-query-accounts-by-role-response';
import { createAccount } from '../fn/account/create-account';
import { CreateAccount$Params } from '../fn/account/create-account';
import { detail } from '../fn/account/detail';
import { Detail$Params } from '../fn/account/detail';
import { listAccount } from '../fn/account/list-account';
import { ListAccount$Params } from '../fn/account/list-account';
import { query } from '../fn/account/query';
import { Query$Params } from '../fn/account/query';
import { query1 } from '../fn/account/query-1';
import { Query1$Params } from '../fn/account/query-1';
import { updateAccount } from '../fn/account/update-account';
import { UpdateAccount$Params } from '../fn/account/update-account';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAccount()` */
  static readonly UpdateAccountPath = '/api/acc/account/{login}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccount$Response(params: UpdateAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountUpdateResponse>> {
    return updateAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccount(params: UpdateAccount$Params, context?: HttpContext): Observable<AcmAccountUpdateResponse> {
    return this.updateAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountUpdateResponse>): AcmAccountUpdateResponse => r.body)
    );
  }

  /** Path part for operation `listAccount()` */
  static readonly ListAccountPath = '/api/acc/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAccount$Response(params: ListAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountListResponse>> {
    return listAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAccount(params: ListAccount$Params, context?: HttpContext): Observable<AcmAccountListResponse> {
    return this.listAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountListResponse>): AcmAccountListResponse => r.body)
    );
  }

  /** Path part for operation `createAccount()` */
  static readonly CreateAccountPath = '/api/acc/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccount$Response(params: CreateAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountCreateResponse>> {
    return createAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccount(params: CreateAccount$Params, context?: HttpContext): Observable<AcmAccountCreateResponse> {
    return this.createAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountCreateResponse>): AcmAccountCreateResponse => r.body)
    );
  }

  /** Path part for operation `query()` */
  static readonly QueryPath = '/api/acc/accounts';

  /**
   * Query the account database for any match (description = login )
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `query()` instead.
   *
   * This method doesn't expect any request body.
   */
  query$Response(params: Query$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountQueryResponse>> {
    return query(this.http, this.rootUrl, params, context);
  }

  /**
   * Query the account database for any match (description = login )
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `query$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  query(params: Query$Params, context?: HttpContext): Observable<AcmAccountQueryResponse> {
    return this.query$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountQueryResponse>): AcmAccountQueryResponse => r.body)
    );
  }

  /** Path part for operation `query1()` */
  static readonly Query1Path = '/api/acc/accounts/byrole';

  /**
   * Query for an accounts within role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `query1()` instead.
   *
   * This method doesn't expect any request body.
   */
  query1$Response(params: Query1$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmQueryAccountsByRoleResponse>> {
    return query1(this.http, this.rootUrl, params, context);
  }

  /**
   * Query for an accounts within role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `query1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  query1(params: Query1$Params, context?: HttpContext): Observable<AcmQueryAccountsByRoleResponse> {
    return this.query1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmQueryAccountsByRoleResponse>): AcmQueryAccountsByRoleResponse => r.body)
    );
  }

  /** Path part for operation `detail()` */
  static readonly DetailPath = '/api/acc/account/{account}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detail()` instead.
   *
   * This method doesn't expect any request body.
   */
  detail$Response(params: Detail$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountDetailsResponse>> {
    return detail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `detail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  detail(params: Detail$Params, context?: HttpContext): Observable<AcmAccountDetailsResponse> {
    return this.detail$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountDetailsResponse>): AcmAccountDetailsResponse => r.body)
    );
  }

  /** Path part for operation `accountRead()` */
  static readonly AccountReadPath = '/api/acc/account/details/{login}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRead$Response(params: AccountRead$Params, context?: HttpContext): Observable<StrictHttpResponse<AcmAccountReadResponse>> {
    return accountRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRead(params: AccountRead$Params, context?: HttpContext): Observable<AcmAccountReadResponse> {
    return this.accountRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<AcmAccountReadResponse>): AcmAccountReadResponse => r.body)
    );
  }

}
