/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OpmQuerySystemConfigurationsReadResponse } from '../models/opm-query-system-configurations-read-response';
import { OpmQuerySystemConfigurationReadResponse } from '../models/opm-query-system-configuration-read-response';

/**
 * Opa Query System Configuration Read
 */
@Injectable({
  providedIn: 'root',
})
class SystemConfigurationReadService extends __BaseService {
  static readonly readSystemConfigurationsUsingGETPath = '/api/opp/system/configuration';
  static readonly readSystemConfigurationUsingGETPath = '/api/opp/system/configuration/{key}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * readSystemConfigurations
   * @param params The `SystemConfigurationReadService.ReadSystemConfigurationsUsingGETParams` containing the following parameters:
   *
   * - `keys`: keys
   *
   * - `header`: header
   *
   * @return OK
   */
  readSystemConfigurationsUsingGETResponse(params: SystemConfigurationReadService.ReadSystemConfigurationsUsingGETParams): __Observable<__StrictHttpResponse<OpmQuerySystemConfigurationsReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.keys || []).forEach(val => {if (val != null) __params = __params.append('keys', val.toString())});
    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/opp/system/configuration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmQuerySystemConfigurationsReadResponse>;
      })
    );
  }
  /**
   * readSystemConfigurations
   * @param params The `SystemConfigurationReadService.ReadSystemConfigurationsUsingGETParams` containing the following parameters:
   *
   * - `keys`: keys
   *
   * - `header`: header
   *
   * @return OK
   */
  readSystemConfigurationsUsingGET(params: SystemConfigurationReadService.ReadSystemConfigurationsUsingGETParams): __Observable<OpmQuerySystemConfigurationsReadResponse> {
    return this.readSystemConfigurationsUsingGETResponse(params).pipe(
      __map(_r => _r.body as OpmQuerySystemConfigurationsReadResponse)
    );
  }

  /**
   * readSystemConfiguration
   * @param params The `SystemConfigurationReadService.ReadSystemConfigurationUsingGETParams` containing the following parameters:
   *
   * - `key`: key
   *
   * - `header`: header
   *
   * @return OK
   */
  readSystemConfigurationUsingGETResponse(params: SystemConfigurationReadService.ReadSystemConfigurationUsingGETParams): __Observable<__StrictHttpResponse<OpmQuerySystemConfigurationReadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.header != null) __headers = __headers.set('header', params.header.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/opp/system/configuration/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OpmQuerySystemConfigurationReadResponse>;
      })
    );
  }
  /**
   * readSystemConfiguration
   * @param params The `SystemConfigurationReadService.ReadSystemConfigurationUsingGETParams` containing the following parameters:
   *
   * - `key`: key
   *
   * - `header`: header
   *
   * @return OK
   */
  readSystemConfigurationUsingGET(params: SystemConfigurationReadService.ReadSystemConfigurationUsingGETParams): __Observable<OpmQuerySystemConfigurationReadResponse> {
    return this.readSystemConfigurationUsingGETResponse(params).pipe(
      __map(_r => _r.body as OpmQuerySystemConfigurationReadResponse)
    );
  }
}

module SystemConfigurationReadService {

  /**
   * Parameters for readSystemConfigurationsUsingGET
   */
  export interface ReadSystemConfigurationsUsingGETParams {

    /**
     * keys
     */
    keys: Array<string>;

    /**
     * header
     */
    header?: string;
  }

  /**
   * Parameters for readSystemConfigurationUsingGET
   */
  export interface ReadSystemConfigurationUsingGETParams {

    /**
     * key
     */
    key: string;

    /**
     * header
     */
    header?: string;
  }
}

export { SystemConfigurationReadService }
