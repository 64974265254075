import {CustomAction} from '../state.model';

export const LAST_VIEWDATE = 'LAST_VIEWDATE';

export function viewdate(state: string = '', action: CustomAction) {
  switch (action.type) {
    case LAST_VIEWDATE:
      return action.payload.viewdate;
    default:
      return state;
  }
}
