/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { list2 } from '../fn/timesheet-delegacy-read/list-2';
import { List2$Params } from '../fn/timesheet-delegacy-read/list-2';
import { TmmEntryDelegacyReadResponse } from '../models/tmm-entry-delegacy-read-response';

@Injectable({ providedIn: 'root' })
export class TimesheetDelegacyReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `list2()` */
  static readonly List2Path = '/api/tms/timesheet/{uuid}/delegacy';

  /**
   * Read list of delegacy with attachments from the timesheet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list2()` instead.
   *
   * This method doesn't expect any request body.
   */
  list2$Response(params: List2$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryDelegacyReadResponse>> {
    return list2(this.http, this.rootUrl, params, context);
  }

  /**
   * Read list of delegacy with attachments from the timesheet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list2(params: List2$Params, context?: HttpContext): Observable<TmmEntryDelegacyReadResponse> {
    return this.list2$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryDelegacyReadResponse>): TmmEntryDelegacyReadResponse => r.body)
    );
  }

}
