/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PrmJobTaskReadResponse } from '../models/prm-job-task-read-response';
import { readJobTasks } from '../fn/pra-job-task-read/read-job-tasks';
import { ReadJobTasks$Params } from '../fn/pra-job-task-read/read-job-tasks';
import { readResponseResponseEntity1 } from '../fn/pra-job-task-read/read-response-response-entity-1';
import { ReadResponseResponseEntity1$Params } from '../fn/pra-job-task-read/read-response-response-entity-1';

@Injectable({ providedIn: 'root' })
export class PraJobTaskReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readJobTasks()` */
  static readonly ReadJobTasksPath = '/api/prj/jobtask/{no}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readJobTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  readJobTasks$Response(params: ReadJobTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobTaskReadResponse>> {
    return readJobTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readJobTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readJobTasks(params: ReadJobTasks$Params, context?: HttpContext): Observable<PrmJobTaskReadResponse> {
    return this.readJobTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobTaskReadResponse>): PrmJobTaskReadResponse => r.body)
    );
  }

  /** Path part for operation `readResponseResponseEntity1()` */
  static readonly ReadResponseResponseEntity1Path = '/api/prj/jobtask/{group}/{no}/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readResponseResponseEntity1()` instead.
   *
   * This method doesn't expect any request body.
   */
  readResponseResponseEntity1$Response(params: ReadResponseResponseEntity1$Params, context?: HttpContext): Observable<StrictHttpResponse<PrmJobTaskReadResponse>> {
    return readResponseResponseEntity1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readResponseResponseEntity1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readResponseResponseEntity1(params: ReadResponseResponseEntity1$Params, context?: HttpContext): Observable<PrmJobTaskReadResponse> {
    return this.readResponseResponseEntity1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrmJobTaskReadResponse>): PrmJobTaskReadResponse => r.body)
    );
  }

}
