/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acceptable } from '../fn/timesheet-read/acceptable';
import { Acceptable$Params } from '../fn/timesheet-read/acceptable';
import { entriesToAccept } from '../fn/timesheet-read/entries-to-accept';
import { EntriesToAccept$Params } from '../fn/timesheet-read/entries-to-accept';
import { list } from '../fn/timesheet-read/list';
import { List$Params } from '../fn/timesheet-read/list';
import { list1 } from '../fn/timesheet-read/list-1';
import { List1$Params } from '../fn/timesheet-read/list-1';
import { list3 } from '../fn/timesheet-read/list-3';
import { List3$Params } from '../fn/timesheet-read/list-3';
import { query } from '../fn/timesheet-read/query';
import { Query$Params } from '../fn/timesheet-read/query';
import { split } from '../fn/timesheet-read/split';
import { Split$Params } from '../fn/timesheet-read/split';
import { statement } from '../fn/timesheet-read/statement';
import { Statement$Params } from '../fn/timesheet-read/statement';
import { TmaEntryForDateReadResponse } from '../models/tma-entry-for-date-read-response';
import { TmmAmountSplitReadResponse } from '../models/tmm-amount-split-read-response';
import { TmmEntryMaximumAcceptableResponse } from '../models/tmm-entry-maximum-acceptable-response';
import { TmmEntryReadResponse } from '../models/tmm-entry-read-response';
import { TmmEntrySingleReadResponse } from '../models/tmm-entry-single-read-response';
import { TmmEntryToAcceptByRoleResponse } from '../models/tmm-entry-to-accept-by-role-response';
import { TmmEntryToAcceptResponse } from '../models/tmm-entry-to-accept-response';
import { toAccept } from '../fn/timesheet-read/to-accept';
import { ToAccept$Params } from '../fn/timesheet-read/to-accept';

@Injectable({ providedIn: 'root' })
export class TimesheetReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `list()` */
  static readonly ListPath = '/api/tms/timesheet';

  /**
   * Statement for the user.
   *
   * List all entries from the timesheet of standard hours and additional.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list()` instead.
   *
   * This method doesn't expect any request body.
   */
  list$Response(params: List$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryReadResponse>> {
    return list(this.http, this.rootUrl, params, context);
  }

  /**
   * Statement for the user.
   *
   * List all entries from the timesheet of standard hours and additional.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list(params: List$Params, context?: HttpContext): Observable<TmmEntryReadResponse> {
    return this.list$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryReadResponse>): TmmEntryReadResponse => r.body)
    );
  }

  /** Path part for operation `list1()` */
  static readonly List1Path = '/api/tms/timesheet/{uuid}';

  /**
   * Read single entries from the timesheet of standard hours and additional.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list1()` instead.
   *
   * This method doesn't expect any request body.
   */
  list1$Response(params: List1$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntrySingleReadResponse>> {
    return list1(this.http, this.rootUrl, params, context);
  }

  /**
   * Read single entries from the timesheet of standard hours and additional.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list1(params: List1$Params, context?: HttpContext): Observable<TmmEntrySingleReadResponse> {
    return this.list1$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntrySingleReadResponse>): TmmEntrySingleReadResponse => r.body)
    );
  }

  /** Path part for operation `list3()` */
  static readonly List3Path = '/api/tms/timesheet/user/{user}';

  /**
   * List all entries from the timesheet of standard hours and additional.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `list3()` instead.
   *
   * This method doesn't expect any request body.
   */
  list3$Response(params: List3$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryReadResponse>> {
    return list3(this.http, this.rootUrl, params, context);
  }

  /**
   * List all entries from the timesheet of standard hours and additional.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `list3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  list3(params: List3$Params, context?: HttpContext): Observable<TmmEntryReadResponse> {
    return this.list3$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryReadResponse>): TmmEntryReadResponse => r.body)
    );
  }

  /** Path part for operation `acceptable()` */
  static readonly AcceptablePath = '/api/tms/timesheet/acceptable';

  /**
   * Calculate number of hours that will be accepted to provide by the user in the context of the project and date.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptable()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptable$Response(params: Acceptable$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryMaximumAcceptableResponse>> {
    return acceptable(this.http, this.rootUrl, params, context);
  }

  /**
   * Calculate number of hours that will be accepted to provide by the user in the context of the project and date.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptable(params: Acceptable$Params, context?: HttpContext): Observable<TmmEntryMaximumAcceptableResponse> {
    return this.acceptable$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryMaximumAcceptableResponse>): TmmEntryMaximumAcceptableResponse => r.body)
    );
  }

  /** Path part for operation `entriesToAccept()` */
  static readonly EntriesToAcceptPath = '/api/tms/timesheet/acceptable/byrole';

  /**
   * Read entries to accept filtered by the role of the user, and or job main category.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entriesToAccept()` instead.
   *
   * This method doesn't expect any request body.
   */
  entriesToAccept$Response(params: EntriesToAccept$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryToAcceptByRoleResponse>> {
    return entriesToAccept(this.http, this.rootUrl, params, context);
  }

  /**
   * Read entries to accept filtered by the role of the user, and or job main category.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entriesToAccept$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entriesToAccept(params: EntriesToAccept$Params, context?: HttpContext): Observable<TmmEntryToAcceptByRoleResponse> {
    return this.entriesToAccept$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryToAcceptByRoleResponse>): TmmEntryToAcceptByRoleResponse => r.body)
    );
  }

  /** Path part for operation `toAccept()` */
  static readonly ToAcceptPath = '/api/tms/timesheet/accept';

  /**
   * List of entries to accept by the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toAccept()` instead.
   *
   * This method doesn't expect any request body.
   */
  toAccept$Response(params: ToAccept$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryToAcceptResponse>> {
    return toAccept(this.http, this.rootUrl, params, context);
  }

  /**
   * List of entries to accept by the user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toAccept$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toAccept(params: ToAccept$Params, context?: HttpContext): Observable<TmmEntryToAcceptResponse> {
    return this.toAccept$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryToAcceptResponse>): TmmEntryToAcceptResponse => r.body)
    );
  }

  /** Path part for operation `statement()` */
  static readonly StatementPath = '/api/tms/statement';

  /**
   * Statement for the user.
   *
   * The service returns the list of entries for the user. The result is filtered for the user by the state and the project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statement()` instead.
   *
   * This method doesn't expect any request body.
   */
  statement$Response(params: Statement$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmEntryToAcceptResponse>> {
    return statement(this.http, this.rootUrl, params, context);
  }

  /**
   * Statement for the user.
   *
   * The service returns the list of entries for the user. The result is filtered for the user by the state and the project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statement(params: Statement$Params, context?: HttpContext): Observable<TmmEntryToAcceptResponse> {
    return this.statement$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmEntryToAcceptResponse>): TmmEntryToAcceptResponse => r.body)
    );
  }

  /** Path part for operation `query()` */
  static readonly QueryPath = '/api/tms/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `query()` instead.
   *
   * This method doesn't expect any request body.
   */
  query$Response(params: Query$Params, context?: HttpContext): Observable<StrictHttpResponse<TmaEntryForDateReadResponse>> {
    return query(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `query$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  query(params: Query$Params, context?: HttpContext): Observable<TmaEntryForDateReadResponse> {
    return this.query$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmaEntryForDateReadResponse>): TmaEntryForDateReadResponse => r.body)
    );
  }

  /** Path part for operation `split()` */
  static readonly SplitPath = '/api/tms/amount/split';

  /**
   * Split the amount into days, using the holiday table do termine business days.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `split()` instead.
   *
   * This method doesn't expect any request body.
   */
  split$Response(params: Split$Params, context?: HttpContext): Observable<StrictHttpResponse<TmmAmountSplitReadResponse>> {
    return split(this.http, this.rootUrl, params, context);
  }

  /**
   * Split the amount into days, using the holiday table do termine business days.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `split$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  split(params: Split$Params, context?: HttpContext): Observable<TmmAmountSplitReadResponse> {
    return this.split$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmmAmountSplitReadResponse>): TmmAmountSplitReadResponse => r.body)
    );
  }

}
