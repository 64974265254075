/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LeaveValidate200ResponseDto } from '../../models/leave-validate-200-response-dto';

export interface LeaveValidate$Params {

/**
 * User login
 */
  login: string;

/**
 * Number of working days for the leave
 */
  workingDays: number;

/**
 * Start date of the leave in yyyy-mm-dd format
 */
  fromDate: string;

/**
 * End date of the leave in yyyy-mm-dd format
 */
  toDate: string;

/**
 * Type of leave
 */
  leaveType: string;
}

export function leaveValidate(http: HttpClient, rootUrl: string, params: LeaveValidate$Params, context?: HttpContext): Observable<StrictHttpResponse<LeaveValidate200ResponseDto>> {
  const rb = new RequestBuilder(rootUrl, leaveValidate.PATH, 'get');
  if (params) {
    rb.path('login', params.login, {});
    rb.query('workingDays', params.workingDays, {});
    rb.query('fromDate', params.fromDate, {});
    rb.query('toDate', params.toDate, {});
    rb.query('leaveType', params.leaveType, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LeaveValidate200ResponseDto>;
    })
  );
}

leaveValidate.PATH = '/api/hld/user/{login}/leave/validate';
