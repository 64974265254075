/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { calculateWorkingDays } from '../fn/calculate/calculate-working-days';
import { CalculateWorkingDays$Params } from '../fn/calculate/calculate-working-days';
import { CalculateWorkingDays200ResponseDto } from '../models/calculate-working-days-200-response-dto';


/**
 * the Calculate API
 */
@Injectable({ providedIn: 'root' })
export class CalculateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `calculateWorkingDays()` */
  static readonly CalculateWorkingDaysPath = '/api/hld/calculate/workingdays';

  /**
   * Calculate the number of working days between fromDate and toDate.
   *
   * Returns the number of working days (excluding weekends and public holidays) between the provided fromDate and toDate parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateWorkingDays()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateWorkingDays$Response(params: CalculateWorkingDays$Params, context?: HttpContext): Observable<StrictHttpResponse<CalculateWorkingDays200ResponseDto>> {
    return calculateWorkingDays(this.http, this.rootUrl, params, context);
  }

  /**
   * Calculate the number of working days between fromDate and toDate.
   *
   * Returns the number of working days (excluding weekends and public holidays) between the provided fromDate and toDate parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculateWorkingDays$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateWorkingDays(params: CalculateWorkingDays$Params, context?: HttpContext): Observable<CalculateWorkingDays200ResponseDto> {
    return this.calculateWorkingDays$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalculateWorkingDays200ResponseDto>): CalculateWorkingDays200ResponseDto => r.body)
    );
  }

}
