import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  CnmContent,
  CnmListContextReadResponse,
  CnmRelatedTopicsReadResponse,
  CnmTopic,
} from 'src/app/api/ctx/models';
import { ContextHelpReadService } from 'src/app/api/ctx/services';

const slideInOutAnimation = trigger('slideInOutAnimation', [
  state(
    '*',
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }),
  ),

  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),

    animate('.2s ease-in-out', style({ transform: 'translateX(0%)' })),
  ]),

  transition(':leave', [
    animate('.2s ease-in-out', style({ transform: 'translateX(-100%)' })),
  ]),
]);

@Component({
  selector: 'app-helpItemPage',
  templateUrl: './helpItemPage.component.html',
  styleUrls: ['./helpItemPage.component.scss'],
  animations: [slideInOutAnimation],
  host: { '[@slideInOutAnimation]': '' },
})
export class HelpItemPageComponent implements OnInit {
  constructor(private _contextHelp: ContextHelpReadService) {}
  @Input() item: CnmContent;
  @Output() closeItem: EventEmitter<boolean> = new EventEmitter();
  itemShow: boolean = false;
  helpTopics: Array<CnmTopic> = undefined;
  itemFromTopic: CnmContent;

  ngOnInit() {
    this.getTopics();
  }

  itemClose() {
    this.closeItem.emit();
  }

  closeItemPage() {
    this.itemShow = false;
  }

  showItem(item) {
    this.itemFromTopic = item;
    this.itemShow = true;
  }

  getTopics() {
    this._contextHelp
      .relatedTopicsReadUsingGET({
        language: 'pl',
        path: this.item.path,
      })
      .subscribe(
        (result: CnmRelatedTopicsReadResponse) => {
          if (result.topics.length) this.helpTopics = result.topics;
        },
        (err) => {},
      );
  }

  getItemContext(item: CnmTopic) {
    this._contextHelp
      .listContextUsingGET({
        language: item.language,
        path: item.path,
        header: item.subject,
      })
      .subscribe(
        (result: CnmListContextReadResponse) => {
          this.showItem(result.content);
        },
        (err) => {},
      );
  }
}
