/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { storeComment } from '../fn/comment-write/store-comment';
import { StoreComment$Params } from '../fn/comment-write/store-comment';
import { StoreIncident200ResponseDto } from '../models/store-incident-200-response-dto';
import { updateIncidentComment } from '../fn/comment-write/update-incident-comment';
import { UpdateIncidentComment$Params } from '../fn/comment-write/update-incident-comment';


/**
 * the CommentWrite API
 */
@Injectable({ providedIn: 'root' })
export class CommentWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateIncidentComment()` */
  static readonly UpdateIncidentCommentPath = '/api/ehs/incident/{incidentId}/comment/{commentUid}';

  /**
   * Update incident comment.
   *
   * Endpoint for updating incident comment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIncidentComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIncidentComment$Response(params: UpdateIncidentComment$Params, context?: HttpContext): Observable<StrictHttpResponse<StoreIncident200ResponseDto>> {
    return updateIncidentComment(this.http, this.rootUrl, params, context);
  }

  /**
   * Update incident comment.
   *
   * Endpoint for updating incident comment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIncidentComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIncidentComment(params: UpdateIncidentComment$Params, context?: HttpContext): Observable<StoreIncident200ResponseDto> {
    return this.updateIncidentComment$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoreIncident200ResponseDto>): StoreIncident200ResponseDto => r.body)
    );
  }

  /** Path part for operation `storeComment()` */
  static readonly StoreCommentPath = '/api/ehs/incident/{incidentId}/comment';

  /**
   * Register a comment to incident.
   *
   * Endpoint for registering comment to incidents.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storeComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeComment$Response(params: StoreComment$Params, context?: HttpContext): Observable<StrictHttpResponse<StoreIncident200ResponseDto>> {
    return storeComment(this.http, this.rootUrl, params, context);
  }

  /**
   * Register a comment to incident.
   *
   * Endpoint for registering comment to incidents.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storeComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storeComment(params: StoreComment$Params, context?: HttpContext): Observable<StoreIncident200ResponseDto> {
    return this.storeComment$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoreIncident200ResponseDto>): StoreIncident200ResponseDto => r.body)
    );
  }

}
