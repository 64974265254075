import { Component, OnInit, Input } from "@angular/core";
import { NvmReportSiteResultItem } from "src/app/api/nav/models";
import { ReportSiteResultReadService } from "src/app/api/nav/services";
import { SnackMessageService } from "src/app/core/service/snack-message.service";
import { ErrorHandlerService } from "src/app/core/service/error-handler.service";
import { isNullOrUndefined } from "src/app/shared/utilities";

@Component({
  selector: "app-site-reporting-project-modification",
  templateUrl: "./site-reporting-project-modification.component.html",
  styleUrls: ["./site-reporting-project-modification.component.scss"],
})
export class SiteReportingProjectModificationComponent implements OnInit {
  private _jobNo: string = null;
  private data: Array<NvmReportSiteResultItem>;
  public modification: NvmReportSiteResultItem = undefined;
  private _range: string = null;

  constructor(
    private _service: ReportSiteResultReadService,
    private _snackMessage: SnackMessageService,
    private _errorHandler: ErrorHandlerService
  ) {}

  @Input()
  set range(value: string) {
    this._range = value;

    if (!isNullOrUndefined(this._jobNo)) {
      this.readCategory();
    }
  }

  @Input()
  set jobNo(value: string) {
    this._jobNo = value;

    if (!isNullOrUndefined(this._jobNo) && this._jobNo !== "") {
      this.readCategory();
    }
  }

  ngOnInit() {}

  private readCategory(): void {
    this._service
      .categoryRead1({
        jobNo: this._jobNo,
        category: "state",
        range: this._range,
      })
      .subscribe(
        (result) => {
          this.data = result.items;

          this.data
            .filter((item) => {
              return item.key === "lastModification";
            })
            .forEach((item) => {
              this.modification = item;
            });
        },
        (error) => {
          this._snackMessage.communicateError(
            this._errorHandler.errorCode(error)
          );
        }
      );
  }
}
