<div class="container-fluid bg-white itemContent h-100">
  <div style="overflow: auto">
    <div class="row mx-0 mb-3 p-0">
      <div class="col-1 d-flex items-center justify-start p-0 cursor-pointer"><mat-icon (click)="itemClose()">keyboard_arrow_left</mat-icon></div>
      <div class="col-11"><span class="v_text_header text-skin-base">{{item.subject}}</span></div>
    </div>
    <div [innerHTML]="item.content" style="overflow-x: scroll; overflow-y: hidden"></div>
    <div class="row m-1 p-1 ng-star-inserted my-3"><div class="col-12"><div style="border-bottom: #e7e7e7 1px solid;"></div></div></div>
    <ng-container *ngIf="helpTopics" >
      <ng-container *ngFor="let item of helpTopics">
        <div class="row shadow-sm item cursor-pointer mx-0 my-2" (click)="getItemContext(item)">
          <div class="col-11 pl-2 d-flex justify-center items-center">
            {{item.subject}}
          </div>
          <div class="col-1 p-0"> <mat-icon>keyboard_arrow_right</mat-icon></div>
        </div>
      </ng-container>
    </ng-container>
    <div *ngIf="helpTopics == undefined">{{"label.noHelpTopics" | translate}}</div>
  </div>
  <app-helpItemPage [item]="itemFromTopic" *ngIf="itemShow" (closeItem)="closeItemPage()"></app-helpItemPage>
</div>
