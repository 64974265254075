/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { downloadXlsx } from '../fn/volume-of-orders-read/download-xlsx';
import { DownloadXlsx$Params } from '../fn/volume-of-orders-read/download-xlsx';

@Injectable({ providedIn: 'root' })
export class VolumeOfOrdersReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `downloadXlsx()` */
  static readonly DownloadXlsxPath = '/api/nav/voo/report/xlsx';

  /**
   * Generate voo report as xlsx file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadXlsx()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadXlsx$Response(params?: DownloadXlsx$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return downloadXlsx(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate voo report as xlsx file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadXlsx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadXlsx(params?: DownloadXlsx$Params, context?: HttpContext): Observable<{
}> {
    return this.downloadXlsx$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
