/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExmExportMachineWorkersResponse } from '../models/exm-export-machine-workers-response';
import { ExmExportOptimaResponse } from '../models/exm-export-optima-response';
import { ExmExportVacancyResponse } from '../models/exm-export-vacancy-response';
import { exportHolidays } from '../fn/export-write/export-holidays';
import { ExportHolidays$Params } from '../fn/export-write/export-holidays';
import { exportMachineWorkers } from '../fn/export-write/export-machine-workers';
import { ExportMachineWorkers$Params } from '../fn/export-write/export-machine-workers';
import { exportOpitma } from '../fn/export-write/export-opitma';
import { ExportOpitma$Params } from '../fn/export-write/export-opitma';

@Injectable({ providedIn: 'root' })
export class ExportWriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `exportHolidays()` */
  static readonly ExportHolidaysPath = '/api/exp/export/vacancy';

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportHolidays()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportHolidays$Response(params: ExportHolidays$Params, context?: HttpContext): Observable<StrictHttpResponse<ExmExportVacancyResponse>> {
    return exportHolidays(this.http, this.rootUrl, params, context);
  }

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportHolidays$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportHolidays(params: ExportHolidays$Params, context?: HttpContext): Observable<ExmExportVacancyResponse> {
    return this.exportHolidays$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExmExportVacancyResponse>): ExmExportVacancyResponse => r.body)
    );
  }

  /** Path part for operation `exportOpitma()` */
  static readonly ExportOpitmaPath = '/api/exp/export/optima';

  /**
   * Export all data to the Optima.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportOpitma()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpitma$Response(params: ExportOpitma$Params, context?: HttpContext): Observable<StrictHttpResponse<ExmExportOptimaResponse>> {
    return exportOpitma(this.http, this.rootUrl, params, context);
  }

  /**
   * Export all data to the Optima.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportOpitma$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportOpitma(params: ExportOpitma$Params, context?: HttpContext): Observable<ExmExportOptimaResponse> {
    return this.exportOpitma$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExmExportOptimaResponse>): ExmExportOptimaResponse => r.body)
    );
  }

  /** Path part for operation `exportMachineWorkers()` */
  static readonly ExportMachineWorkersPath = '/api/exp/export/machineworkerhours';

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportMachineWorkers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportMachineWorkers$Response(params: ExportMachineWorkers$Params, context?: HttpContext): Observable<StrictHttpResponse<ExmExportMachineWorkersResponse>> {
    return exportMachineWorkers(this.http, this.rootUrl, params, context);
  }

  /**
   * Export accepted vacancy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportMachineWorkers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportMachineWorkers(params: ExportMachineWorkers$Params, context?: HttpContext): Observable<ExmExportMachineWorkersResponse> {
    return this.exportMachineWorkers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExmExportMachineWorkersResponse>): ExmExportMachineWorkersResponse => r.body)
    );
  }

}
