/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { NvmQueryTransactionResponse } from '../models/nvm-query-transaction-response';
import { query } from '../fn/nav-read/query';
import { Query$Params } from '../fn/nav-read/query';

@Injectable({ providedIn: 'root' })
export class NavReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `query()` */
  static readonly QueryPath = '/api/nav/query/transactions';

  /**
   * Query transaction resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `query()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  query$Response(params: Query$Params, context?: HttpContext): Observable<StrictHttpResponse<NvmQueryTransactionResponse>> {
    return query(this.http, this.rootUrl, params, context);
  }

  /**
   * Query transaction resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `query$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  query(params: Query$Params, context?: HttpContext): Observable<NvmQueryTransactionResponse> {
    return this.query$Response(params, context).pipe(
      map((r: StrictHttpResponse<NvmQueryTransactionResponse>): NvmQueryTransactionResponse => r.body)
    );
  }

}
