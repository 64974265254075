/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { dictionary } from '../fn/cost-read/dictionary';
import { Dictionary$Params } from '../fn/cost-read/dictionary';
import { TmaCostDictionaryReadResponse } from '../models/tma-cost-dictionary-read-response';

@Injectable({ providedIn: 'root' })
export class CostReadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dictionary()` */
  static readonly DictionaryPath = '/api/tms/cost/dictionary';

  /**
   * The service returns the dictionary for the costs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionary()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionary$Response(params?: Dictionary$Params, context?: HttpContext): Observable<StrictHttpResponse<TmaCostDictionaryReadResponse>> {
    return dictionary(this.http, this.rootUrl, params, context);
  }

  /**
   * The service returns the dictionary for the costs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionary(params?: Dictionary$Params, context?: HttpContext): Observable<TmaCostDictionaryReadResponse> {
    return this.dictionary$Response(params, context).pipe(
      map((r: StrictHttpResponse<TmaCostDictionaryReadResponse>): TmaCostDictionaryReadResponse => r.body)
    );
  }

}
