<div class="helpContainer bg-white shadow-xl" [@openClose]="isResize" style="z-index: 1050">
  <div class="container-fluid mt-1">
    <div class="row mx-0 mb-3 mt-0">
      <div class="col-11 p-0">
        <span class="v_text_header text-skin-base d-none d-sm-flex">{{
          'label.help' | translate
          }}</span>
      </div>
      <div class="col-1 d-flex items-center justify-end p-0">
        <mat-icon (click)="resize()" class="cursor-pointer d-none d-sm-flex">fullscreen</mat-icon>
        <mat-icon (click)="closeHelp()" class="cursor-pointer helpHeader ">close</mat-icon>
      </div>
    </div>
    <div style="overflow: auto" [ngClass]="{'helpContentBig': isResize, 'helpContentSmall': !isResize}">
      <div class="row bg-skin-theme100 m-0 p-2 mb-3 borderRadius d-flex items-center v_text_descriptive_blue" style="height: 80px">
        <div class="col-9" *ngIf="pageContent">
          <span class="v_text_header">
            {{ pageContent?.subject }}
          </span>
        </div>
        <div class="col-3">
          <span class="d-flex items-center">
            <mat-icon style="font-size: 50px; height: 50px">help_outline</mat-icon>
          </span>
        </div>
      </div>
      <div class="row-col" *ngIf="pageContent">
        <div [innerHTML]="pageContent.content"></div>
      </div>
      <div class="row m-1 p-1 ng-star-inserted my-3">
        <div class="col-12">
          <div style="border-bottom: #e7e7e7 1px solid"></div>
        </div>
      </div>
      <ng-container *ngIf="helpTopics">
        <ng-container *ngFor="let item of helpTopics">
          <div class="row shadow-sm item cursor-pointer mx-0 my-2" (click)="getItemContext(item)">
            <div class="col-11 pl-2 d-flex justify-center items-center">
              {{ item.subject }}
            </div>
            <div class="col-1 p-0">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <app-helpItemPage [item]="item" *ngIf="itemShow" (closeItem)="closeItem()" class="itemPage"></app-helpItemPage>
</div>
