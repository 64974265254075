/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { TransferUpdateService } from './services/transfer-update.service';
import { InventoryReadService } from './services/inventory-read.service';
import { InventoryWriteService } from './services/inventory-write.service';
import { InaInventoryChangeCategoryService } from './services/ina-inventory-change-category.service';
import { InventoryUidWriteService } from './services/inventory-uid-write.service';
import { InaExecuteOperationService } from './services/ina-execute-operation.service';
import { AnalyticsReadService } from './services/analytics-read.service';
import { InventoryCommentReadService } from './services/inventory-comment-read.service';
import { InventoryItemReadService } from './services/inventory-item-read.service';
import { ConfigurationReadService } from './services/configuration-read.service';
import { DictionaryReadService } from './services/dictionary-read.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    TransferUpdateService,
    InventoryReadService,
    InventoryWriteService,
    InaInventoryChangeCategoryService,
    InventoryUidWriteService,
    InaExecuteOperationService,
    AnalyticsReadService,
    InventoryCommentReadService,
    InventoryItemReadService,
    ConfigurationReadService,
    DictionaryReadService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
