import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'src/app/shared/utilities';
import { NumericInputComponent } from '../numeric-keyboard';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-numeric-keyboard-input',
  templateUrl: './numeric-keyboard-input.component.html',
  styleUrls: ['./numeric-keyboard-input.component.scss'],
})
export class NumericKeyboardInputComponent implements OnInit, AfterContentInit {
  @Input() placeholderLabel: string;
  @Input() formControlId: string;
  @Input() controlId: string;
  @Input() form: FormGroup;
  @Input() layout = 'number';
  @Input() top = 0;
  @Output() keyboardIsVisible: EventEmitter<void> = new EventEmitter();
  @Output() keyboardIsHidden: EventEmitter<void> = new EventEmitter();
  @Output() valueUpdated: EventEmitter<void> = new EventEmitter();
  @ViewChild('localcomponent', { static: false }) input: NumericInputComponent;
  public value = 0;
  public showKeyControl = false;
  public isMobile = false;

  constructor(private _deviceDetectorService: DeviceDetectorService) {
    this.isMobile = this._deviceDetectorService.isMobile();
  }

  ngOnInit() {}

    public showBottomPad() {
    this.keyboardIsVisible.emit();
  }

  onLostBlur() {
    this.keyboardIsHidden.emit();
    this.onModelChange(this.value);
  }

  onModelChange($event) {
    if (!isNullOrUndefined(this.form.get(this.formControlId))) {
      this.form.get(this.formControlId).setValue($event);
    }

    this.valueUpdated.emit();
  }

  ngAfterContentInit(): void {
    this.value = this.form.get(this.formControlId).value;

    this.form.get(this.formControlId).valueChanges.subscribe((value) => {
      this.value = value;
    });
  }
}
