<form [formGroup]="form" (keyup.esc)="close()">
  <div class="container-fluid d-flex flex-column m-0 p-0" style="height: 100%">
    <div class="row m-0 p-0 border-bottom">
      <div class="col no_margins_paddings">
        <app-top-menu
          [show_logo]="false"
          [show_menu]="false"
          [show_back_button]="false"
          background_color="#ffffff"
          [description]="'label.project' | translate"
        >
        </app-top-menu>
      </div>
    </div>
    <div
      class='row m-0 p-0 overflow-y-scroll overflow-x-hidden'
      style="height:75vh"
    >
      <div class="col-12 d-flex justify-content-center">
        <div class="container-fluid">
          <div class="row m-1 p-1">
            <div
              class="
                col-12
                d-flex
                justify-content-start
                align-items-center
                v_text_header
              "
            >
              {{ 'label.project.group.identification' | translate }}
            </div>
            <div class="col-12 col-lg-6 mx-0 p-1">
              <mat-form-field
                class="input_maximized  m-1"
              >
                <mat-label>{{ 'label.project.jobno' | translate }}</mat-label>
                <input
                  matInput
                  (change)="dataChanged()"
                  [placeholder]="'label.fill' | translate"
                  autocomplete="off"
                  
                  formControlName="jobNo"
                  minlength="3"
                  maxlength="30"
                />
                <mat-error *ngIf="form.get('jobNo').hasError('required')">
                  {{ 'message.required' | translate }}</mat-error
                >

                <mat-error *ngIf="form.get('jobNo').hasError('maxLen')">
                  {{ 'label.maximum.items' | translate }} 7
                </mat-error>

                <mat-error *ngIf="form.get('jobNo').hasError('projectNo')">
                  {{ 'label.project.Exist' | translate }}
                </mat-error>

                <mat-error *ngIf="form.get('jobNo').hasError('minLen')">
                  {{ 'label.minimum.items' | translate }} 3
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-6 mx-0  p-1">
              <mat-form-field
                class="input_maximized  m-1"
              >
                <mat-label>{{ 'label.project.name' | translate }}</mat-label>
                <input
                  matInput
                  (change)="dataChanged()"
                  [placeholder]="'label.fill' | translate"
                  autocomplete="off"
                  
                  formControlName="jobName"
                />
              </mat-form-field>
            </div>

            <div class="col-12 col-lg-6 mx-0  p-1">
              <mat-form-field class="input_maximized   m-1">
                <mat-label class="required_form_input">{{ 'label.project.category' | translate }}</mat-label>
                <mat-select
                  required="true"
                  formControlName="jobCat"
                  
                >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let type of categoryList"
                  [value]="type.code"
                >
                  {{ type.code | translate}}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('jobCat').hasError('required')">
                  {{ 'message.required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-6 mx-0  p-1">
              <mat-form-field class="input_maximized   m-1">
                <mat-label class="required_form_input">{{ 'label.inventory.state' | translate }}</mat-label>
                <mat-select
                  required="true"
                  formControlName="status"
                  
                >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let type of stateList"
                  [value]="type.code"
                >
                  {{ type.label | translate}}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('status').hasError('required')">
                  {{ 'message.required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div style="border-bottom: #e7e7e7 1px solid"></div>
            </div>
          </div>
          <div class="row m-1 p-1">
            <div
              class="
                col-12
                d-flex
                justify-content-start
                align-items-center
                v_text_header"
            >
              {{ 'label.project.client_data' | translate }}
            </div>
            <div class="col-12 col-lg-4  p-1">
              <mat-form-field
                class="input_maximized  m-1"
              >
                <mat-label>{{
                  'label.project.client.no' | translate
                }}</mat-label>
                <input
                  matInput
                  (change)="dataChanged()"
                  inputmode="numeric"
                  [placeholder]="'label.fill' | translate"
                  autocomplete="off"
                  
                  formControlName="customerNo"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-4  p-1">
              <mat-form-field
                class="input_maximized  m-1"
              >
                <mat-label>{{
                  'label.project.client.name' | translate
                }}</mat-label>
                <input
                  matInput
                  (change)="dataChanged()"
                  inputmode="numeric"
                  [placeholder]="'label.fill' | translate"
                  autocomplete="off"
                  
                  formControlName="customerName"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-4  p-1">
              <mat-form-field
                class="input_maximized  m-1"
              >
                <mat-label>{{
                  'label.project.client.crm' | translate
                }}</mat-label>
                <input
                  matInput
                  (change)="dataChanged()"
                  inputmode="numeric"
                  [placeholder]="'label.fill' | translate"
                  autocomplete="off"
                  
                  formControlName="CRM"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div style="border-bottom: #e7e7e7 1px solid"></div>
            </div>
          </div>
          <div class="row m-1 p-1">
            <div
              class="
                col-12
                d-flex
                justify-content-start
                align-items-center
                v_text_header
              "
            >
              {{ 'label.project.key_persons' | translate }}
            </div>
            <div class="col-12 col-lg-3  p-1">
              <mat-form-field
                class="input_maximized  m-1"
              >
                <mat-label>{{
                  'label.project.person.kb' | translate
                }}</mat-label>
                <input
                  matInput
                  (change)="dataChanged()"
                  autocomplete="off"
                  [placeholder]="'label.manager' | translate"
                  formControlName="kb"
                  
                />
                <div
                  style="
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    font-size: 11px;
                    z-index: 99;
                  "
                  class="clickable"
                  *ngIf="form.get('kb').value != ''"
                  (click)="clear_field('kb')"
                >
                  <mat-icon>clear</mat-icon>
                </div>
                <img
                  class="user_select_button clickable"
                  style="
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    font-size: 11px;
                  "
                  *ngIf="!(form.get('kb').value != '')"
                  height="18"
                  (click)="select_user('kb')"
                  src="assets/icons/icons_user_select.png"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-3  p-1">
              <mat-form-field
                class="input_maximized  m-1"
              >
                <mat-label>{{
                  'label.project.person.pm' | translate
                }}</mat-label>
                <input
                  matInput
                  (change)="dataChanged()"
                  autocomplete="off"
                  [placeholder]="'label.project.person.pm' | translate"
                  formControlName="PM"
                  
                />
                <div
                  style="
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    font-size: 11px;
                    z-index: 99;
                  "
                  class="clickable"
                  *ngIf="form.get('PM').value != ''"
                  (click)="clear_field('PM')"
                >
                  <mat-icon>clear</mat-icon>
                </div>
                <img
                  class="user_select_button clickable"
                  style="
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    font-size: 11px;
                  "
                  *ngIf="!(form.get('PM').value != '')"
                  height="18"
                  (click)="select_user('PM')"
                  src="assets/icons/icons_user_select.png"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-3  p-1">
              <mat-form-field
                class="input_maximized  m-1"
              >
                <mat-label>{{
                  'label.project.person.dr' | translate
                }}</mat-label>
                <input
                  matInput
                  (change)="dataChanged()"
                  autocomplete="off"
                  [placeholder]="'label.project.person.dr' | translate"
                  formControlName="DR"
                  
                />
                <div
                  style="
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    font-size: 11px;
                    z-index: 99;
                  "
                  class="clickable"
                  *ngIf="form.get('DR').value != ''"
                  (click)="clear_field('DR')"
                >
                  <mat-icon>clear</mat-icon>
                </div>
                <img
                  class="user_select_button clickable"
                  style="
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    font-size: 11px;
                  "
                  *ngIf="!(form.get('DR').value != '')"
                  height="18"
                  (click)="select_user('DR')"
                  src="assets/icons/icons_user_select.png"
                />
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-3  p-1">
              <mat-form-field
                class="input_maximized  m-1"
              >
                <mat-label>{{
                  'label.project.person.designer' | translate
                }}</mat-label>
                <input
                  matInput
                  (change)="dataChanged()"
                  autocomplete="off"
                  [placeholder]="'label.project.person.designer' | translate"
                  formControlName="Designer"
                  
                />
                <div
                  style="
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    font-size: 11px;
                    z-index: 99;
                  "
                  class="clickable"
                  *ngIf="form.get('Designer').value != ''"
                  (click)="clear_field('Designer')"
                >
                  <mat-icon>clear</mat-icon>
                </div>
                <img
                  class="user_select_button clickable"
                  style="
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    font-size: 11px;
                  "
                  *ngIf="!(form.get('Designer').value != '')"
                  height="18"
                  (click)="select_user('Designer')"
                  src="assets/icons/icons_user_select.png"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div style="border-bottom: #e7e7e7 1px solid"></div>
            </div>
          </div>
          <div class="row m-1 p-1">
            <div
              class="
                col-12
                d-flex
                justify-content-start
                align-items-center
                v_text_header
              "
            >
              {{ 'label.parameters' | translate }}
            </div>
            <div class="col-12 col-lg-4  p-1">
              <mat-form-field class="input_maximized   m-1">
                <mat-label class="required_form_input">{{ 'label.dictionary.departmentCode' | translate }}</mat-label>
                <mat-select
                  required="true"
                  formControlName="departmentCode"
                  
                >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let type of departmentList"
                  [value]="type.code"
                >
                  {{ type.label | translate}}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('departmentCode').hasError('required')">
                  {{ 'message.required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-4  p-1">
              <mat-form-field class="input_maximized   m-1">
                <mat-label class="required_form_input">{{ 'label.dictionary.technologyCode' | translate }}</mat-label>
                <mat-select
                  required="true"
                  formControlName="technologyCode"
                  
                >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let type of technologyList"
                  [value]="type.code"
                >
                  {{ type.label | translate}}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('technologyCode').hasError('required')">
                  {{ 'message.required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-lg-4  p-1">
              <mat-form-field class="input_maximized   m-1">
                <mat-label class="required_form_input">{{ 'label.dictionary.marketCode' | translate }}</mat-label>
                <mat-select
                  required="true"
                  formControlName="marketCode"
                >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let type of marketList"
                  [value]="type.code"
                >
                  {{ type.label | translate}}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('marketCode').hasError('required')">
                  {{ 'message.required' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6 d-flex justify-center px-0 py-2">
              <button
                class="standard-button-cancel"
                mat-button
                style="min-width: 120px"
                (click)="close()"
              >
                {{ 'label.close' | translate }}
              </button>
            </div>
            <div class="col-6 d-flex justify-center px-0 py-2">
              <button
                class="standard-button-action"
                mat-button
                style="min-width: 120px"
                (click)="createNewProject()"
              >
                {{ 'label.save' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
