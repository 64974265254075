import {CustomAction} from '../state.model';

export const SET_JWT_TOKEN = 'SET_JWT_TOKEN';
export const CLEAR_JWT_TOKEN = 'CLEAR_JWT_TOKEN';

export function token(state: string = '', action: CustomAction) {
  switch (action.type) {
    case SET_JWT_TOKEN:
      return action.payload.token;
    case CLEAR_JWT_TOKEN:
      return 'CLEARED';
    default:
      return state;
  }
}
