import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { HelpIconComponent } from './help/helpIcon/helpIcon.component';
import { HelpComponent } from './help/help.component';
import { HelpItemPageComponent } from './help/helpItemPage/helpItemPage.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

@NgModule(
  {
    exports: [
      FooterComponent,
      HelpIconComponent,
      HelpComponent,
      HelpItemPageComponent,
    ],
    declarations: [
      FooterComponent,
      HelpIconComponent,
      HelpComponent,
      HelpItemPageComponent,
    ],
    imports: [
      MatIconModule,
      TranslateModule,
      MatTooltipModule,
      CommonModule,
    ],
  },
)
export class FooterModule{}
