import {Component, Inject, Input, OnInit} from '@angular/core';
import {CmsNotificationService} from '../cms-notification.service';
import {CmmNotification} from '../../../api/cmm/models/cmm-notification';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import { isNullOrUndefined } from "src/app/shared/utilities";

@Component({
  selector: 'app-message-notification',
  templateUrl: './message-notification.component.html',
  styleUrls: ['./message-notification.component.scss']
})
export class MessageNotificationComponent implements OnInit {
  public notifications: CmmNotification[] = [];
  @Input() flag = false;
  private cache = [];

  constructor(
    private service: CmsNotificationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    this.cache = storage.get('message_cache');
    if (isNullOrUndefined(this.cache)) {
      this.cache = [];
    }
  }

  ngOnInit() {
    this.loadMessages();
  }

  private loadMessages() {
    this.service.start(
      (notifications: CmmNotification[]) => {
        this.notifications = notifications.filter(
          item => {
            return this.cache.indexOf(item.uuid) === -1;
          }
        );
      }
    );
  }

  hideMessage(uuid: string) {
    this.cache[this.cache.length] = uuid;
    this.dumpCache();
    this.loadMessages();
  }

  private dumpCache(): void {
    this.storage.set('message_cache', this.cache);
  }
}
